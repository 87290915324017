import React from "react";
import i18n from "i18next";
import Modal from "../../../modal/ModalNotify.js";
import { disconnectUser } from "../../../../utils/replaceUserSignIn.js";
import axios from "axios";
function NoCreditClient(props) {
  const { show, setShow, showModal, hideModal, user, plan } = props;

  function handleStripe(modification) {
    const token = localStorage.getItem("token");
    if (!token) {
      disconnectUser();
    }
    const config = {
      headers: { Authorization: `${token}` },
    };

    let data = {
      user: {
        stripe_id: user.stripe_id,
      },
      lng: localStorage.getItem("i18nextLng"),
      modification: modification,
    };

    axios
      .post(
        process.env.REACT_APP_URL_API + "/create-customer-portal-session",
        data,
        config
      )
      .then((response) => {
        window.location = response["data"].response;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function numberOfDay() {
    const today = new Date();
    const planDate = new Date(plan.created_at);
    const planDay = planDate.getDate();

    let nextDate = new Date();
    nextDate.setDate(planDay);
    if (today.getDate() > planDay) {
      nextDate.setMonth(nextDate.getMonth() + 1);
    }

    const diffMs = nextDate - today;
    const diffDays = Math.ceil(diffMs / (1000 * 60 * 60 * 24));
    return diffDays;
  }

  return (
    plan &&
    user && (
      <main>
        <Modal show={show} handleClose={hideModal}>
          <div className="flex flex-col gap-y-3.5 text-secondary pb-5 max-w-xl">
            <h1 className="">{i18n.t("modal.client.no_credit.title")}</h1>
            <p
              dangerouslySetInnerHTML={{
                __html: i18n.t(`modal.client.no_credit.description`, {
                  days: numberOfDay(),
                }),
              }}
            />
          </div>
          <hr className="separator-product"></hr>
          <div className="flex flex-col gap-y-5 justify-start pt-5 ">
            <button
              onClick={() => handleStripe("upgrade")}
              className="button-modal button-blue-modal"
            >
              <p className="font-semibold	">
                {i18n.t("modal.client.no_credit.action1")}
              </p>
            </button>
            <button className="flex flex-row gap-x-2.5 text-secondary w-fit p-0 ">
              <svg
                width="18"
                height="19"
                viewBox="0 0 18 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_3310_11061)">
                  <path
                    d="M6.78124 12.8962C6.97569 12.9767 7.1687 12.9767 7.4885 12.9767C7.8083 12.9767 8.0013 12.9767 8.19575 12.8962C8.45678 12.788 8.66031 12.5847 8.76842 12.3235C8.84896 12.129 8.84896 11.936 8.84896 11.6162V8.59294C8.84896 8.27314 8.84896 8.08013 8.76842 7.88568C8.66031 7.62465 8.45696 7.42113 8.19575 7.31302C8.0013 7.23248 7.8083 7.23248 7.4885 7.23248C7.1687 7.23248 6.97569 7.23248 6.78124 7.31302C6.52003 7.42113 6.31669 7.62447 6.20858 7.88568C6.12804 8.08013 6.12804 8.27314 6.12804 8.59294V11.6162C6.12804 11.936 6.12804 12.129 6.20858 12.3235C6.31669 12.5847 6.52003 12.788 6.78124 12.8962ZM7.03501 8.59294C7.03501 8.43603 7.03501 8.27386 7.04735 8.23087C7.06276 8.19496 7.0907 8.16702 7.12662 8.1516C7.16961 8.13945 7.33177 8.13945 7.4885 8.13945C7.64522 8.13945 7.80721 8.13945 7.85038 8.1516C7.88629 8.16702 7.91441 8.19496 7.92965 8.23087C7.94198 8.27386 7.94198 8.43603 7.94198 8.59294V11.6162C7.94198 11.7731 7.94198 11.9351 7.92965 11.9781C7.91441 12.0135 7.88575 12.0423 7.85038 12.0574C7.80739 12.0695 7.64522 12.0695 7.4885 12.0695C7.33177 12.0695 7.16961 12.0695 7.12662 12.0574C7.09124 12.0422 7.0624 12.0135 7.04735 11.9781C7.03501 11.9351 7.03501 11.773 7.03501 11.6161V8.59294Z"
                    fill="#486497"
                  />
                  <path
                    d="M9.80454 12.8962C9.999 12.9767 10.192 12.9767 10.5118 12.9767C10.8316 12.9767 11.0246 12.9767 11.2191 12.8962C11.4801 12.788 11.6834 12.5847 11.7917 12.3233C11.8723 12.1289 11.8723 11.9359 11.8723 11.6162V8.59294C11.8723 8.27314 11.8723 8.08013 11.7917 7.88568C11.6836 7.62465 11.4803 7.42131 11.2191 7.31302C11.0246 7.23248 10.8316 7.23248 10.5118 7.23248C10.192 7.23248 9.999 7.23248 9.80454 7.31302C9.54333 7.42113 9.33999 7.62465 9.23188 7.88568C9.15134 8.08013 9.15134 8.27314 9.15134 8.59294V11.6162C9.15134 11.936 9.15134 12.129 9.23188 12.3235C9.33999 12.5847 9.54333 12.788 9.80454 12.8962ZM10.0583 8.59294C10.0583 8.43621 10.0583 8.27404 10.0705 8.23105C10.0857 8.19568 10.1144 8.16702 10.1497 8.15178C10.1927 8.13945 10.3549 8.13945 10.5118 8.13945C10.6687 8.13945 10.8309 8.13945 10.8739 8.15178C10.9092 8.16702 10.9379 8.19568 10.9531 8.23105C10.9655 8.27404 10.9655 8.43621 10.9655 8.59312V11.6164C10.9655 11.7733 10.9655 11.9355 10.9531 11.9785C10.9379 12.0139 10.9092 12.0425 10.8739 12.0578C10.8309 12.0701 10.6687 12.0701 10.5118 12.0701C10.3549 12.0701 10.1927 12.0701 10.1497 12.0578C10.1144 12.0425 10.0857 12.0139 10.0705 11.9785C10.0583 11.9355 10.0583 11.7733 10.0583 11.6166V8.59294Z"
                    fill="#486497"
                  />
                  <path
                    d="M7.7907 3.90697H10.2092C10.4597 3.90697 10.6627 3.70399 10.6627 3.45349C10.6627 3.20298 10.4597 3 10.2094 3H7.79088C7.54038 3 7.3374 3.20298 7.3374 3.45349C7.3374 3.70399 7.54019 3.90697 7.7907 3.90697Z"
                    fill="#486497"
                  />
                  <path
                    d="M13.1687 5.93605C12.0553 4.82265 10.5747 4.20936 9.00006 4.20936C7.96375 4.20936 6.94431 4.48217 6.05203 4.99824C5.83527 5.12359 5.76108 5.40112 5.8866 5.61789C6.01195 5.83465 6.28948 5.90884 6.50625 5.78332C7.26067 5.34688 8.12301 5.11633 9.00006 5.11633C10.3326 5.11633 11.5851 5.63512 12.5273 6.57746C13.4694 7.51963 13.9884 8.77234 13.9884 10.1047C13.9884 11.437 13.4696 12.6897 12.5273 13.6319C11.5851 14.5741 10.3324 15.093 9.00006 15.093C7.66771 15.093 6.415 14.5742 5.47284 13.6319C4.53068 12.6897 4.01171 11.437 4.01171 10.1047C4.01171 9.22764 4.24244 8.36529 4.6787 7.61087C4.80404 7.3941 4.73003 7.11657 4.51326 6.99122C4.2965 6.86588 4.01915 6.93989 3.89362 7.15665C3.37755 8.04893 3.10474 9.06819 3.10474 10.1047C3.10474 11.6794 3.71803 13.1599 4.83143 14.2733C5.94483 15.3867 7.42537 16 9.00006 16C10.5747 16 12.0553 15.3867 13.1687 14.2733C14.2821 13.1599 14.8954 11.6794 14.8954 10.1047C14.8954 8.52999 14.2821 7.04945 13.1687 5.93605Z"
                    fill="#486497"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_3310_11061">
                    <rect
                      width="11.7906"
                      height="13"
                      fill="white"
                      transform="translate(3.10474 3)"
                    />
                  </clipPath>
                </defs>
              </svg>
              <p>{i18n.t("modal.client.no_credit.action2")}</p>
            </button>
          </div>
        </Modal>
        <button
          className="account-btn hidden"
          type="button"
          onClick={() => showModal}
        >
          <svg
            width="16"
            height="11"
            viewBox="0 0 16 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 0.166687C3.24357 0.166687 0.171194 5.07932 0.115625 5.16877C0.0408285 5.26299 8.08654e-05 5.37972 0 5.50002C9.4615e-05 5.60322 0.030128 5.70417 0.0864583 5.79065L0.0885417 5.79377C0.0977116 5.81269 2.67671 10.8334 8 10.8334C13.3007 10.8334 15.8759 5.86202 15.9073 5.80106C15.9094 5.79761 15.9115 5.79414 15.9135 5.79065C15.9699 5.70417 15.9999 5.60322 16 5.50002C16 5.3802 15.9596 5.26389 15.8854 5.16981L15.8844 5.16877C15.8288 5.07932 12.7564 0.166687 8 0.166687ZM8 1.76669C10.0619 1.76669 11.7333 3.43815 11.7333 5.50002C11.7333 7.56189 10.0619 9.23335 8 9.23335C5.93813 9.23335 4.26667 7.56189 4.26667 5.50002C4.26667 3.43815 5.93813 1.76669 8 1.76669ZM8 3.90002C7.57565 3.90002 7.16869 4.06859 6.86863 4.36865C6.56857 4.66871 6.4 5.07567 6.4 5.50002C6.4 5.92437 6.56857 6.33133 6.86863 6.63139C7.16869 6.93145 7.57565 7.10002 8 7.10002C8.42435 7.10002 8.83131 6.93145 9.13137 6.63139C9.43143 6.33133 9.6 5.92437 9.6 5.50002C9.6 5.07567 9.43143 4.66871 9.13137 4.36865C8.83131 4.06859 8.42435 3.90002 8 3.90002Z"
              fill="white"
            />
          </svg>
          <div>{i18n.t("sidebar.payment.subscription")}</div>
        </button>
      </main>
    )
  );
}

export default NoCreditClient;
