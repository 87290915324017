import React, { useState, useEffect } from "react";
import { ConfirmErasePromptsHistorical } from "../modal/confirm/confirmErasePromptsHistorical";
import { ConfirmErasePrompt } from "../modal/confirm/confirmErasePrompt";

import { isTokenValid } from "../../utils/tokenUtils";
import { disconnectUser } from "../../utils/replaceUserSignIn";

import axios from "axios";
import ReactPaginate from "react-paginate";
import i18n from "i18next";

import { ReturnImage } from "../../components/product/component/sharedFunction.js";

import "./styles/history.css";
import "../../assets/Common.css";

function formatDate(newDate, format) {
  let date = new Date(newDate);
  let formatted = "";
  if (format === "date") {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const monthName = ("0" + month.toString()).slice(-2);
    const dayName = ("0" + date.getDate().toString()).slice(-2);
    formatted = `${dayName}/${monthName}/${year}`;
  } else {
    const hours = date.getHours();
    const minutes =
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    formatted = `${hours}:${minutes}`;
  }
  return formatted.toString();
}

function handleNavigatePrompt(id) {
  window.location = process.env.REACT_APP_URL + `/history/${id}`;
}

const handleDeletePrompt = (id) => {
  const token = localStorage.getItem("token");
  if (!token) {
    window.location.replace(process.env.REACT_APP_URL + `/users/sign_in`);
  }
  const config = {
    headers: { Authorization: `${token}` },
  };
  axios
    .delete(process.env.REACT_APP_URL_API + "/api/v1/prompts/" + id, config)
    .then((response) => {
      if (response.data === "ok") {
        document.getElementById("history_" + id).remove();
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

const Post = (currentItems) => {
  return (
    currentItems.currentItems &&
    currentItems.currentItems.map((post, index) => (
      <div key={post.id}>
        <div className="history-line" id={`history_${post.id}`}>
          <div className="flex flex-row gap-x-3.5 items-center">
            <ReturnImage
              icon={post.product}
              classList="bg-img-history-paginated"
            ></ReturnImage>
            <div className="flex flex-row m-auto gap-x-2">
              <span className="text-secondary text-md">{post.title}</span>
            </div>
          </div>
          <div className="flex flex-row gap-x-8 items-center">
            {post && (
              <div
                className={`rounded history-status-${
                  post.status ? post.status : "error"
                }`}
              >
                {post.product === "blogv2"
                  ? i18n.t(`prompt.${post.status}`)
                  : post.status === "valid"
                  ? i18n.t("prompt.available")
                  : post.status === "error"
                  ? i18n.t("prompt.error")
                  : i18n.t("prompt.writing")}
              </div>
            )}
            <div className="text-secondary m-auto history-date">
              {formatDate(post.created_at, "date")}
            </div>
            <div className="text-secondary m-auto history-date">
              {formatDate(post.created_at, "hours")}
            </div>
            <div className="flex flex-row gap-x-3">
              <div className="history-consult border border-gray-custom-input rounded-custom-input">
                <button
                  disabled={
                    (post && post.product !== "blogv2" && post.output === "") ||
                    (post &&
                      post.product === "blogv2" &&
                      post.state !== "summary" &&
                      post.state !== "end") ||
                    (post && post.product === "blogv2" && post.output === "")
                  }
                  onClick={() => handleNavigatePrompt(post.id)}
                  className="history-btn bg-white padding-see border btn h-100"
                >
                  <svg
                    width="16"
                    height="12"
                    viewBox="0 0 16 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8 0.346191C3.24358 0.346191 0.171194 5.41234 0.115625 5.50459C0.0408285 5.60175 8.08654e-05 5.72214 0 5.84619C9.4615e-05 5.95262 0.030128 6.05673 0.0864583 6.1459C0.0871491 6.14698 0.0878436 6.14805 0.0885417 6.14912C0.0977116 6.16864 2.67671 11.3462 8 11.3462C13.3007 11.3462 15.8759 6.2195 15.9073 6.15664C15.9094 6.15309 15.9115 6.1495 15.9135 6.1459C15.9699 6.05673 15.9999 5.95262 16 5.84619C16 5.72263 15.9596 5.60268 15.8854 5.50566L15.8844 5.50459C15.8288 5.41234 12.7564 0.346191 8 0.346191ZM8 1.99619C10.0619 1.99619 11.7333 3.71989 11.7333 5.84619C11.7333 7.97249 10.0619 9.69619 8 9.69619C5.93813 9.69619 4.26667 7.97249 4.26667 5.84619C4.26667 3.71989 5.93813 1.99619 8 1.99619ZM8 4.19619C7.57565 4.19619 7.16869 4.37003 6.86863 4.67947C6.56857 4.9889 6.4 5.40858 6.4 5.84619C6.4 6.2838 6.56857 6.70348 6.86863 7.01292C7.16869 7.32235 7.57565 7.49619 8 7.49619C8.42435 7.49619 8.83131 7.32235 9.13137 7.01292C9.43143 6.70348 9.6 6.2838 9.6 5.84619C9.6 5.40858 9.43143 4.9889 9.13137 4.67947C8.83131 4.37003 8.42435 4.19619 8 4.19619Z"
                      fill="#486497"
                    />
                  </svg>
                </button>
              </div>
              <ConfirmErasePrompt
                post={post}
                accept={() => handleDeletePrompt(post.id)}
              />
            </div>
          </div>
        </div>
        {index !== currentItems.currentItems.length - 1 && (
          <hr className="separator-product mx-4"></hr>
        )}
      </div>
    ))
  );
};

export default function PromptsListPaginated({
  itemsPerPage,
  setItemsPerPage,
  selectedProject,
  user_id,
  classes = "user",
}) {
  const [posts, setPosts] = useState("");
  const [endOffset, setEndOffset] = useState("");
  const [currentItems, setCurrentItems] = useState("");
  const [pageCount, setPageCount] = useState("");
  const [itemOffset, setItemOffset] = useState(0);
  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      window.location.replace(process.env.REACT_APP_URL + `/users/sign_in`);
    }
    const config = {
      headers: { Authorization: `${token}` },
      params: {
        user: user_id,
      },
    };
    axios
      .get(process.env.REACT_APP_URL_API + "/api/v1/prompts", config)
      .then((response) => {
        if (selectedProject !== null) {
          const data = response.data.filter(
            (post) => parseInt(post.project) === selectedProject.id
          );
          if (data.length === 0) {
            setHidden(true);
          }
          const dataWithStatus = data.map((item) => ({
            ...item,
            status: createStatusForPost(item),
          }));
          setPosts(dataWithStatus);
          const newEndOffset = itemOffset + itemsPerPage;

          setEndOffset(newEndOffset);
          setCurrentItems(dataWithStatus.slice(itemOffset, newEndOffset));
          setPageCount(Math.ceil(dataWithStatus.length / itemsPerPage));
        }
      })
      .catch((error) => {
        if (error["response"]["status"] === 401 && !isTokenValid()) {
          disconnectUser();
        }
      });
  }, [endOffset, itemOffset, itemsPerPage, selectedProject, user_id]);

  const createStatusForPost = (post) => {
    if (post.product === "blogv2") {
      if (post.output === "") {
        return "redaction";
      } else {
        return post.state;
      }
    } else {
      if (
        post.output === "" &&
        new Date(post.updated_at) < new Date(new Date().getTime() - 5 * 60000)
      ) {
        return "error";
      }
      if (
        post.output === "" &&
        new Date(post.updated_at) > new Date(new Date().getTime() - 5 * 60000)
      ) {
        return "wip";
      }
      if (post.output !== "") {
        return "valid";
      }
    }
  };

  const handlePageClick = (event) => {
    const newOffset = event.selected * itemsPerPage;
    setItemOffset(newOffset);
  };

  function deleteByProject(project) {
    const token = localStorage.getItem("token");
    if (!token) {
      window.location.replace(process.env.REACT_APP_URL + `/users/sign_in`);
    }
    axios
      .delete(process.env.REACT_APP_URL_API + "/api/v1/prompts_all", {
        headers: {
          Authorization: token,
        },
        data: {
          project: project,
        },
      })
      .then((response) => {
        if (response.data === "ok") {
          const elementsToRemove = document.querySelectorAll(".history-line");
          elementsToRemove.forEach((element) => {
            element.remove();
          });
          setHidden(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleFilterChange(event) {
    let filteredUsers;

    const filterText = event.target.value.toLowerCase();
    if (filterText !== "") {
      filteredUsers = posts.filter((post) => {
        return post.title === null
          ? ""
          : post.title.toLowerCase().includes(filterText);
      });
    } else {
      filteredUsers = posts;
    }
    setEndOffset(itemOffset + itemsPerPage);
    setCurrentItems(filteredUsers.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(filteredUsers.length / itemsPerPage));
  }

  return (
    <div className={classes === "user" ? "container" : ""}>
      <div
        className={
          classes === "user"
            ? "flex flex-row justify-between"
            : "pb-2 flex flex-row justify-between"
        }
      >
        <h1
          className={
            classes === "user"
              ? "text-secondary pb-2.5"
              : "text-secondary pb-2.5"
          }
        >{`${
          classes === "user"
            ? i18n.t("prompt.my_document")
            : i18n.t("prompt.document_info")
        } (${selectedProject && selectedProject.name})`}</h1>
      </div>
      {(posts.length > 0 || hidden === false) && (
        <div className="flex flex-col gap-y-2.5">
          <div className="flex flex-col gap-y-6">
            <div className="flex flex-row gap-x-12 justify-between">
              <input
                name="search"
                type="text"
                placeholder="recherche un document"
                className="admin-input w-full"
                onChange={(e) => handleFilterChange(e)}
              />
              {posts.length > 0 && (
                <ConfirmErasePromptsHistorical
                  accept={() => deleteByProject(selectedProject)}
                ></ConfirmErasePromptsHistorical>
              )}
            </div>
            <hr className="separator-product"></hr>
            <div className="border rounded-2xl border-gray-custom-input flex flex-col bg-white">
              <Post
                key={currentItems.id}
                currentItems={currentItems}
                removeFunction={handleDeletePrompt}
              />
              {currentItems && currentItems.length > 1 && (
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={i18n.t("prompt.next_button")}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel={i18n.t("prompt.previous_button")}
                  renderOnZeroPageCount={null}
                  containerClassName="paginate"
                />
              )}
            </div>
          </div>

          <div className="w-fit">
            <div className="items-per-page">
              <p className="text-secondary my-auto font-normal">
                {i18n.t("history.display")}
              </p>
              <div className="flex flex-row bg-white gap-x-2.5">
                <button
                  onClick={() => setItemsPerPage(25)}
                  className={`bg-white btn-per-page ${
                    itemsPerPage === 25
                      ? "text-secondary"
                      : "text-gray opacity-gray"
                  }`}
                >
                  25
                </button>
                <hr className="display-btn"></hr>
                <button
                  onClick={() => setItemsPerPage(50)}
                  className={`bg-white btn-per-page ${
                    itemsPerPage === 50
                      ? "text-secondary"
                      : "text-gray opacity-gray"
                  }`}
                >
                  50
                </button>
                <hr className="display-btn"></hr>
                <button
                  onClick={() => setItemsPerPage(100)}
                  className={`bg-white btn-per-page ${
                    itemsPerPage === 100
                      ? "text-secondary"
                      : "text-gray opacity-gray"
                  }`}
                >
                  100
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {hidden === true && (
        <div>
          <div className="flex flex-col justify-center h-3/6	">
            <h1 className="text-primary text-2xl text-center">
              {i18n.t("history.nothing")}
            </h1>
          </div>
        </div>
      )}
    </div>
  );
}
