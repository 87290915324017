import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

import Project from "./Project.jsx";
import ModalSidebar from "../components/modal/ModalSideBar.js";

import "../assets/Common.css";

const Projects = ({
  projects,
  selectedProject,
  setSelectedProject,
  setCurrentProject,
  classes = "user",
}) => {
  const [show, setShow] = useState(false);
  const [project, setProject] = useState("");
  const [projectList, setProjectList] = useState(projects);
  const [validate, setValidate] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const modalRef = useRef();
  const { t } = useTranslation();

  useEffect(() => {
    const project = selectedProject;
    if (project === null) {
      if (projectList != null) {
        let projectStorage = localStorage.getItem("project");
        if (projectStorage === null) {
          setSelectedProject(projectList[0]);
        } else {
          const filteredProjects = projectList.filter(
            (project) => project.id === parseInt(projectStorage)
          );
          if (filteredProjects[0]) {
            setSelectedProject(filteredProjects[0]);
            localStorage.setItem("project", filteredProjects[0].id);
          } else {
            setSelectedProject(projectList[0]);
          }
        }
      }
    } else {
      setSelectedProject(project);
      localStorage.setItem("project", project.id);
    }

    function handleClickOutside(event) {
      if (
        isMounted &&
        modalRef.current &&
        !modalRef.current.contains(event.target) &&
        !event.target.classList.contains("project-btn") &&
        !event.target.classList.contains("project-name") &&
        !event.target.classList.contains("my-project")
      ) {
        setShow(false);
        const header = document.getElementById("header");
        if (header) {
          header.classList.add("z-50");
        }
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    setIsMounted(true);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [projectList, setSelectedProject, modalRef, isMounted, selectedProject]);

  function showModal() {
    setShow(!show);
    const header = document.getElementById("header");
    if (header) {
      header.classList.remove("z-50");
    }
  }

  function handleSelect(e) {
    const project_name = document.getElementById(e.target.id).innerHTML;
    const filteredProjects = projectList.filter(
      (project) => project.name === project_name
    );
    setSelectedProject(filteredProjects[0]);
  }

  function handleChange(e) {
    const inputValue = e.target.value;
    setProject(inputValue);
    if (inputValue.length > 0) {
      setValidate(true);
    } else {
      setValidate(false);
    }
  }

  function handleNewProject(e) {
    e.preventDefault();
    let token = localStorage.getItem("token");
    if (!token) {
      return;
    }
    const config = {
      headers: { Authorization: `${token}` },
    };
    let data = { project: { name: project } };
    axios
      .post(process.env.REACT_APP_URL_API + "/api/v1/projects", data, config, {
        validateStatus: function (status) {
          return status >= 200 && status < 500; // Catch errors from 200 to 499
        },
      })
      .then((response) => {
        if (response.status !== 204) {
          setProjectList(projectList.concat(response.data));
          document
            .getElementById("new-directory")
            .classList.remove("border-error");
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 404) {
          document
            .getElementById("new-directory")
            .classList.add("border-error");
        }
      });
  }

  return (
    <div>
      {show && classes === "user" && <div className="overlay"></div>}

      <div
        className={
          classes === "user" ? "project-sidebar" : "project-admin-list"
        }
      >
        <button
          className={
            classes === "user" ? "project-btn" : "project-btn bg-white"
          }
          type="button"
          onClick={showModal}
        >
          <div className="flex flex-col gap-y-2">
            <p className="my-project text-primary text-xs my-auto">
              {t("sidebar.document.my")}
            </p>
            <h3
              id="current-project"
              className="project-name text-secondary font-semibold text-base"
            >
              {selectedProject && selectedProject.name}
            </h3>
          </div>
          <div>
            <svg
              width="8"
              height="13"
              viewBox="0 0 8 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 8.5L4 11.5L1 8.5"
                stroke="#8E90AE"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M1 4L4 1L7 4"
                stroke="#8E90AE"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </button>
      </div>
      <ModalSidebar show={show} classes={classes}>
        <div ref={modalRef} className="modal-content">
          <div className="project-modal">
            <div className="flex flex-col gap-y-4">
              <h1 className="text-secondary">{t("sidebar.document.title")}</h1>
              <p
                className="text-secondary"
                dangerouslySetInnerHTML={{
                  __html: t(`sidebar.document.description`),
                }}
              />
              <hr className="separator-product my-3"></hr>
              <div className="flex flex-col gap-y-6">
                <div>
                  {projectList &&
                    projectList.map((project, index) => {
                      return (
                        <Project
                          key={index}
                          id={project.id}
                          text={project.name}
                          handleSelect={handleSelect}
                          setSelectedProject={setSelectedProject}
                          selectedProject={selectedProject}
                          length={projectList.length}
                          setCurrentProject={setCurrentProject}
                          projectList={projectList}
                        ></Project>
                      );
                    })}
                </div>
                {classes !== "admin" && (
                  <form
                    onSubmit={(e) => handleNewProject(e)}
                    className="flex flex-col gap-y-2"
                  >
                    <input
                      id="new-directory"
                      className="flex w-full border ctn-document-2"
                      placeholder={t("sidebar.document.placeholder")}
                      value={project}
                      onInput={handleChange}
                      maxLength="20"
                    ></input>
                    <button className="hidden" type="submit">
                      <svg
                        style={{
                          display: validate ? "block" : "none",
                          cursor: "pointer",
                        }}
                        width="14"
                        height="14"
                        viewBox="0 0 13 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.6993 10.3137L0.158451 6.77284C-0.0119642 6.60242 -0.0119642 6.33733 0.158451 6.17638L1.06733 5.2675C1.14307 5.19176 1.24722 5.15389 1.36083 5.15389C1.46497 5.15389 1.56911 5.19176 1.65432 5.27697L3.58569 7.20834C3.80345 7.4261 4.15374 7.4261 4.36203 7.20834L11.2639 0.306522C11.4343 0.136107 11.6994 0.136107 11.8603 0.306522L12.7692 1.2154C12.9396 1.38582 12.9396 1.64144 12.7692 1.81186L4.28629 10.3137C4.21055 10.3894 4.10641 10.4368 3.9928 10.4368C3.87919 10.4368 3.77504 10.3894 3.6993 10.3137Z"
                          fill="#4D5072"
                        />
                      </svg>
                    </button>
                    <p className="text-xs text-primary">
                      {t("sidebar.document.validate")}
                    </p>
                  </form>
                )}
              </div>
              <hr className="separator-product my-3"></hr>
              <a
                href="https://screebot.com/docs/"
                target="_blank"
                className="flex flex-row gap-x-2"
                rel="noreferrer"
              >
                <svg
                  className="my-auto"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.7092 6.96515C10.7092 6.0226 9.94247 5.25586 8.99991 5.25586C8.05736 5.25586 7.29062 6.0226 7.29062 6.96515C7.29062 7.23493 7.50921 7.45352 7.77899 7.45352C8.04876 7.45352 8.26736 7.23493 8.26736 6.96515C8.26736 6.56117 8.59593 6.2326 8.99991 6.2326C9.40389 6.2326 9.73247 6.56117 9.73247 6.96515C9.73247 7.22692 9.59768 7.4629 9.37205 7.59632C8.66079 8.0171 8.51154 8.50332 8.51154 8.83717V9.65119C8.51154 9.92096 8.73014 10.1396 8.99991 10.1396C9.26969 10.1396 9.48828 9.92096 9.48828 9.65119V8.83717C9.48828 8.7309 9.63792 8.57384 9.86941 8.43691C10.3875 8.1304 10.7092 7.56643 10.7092 6.96515Z"
                    fill="#486497"
                  />
                  <path
                    d="M9.10459 12.2471C9.45954 12.1894 9.70057 11.855 9.64295 11.5C9.58534 11.1451 9.25089 10.9041 8.89595 10.9617C8.541 11.0193 8.29997 11.3537 8.35759 11.7087C8.4152 12.0636 8.74965 12.3047 9.10459 12.2471Z"
                    fill="#486497"
                  />
                  <path
                    d="M15.5116 8.51153C15.2419 8.51153 15.0233 8.73013 15.0233 8.9999C15.0233 11.9309 15.0233 13.4021 14.2126 14.2126C13.4021 15.0231 11.9309 15.0231 8.9999 15.0231C6.0689 15.0231 4.59774 15.0231 3.78724 14.2124C2.97674 13.4019 2.97674 11.9307 2.97674 8.99971C2.97674 6.06871 2.97674 4.59754 3.78724 3.78704C4.59774 2.97654 6.0689 2.97654 8.9999 2.97654C11.9309 2.97654 13.4021 2.97654 14.2126 3.78704C14.7068 4.28127 14.9168 4.99468 14.9879 6.41936C15.0014 6.68874 15.2309 6.89659 15.4999 6.88272C15.7693 6.86924 15.9768 6.6401 15.9635 6.37071C15.8937 4.97202 15.6869 3.87983 14.9033 3.09629C14.3692 2.56221 13.6705 2.26196 12.6377 2.12326C11.7186 2 10.5783 2 8.9999 2C7.42149 2 6.28125 2 5.36233 2.12366C4.32952 2.26255 3.63076 2.5626 3.09649 3.09668C2.56221 3.63076 2.26235 4.32952 2.12366 5.36233C2 6.28144 2 7.42169 2 8.9999C2 10.5781 2 11.7186 2.12366 12.6375C2.26255 13.6703 2.5626 14.369 3.09668 14.9031C3.63076 15.4372 4.32952 15.7375 5.36252 15.8762C6.28144 15.9998 7.42188 15.9998 9.0001 15.9998C10.5783 15.9998 11.7188 15.9998 12.6377 15.8762C13.6705 15.7373 14.3692 15.4372 14.9033 14.9031C15.4374 14.369 15.7376 13.6703 15.8763 12.6375C16 11.7186 16 10.5781 16 8.9999C16 8.73013 15.7814 8.51153 15.5116 8.51153Z"
                    fill="#486497"
                  />
                </svg>
                <p className="text-primary">
                  {t("sidebar.document.know_more")}
                </p>
              </a>
            </div>
          </div>
        </div>
      </ModalSidebar>
    </div>
  );
};

export default Projects;
