import React from "react";
import { useNavigate } from "react-router-dom";

import "../styles/admin.css";

const AdminPanelButton = ({ panel, setPanel }) => {
  const navigate = useNavigate();

  const urlName = (index) => {
    switch (index) {
      case 0:
        return "dashboard";
      case 1:
        return "users";
      case 2:
        return "affiliation";
      default:
        return "";
    }
  };
  const handleChange = (e, index) => {
    setPanel(index);
    navigate(`/users/admin?${urlName(index)}`);
  };

  return (
    <div className="flex flex-row gap-x-12">
      <p
        className={`font-medium text-base text-secondary cursor-pointer my-auto ${
          panel !== 0 ? "opacity-60" : "cursor-not-allowed"
        }`}
        onClick={(e) => handleChange(e, 0)}
      >
        Tableau de bord
      </p>
      <p
        className={`font-medium text-base text-secondary cursor-pointer my-auto ${
          panel !== 1 ? "opacity-60" : "cursor-not-allowed"
        }`}
        onClick={(e) => handleChange(e, 1)}
      >
        Utilisateurs
      </p>
      <p
        className={`font-medium text-base text-secondary cursor-pointer my-auto ${
          panel !== 2 ? "opacity-60" : "cursor-not-allowed"
        }`}
        onClick={(e) => handleChange(e, 2)}
      >
        Affiliation
      </p>
    </div>
  );
};

export default AdminPanelButton;
