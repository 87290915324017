import './modal.css';

const Modal = ({ handleClose, show, children }) => {
  const showHideClassName = show ? "modal block" : "modal hidden";

  return (
    <div className={showHideClassName} onClick={handleClose}>
      <section className="modal-main-pricing" style={{ background: "#F5F5F5"}}>
        {children}
        <button type="button" className="close-modal" onClick={handleClose}>
          <svg width="30" height="30" viewBox="0 0 40 39" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.25 0C31.0022 0 39.7184 8.71616 39.7184 19.4684C39.7184 30.2206 31.0022 38.9368 20.25 38.9368C9.49778 38.9368 0.781616 30.2206 0.781616 19.4684C0.781616 8.71616 9.49778 0 20.25 0ZM23.6919 26.3513C24.6418 27.3012 26.183 27.302 27.1337 26.3513C28.0844 25.4006 28.0836 23.8601 27.1337 22.9094C26.9447 22.7212 25.1682 20.9447 23.6919 19.4684C25.1682 17.992 26.9447 16.2155 27.1337 16.0265C28.0836 15.0766 28.0844 13.5354 27.1337 12.5847C26.1838 11.6348 24.6426 11.6348 23.6919 12.5847C23.5029 12.7737 21.7264 14.5502 20.25 16.0265C18.7737 14.5502 16.9972 12.7737 16.8082 12.5847C15.8583 11.6348 14.317 11.634 13.3663 12.5847C12.4156 13.5354 12.4164 15.0758 13.3663 16.0265C13.5545 16.2147 15.3318 17.992 16.8082 19.4684C15.3318 20.9447 13.5553 22.7212 13.3663 22.9102C12.4164 23.8601 12.4164 25.4014 13.3663 26.3521C14.317 27.3028 15.8575 27.302 16.8082 26.3521C16.9964 26.1639 18.7737 24.3866 20.25 22.9102C21.7264 24.3866 23.5029 26.1631 23.6919 26.3513Z" fill="#645FEB"/>
          </svg>
        </button>
      </section>
    </div>
  );
};

export default Modal;