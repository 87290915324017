import React, { Component } from "react";
import Modal from "../../../modal/Modal.js";
import i18n from "i18next";
import "../../../../sidebar/Sidebar.css";

class Pricing extends Component {
  constructor() {
    super();
    this.state = {
      show: false,
    };
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  showModal = () => {
    document.getElementById("header").classList.remove("z-50");
    this.setState({ show: true });
  };

  hideModal = () => {
    document.getElementById("header").classList.add("z-50");
    this.setState({ show: false });
  };

  render() {
    const { user } = this.props;
    return (
      <main className="flex flex-row gap-x-4">
        <div
          className="flex flex-row gap-x-4 cursor-pointer"
          type="button"
          onClick={this.showModal}
        >
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.58703 13.3551C7.85681 13.3551 8.07541 13.1365 8.07541 12.8667V10.9102C8.07541 10.9102 8.07541 10.9097 8.07541 10.9093V10.9075C8.07462 10.8436 8.06173 10.7829 8.03868 10.727C8.017 10.6743 7.98554 10.6246 7.94472 10.5807C7.94472 10.5807 7.94433 10.5803 7.94413 10.5801C7.94393 10.5799 7.94374 10.5795 7.94335 10.5793C7.93553 10.5711 7.92753 10.5631 7.91932 10.5555C7.87654 10.5158 7.82848 10.4852 7.7775 10.4635C7.72026 10.4392 7.65755 10.4256 7.59152 10.425C7.59113 10.425 7.59055 10.425 7.59015 10.425C7.58976 10.425 7.58918 10.425 7.58879 10.425C7.5882 10.425 7.5882 10.4252 7.58722 10.425H5.63372C5.36394 10.425 5.14534 10.6436 5.14534 10.9134C5.14534 11.1831 5.36394 11.4017 5.63372 11.4017H6.40809L4.96269 12.8471C4.77203 13.0378 4.77203 13.347 4.96269 13.5379C5.05802 13.6332 5.18304 13.6809 5.30807 13.6809C5.43309 13.6809 5.55812 13.6332 5.65345 13.5379L7.09885 12.0925V12.8671C7.09885 13.1368 7.31745 13.3554 7.58722 13.3554L7.58703 13.3551Z"
              fill="#486497"
            />
            <path
              d="M15.5118 9.01162C15.242 9.01162 15.0234 9.23022 15.0234 9.5C15.0234 12.431 15.0234 13.9022 14.2127 14.7127C13.4022 15.5232 11.931 15.5232 9 15.5232C6.06896 15.5232 4.59777 15.5232 3.78726 14.7125C2.97675 13.902 2.97675 12.4308 2.97675 9.4998C2.97675 6.56876 2.97675 5.09758 3.78726 4.28707C4.59777 3.47656 6.06896 3.47656 9 3.47656C11.931 3.47656 13.4022 3.47656 14.2127 4.28707C14.707 4.78131 14.917 5.49473 14.9881 6.91942C15.0016 7.18881 15.2311 7.39666 15.5001 7.38279C15.7695 7.36931 15.9769 7.14016 15.9637 6.87078C15.8939 5.47207 15.687 4.37986 14.9035 3.59631C14.3694 3.06222 13.6706 2.76197 12.6378 2.62327C11.7187 2.5 10.5784 2.5 9 2.5C7.42157 2.5 6.28131 2.5 5.36238 2.62366C4.32956 2.76255 3.63079 3.06261 3.0965 3.5967C2.56222 4.13079 2.26236 4.82956 2.12366 5.86238C2 6.7815 2 7.92176 2 9.5C2 11.0782 2 12.2187 2.12366 13.1376C2.26255 14.1704 2.56261 14.8692 3.0967 15.4033C3.63079 15.9374 4.32956 16.2376 5.36257 16.3763C6.2815 16.5 7.42196 16.5 9.0002 16.5C10.5784 16.5 11.7189 16.5 12.6378 16.3763C13.6706 16.2374 14.3694 15.9374 14.9035 15.4033C15.4376 14.8692 15.7378 14.1704 15.8765 13.1376C16.0002 12.2187 16.0002 11.0782 16.0002 9.5C16.0002 9.23022 15.7816 9.01162 15.5118 9.01162Z"
              fill="#486497"
            />
          </svg>
          <p className="text-secondary ">{i18n.t("account.all_plan")}</p>
        </div>
        <Modal show={this.state.show} handleClose={this.hideModal}>
          <div
            style={{
              paddingBottom: "2rem",
              paddingTop: "2rem",
              textAlign: "center",
            }}
            className="modal-title"
          >
            {i18n.t("sidebar.payment.package")}
          </div>
          <stripe-pricing-table
            pricing-table-id={`${process.env.REACT_APP_STRIPE_PRICING_ID}`}
            publishable-key={`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`}
            customer-email={user.email}
          ></stripe-pricing-table>
        </Modal>
      </main>
    );
  }
}

export default Pricing;
