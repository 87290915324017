import React, { useEffect, useState } from "react";
import axios from "axios";
import MyTransaction from "../dropzone/Transaction.jsx";
import Select from "react-dropdown-select";
import { NotifyTransactionReceived } from "../../../product/component/Toast.jsx";

import { formatDate } from "../../../product/component/sharedFunction.js";

import "../../styles/affiliateStyle.css";
import "../../../../assets/Common.css";

function Transaction(props) {
  const { from, transaction, view } = props;

  const [tmpTransaction, setTmpTransaction] = useState(0);
  const [file, setFile] = useState(null);
  const options = {
    status: [
      { value: "invoice_not_received", label: "facture non reçue" },
      { value: "invoice_received", label: "facture reçue" },
      { value: "payed", label: "payé" },
      { value: "problem", label: "problème" },
    ],
  };

  useEffect(() => {
    setTmpTransaction(transaction);
  }, [transaction]);

  const handleFileChange = (file) => {
    setFile(file);
  };

  const handleSendEmail = () => {
    if (file) {
      const token = localStorage.getItem("token");
      const config = {
        headers: { Authorization: `${token}` },
      };
      const formData = new FormData();
      formData.append("pdfFile", file);
      formData.append("counter", transaction.amount);
      axios
        .post(
          process.env.REACT_APP_URL_API + "/api/v1/transactions/send_email",
          formData,
          config,
          {
            validateStatus: function (status) {
              return status >= 200 && status < 500; // Catch errors from 200 to 499
            },
          }
        )
        .then((response) => {
          NotifyTransactionReceived();
          updateStatus(tmpTransaction.id, "invoice_received");
          transaction.status = "invoice_received";
          setTmpTransaction(transaction);
        })
        .catch((error) => {
          console.error("Error sending email:", error);
        });
    }
  };

  function updateStatus(id, e) {
    const value = e[0].value === undefined ? e : e[0].value;
    let data = { transaction: { status: value } };
    const token = localStorage.getItem("token");
    if (!token) {
      window.location.replace(process.env.REACT_APP_URL + `/users/sign_in`);
    }
    const config = {
      headers: { Authorization: `${token}` },
    };

    axios
      .put(
        process.env.REACT_APP_URL_API + "/api/v1/transactions/" + id,
        data,
        config
      )
      .then((response) => {
        setTmpTransaction((prevTransaction) => ({
          ...prevTransaction,
          status: value,
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function status_for_humain(status) {
    switch (status) {
      case "invoice_not_received":
        return "facture non reçue";
      case "invoice_received":
        return "facture reçue";
      case "payed_in_progress":
        return "paiement en cours";
      case "payed":
        return "payé";
      case "problem":
        return "problème";
      default:
        return "problème";
    }
  }

  return (
    (from === "big_card" && (
      <div className="flex flex-row justify-between">
        <div className="flex flex-col gap-y-1">
          <div className="flex flex-row gap-x-4">
            <span className="label text-lg text-secondary  uppercase font-semibold">
              {tmpTransaction &&
                formatDate(tmpTransaction.created_at, "month_year")}
            </span>
            <div className="label text-md text-gray my-auto">
              {status_for_humain(tmpTransaction.status)}
            </div>
          </div>
          <span
            className={`label text-md  text-gray ${
              tmpTransaction.status === "invoice_received" ||
              tmpTransaction.status === "payed"
                ? "text-cross"
                : ""
            }`}
          >
            Montant à facturer : {transaction.amount}{" "}
            <span className="text-gray">€ hors taxes</span>
          </span>{" "}
        </div>
        <div className="my-auto">
          {view !== "admin" && (
            <div className="flex flex-row gap-x-4">
              <MyTransaction
                disabled={
                  tmpTransaction.status === "invoice_received" ||
                  tmpTransaction.status === "payed"
                }
                onPdfChange={handleFileChange}
              ></MyTransaction>
              <button
                onClick={handleSendEmail}
                disabled={
                  file === null ||
                  tmpTransaction.status === "invoice_received" ||
                  tmpTransaction.status === "payed"
                }
                className={`flex flex-row gap-x-4 bg-secondary text-white rounded-custom-input btn btn-padding ${
                  tmpTransaction.status ===
                  (tmpTransaction.status === "invoice_received" ||
                    tmpTransaction.status === "payed")
                    ? "cursor-not-allowed"
                    : ""
                }`}
              >
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.79378 12.2108C2.06357 11.4827 1.51244 10.5951 1.18383 9.61772C0.855226 8.64039 0.758121 7.60009 0.900173 6.57883C1.04222 5.55756 1.41955 4.58325 2.00241 3.73267C2.58527 2.88209 3.35773 2.1785 4.25891 1.67735C5.15659 1.19235 6.15282 0.917834 7.17224 0.874562C8.19166 0.831291 9.20757 1.0204 10.1431 1.42759C11.0787 1.83477 11.9094 2.44937 12.5724 3.22488C13.2354 4.0004 13.7133 4.91652 13.97 5.90396C14.2373 7.01673 14.2164 8.17929 13.9091 9.2817C13.6018 10.3841 13.0184 11.3899 12.2141 12.2041C11.836 12.5896 12.4286 13.183 12.8067 12.7966C14.0326 11.5694 14.7951 9.95518 14.9643 8.22889C15.1334 6.5026 14.6987 4.77107 13.7343 3.32929C12.7699 1.88752 11.3354 0.824696 9.67518 0.321905C8.01496 -0.180887 6.23177 -0.0925444 4.62939 0.571882C3.48979 1.04378 2.48421 1.78949 1.70177 2.74294C0.919333 3.69638 0.384199 4.8281 0.143798 6.03781C-0.0966037 7.24751 -0.0348476 8.49784 0.323592 9.67797C0.682031 10.8581 1.32609 11.9316 2.19867 12.8033C2.57669 13.1897 3.16928 12.5963 2.79126 12.2108L2.79378 12.2108Z"
                    fill="white"
                  />
                  <path
                    d="M4.68974 8.67644L7.20427 6.1621C7.28165 6.08314 7.38709 6.03795 7.49763 6.03638C7.55373 6.03643 7.60927 6.04756 7.66105 6.06913C7.71283 6.09071 7.75984 6.1223 7.79938 6.1621L10.3139 8.67644C10.3928 8.75535 10.4372 8.86237 10.4372 8.97397C10.4372 9.08556 10.3928 9.19259 10.3139 9.2715C10.235 9.35041 10.128 9.39474 10.0164 9.39474C9.90475 9.39474 9.79772 9.35041 9.71881 9.2715L7.9251 7.47794L7.9251 14.5809C7.92337 14.6915 7.87867 14.7971 7.80044 14.8753C7.72222 14.9536 7.61662 14.9983 7.50601 15C7.39487 15 7.28827 14.9558 7.20967 14.8773C7.13108 14.7987 7.08693 14.6921 7.08693 14.5809L7.08693 7.46536L5.28484 9.27569C5.24577 9.31476 5.19938 9.34575 5.14832 9.3669C5.09727 9.38804 5.04255 9.39893 4.98729 9.39893C4.93203 9.39893 4.87731 9.38804 4.82626 9.3669C4.7752 9.34575 4.72881 9.31476 4.68974 9.27569C4.65066 9.23661 4.61967 9.19023 4.59852 9.13918C4.57737 9.08813 4.56649 9.03341 4.56649 8.97816C4.56649 8.9229 4.57737 8.86818 4.59852 8.81713C4.61967 8.76608 4.65066 8.7197 4.68974 8.68063L4.68974 8.67644Z"
                    fill="white"
                  />
                </svg>
                envoyer la facture
              </button>
            </div>
          )}
          {view === "admin" && tmpTransaction && (
            <Select
              name="type"
              id="type"
              values={[
                options.status.find(
                  (opt) => opt.value === tmpTransaction.status
                ),
              ]}
              options={options.status}
              onChange={(e) => updateStatus(tmpTransaction.id, e)}
            />
          )}
        </div>
      </div>
    )) ||
    (from === "small_card" && (
      <div className="flex flex-row justify-between small-card-screebot">
        <div className="flex flex-col gap-y-1">
          <div className="flex flex-row gap-x-4">
            <span className="label text-md text-secondary my-auto uppercase font-semibold">
              {tmpTransaction &&
                formatDate(tmpTransaction.created_at, "month_year")}
            </span>
            <div className="label text-md text-gray my-auto">
              {status_for_humain(tmpTransaction.status)}
            </div>
          </div>
        </div>
        <span className="label text-md my-auto text-green text-right">
          {transaction.amount} <span className="text-gray">€ ht</span>
        </span>
      </div>
    ))
  );
}

export default Transaction;
