import './modal.css';

const ModalSidebar = ({ handleClose, show, children, classes}) => {
  return (
      show && (
        <div className="modal-sidebar" onClick={handleClose}>
          <section className={classes === 'user' ? 'modal-main-sidebar' : 'modal-main-admin-modal-project'}>
            {children}
          </section>
        </div>
      )
  );
};

export default ModalSidebar;