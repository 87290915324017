import i18n from "i18next";
import i18nBackend from "i18next-http-backend";
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";

i18n
  .use(i18nBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // fallbackLng: !localStorage.getItem("i18nextLng") ? "fr" : localStorage.getItem("i18nextLng"),
    // lng: !localStorage.getItem("i18nextLng") ? "fr" : localStorage.getItem("i18nextLng"),
    interpolation: {
      escapeValue: false,
    },
    // debug: true,
    backend: {
        loadPath: `${process.env.REACT_APP_URL}/i18n/{{lng}}.json`,
    },
    options: {
      // order and from where user language should be detected
      order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
    
      // keys or params to lookup language from
      lookupQuerystring: 'lng',
      lookupCookie: 'i18nextLng',
      lookupLocalStorage: 'i18nextLng',
      lookupSessionStorage: 'i18nextLng',
      lookupFromPathIndex: 0,
      lookupFromSubdomainIndex: 0,
      nonExplicitSupportedLngs: true,
      supportedLngs: ['fr', 'en', 'de', 'es', 'it'],
    
      // cache user language on
      caches: ['localStorage', 'cookie'],
      // excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
    
      // optional conversion function to use to modify the detected language code
      // convertDetectedLanguage: 'Iso15897',
      // convertDetectedLanguage: (lng) => lng.replace('-', '_')
    }
  });

export default i18n;