import React from 'react';
import axios from 'axios';

function ConfirmationToken() {
    // const url = window.location.origin;
    // const token = localStorage.getItem("token");
    // if(!token){
    //     window.location.replace(process.env.REACT_APP_URL + `/users/sign_in`);
    // }
    const queryParameters = new URLSearchParams(window.location.search)
    const confirmation_token = queryParameters.get("confirmation_token")
    const lng = queryParameters.get("lng")

    axios.get(process.env.REACT_APP_URL_API + `/confirmation?confirmation_token=`+confirmation_token + `&lng=` + lng)
    .then(response => {
        window.location.replace(process.env.REACT_APP_URL + `/users/sign_in`);
    })
    .catch(response => {
        window.location.replace(process.env.REACT_APP_URL + `/users/sign_in`);
    });
    return (
        <div></div>
    );
}

export default ConfirmationToken;