import React, { Component } from "react";
import i18n from "i18next";
import parse from "html-react-parser";
import Select from "react-dropdown-select";

import Settings from "./component/Settings.jsx";
import {
  renderButtonHtml,
  initFieldbacksService,
  refreshPage,
  handleCommonSubmit,
  handleChange,
  headerProduct,
  btnCopy,
  canGenerate,
} from "./component/sharedFunction.js";

import screebotNormal from "./assets/zap.svg";
import screebotHuhu from "./assets/huhu.svg";
import iconeHeader from "../../assets/product/social.svg";

import "./styles/product.css";
import "../../assets/Common.css";

class Social extends Component {
  state = {
    title: "",

    social: "linkedin",
    post_type: "product",
    business_type: "",
    title_article: "",
    tone: "neutral",
    pov: "i",
    objectif: "",
    cie_name: "",
    product_name: "",
    link_cta: "",
    anchor_cta: "",
    hashtag: "true",
    emojis: "true",
    context: "",

    output: "",
    product: "social",
    click: false,
    buttonDisabled: true,
    returnApiSpan: "",
    copied: false,
    txtScreebot: "",
    imgScreebot: "",
    colorBackgroundScreebot: "",
    finished: "",
    id: "",
    project: "",
    language: "",
    problemTokenOrPlan: false,
    fieldbacks: null,
    loading: "",
  };

  options = {
    social: [
      {
        value: "linkedin",
        label: "Linkedin",
      },
      {
        value: "faceboook",
        label: "Facebook",
      },
    ],
    hashtag: [
      {
        value: "true",
        label: i18n.t("products.social.hashtag_yes"),
      },
      {
        value: "false",
        label: i18n.t("products.social.hashtag_no"),
      },
    ],
    emojis: [
      {
        value: "true",
        label: i18n.t("products.social.emoji_yes"),
      },
      {
        value: "false",
        label: i18n.t("products.social.emoji_no"),
      },
    ],
  };

  constructor(props) {
    super(props);

    if (this.props.selectedProject) {
      if (!canGenerate(this.props.plan, this.props.user)) {
        this.state = {
          title: "",
          output: "",
          product: "social",
          post_type: "product",
          social: "linkedin",
          objectif: "",
          hashtag: "true",
          business_type: "",
          title_article: "",
          tone: "neutral",
          pov: "i",
          cie_name: "",
          product_name: "",
          link_cta: "",
          anchor_cta: "",
          emojis: "true",
          buttonDisabled: false,
          click: false,
          returnApiSpan: "",
          copied: false,
          txtScreebot: i18n.t("products.shared.choose"),
          imgScreebot: screebotHuhu,
          colorBackgroundScreebot: "#486497",
          finished: false,
          id: null,
          project: this.props.selectedProject.id,
          language: this.props.selectCountry,
          problemTokenOrPlan: true,
          loading: "",
        };
      } else {
        this.state = {
          title: "",
          output: "",
          product: "social",
          post_type: "product",
          social: "linkedin",
          objectif: "",
          hashtag: "true",
          business_type: "",
          title_article: "",
          tone: "neutral",
          pov: "i",
          cie_name: "",
          product_name: "",
          link_cta: "",
          anchor_cta: "",
          emojis: "true",
          buttonDisabled: true,
          click: false,
          returnApiSpan: "",
          copied: false,
          txtScreebot: i18n.t("products.shared.generate"),
          imgScreebot: screebotNormal,
          colorBackgroundScreebot: "#486497",
          finished: false,
          id: null,
          project: this.props.selectedProject.id,
          language: this.props.selectCountry,
          problemTokenOrPlan: false,
          loading: "",
        };
      }

      initFieldbacksService(
        this,
        this.state.product,
        this.props.selectedProject.id
      );
    }
  }

  render() {
    return (
      <div className="container flex flex-col gap-y-5">
        <div className="flex flex-col w-full gap-y-1.5">
          {headerProduct(iconeHeader, this.state.product)}

          <form
            className="flex flex-col gap-y-5 w-full"
            onSubmit={(e) =>
              handleCommonSubmit(
                e,
                this.state,
                this,
                this.state.id,
                this.props.user && this.props.user.token
              )
            }
          >
            <div className="flex flex-col gap-y-1.5 ctn-document">
              <div className="flex flex-row items-center gap-x-5">
                <input
                  disabled={this.state.click}
                  value={this.state.title}
                  type="text"
                  name="title"
                  onInput={(e) => handleChange(this, e)}
                  onChange={(e) => handleChange(this, e)}
                  placeholder={i18n.t("products.shared.place_holder_document")}
                />
                <Settings
                  setSelectCountry={this.props.setSelectCountry}
                  selectCountry={this.props.selectCountry}
                ></Settings>
              </div>
            </div>

            <hr className="separator-product my-5"></hr>

            <input
              value={this.state.type}
              className="hidden"
              type="text"
              name="title"
            />
            <h2 className="text-secondary text-left">
              {i18n.t("products.shared.parameter")}
            </h2>

            <div className="flex flex-row relative gap-y-1.5 w-full justify-between gap-x-12">
              <div className="flex flex-col relative gap-y-1.5 w-full">
                {/* Social network  */}
                <h4
                  id="social"
                  className="flex flex-row text-primary obligatory"
                >
                  {i18n.t("products.social.choose")}
                </h4>
                <Select
                  name="social"
                  id="social"
                  values={[
                    this.options.social.find(
                      (opt) => opt.value === this.state.social
                    ),
                  ]}
                  disabled={this.state.click}
                  options={this.options.social}
                  onChange={(e) => handleChange(this, e, false, 80, "social")}
                />
              </div>
              <div className="flex flex-col relative gap-y-1.5 w-full">
                {/* Objectif du post */}
                <div className="flex flex-row justify-between w-full">
                  <h4
                    id="objectif"
                    name="objectif"
                    className="flex flex-row text-primary obligatory"
                  >
                    {i18n.t("products.social.post_objectif")}
                  </h4>
                  <p className="text-primary text-xs my-auto">
                    <span>0</span>
                    <span>/80</span>
                  </p>
                </div>
                {renderButtonHtml(this, "objectif", () =>
                  handleChange(
                    this,
                    {
                      target: {
                        value: this.state.objectif,
                        name: "objectif",
                      },
                    },
                    true,
                    80
                  )
                )}
                <input
                  disabled={this.state.click}
                  value={this.state.objectif}
                  placeholder={i18n.t(
                    "products.social.post_objectif_placeholder"
                  )}
                  name="objectif"
                  onInput={(e) => handleChange(this, e, true, 80)}
                  onChange={(e) => handleChange(this, e, true, 80)}
                />
              </div>
              <div className="flex flex-col relative gap-y-1.5 w-full">
                <div className="flex flex-row justify-between w-full">
                  <h4
                    id="cie_name"
                    className="flex flex-row text-primary obligatory"
                  >
                    {i18n.t("products.social.cie_name")}
                  </h4>
                  <p className="text-primary text-xs my-auto">
                    <span>0</span>
                    <span>/80</span>
                  </p>
                </div>
                {renderButtonHtml(this, "cie_name", () =>
                  handleChange(
                    this,
                    {
                      target: {
                        value: this.state.cie_name,
                        name: "cie_name",
                      },
                    },
                    true,
                    80
                  )
                )}
                <input
                  disabled={this.state.click}
                  value={this.state.cie_name}
                  placeholder={i18n.t("products.social.cie_name_placeholder")}
                  name="cie_name"
                  onInput={(e) => handleChange(this, e, true, 80)}
                  onChange={(e) => handleChange(this, e, true, 80)}
                />
              </div>
            </div>

            <div className="flex flex-row relative gap-y-1.5 w-full justify-between gap-x-12">
              {/* Nom du produit / nom du service */}
              <div className="flex flex-col relative gap-y-1.5 w-full">
                <div className="flex flex-row justify-between w-full">
                  <h4
                    id="product_name"
                    className="flex flex-row text-primary obligatory"
                  >
                    {i18n.t("products.social.product_name")}
                  </h4>
                  <p className="text-primary text-xs my-auto">
                    <span>0</span>
                    <span>/80</span>
                  </p>
                </div>
                {renderButtonHtml(this, "product_name", () =>
                  handleChange(
                    this,
                    {
                      target: {
                        value: this.state.product_name,
                        name: "product_name",
                      },
                    },
                    true,
                    80
                  )
                )}
                <input
                  disabled={this.state.click}
                  value={this.state.product_name}
                  placeholder={i18n.t(
                    "products.social.product_name_placeholder"
                  )}
                  name="product_name"
                  onInput={(e) => handleChange(this, e, true, 80)}
                  onChange={(e) => handleChange(this, e, true, 80)}
                />
              </div>

              {/* Lien vers le produit */}
              <div className="flex flex-col relative gap-y-1.5 w-full">
                <div className="flex flex-row justify-between w-full">
                  <h4
                    id="link_cta"
                    className="flex flex-row text-primary obligatory"
                  >
                    {i18n.t("products.social.link_cta")}
                  </h4>
                  <p className="text-primary text-xs my-auto">
                    <span>0</span>
                    <span>/80</span>
                  </p>
                </div>
                {renderButtonHtml(this, "link_cta", () =>
                  handleChange(
                    this,
                    {
                      target: {
                        value: this.state.link_cta,
                        name: "link_cta",
                      },
                    },
                    true
                  )
                )}
                <input
                  disabled={this.state.click}
                  value={this.state.link_cta}
                  placeholder={i18n.t("products.social.link_cta_placeholder")}
                  name="link_cta"
                  onInput={(e) => handleChange(this, e, true, 80)}
                  onChange={(e) => handleChange(this, e, true, 80)}
                />
              </div>

              {/* Texte du lien */}
              <div className="flex flex-col relative gap-y-1.5 w-full">
                <div className="flex flex-row justify-between w-full">
                  <h4
                    id="anchor_cta"
                    className="flex flex-row text-primary obligatory"
                  >
                    {i18n.t("products.social.anchor_cta")}
                  </h4>
                  <p className="text-primary text-xs my-auto">
                    <span>0</span>
                    <span>/80</span>
                  </p>
                </div>
                {renderButtonHtml(this, "anchor_cta", () =>
                  handleChange(
                    this,
                    {
                      target: {
                        value: this.state.anchor_cta,
                        name: "anchor_cta",
                      },
                    },
                    true,
                    80
                  )
                )}
                <input
                  disabled={this.state.click}
                  value={this.state.anchor_cta}
                  placeholder={i18n.t("products.social.anchor_cta_placeholder")}
                  name="anchor_cta"
                  onInput={(e) => handleChange(this, e, true, 80)}
                  onChange={(e) => handleChange(this, e, true, 80)}
                />
              </div>
            </div>

            <div className="flex flex-row relative gap-y-1.5 w-full justify-between gap-x-12">
              {/* Hashtag */}
              <div className="flex flex-col relative gap-y-1.5 w-full">
                <h4
                  id="hashtag"
                  className="flex flex-row text-primary obligatory gap-x-2"
                >
                  <svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_1869_5935)">
                      <path
                        d="M18.0674 20.5001H15.2674C14.058 20.5001 13.334 19.7764 13.334 18.5667V15.7667C13.334 14.5573 14.0577 13.8333 15.2674 13.8333H18.0674C19.2768 13.8333 20.0008 14.557 20.0008 15.7667V18.5667C20.0008 19.7761 19.2771 20.5001 18.0674 20.5001ZM18.2768 17.3478C18.1626 17.2335 18.0103 17.1764 17.8577 17.1764C17.7051 17.1764 17.5528 17.2335 17.4386 17.3478L17.2671 17.5193V15.3858C17.2671 15.0524 17.0006 14.7858 16.6671 14.7858C16.3337 14.7858 16.0671 15.0524 16.0671 15.3858V17.5193L15.8957 17.3478C15.6671 17.1193 15.2863 17.1193 15.0577 17.3478C14.8291 17.5764 14.8291 17.9573 15.0577 18.1858L16.2483 19.3764C16.296 19.4241 16.3626 19.4621 16.4291 19.4907C16.4483 19.5001 16.4671 19.5001 16.4863 19.5098C16.534 19.529 16.5814 19.5384 16.6386 19.5384H16.6957C16.7623 19.5384 16.8194 19.529 16.8863 19.5004H16.9054C16.972 19.4718 17.0291 19.4338 17.0768 19.3861C17.0831 19.3798 17.0895 19.3767 17.096 19.3767L18.2866 18.1861C18.5151 17.9575 18.5151 17.5767 18.2771 17.3481L18.2768 17.3478Z"
                        fill="#486497"
                      />
                      <path
                        d="M9.45875 8.13307C9.80164 8.13307 10.0796 7.8551 10.0796 7.51221C10.0796 7.16932 9.80164 6.89136 9.45875 6.89136C9.11586 6.89136 8.83789 7.16932 8.83789 7.51221C8.83789 7.8551 9.11586 8.13307 9.45875 8.13307Z"
                        fill="#486497"
                      />
                      <path
                        d="M6.47633 8.13307C6.81922 8.13307 7.09718 7.8551 7.09718 7.51221C7.09718 7.16932 6.81922 6.89136 6.47633 6.89136C6.13344 6.89136 5.85547 7.16932 5.85547 7.51221C5.85547 7.8551 6.13344 8.13307 6.47633 8.13307Z"
                        fill="#486497"
                      />
                      <path
                        d="M19.0191 5.462C18.8286 2.52857 17.0191 0.719143 14.0857 0.528571C13.8951 0.509429 13.7049 0.5 13.5143 0.5H5.53343C5.34286 0.5 5.15257 0.509429 4.962 0.528571C2.02857 0.719143 0.219143 2.52857 0.0285714 5.462C0.00942857 5.65257 0 5.84286 0 6.03343V14.0143C0 14.2049 0.00942857 14.3951 0.0285714 14.5857C0.219143 17.5191 2.02857 19.3286 4.962 19.5191C5.15257 19.5383 5.34286 19.5477 5.53343 19.5477H10.9526C11.4763 19.5477 11.9049 19.1191 11.9049 18.5954V15.7669C11.9049 13.9097 13.4097 12.4049 15.2669 12.4049H18.0954C18.6191 12.4049 19.0477 11.9763 19.0477 11.4526V6.03343C19.0477 5.84286 19.0383 5.65257 19.0191 5.462ZM7.96657 13.4777C5.22086 13.4777 2.99514 11.252 2.99514 8.50629C2.99514 5.76057 5.22114 3.53486 7.96657 3.53486C10.712 3.53486 12.938 5.76057 12.938 8.50629C12.938 11.252 10.7123 13.4777 7.96657 13.4777Z"
                        fill="#486497"
                      />
                      <path
                        d="M10.2481 9.83065C10.0864 9.70293 9.85214 9.73065 9.72443 9.89208C9.30243 10.4266 8.66128 10.7441 7.96728 10.7441C7.27328 10.7441 6.63071 10.4258 6.20843 9.89007C6.081 9.72836 5.84671 9.70065 5.685 9.82808C5.52328 9.9555 5.49557 10.1898 5.623 10.3515C6.185 11.0646 7.04157 11.4898 7.96728 11.4898C8.893 11.4898 9.74757 11.0661 10.3096 10.3544C10.4373 10.1926 10.4096 9.95836 10.2481 9.83065Z"
                        fill="#486497"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1869_5935">
                        <rect y="0.5" width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  {i18n.t("products.social.insert_hashtag")}
                </h4>
                <Select
                  name="hashtag"
                  id="hashtag"
                  values={[
                    this.options.hashtag.find(
                      (opt) => opt.value === this.state.hashtag
                    ),
                  ]}
                  disabled={this.state.click}
                  options={this.options.hashtag}
                  onChange={(e) => handleChange(this, e, false, 80, "hashtag")}
                />
              </div>
              {/* Emoji */}
              <div className="flex flex-col relative gap-y-1.5 w-full">
                <h4
                  id="emojis"
                  className="flex flex-row text-primary obligatory gap-x-2"
                >
                  <svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_1869_5921)">
                      <path
                        d="M18.0674 20.5001H15.2674C14.058 20.5001 13.334 19.7764 13.334 18.5667V15.7667C13.334 14.5573 14.0577 13.8333 15.2674 13.8333H18.0674C19.2768 13.8333 20.0008 14.557 20.0008 15.7667V18.5667C20.0008 19.7761 19.2771 20.5001 18.0674 20.5001ZM18.2768 17.3478C18.1626 17.2335 18.0103 17.1764 17.8577 17.1764C17.7051 17.1764 17.5528 17.2335 17.4386 17.3478L17.2671 17.5193V15.3858C17.2671 15.0524 17.0006 14.7858 16.6671 14.7858C16.3337 14.7858 16.0671 15.0524 16.0671 15.3858V17.5193L15.8957 17.3478C15.6671 17.1193 15.2863 17.1193 15.0577 17.3478C14.8291 17.5764 14.8291 17.9573 15.0577 18.1858L16.2483 19.3764C16.296 19.4241 16.3626 19.4621 16.4291 19.4907C16.4483 19.5001 16.4671 19.5001 16.4863 19.5098C16.534 19.529 16.5814 19.5384 16.6386 19.5384H16.6957C16.7623 19.5384 16.8194 19.529 16.8863 19.5004H16.9054C16.972 19.4718 17.0291 19.4338 17.0768 19.3861C17.0831 19.3798 17.0895 19.3767 17.096 19.3767L18.2866 18.1861C18.5151 17.9575 18.5151 17.5767 18.2771 17.3481L18.2768 17.3478Z"
                        fill="#486497"
                      />
                      <path
                        d="M9.45875 8.13307C9.80164 8.13307 10.0796 7.8551 10.0796 7.51221C10.0796 7.16932 9.80164 6.89136 9.45875 6.89136C9.11586 6.89136 8.83789 7.16932 8.83789 7.51221C8.83789 7.8551 9.11586 8.13307 9.45875 8.13307Z"
                        fill="#486497"
                      />
                      <path
                        d="M6.47633 8.13307C6.81922 8.13307 7.09718 7.8551 7.09718 7.51221C7.09718 7.16932 6.81922 6.89136 6.47633 6.89136C6.13344 6.89136 5.85547 7.16932 5.85547 7.51221C5.85547 7.8551 6.13344 8.13307 6.47633 8.13307Z"
                        fill="#486497"
                      />
                      <path
                        d="M19.0191 5.462C18.8286 2.52857 17.0191 0.719143 14.0857 0.528571C13.8951 0.509429 13.7049 0.5 13.5143 0.5H5.53343C5.34286 0.5 5.15257 0.509429 4.962 0.528571C2.02857 0.719143 0.219143 2.52857 0.0285714 5.462C0.00942857 5.65257 0 5.84286 0 6.03343V14.0143C0 14.2049 0.00942857 14.3951 0.0285714 14.5857C0.219143 17.5191 2.02857 19.3286 4.962 19.5191C5.15257 19.5383 5.34286 19.5477 5.53343 19.5477H10.9526C11.4763 19.5477 11.9049 19.1191 11.9049 18.5954V15.7669C11.9049 13.9097 13.4097 12.4049 15.2669 12.4049H18.0954C18.6191 12.4049 19.0477 11.9763 19.0477 11.4526V6.03343C19.0477 5.84286 19.0383 5.65257 19.0191 5.462ZM7.96657 13.4777C5.22086 13.4777 2.99514 11.252 2.99514 8.50629C2.99514 5.76057 5.22114 3.53486 7.96657 3.53486C10.712 3.53486 12.938 5.76057 12.938 8.50629C12.938 11.252 10.7123 13.4777 7.96657 13.4777Z"
                        fill="#486497"
                      />
                      <path
                        d="M10.2481 9.83065C10.0864 9.70293 9.85214 9.73065 9.72443 9.89208C9.30243 10.4266 8.66128 10.7441 7.96728 10.7441C7.27328 10.7441 6.63071 10.4258 6.20843 9.89007C6.081 9.72836 5.84671 9.70065 5.685 9.82808C5.52328 9.9555 5.49557 10.1898 5.623 10.3515C6.185 11.0646 7.04157 11.4898 7.96728 11.4898C8.893 11.4898 9.74757 11.0661 10.3096 10.3544C10.4373 10.1926 10.4096 9.95836 10.2481 9.83065Z"
                        fill="#486497"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1869_5921">
                        <rect y="0.5" width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  {i18n.t("products.social.insert_emoji")}
                </h4>
                <Select
                  name="emojis"
                  id="emojis"
                  values={[
                    this.options.emojis.find(
                      (opt) => opt.value === this.state.emojis
                    ),
                  ]}
                  disabled={this.state.click}
                  options={this.options.emojis}
                  onChange={(e) => handleChange(this, e, false, 80, "emojis")}
                />
              </div>
            </div>

            {/* Contexte */}
            <div className="flex flex-col relative gap-y-1.5 w-full">
              <div className="flex flex-row justify-between w-full">
                <h4
                  id="context"
                  htmlFor="context"
                  className="flex flex-row text-primary"
                >
                  {i18n.t("products.social.context")}
                </h4>
                <p className="text-primary text-xs my-auto">
                  <span>0</span>
                  <span>/2000</span>
                </p>
              </div>
              {renderButtonHtml(this, "context", () =>
                handleChange(
                  this,
                  {
                    target: {
                      value: this.state.context,
                      name: "context",
                    },
                  },
                  true,
                  2000
                )
              )}
              <textarea
                disabled={this.state.click}
                value={this.state.context}
                className="textarea-context"
                name="context"
                cols="30"
                rows="10"
                onInput={(e) => handleChange(this, e, true, 2000)}
                onChange={(e) => handleChange(this, e, true, 2000)}
                placeholder={i18n.t("products.social.context_placeholder")}
              ></textarea>
            </div>

            <div className="flex flex-row gap-x-8">
              <div>
                <button
                  disabled={this.state.buttonDisabled}
                  id="generate-button"
                  className="button-product text-sm"
                  style={{ background: this.state.colorBackgroundScreebot }}
                >
                  <img
                    src={this.state.imgScreebot}
                    alt="logo product"
                    style={{ margin: "auto" }}
                  ></img>
                  <div>{this.state.txtScreebot}</div>
                </button>
              </div>
              {this.state.click && (
                <div>
                  <div
                    onClick={() => refreshPage(this)}
                    disabled={!this.state.buttonDisabled}
                    className="button-product bg-white text-secondary btn text-sm"
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_1855_5207)">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M3.83375 1.77436C2.66957 1.77436 1.72578 2.71816 1.72578 3.88233V12.1179C1.72578 13.2821 2.66957 14.2259 3.83375 14.2259H12.0693C13.2335 14.2259 14.1773 13.2821 14.1773 12.1179V8.00023C14.1773 7.6483 14.4626 7.36316 14.8143 7.36316C15.1661 7.36316 15.4514 7.64852 15.4514 8.00023V12.1179C15.4514 13.9859 13.9371 15.5002 12.0691 15.5002H3.83375C1.96572 15.5 0.451416 13.9857 0.451416 12.1177V3.88233C0.451416 2.0143 1.96572 0.5 3.83375 0.5H7.95142C8.30334 0.5 8.58848 0.785365 8.58848 1.13707C8.58848 1.48877 8.30312 1.77414 7.95142 1.77414H3.83375V1.77436Z"
                          fill="#486497"
                        />
                        <path
                          d="M12.8237 5.75138C11.3771 5.75138 10.2001 4.57439 10.2001 3.12778C10.2001 1.68116 11.3771 0.504395 12.8237 0.504395C14.2704 0.504395 15.4473 1.68139 15.4473 3.128C15.4473 4.57462 14.2704 5.75161 12.8237 5.75161V5.75138ZM12.8237 1.77853C12.0799 1.77853 11.4745 2.38389 11.4745 3.12778C11.4745 3.87166 12.0799 4.47702 12.8237 4.47702C13.5676 4.47702 14.173 3.87166 14.173 3.12778C14.173 2.38389 13.5676 1.77853 12.8237 1.77853Z"
                          fill="#486497"
                        />
                        <path
                          d="M7.23224 11.6355H4.20341C3.85149 11.6355 3.56635 11.3503 3.56635 10.9984C3.56635 10.6465 3.85171 10.3613 4.20341 10.3613H7.23224C7.58417 10.3613 7.86931 10.6465 7.86931 10.9984C7.86931 11.3503 7.58395 11.6355 7.23224 11.6355Z"
                          fill="#486497"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1855_5207">
                          <rect
                            width="15"
                            height="15"
                            fill="white"
                            transform="translate(0.451416 0.5)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                    {i18n.t("products.shared.new")}
                  </div>
                </div>
              )}
            </div>
          </form>
        </div>

        {this.state.click && (
          <div className="output-response">
            {!this.state.output && (
              <>
                <div className="ctn-loader">
                  <lottie-player
                    src="https://assets8.lottiefiles.com/packages/lf20_xv923ge8.json"
                    background="transparent"
                    speed="1"
                    style={{ width: "75px", height: "25px" }}
                    loop
                    autoplay
                  ></lottie-player>
                  <span
                    style={{
                      textAlign: "left",
                      marginTop: "auto",
                      marginBottom: "auto",
                    }}
                  >
                    {this.state.loading}
                  </span>
                </div>
              </>
            )}

            <span id="output-screebot" className="output-screebot">
              {parse(this.state.output.replace(/<br\s*\/?>/gi, ""))}
            </span>

            {this.state.output && (
              <div className="button-copy-list">
                {btnCopy(
                  this,
                  document.getElementById("output-screebot").innerText,
                  "copied2",
                  "text"
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default Social;
