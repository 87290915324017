import React from "react";
import axios from "axios";

function Deconnexion() {
  const token = localStorage.getItem("token");
  if (!token) {
    window.location.replace(process.env.REACT_APP_URL + `/users/sign_in`);
  }

  const config = {
    headers: { Authorization: `${token}` },
  };
  axios
    .delete(process.env.REACT_APP_URL_API + `/logout`, config, {
      validateStatus: function (status) {
        return status >= 200 && status < 500;
      },
    })
    .then((response) => {
      localStorage.removeItem("noCreditClient");
      localStorage.removeItem("noCreditProspect");
      localStorage.removeItem("token");
      localStorage.removeItem("tokenExpiration");
      window.location.replace(process.env.REACT_APP_URL + `/users/sign_in`);
    })
    .catch((error) => {
      // Deconnexion();
      // window.location.replace(process.env.REACT_APP_URL + `/users/sign_in`);
      console.log(error);
    });
  return <div></div>;
}

export default Deconnexion;
