import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState, useCallback } from "react";
import axios from "axios";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";
import Markdown from "react-markdown";

import {
  ReturnImage,
  btnCopyHistory,
  generateV2,
} from "../../components/product/component/sharedFunction.js";

import disclaimerIcon from "./assets/disclaimer_icon.svg";
import infoIcon from "./assets/info_icon.svg";

import "./styles/history.css";
import "../../assets/Common.css";
import screebotNormal from "../product/assets/zap.svg";

// function ReturnName(props) {
//   const { name } = props;
//   const { i18n } = useTranslation();
//   switch (name) {
//     case "rewrite":
//       return <span>{i18n.t("sidebar.rewrite")}</span>;
//     case "sheet":
//       return <span>{i18n.t("sidebar.sheet")}</span>;
//     case "faq":
//       return <span>{i18n.t("sidebar.faq")}</span>;
//     case "paragraph":
//       return <span>{i18n.t("sidebar.paragraph")}</span>;
//     case "metas":
//       return <span>{i18n.t("sidebar.metas")}</span>;
//     case "blog":
//       return <span>{i18n.t("sidebar.blog")}</span>;
//     case "social":
//       return <span>{i18n.t("sidebar.social")}</span>;
//     case "web":
//       return <span>{i18n.t("sidebar.web")}</span>;
//     case "definition":
//       return <span>{i18n.t("sidebar.definition")}</span>;
//     case "excel":
//       return <span>{i18n.t("sidebar.excel")}</span>;
//     case "press":
//       return <span>{i18n.t("sidebar.press")}</span>;
//     case "blogv2":
//       return <span>{i18n.t("sidebar.blog")}</span>;
//     default:
//       return <span>{i18n.t("sidebar.rewrite")}</span>;
//   }
// }

function formatDate(newDate, format) {
  let date = new Date(newDate);
  let formatted = "";
  if (format === "date") {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const monthName = ("0" + month.toString()).slice(-2);
    const dayName = ("0" + date.getDate().toString()).slice(-2);
    formatted = `${dayName}/${monthName}/${year}`;
  } else {
    const hours = date.getHours();
    const minutes =
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    formatted = `${hours}:${minutes}`;
  }
  return formatted.toString();
}

function handleDeletePrompt(id) {
  const token = localStorage.getItem("token");
  if (!token) {
    window.location.replace(process.env.REACT_APP_URL + `/users/sign_in`);
  }
  const config = {
    headers: { Authorization: `${token}` },
  };
  axios
    .delete(process.env.REACT_APP_URL_API + "/api/v1/prompts/" + id, config)
    .then((response) => {
      if (response.data === "ok") {
        window.location.replace(process.env.REACT_APP_URL + `/history`);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

const PromptHistory = (props) => {
  const { user, selectCountry } = props;
  const params = useParams();
  const { i18n } = useTranslation();
  let navigate = useNavigate();
  const [currentPrompt, setCurrentPrompt] = useState(null);
  const [currentPromptSteps, setCurrentPromptSteps] = useState(null);
  const [currentPromptInputSteps, setCurrentPromptInputSteps] = useState(null);
  const [state, setState] = useState(null);
  const [event, setEvent] = useState(null);
  const [copied, setCopied] = useState(false);
  const [copied2, setCopied2] = useState(false);
  const [textCopied, setTextCopied] = useState(
    i18n.t(`products.shared.copy_excel`)
  );
  const [textCopied2, setTextCopied2] = useState(
    i18n.t(`products.shared.copy_excel`)
  );
  const [display, setDisplay] = useState(null);
  const [isElementDisplayed, setIsElementDisplayed] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const goodDisplay = useCallback((product, output, option = null) => {
    output = output.replace('<lang="fr">', "");
    output = output.replace('< lang="fr">', "");
    output = output.replace("html", "");
    switch (product) {
      case "rewrite":
        return (
          <div className="history-output">
            {output.split("\n").map((line, index) => (
              <p key={index}>{line}</p>
            ))}
          </div>
        );
      case "sheet":
        return <div className="history-output">{parse(output)}</div>;
      case "faq":
        return <div className="history-output">{parse(output)}</div>;
      case "paragraph":
        return <div className="history-output">{parse(output)}</div>;
      case "metas":
        return (
          <div className="history-output">
            <p>{output.split("|;|;|")[0]}</p>
            <p>{output.split("|;|;|")[1]}</p>
          </div>
        );
      case "blog":
        return <div className="history-output">{parse(output)}</div>;
      case "web":
        return <div className="history-output">{parse(output)}</div>;
      case "definition":
        return <div className="history-output">{parse(output)}</div>;
      case "excel":
        return <div className="history-output">{parse(output)}</div>;
      case "press":
        return <div className="history-output">{parse(output)}</div>;
      case "blogv2":
        return (
          <div className="history-output">
            {option !== null && <h1>{option}</h1>}
            <Markdown className="markdown pb-6">{output}</Markdown>
          </div>
        );
      default:
        return (
          <div className="history-output">
            {output.split("\n").map((line, index) => (
              <p key={index}>{line}</p>
            ))}
          </div>
        );
    }
  }, []);

  const waitForElementToDisplay = useCallback(() => {
    const element = document.getElementById("output-screebot");
    if (element) {
      setIsElementDisplayed(true);
    } else {
      setTimeout(waitForElementToDisplay, 50);
    }
  }, []);

  const displayAdminText = (input) => {
    if (!input) {
      return null;
    }
    const parts = input.split("**");
    const spans = [];

    parts.forEach((part, index) => {
      if (index % 2 === 1) {
        spans.push(
          <span key={index} className="highlighted">
            {part}
          </span>
        );
      } else {
        const words = part.split(" ");
        const wordSpans = words.map((word, wordIndex) => (
          <span key={wordIndex}>{word} </span>
        ));
        spans.push(<span key={index}>{wordSpans}</span>);
      }
    });

    return <span style={{ textAlign: "left" }}>{spans}</span>;
  };

  useEffect(() => {
    if (event !== null) {
      if (state.step === "introduction") {
        generateV2(event, state, this, currentPrompt.id, user.token);
      }
    }
  }, [event, currentPrompt, state, user]); // Runs whenever `event` changes

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      window.location.replace(process.env.REACT_APP_URL + `/users/sign_in`);
    }
    const config = {
      headers: { Authorization: `${token}` },
    };

    waitForElementToDisplay();
    setState({ language: selectCountry });

    axios
      .get(
        process.env.REACT_APP_URL_API + "/api/v1/prompts/" + params.id,
        config
      )
      .then((response) => {
        setCurrentPrompt(response.data);
        setTextCopied(
          i18n.t(
            `products.shared.copy_${
              response.data.product === "excel" ? "excel" : "html"
            }`
          )
        );
        setTextCopied2(
          i18n.t(
            `products.shared.copy_${
              response.data.product === "excel" ? "excel" : "text"
            }`
          )
        );
        setDisplay(goodDisplay(response.data.product, response.data.output));
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(
        process.env.REACT_APP_URL_API +
          "/api/v1/prompts/" +
          params.id +
          "/steps",
        config
      )
      .then((response) => {
        setCurrentPromptSteps(
          response.data.output
            .map((step) => "\n" + step.replace(/\n +/g, "\n"))
            .join(" ")
        );
        setCurrentPromptInputSteps(
          response.data.input.map((step) => step).join("\n")
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, [goodDisplay, i18n, params.id, selectCountry, waitForElementToDisplay]);

  return (
    currentPrompt && (
      <div className="container">
        <div className="flex flex-col gap-y-10">
          <div className="flex flex-col gap-y-2.5">
            <h1 className="text-secondary text-2xl pb-2.5 text-left">
              {i18n.t("prompt.my_document")}
            </h1>
            <button
              onClick={() => navigate(-1)}
              className="flex flex-row text-white bg-secondary gap-x-3.5 rounded-custom-input w-fit"
            >
              <svg
                className="my-auto"
                width="18"
                height="19"
                viewBox="0 0 18 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.5 12.5L7.5 9.5L10.5 6.5"
                  stroke="white"
                  strokeWidth="1.2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              retour aux documents
            </button>
          </div>
          <div
            className="bg-white border border-gray-custom-input rounded-2xl padding-custom-box"
            key={params.id}
          >
            <div className="flex flex-col gap-y-6 pb-7">
              <div className="flex flex-row justify-between gap-x-2">
                <div className="flex flex-col gap-y-1">
                  <div className="flex flex-row gap-x-2">
                    <ReturnImage
                      icon={currentPrompt.product}
                      classList="bg-img-history-paginated"
                    ></ReturnImage>
                    <h1 className="my-auto text-secondary">
                      {currentPrompt.title}
                    </h1>
                  </div>
                  <div className="flex flex-row gap-x-7 text-secondary">
                    <p className="my-auto">
                      {formatDate(currentPrompt.created_at, "date")}
                    </p>
                    <p className="my-auto">
                      {formatDate(currentPrompt.created_at, "hours")}
                    </p>
                  </div>
                </div>

                <div className="flex flex-row gap-x-2 h-full my-auto">
                  <div className="flex flex-row gap-x-1">
                    {(currentPrompt.product === "blog" ||
                      currentPrompt.product === "faq" ||
                      currentPrompt.product === "social" ||
                      currentPrompt.product === "web" ||
                      (currentPrompt.product === "blogv2" &&
                        currentPrompt.state !== "end")) &&
                      isElementDisplayed && (
                        <>
                          {btnCopyHistory(
                            currentPrompt,
                            currentPrompt.output,
                            copied,
                            setCopied,
                            textCopied,
                            setTextCopied,
                            "html"
                          )}
                        </>
                      )}
                    {(currentPrompt.product === "faq" ||
                      currentPrompt.product === "social" ||
                      currentPrompt.product === "web" ||
                      (currentPrompt.product === "blogv2" &&
                        currentPrompt.state === "end")) &&
                      isElementDisplayed && (
                        <>
                          {btnCopyHistory(
                            currentPrompt,
                            currentPrompt &&
                              "# " +
                                ((currentPrompt.input.match(/\*\*(.*?)\*\*/) ||
                                  [])[1] || "") +
                                currentPromptSteps,
                            copied,
                            setCopied,
                            textCopied,
                            setTextCopied,
                            "html"
                          )}
                        </>
                      )}
                    {currentPrompt.product === "excel" &&
                      isElementDisplayed && (
                        <div className="">
                          {btnCopyHistory(
                            currentPrompt,
                            document
                              .getElementById("output-screebot")
                              .getElementsByClassName("formula-result")[0]
                              .innerHTML,
                            copied2,
                            setCopied2,
                            textCopied2,
                            setTextCopied2,
                            "excel"
                          )}
                        </div>
                      )}
                    {isElementDisplayed &&
                      currentPrompt.product !== "excel" &&
                      currentPrompt.product !== "blogv2" && (
                        <div className="">
                          {btnCopyHistory(
                            currentPrompt,
                            document.getElementById("output-screebot")
                              .innerText,
                            copied2,
                            setCopied2,
                            textCopied2,
                            setTextCopied2,
                            "text"
                          )}
                        </div>
                      )}
                  </div>
                  <div>
                    <button
                      onClick={() => handleDeletePrompt(currentPrompt.id)}
                      className="btn bg-error p-3.5"
                    >
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M7.68964 8.27273C8.03982 8.27273 8.3237 8.58671 8.3237 8.97403V12.4805C8.3237 12.8678 8.03982 13.1818 7.68964 13.1818C7.33946 13.1818 7.05558 12.8678 7.05558 12.4805V8.97403C7.05558 8.58671 7.33946 8.27273 7.68964 8.27273Z"
                          fill="#DC2626"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M10.3104 8.27273C10.6606 8.27273 10.9445 8.58671 10.9445 8.97403V12.4805C10.9445 12.8678 10.6606 13.1818 10.3104 13.1818C9.96024 13.1818 9.67636 12.8678 9.67636 12.4805V8.97403C9.67636 8.58671 9.96024 8.27273 10.3104 8.27273Z"
                          fill="#DC2626"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M3.16669 5.49998C3.16669 5.12342 3.45687 4.81816 3.81484 4.81816H14.1852C14.5432 4.81816 14.8334 5.12342 14.8334 5.49998C14.8334 5.87654 14.5432 6.1818 14.1852 6.1818H3.81484C3.45687 6.1818 3.16669 5.87654 3.16669 5.49998Z"
                          fill="#DC2626"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M5.08698 6.90909C5.44717 6.90909 5.73915 7.22218 5.73915 7.60839V13.2028C5.73915 13.9752 6.32313 14.6014 7.0435 14.6014H10.9565C11.6769 14.6014 12.2609 13.9753 12.2609 13.2028V7.60839C12.2609 7.22218 12.5529 6.90909 12.9131 6.90909C13.2733 6.90909 13.5652 7.22218 13.5652 7.60839V13.2028C13.5652 14.7477 12.3973 16 10.9565 16H7.0435C5.60277 16 4.43481 14.7477 4.43481 13.2028V7.60839C4.43481 7.22218 4.7268 6.90909 5.08698 6.90909Z"
                          fill="#DC2626"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M8.34482 3.39394C7.98297 3.39394 7.68963 3.70598 7.68963 4.09091V4.78788H10.3104V4.09091C10.3104 3.70598 10.0171 3.39394 9.65521 3.39394H8.34482ZM6.37924 4.09091C6.37924 2.93613 7.25926 2 8.34482 2H9.65521C10.7408 2 11.6208 2.93614 11.6208 4.09091V5.48485C11.6208 5.86978 11.3274 6.18182 10.9656 6.18182H7.03444C6.67258 6.18182 6.37924 5.86978 6.37924 5.48485V4.09091Z"
                          fill="#DC2626"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div className="flex flex-row justify-between">
                <div className="flex flex-row gap-x-2.5 text-nowrap">
                  <img src={disclaimerIcon} alt="tooltip"></img>
                  <p className="text-secondary text-xs italic my-auto">
                    {i18n.t("prompt.check_info")}
                  </p>
                </div>
                {isElementDisplayed && currentPrompt.product === "blogv2" && (
                  <a
                    target="_blank"
                    href="https://screebot.com/docs/comment-coller-article-wordpress/"
                    className="flex flex-row gap-x-2.5"
                    rel="noreferrer"
                  >
                    <img src={infoIcon} alt="tooltip"></img>
                    <p className="text-secondary text-sm">
                      {i18n.t("prompt.past_info")}
                    </p>
                  </a>
                )}
              </div>
            </div>
            <hr className="separator-product"></hr>
            <div className="pt-5 pr-12 pb-0 pl-5">
              <div id="output-screebot">
                {((currentPromptSteps &&
                  currentPromptSteps.length > 0 &&
                  currentPrompt.state === "end") ||
                  state.output) &&
                  goodDisplay(
                    "blogv2",
                    currentPromptSteps || state.output,
                    currentPrompt &&
                      (
                        (currentPrompt.input.match(/\*\*(.*?)\*\*/) || [])[1] ||
                        ""
                      ).trim()
                  )}
                {!currentPromptSteps && display}
                {(currentPromptSteps !== null &&
                  currentPrompt.state !== "end") ||
                  (state.output && { display })}
              </div>
              <div className="flex flex-row gap-x-1">
                {currentPrompt.product === "blogv2" &&
                  currentPrompt.state !== "end" && (
                    <button
                      disabled={disabled}
                      className="button-product text-sm"
                      onClick={(e) => {
                        setDisabled(true);
                        setState({ step: "introduction" });
                        setEvent(e);
                      }}
                      id="generate-button"
                      style={{ background: "#486497" }}
                    >
                      <img
                        src={screebotNormal}
                        alt="logo product"
                        className="m-auto"
                      ></img>
                      <div>{i18n.t("products.blogv2.generate")}</div>
                    </button>
                  )}
                {disabled && (
                  <span className="my-auto">
                    {i18n.t("products.shared.thinking")}
                  </span>
                )}
              </div>
            </div>
          </div>
          {((currentPromptSteps && currentPromptSteps.length > 0) ||
            state.output) && (
            <div className="bg-white border border-gray-custom-input rounded-2xl padding-custom-box flex flex-col gap-y-3.5">
              <div className="flex justify-end">
                {currentPrompt.state !== "end" &&
                  btnCopyHistory(
                    currentPrompt,
                    currentPrompt &&
                      (
                        (currentPrompt.input.match(/\*\*(.*?)\*\*/) || [])[1] ||
                        ""
                      ).trim() +
                        " " +
                        currentPromptSteps,
                    copied,
                    setCopied,
                    textCopied,
                    setTextCopied,
                    "html"
                  )}
                {currentPrompt.state === "end" &&
                  btnCopyHistory(
                    currentPrompt,
                    currentPrompt && currentPrompt.output,
                    copied,
                    setCopied,
                    textCopied,
                    setTextCopied,
                    "html"
                  )}
              </div>
              <div className="flex justify-start">
                <div className="mb-8">{display}</div>
              </div>
            </div>
          )}
          {user && user.role === "admin" && (
            <div className="bg-white border border-gray-custom-input rounded-2xl padding-custom-box">
              <div className="text-left">
                <div
                  className="history-title-document"
                  style={{ fontSize: "1.25rem", marginBottom: "0.5rem" }}
                >
                  Informations Admin
                </div>
                <div style={{ marginBottom: "2rem" }}>
                  <div
                    style={{
                      color: "#00000",
                      fontWeight: "600",
                      marginBottom: "0.5rem",
                    }}
                  >
                    Prompt généré:
                  </div>
                  {displayAdminText(currentPrompt.input)}
                  {currentPromptSteps &&
                    currentPromptSteps.length > 0 &&
                    currentPromptInputSteps}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: "0.5rem",
                    columnGap: "0.5rem",
                  }}
                >
                  <div style={{ color: "#00000", marginBottom: "0.5rem" }}>
                    Modèle utilisé:{" "}
                  </div>
                  <div style={{ color: "#00000", fontWeight: "600" }}>
                    {currentPrompt.model}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: "0.5rem",
                    columnGap: "0.5rem",
                  }}
                >
                  <div style={{ color: "#00000", marginBottom: "0.5rem" }}>
                    Crédits Screebot:{" "}
                  </div>
                  <div>
                    <span style={{ color: "#00000", fontWeight: "600" }}>
                      {currentPrompt.screebot_token}
                    </span>
                    <span> crédits</span>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: "0.5rem",
                    columnGap: "0.5rem",
                  }}
                >
                  <div style={{ color: "#00000", marginBottom: "0.5rem" }}>
                    Tokens consommés:{" "}
                  </div>
                  <div>
                    <span style={{ color: "#00000", fontWeight: "600" }}>
                      {currentPrompt.openai_token}
                    </span>
                    <span> tokens</span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  );
};

export default PromptHistory;
