import React, { useState, useEffect, useMemo } from "react";
import { useDropzone } from "react-dropzone";

import "./css/dropzone.css";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
  margin: "auto",
};

const thumb = {
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "0.75rem 1.875rem 0.75rem 1.25rem",
  borderWidth: 1,
  borderRadius: "0.9375rem",
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "white",
  color: "#486497",
  outline: "none",
  transition: "border .24s ease-in-out",
  height: "100%",
  boxShadow: "0px 2px 20px 0px rgba(172, 172, 201, 0.25)",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

function MyTransaction(props) {
  // const { i18n } = useTranslation();
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      noClick: props.disabled,
      accept: { "application/pdf": [] },
      onDrop: (acceptedFiles) => {
        if (!props.disabled) {
          setFiles(
            acceptedFiles.map((file) =>
              Object.assign(file, {
                preview: URL.createObjectURL(file),
              })
            )
          );
          props.onPdfChange(acceptedFiles[0]);
        }
      },
      onChange: () => {},
      onFocus: () => {},
    });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <span>{file.name}</span>
      </div>
    </div>
  ));

  useEffect(() => {
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (
    <section
      className={`${props.disabled ? "cursor-not-allowed" : "cursor-pointer"}`}
    >
      <div {...getRootProps({ style })}>
        <input
          disabled={props.disabled}
          name="bill"
          id="bill"
          {...getInputProps()}
        />
        <div className="dropzone-text flex flex-row gap-x-4">
          <svg
            className="my-auto"
            width="15"
            height="16"
            viewBox="0 0 15 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.79378 12.7108C2.06357 11.9827 1.51244 11.0951 1.18383 10.1177C0.855226 9.14039 0.758121 8.10009 0.900173 7.07883C1.04222 6.05756 1.41955 5.08325 2.00241 4.23267C2.58527 3.38209 3.35773 2.6785 4.25891 2.17735C5.15659 1.69235 6.15282 1.41783 7.17224 1.37456C8.19166 1.33129 9.20757 1.5204 10.1431 1.92759C11.0787 2.33477 11.9094 2.94937 12.5724 3.72488C13.2354 4.5004 13.7133 5.41652 13.97 6.40396C14.2373 7.51673 14.2164 8.67929 13.9091 9.7817C13.6018 10.8841 13.0184 11.8899 12.2141 12.7041C11.836 13.0896 12.4286 13.683 12.8067 13.2966C14.0326 12.0694 14.7951 10.4552 14.9643 8.72889C15.1334 7.0026 14.6987 5.27107 13.7343 3.82929C12.7699 2.38752 11.3354 1.3247 9.67518 0.821905C8.01496 0.319113 6.23177 0.407456 4.62939 1.07188C3.48979 1.54378 2.48421 2.28949 1.70177 3.24294C0.919333 4.19638 0.384199 5.3281 0.143798 6.53781C-0.0966037 7.74751 -0.0348476 8.99784 0.323592 10.178C0.682031 11.3581 1.32609 12.4316 2.19867 13.3033C2.57669 13.6897 3.16928 13.0963 2.79126 12.7108L2.79378 12.7108Z"
              fill="#486497"
            />
            <path
              d="M4.68974 9.17644L7.20427 6.6621C7.28165 6.58314 7.38709 6.53795 7.49763 6.53638C7.55373 6.53643 7.60927 6.54756 7.66105 6.56913C7.71283 6.59071 7.75984 6.6223 7.79938 6.6621L10.3139 9.17644C10.3928 9.25535 10.4372 9.36237 10.4372 9.47397C10.4372 9.58556 10.3928 9.69259 10.3139 9.7715C10.235 9.85041 10.128 9.89474 10.0164 9.89474C9.90475 9.89474 9.79772 9.85041 9.71881 9.7715L7.9251 7.97794L7.9251 15.0809C7.92337 15.1915 7.87867 15.2971 7.80044 15.3753C7.72222 15.4536 7.61662 15.4983 7.50601 15.5C7.39487 15.5 7.28827 15.4558 7.20967 15.3773C7.13108 15.2987 7.08693 15.1921 7.08693 15.0809L7.08693 7.96536L5.28484 9.77569C5.24577 9.81476 5.19938 9.84575 5.14832 9.8669C5.09727 9.88804 5.04255 9.89893 4.98729 9.89893C4.93203 9.89893 4.87731 9.88804 4.82626 9.8669C4.7752 9.84575 4.72881 9.81476 4.68974 9.77569C4.65066 9.73661 4.61967 9.69023 4.59852 9.63918C4.57737 9.58813 4.56649 9.53341 4.56649 9.47816C4.56649 9.4229 4.57737 9.36818 4.59852 9.31713C4.61967 9.26608 4.65066 9.2197 4.68974 9.18063L4.68974 9.17644Z"
              fill="#486497"
            />
          </svg>
          {thumbs.length > 0 ? (
            <aside style={thumbsContainer}>{thumbs}</aside>
          ) : (
            ""
          )}
          {thumbs.length === 0 ? (
            <span className="label text-primary">
              télécharger la facture en PDF
            </span>
          ) : (
            ""
          )}
        </div>
      </div>
    </section>
  );
}

export default MyTransaction;
