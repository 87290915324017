import React, { forwardRef } from 'react';
import './modal.css';

const Modal = forwardRef((props, ref) => {
  const { handleClose, show, children } = props;
  const showHideClassName = show ? "modal display-block" : "modal hidden";

  return (
    <div className={showHideClassName} onClick={handleClose}>
      <section ref={ref} className='bg-white rounded-2xl border border-gray-custom-input w-fit p-8 m-auto'>
        {children}
      </section>
    </div>
  );
});

export default Modal;