import React from 'react';
import i18n from 'i18next';

function NewBadge() {
  return (
    <span className="bubble b-small b-new">
      {i18n.t(`dashboard.badges.NEW`)}
    </span>
  );
}

export default NewBadge;