import { toast } from "react-toastify";
import i18n from "i18next";
import axios from "axios";
import { disconnectUser } from "../../../utils/replaceUserSignIn";

import "./toast.css";

const handleClickYourDocument = (id) =>
  (window.location = process.env.REACT_APP_URL + "/history/" + id);
const MsgYourDocument = ({ name, id }) => (
  <div className="custom-toast" onClick={() => handleClickYourDocument(id)}>
    <div className="title-toast">
      {i18n.t("account.your_document", { name: name })}
    </div>
    <div className="subtitle-toast">{i18n.t("account.click_on_document")}</div>
  </div>
);
const NotifyYourDocument = (name, id) => {
  toast.success(<MsgYourDocument name={name} id={id} />, {
    position: "bottom-right",
    autoClose: 10000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};

function handleClickPaymentError(user) {
  const token = localStorage.getItem("token");
  if (!token) {
    disconnectUser();
  }
  const config = {
    headers: { Authorization: `${token}` },
  };

  let data = {
    user: {
      stripe_id: user.stripe_id,
    },
    lng: localStorage.getItem("i18nextLng"),
    modification: "update_payment",
  };

  axios
    .post(
      process.env.REACT_APP_URL_API + "/create-customer-portal-session",
      data,
      config
    )
    .then((response) => {
      window.location = response["data"].response;
    })
    .catch((error) => {
      console.error(error);
    });
}
const MsgPaymentError = ({ error, user }) => (
  <div className="custom-toast" onClick={() => handleClickPaymentError(user)}>
    <div className="title-toast">{error}</div>
    <div className="subtitle-toast">
      {i18n.t("account.update_payment_error_msg")}
    </div>
  </div>
);
const NotifyPaymentError = (error, user) => {
  toast.error(<MsgPaymentError error={error} user={user} />, {
    position: "top-center",
    autoClose: 10000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};

const MsgTransactionReceived = () => (
  <div className="custom-toast">
    <div className="title-toast">Votre facture a bien été envoyée</div>
    <div className="subtitle-toast">
      Votre paiement devrait intervenir dans quelques jours
    </div>
  </div>
);
const NotifyTransactionReceived = () => {
  toast.success(<MsgTransactionReceived />, {
    position: "bottom-right",
    autoClose: 10000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};

const MsgGeneralist = ({ title, subtitle }) => (
  <div className="custom-toast">
    <div className="title-toast">{title}</div>
    <div className="subtitle-toast">{subtitle}</div>
  </div>
);
const NotifyGeneralist = (title, subtitle) => {
  toast.success(<MsgGeneralist title={title} subtitle={subtitle} />, {
    position: "bottom-right",
    autoClose: 10000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};

export {
  NotifyYourDocument,
  NotifyPaymentError,
  NotifyTransactionReceived,
  NotifyGeneralist,
};
