import React, { useState, useEffect, useMemo} from 'react';
import {useDropzone} from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import './css/dropzone.css'

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
  margin: 'auto',
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 1,
  borderRadius: '0.9375rem',
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: 'white',
  color: '#486497',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  height: '100%',
  boxShadow: '0px 2px 20px 0px rgba(172, 172, 201, 0.25)'
};

const focusedStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

function MyAvatar(props) {
  const { i18n } = useTranslation();
  const [files, setFiles] = useState([]);
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: {'image/*': []}, 
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
      props.onAvatarChange(acceptedFiles[0]);
    },
    onChange: () => {
    },
    onFocus: () => {
    },
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);

  const thumbs = files.map(file => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          alt="img"
          src={file.preview}
          style={img}
          onLoad={() => { URL.revokeObjectURL(file.preview) }}
        />
      </div>
    </div>
  ));

  useEffect(() => {
    return () => files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (
    <section className="container-dropzone">
      <div {...getRootProps({style})}>
        <input name="avatar" id="avatar"  {...getInputProps()} />
        <div className="dropzone-text">
            {thumbs.length === 0 ? 
            <svg style={{margin: "auto"}} width="36" height="40" viewBox="0 0 36 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.6 0C1.611 0 0 1.62727 0 3.63636V32.7273C0 34.7364 1.611 36.3636 3.6 36.3636H15.6305C15.0977 35.2327 14.7259 34.0127 14.5441 32.7273H5.4C4.4064 32.7273 3.6 31.9127 3.6 30.9091V26.2074C3.6 25.7256 3.78894 25.2619 4.12734 24.9219L5.92734 23.1037C6.63114 22.3928 7.77066 22.3928 8.47266 23.1037L14.4 29.0909L14.5687 28.9205C15.5155 22.8386 20.7126 18.1818 27 18.1818C27.612 18.1818 28.2114 18.242 28.8 18.3274V12.4148C28.8 11.4511 28.4203 10.5256 27.7453 9.84375L19.0547 1.06534C18.3797 0.383523 17.4634 0 16.5094 0H3.6ZM16.2 2.6456L26.1 12.7273H18C17.0064 12.7273 16.2 11.9127 16.2 10.9091V2.6456ZM14.4 16.3636C15.3936 16.3636 16.2 17.1782 16.2 18.1818C16.2 19.1855 15.3936 20 14.4 20C13.4064 20 12.6 19.1855 12.6 18.1818C12.6 17.1782 13.4064 16.3636 14.4 16.3636ZM27 21.8182C22.0302 21.8182 18 25.8891 18 30.9091C18 35.9291 22.0302 40 27 40C31.9698 40 36 35.9291 36 30.9091C36 25.8891 31.9698 21.8182 27 21.8182ZM27 25.4545C27.9936 25.4545 28.8 26.2691 28.8 27.2727V29.0909H30.6C31.5936 29.0909 32.4 29.9055 32.4 30.9091C32.4 31.9127 31.5936 32.7273 30.6 32.7273H28.8V34.5455C28.8 35.5491 27.9936 36.3636 27 36.3636C26.0064 36.3636 25.2 35.5491 25.2 34.5455V32.7273H23.4C22.4064 32.7273 21.6 31.9127 21.6 30.9091C21.6 29.9055 22.4064 29.0909 23.4 29.0909H25.2V27.2727C25.2 26.2691 26.0064 25.4545 27 25.4545Z" fill="#2B3E60"/>
            </svg>
            : 
            <aside style={thumbsContainer}>
              {thumbs}
            </aside>}
            <span>{ i18n.t('account.form.image_placeholder') }</span>
        </div>
      </div>
    </section>
  )
}

export default MyAvatar