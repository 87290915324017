import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { ProSidebarProvider } from "react-pro-sidebar";
import { ToastContainer } from "react-toastify";

import Home from "./home/Home";
import Error from "./home/Error";
import Admin from "./home/Admin";

import AdminUser from "./home/component/AdminUser";
import AdminAffiliater from "./home/component/AdminAffiliater";

import NoCreditClient from "./components/users/component/modal/noCreditClient";
import NoCreditProspect from "./components/users/component/modal/noCreditProspect";

import Header from "./header/Header";
import SideBar from "./sidebar/Sidebar";

import Dashboard from "./components/dashboard/Dashboard";

import SignUp from "./components/users/Signup";
import SignIn from "./components/users/Signin";
import SignInValidation from "./components/users/SigninValidation";
import CreatePassword from "./components/users/CreatePassword";
import ResetPassword from "./components/users/ResetPassword";
// import ResetPasswordForm from "./components/users/ResetPasswordForm";
import Account from "./components/users/Account";
import Team from "./components/users/Team";
import Deconnexion from "./components/users/Deconnexion";
import ConfirmationToken from "./components/users/ConfirmationToken";
import PromptsListPaginated from "./components/users/PromptsPaginated";
import PromptHistory from "./components/users/PromptHistory";
import Affiliater from "./components/users/Affiliater";
import AffiliationSignUp from "./components/users/AffiliationSignUp";

import Rewrite from "./components/product/Rewrite";
import Metas from "./components/product/Metas";
import Faq from "./components/product/Faq";
import Paragraph from "./components/product/Paragraph";
import Sheet from "./components/product/Sheet";
import Blog from "./components/product/Blog";
import BlogPremium from "./components/product/BlogPremium";
import Social from "./components/product/Social";
import Web from "./components/product/Web";
import Definition from "./components/product/Definition";
import Excel from "./components/product/Excel";
import Press from "./components/product/Press";
import BlogPremiumAdmin from "./components/product/BlogPremiumAdmin";

import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";

function App() {
  const [currentUser, setCurrentUser] = useState(null);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [currentProject, setCurrentProject] = useState(null);
  const [currentAvatar, setCurrentAvatar] = useState(null);
  const [currentTeam, setCurrentTeam] = useState(null);
  const [currentTeamMember, setCurrentTeamMember] = useState(null);
  const [currentTeamLeader, setCurrentTeamLeader] = useState(null);
  const [userPayer, setUserPayer] = useState(null);
  const [isDarkMode, setDarkMode] = React.useState(false);
  const [theme, setTheme] = React.useState("light");
  const [isLoading, setIsLoading] = useState(true);
  const [selectedProject, setSelectedProject] = useState(null);
  const [currentToken, setCurrentToken] = useState(null);
  const [selectCountry, setSelectCountry] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [showModalC, setShowModalC] = useState(false);
  const [showModalP, setShowModalP] = useState(false);
  const modalRefProspect = useRef(null);
  const modalRefProspectButton = useRef(null);

  const showModalClient = () => {
    const header = document.getElementById("header")
    if (header) {
      header.classList.remove("z-50");
    }
    setShowModalC(true);
  };

  const hideModalClient = () => {
    localStorage.setItem("noCreditClient", true);
    const header = document.getElementById("header")
    if (header) {
      header.classList.add("z-50");
    }
    setShowModalC(false);
  };

  const showModalProspect = () => {
    const header = document.getElementById("header")
    if (header) {
      header.classList.remove("z-50");
    }
    setShowModalP(true);
  };

  const hideModalProspect = (e) => {
    if (modalRefProspectButton.current && modalRefProspectButton.current.contains(e.target)) {
      localStorage.setItem("noCreditProspect", true);
      const header = document.getElementById("header")
      if (header) {
        header.classList.add("z-50");
      }
      setShowModalP(false);
      setTimeout(() => {
        document.getElementById('pricing-button').click();
      }, 500);
    } else if (modalRefProspect.current && modalRefProspect.current.contains(e.target)) {
    } else {
      localStorage.setItem("noCreditProspect", true);
      const header = document.getElementById("header")
      if (header) {
        header.classList.add("z-50");
      }
      setShowModalP(false);
    }
  };


  const toggleDarkMode = (checked) => {
    setDarkMode(checked);
    setTheme(checked ? "dark" : "light");
  };

  const checkIfSignUpAffiliation = () => {
    let url = window.location.href;
    if (url.includes("/aff/")) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (checkIfSignUpAffiliation()) {
      localStorage.setItem(
        "affiliater",
        window.location.pathname.replace("/aff/", "")
      );
      window.location = process.env.REACT_APP_URL + "/users/sign_up";
      return;
    }
    let token = localStorage.getItem("token");
    if (!token) {
      setIsLoading(false);
      return;
    }
    let language = localStorage.getItem("language");
    if (!language) {
      setSelectCountry("FR");
      localStorage.setItem("language", "FR");
    } else {
      setSelectCountry(language);
    }
    let lang = document.getElementById('lang')
    if (lang) {
      if(language === null) {
        lang.lang = "fr";
      } else {
        lang.lang = language.toLowerCase();
      }
    }

    const config = {
      headers: { Authorization: `${token}` },
    };

    axios
      .get(process.env.REACT_APP_URL_API + "/current_user", config)
      .then((res) => {
        if (res.data == null) {
          setTimeout(() => {
            axios
              .get(process.env.REACT_APP_URL_API + "/current_user", config)
              .then((res) => {
                if (res.data == null) {
                  localStorage.removeItem("token");
                  localStorage.removeItem("tokenExpiration");
                  return;
                } else {
                  setCurrentUser(res.data["user"]);
                  setCurrentAvatar(res.data["avatar"]);
                  setCurrentProject(res.data["project"]);
                  setCurrentToken(res.data["user"]["token"] > 0 ? res.data["user"]["token"] : 0);
                  // setIsLoading(false);
                }
              })
              .catch((res) => {
                if (res.response["data"] === "Signature has expired") {
                  localStorage.removeItem("token");
                  localStorage.removeItem("tokenExpiration");
                  setIsLoading(false);
                  window.location =
                    process.env.REACT_APP_URL + "/users/sign_in";
                  return;
                }
                if (res.response.status === 401) {
                  localStorage.removeItem("token");
                  localStorage.removeItem("tokenExpiration");
                  setIsLoading(false);
                  window.location =
                    process.env.REACT_APP_URL + "/users/sign_in";
                  return;
                }
              });
          }, 1000);
        } else {
          setCurrentUser(res.data["user"]);
          setCurrentAvatar(res.data["avatar"]);
          setCurrentProject(res.data["project"]);
          setCurrentToken(res.data["user"]["token"] > 0 ? res.data["user"]["token"] : 0);
          if (res.data["user"].role !== "member") {
            setCurrentToken(res.data["user"]["token"] > 0 ? res.data["user"]["token"] : 0);
            setUserPayer(res.data["user"]);
          }
          // setIsLoading(false);
          // localStorage.removeItem("affiliater");
        }
      })
      .catch((res) => {
        if (res.response.status === 401 || res.response.status === 500) {
          localStorage.removeItem("token");
          localStorage.removeItem("tokenExpiration");
          // setIsLoading(false);
          window.location = process.env.REACT_APP_URL + "/users/sign_in";
          return;
        }
        if (
          res.response["data"] === "Signature has expired" ||
          res.response["data"] === "No verification key available"
        ) {
          localStorage.removeItem("token");
          localStorage.removeItem("tokenExpiration");
          setIsLoading(false);
          window.location = process.env.REACT_APP_URL + "/users/sign_in";
          return;
        }
      });

    axios
      .get(process.env.REACT_APP_URL_API + "/current_plan", config)
      .then((res) => {
        if (res.data == null) {
          window.location = process.env.REACT_APP_URL + "/users/sign_in";
          localStorage.removeItem("token");
          localStorage.removeItem("tokenExpiration");
          return;
        } else {
          if (res.data["plan"] === null) {
            return;
          } else {
            setCurrentPlan(res.data);
            // setIsLoading(false);
          }
        }
      })
      .catch((res) => {
        if (res.response["data"] === "Signature has expired") {
          localStorage.removeItem("token");
          localStorage.removeItem("tokenExpiration");
          setIsLoading(false);
          window.location = process.env.REACT_APP_URL + "/users/sign_in";
          return;
        }
        if (res.response.status === 401 || res.response.status === 500) {
          localStorage.removeItem("token");
          localStorage.removeItem("tokenExpiration");
          setIsLoading(false);
          window.location = process.env.REACT_APP_URL + "/users/sign_in";
          return;
        }
      });

    axios
      .get(process.env.REACT_APP_URL_API + "/api/v1/teams", config)
      .then((res) => {
        if (res.data !== null) {
          setCurrentTeam(res.data["team"]);
          setCurrentTeamMember(res.data["member"]);
          if (res.data["leader"] !== null) {
            setCurrentTeamLeader(res.data["leader"]["user"]);
          }
          if (res.data["leader"]) {
            setUserPayer(res.data["leader"]["user"]);
            setCurrentToken(res.data["leader"]["user"].token > 0 ? res.data["leader"]["user"].token : 0);
          }
          setIsLoading(false);
        }
      })
      .catch((res) => {
        setIsLoading(false);
        if (res.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("tokenExpiration");
          window.location = process.env.REACT_APP_URL + "/users/sign_in";
          return;
        }
        if (
          res.response["data"] === "Signature has expired" ||
          res.response["data"] === "No verification key available"
        ) {
          localStorage.removeItem("token");
          localStorage.removeItem("tokenExpiration");
          window.location = process.env.REACT_APP_URL + "/users/sign_in";
          return;
        }
      });
  }, []);

  useEffect(() => {
    const noCreditClient = localStorage.getItem("noCreditClient");
    if (currentPlan &&
      (currentPlan.status === 1 && currentPlan.id_product !== "-1") &&
      currentUser && currentUser.token <= 0 && !noCreditClient) {
      showModalClient()
    }
    const noCreditProspect = localStorage.getItem("noCreditProspect");
    if (currentPlan &&
      (currentPlan.status === 1 && currentPlan.id_product === "-1") &&
      currentUser && currentUser.token <= 0 && !noCreditProspect) {
      showModalProspect()
    }
  });

  function AddSideBarAndHeader(props) {
    const { isDarkMode, toggleDarkMode, theme } = props;
    const location = useLocation();

    if (
      location.pathname.includes("sign_") ||
      location.pathname.includes("reset")
    ) {
      return (
        <div className='w-full flex flex-row'>
          <ToastContainer />
          <Routes>
            <Route path="*" element={<Error />} />
            <Route path="/" element={<Home />} />
            <Route path="/users/sign_in" element={<SignIn />} />
            <Route
              path="/users/sign_in/validation"
              element={<SignInValidation />}
            />
            <Route path="/users/sign_up" element={<SignUp />} />
            <Route path="/users/sign_out" element={<Deconnexion />} />
            <Route path="/users/reset_password" element={<ResetPassword />} />
            <Route
              path="/users/sign_up/create_password"
              element={<CreatePassword />}
            />
            {/* <Route path="/users/reset" element={<ResetPasswordForm />} /> */}
            <Route
              path="/users/account/confirmation"
              element={<ConfirmationToken />}
            />
            <Route path="/aff/:uuid" element={<AffiliationSignUp />} />
          </Routes>
        </div>

      );
    } else {
      return (
        <div className='w-full flex flex-row-reverse'>
          <ToastContainer />
          <div className='w-full'>
            <Header
              isDarkMode={isDarkMode}
              toggleDarkMode={toggleDarkMode}
              user={currentUser}
              plan={currentPlan}
              avatar={currentAvatar}
              tokenprops={currentToken}
              setSelectCountry={setSelectCountry}
              selectCountry={selectCountry}
            ></Header>
            <Routes>
              <Route path="*" element={<Error />} />
              <Route
                path="/"
                element={<Home user={currentUser} plan={currentPlan} />}
              />
              <Route
                path="/dashboard"
                element={<Dashboard user={currentUser} plan={currentPlan} />}
              />
              <Route
                path="/users/account"
                element={
                  <Account
                    user={currentUser}
                    plan={currentPlan}
                    avatar={currentAvatar}
                    team={currentTeam}
                    member={currentTeamMember}
                    leader={currentTeamLeader}
                  />
                }
              />
              <Route
                path="/users/team"
                element={
                  <Team
                    user={currentUser}
                    plan={currentPlan}
                    team={currentTeam}
                    member={currentTeamMember}
                    leader={currentTeamLeader}
                  />
                }
              />
              <Route
                path="/users/account/confirmation"
                element={<ConfirmationToken />}
              />
              <Route
                path="/history"
                element={
                  <PromptsListPaginated
                    itemsPerPage={itemsPerPage}
                    setItemsPerPage={setItemsPerPage}
                    selectedProject={selectedProject}
                    user_id={currentUser && currentUser.id}
                  />
                }
              />
              <Route
                path="/history/:id"
                element={<PromptHistory user={currentUser} selectCountry={selectCountry} />}
              />
              <Route
                path="/product/rewrite"
                element={
                  <Rewrite
                    user={userPayer}
                    plan={currentPlan}
                    selectCountry={selectCountry}
                    setSelectCountry={setSelectCountry}
                    selectedProject={selectedProject}
                  />
                }
              />
              <Route
                path="/product/metas"
                element={
                  <Metas
                    user={userPayer}
                    plan={currentPlan}
                    selectCountry={selectCountry}
                    setSelectCountry={setSelectCountry}
                    selectedProject={selectedProject}
                  />
                }
              />
              <Route
                path="/product/faq"
                element={
                  <Faq
                    user={userPayer}
                    plan={currentPlan}
                    selectCountry={selectCountry}
                    setSelectCountry={setSelectCountry}
                    selectedProject={selectedProject}
                  />
                }
              />
              <Route
                path="/product/paragraph"
                element={
                  <Paragraph
                    user={userPayer}
                    plan={currentPlan}
                    selectCountry={selectCountry}
                    setSelectCountry={setSelectCountry}
                    selectedProject={selectedProject}
                  />
                }
              />
              <Route
                path="/product/sheet"
                element={
                  <Sheet
                    user={userPayer}
                    plan={currentPlan}
                    selectCountry={selectCountry}
                    setSelectCountry={setSelectCountry}
                    selectedProject={selectedProject}
                  />
                }
              />
              <Route
                path="/product/blog"
                element={
                  <Blog
                    user={userPayer}
                    userToken={currentToken}
                    plan={currentPlan}
                    selectCountry={selectCountry}
                    setSelectCountry={setSelectCountry}
                    selectedProject={selectedProject}
                  />
                }
              />
              <Route
                path="/product/social"
                element={
                  <Social
                    user={userPayer}
                    plan={currentPlan}
                    selectCountry={selectCountry}
                    setSelectCountry={setSelectCountry}
                    selectedProject={selectedProject}
                  />
                }
              />
              <Route
                path="/product/web"
                element={
                  <Web
                    user={userPayer}
                    plan={currentPlan}
                    selectCountry={selectCountry}
                    setSelectCountry={setSelectCountry}
                    selectedProject={selectedProject}
                  />
                }
              />
              <Route
                path="/product/definition"
                element={
                  <Definition
                    user={userPayer}
                    plan={currentPlan}
                    selectCountry={selectCountry}
                    setSelectCountry={setSelectCountry}
                    selectedProject={selectedProject}
                  />
                }
              />
              <Route
                path="/product/excel"
                element={
                  <Excel
                    user={userPayer}
                    plan={currentPlan}
                    selectCountry={selectCountry}
                    setSelectCountry={setSelectCountry}
                    selectedProject={selectedProject}
                  />
                }
              />
              <Route
                path="/product/press"
                element={
                  <Press
                    user={userPayer}
                    plan={currentPlan}
                    selectCountry={selectCountry}
                    setSelectCountry={setSelectCountry}
                    selectedProject={selectedProject}
                  />
                }
              />
              <Route
                path="/product/blog-premium"
                element={
                  <BlogPremium
                    user={userPayer}
                    userToken={currentToken}
                    plan={currentPlan}
                    selectCountry={selectCountry}
                    setSelectCountry={setSelectCountry}
                    selectedProject={selectedProject}
                  />
                }
              />
              {currentUser && currentUser.role === "admin" && (
                <>
                  <Route path="/users/admin" element={<Admin />} />
                  <Route path="/users/admin/:id" element={<AdminUser />} />
                  <Route path="/users/admin/affiliater/:id" element={<AdminAffiliater />} />
                  <Route path="/product/blog-premium-admin" element={
                    <BlogPremiumAdmin
                      user={userPayer}
                      plan={currentPlan}
                      selectCountry={selectCountry}
                      setSelectCountry={setSelectCountry}
                      selectedProject={selectedProject}
                    />
                  } />
                </>
              )}

              {currentUser &&
                (currentUser.role === "affiliater" ||
                  currentUser.role === "admin") && (
                  <>
                    <Route
                      path="/users/affiliater"
                      element={
                        <Affiliater
                          user={currentUser}
                          plan={currentPlan}
                          avatar={currentAvatar}
                        />
                      }
                    />
                  </>
                )}
            </Routes>
          </div>
          <SideBar
            backgroundColorProps={{ theme }}
            user={currentUser}
            team={currentTeam}
            projects={currentProject}
            selectedProject={selectedProject}
            setSelectedProject={setSelectedProject}
            setCurrentProject={setCurrentProject}
            currentPlan={currentPlan}
          />
        </div>
      );
    }
  }

  if (isLoading) {
    return <div></div>;
  }



  return (
    <div>
      {
        <ProSidebarProvider>
          <NoCreditProspect buttonRef={modalRefProspectButton} modalRef={modalRefProspect} show={showModalP} setShow={setShowModalP} showModal={showModalProspect} hideModal={hideModalProspect} user={currentUser} plan={currentPlan}></NoCreditProspect>
          <NoCreditClient show={showModalC} setShow={setShowModalC} showModal={showModalClient} hideModal={hideModalClient} user={currentUser} plan={currentPlan}></NoCreditClient>
          <Router basename="/">
            <div className={`App ${theme} flex`}>
              <AddSideBarAndHeader
                isDarkMode={isDarkMode}
                toggleDarkMode={toggleDarkMode}
                theme={theme}
              ></AddSideBarAndHeader>
            </div>
          </Router>
        </ProSidebarProvider>
      }
    </div>
  );
}

export default App;
