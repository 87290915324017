import React from 'react';
import ReactFlagsSelect from "react-flags-select";
import { useTranslation } from 'react-i18next';

import '../../product/styles/product.css'
import "./flags.css";

const Settings = ({selectCountry, setSelectCountry }) => {
    const { i18n } = useTranslation();

    const onSelect = (code) => {
        setSelectCountry(code);
        localStorage.setItem("language", code);
    }

    return (
        <>
            <ReactFlagsSelect
                className="prompt-language"
                selected={selectCountry}
                onSelect={onSelect}
                countries={["FR", "GB", "US", "IT", "ES", "DE", "PT"]}
                customLabels={{ US: i18n.t('settings.language.us'), GB: i18n.t('settings.language.en'), FR: i18n.t('settings.language.fr'), DE: i18n.t('settings.language.de'), IT:i18n.t('settings.language.it'), ES: i18n.t('settings.language.es'), PT: i18n.t('settings.language.pt') }}
                fullWidth={true}
            />
        </>
    )
}

export default Settings;