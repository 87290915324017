import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Switch from "react-switch";
import { useNavigate } from "react-router-dom";
import { NotifyPaymentError } from "../product/component/Toast";
import { useTranslation } from "react-i18next";
import Pricing from "./component/pricing_table/pricingAccount";
import Team from "./Team";

import AccountForm from "./component/account/form/AccountForm.jsx";
import { ConfirmEraseUserAccount } from "../modal/confirm/confirmEraseUserAccount";
import { formatDate } from "../../components/product/component/sharedFunction.js";

import { disconnectUser } from "../../utils/replaceUserSignIn";
import Page from "./component/account/Page";
import ReactFlagsSelect from "react-flags-select";

import "./styles/accountStyle.css";
import "../../assets/Common.css";

function Account(props) {
  const { user, plan, avatar, team, member, leader } = props;
  const [setShowStripe] = useState(false);
  const [, setTokenPrompt] = useState(0);
  const [page, setPage] = useState(0);
  const { i18n } = useTranslation();
  const [isMounted] = useState(false);
  const modalRef = useRef();
  const [selected, setSelected] = useState("");
  const [emailBilling, setEmailBilling] = useState(
    user && user.email_billing ? user && user.email_billing : ""
  );
  const [sendInvoice, setSendInvoice] = useState(
    user && user.send_invoice ? user && user.send_invoice : false
  );
  const navigate = useNavigate();

  const urlName = (index) => {
    switch (index) {
      case 0:
        return "profile";
      case 1:
        return "plan";
      case 2:
        return "billing";
      case 3:
        return "team";
      case 4:
        return "danger";
      default:
        return "";
    }
  };

  const setPageAndIndex = (index) => {
    setPage(index);
    navigate(`/users/account?${urlName(index)}`);
  };

  const convertDetectedLanguage = (lng) => {
    const regex = /-(\w+)/;
    const cleanedLng = lng.replace(regex, "");
    return cleanedLng;
  };

  function handleAccountStripe(plan) {
    let token = localStorage.getItem("token");
    if (!token) {
      return;
    }
    const config = {
      headers: { Authorization: `${token}` },
    };

    if (plan && plan.status === -2) {
      axios
        .get(process.env.REACT_APP_URL_API + "/reactive_subscription", config)
        .then((response) => {
          window.location.reload(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }

    if (plan && plan.status === 1) {
      axios
        .delete(process.env.REACT_APP_URL_API + "/cancel_subscription", config)
        .then((response) => {
          window.location.reload(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (!plan) {
      axios
        .delete(process.env.REACT_APP_URL_API + "/user", config)
        .then((response) => {
          disconnectUser();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  function handleStripe(modification) {
    const token = localStorage.getItem("token");
    if (!token) {
      disconnectUser();
    }
    const config = {
      headers: { Authorization: `${token}` },
    };

    let data = {
      user: {
        stripe_id: user.stripe_id,
      },
      lng: localStorage.getItem("i18nextLng"),
      modification: modification,
    };

    axios
      .post(
        process.env.REACT_APP_URL_API + "/create-customer-portal-session",
        data,
        config
      )
      .then((response) => {
        window.location = response["data"].response;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const onSelect = (code) => {
    const lang_code = code.toLowerCase();
    const lng = lang_code === "us" ? "en" : lang_code;
    i18n.changeLanguage(lng);
    localStorage.setItem("i18nextLng", lng);
    setSelected(convertDetectedLanguage(lang_code.toUpperCase()));

    let data = { lng: lng };
    const token = localStorage.getItem("token");
    if (!token) {
      window.location.replace(process.env.REACT_APP_URL + `/users/sign_in`);
    }
    const config = {
      headers: { Authorization: `${token}` },
    };
    axios
      .put(process.env.REACT_APP_URL_API + "/update_lng", data, config, {
        validateStatus: function (status) {
          return status >= 200 && status < 500; // Catch errors from 200 to 499
        },
      })
      .then((response) => {});
  };

  useEffect(() => {
    let token = localStorage.getItem("token");
    if (!token) {
      return;
    }
    if (user !== null) {
      setTokenPrompt(user.token);
    }

    function handleClickOutside(event) {
      if (
        isMounted &&
        modalRef.current &&
        !modalRef.current.contains(event.target) &&
        !event.target.classList.contains("modal-main-account")
      ) {
        setShowStripe(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    if (plan && plan && plan.status === -3) {
      NotifyPaymentError(i18n.t("account.payment_update"), user);
    }
    console.log(plan);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [user, isMounted, setShowStripe, plan, i18n]);

  useEffect(() => {
    let traduction = localStorage.getItem("i18nextLng");
    if (!traduction) {
      traduction = "fr";
    }
    localStorage.setItem(
      "i18nextLng",
      traduction === "us" ? "en" : convertDetectedLanguage(traduction)
    );
    setSelected(
      traduction === "en"
        ? "US"
        : convertDetectedLanguage(traduction.toUpperCase())
    );
  }, [setSelected]);

  useEffect(() => {
    const queryParameters = window.location.search;
    switch (queryParameters) {
      case "?profile":
        setPage(0);
        break;
      case "?plan":
        setPage(1);
        break;
      case "?billing":
        setPage(2);
        break;
      case "?team":
        setPage(3);
        break;
      case "?danger":
        setPage(4);
        break;
      default:
        break;
    }
  }, []);
  const handleSubmit = (event) => {
    event.preventDefault();
    document.getElementById("submit-form").disabled = true;
    let token = localStorage.getItem("token");
    if (!token) {
      return;
    }
    const config = {
      headers: {
        Authorization: `${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    let data = new FormData();
    if (emailBilling !== "" && emailBilling !== null) {
      data.append("user[email_billing]", emailBilling);
    }
    if (
      sendInvoice !== "" &&
      sendInvoice !== null &&
      (sendInvoice === true || sendInvoice === false)
    ) {
      data.append("user[send_invoice]", sendInvoice);
    }

    axios
      .put(process.env.REACT_APP_URL_API + "/update_user", data, config)
      .then((response) => {
        if (response["data"]["email_billing"]) {
          document.getElementById("email_billing").src =
            process.env.REACT_APP_URL_API + response["data"]["email_billing"];
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const toggleSendBill = () => {
    setSendInvoice(!sendInvoice);
  };

  return (
    user && (
      <div className="container text-left gap-y-2.5">
        <h1 className="text-secondary">{i18n.t("account.my")}</h1>
        <div className="flex flex-col gap-y-6">
          <Page page={page} setPage={setPageAndIndex} user={user} plan={plan} />
          <hr className="transaction-divider-sidebar"></hr>
          <div className="flex flex-col w-full gap-y-2">
            {page === 0 && (
              <div className="flex flex-col w-full gap-y-2">
                <h3 className="text-primary font-semibold">
                  {i18n.t("account.profil")}
                </h3>
                <div className="flex flex-row gap-x-4">
                  <p className="my-auto text-secondary">
                    {i18n.t("account.lng")}
                  </p>
                  <div style={{ width: "5rem", height: "3rem" }}>
                    <ReactFlagsSelect
                      selected={selected}
                      onSelect={onSelect}
                      className="menu-flags border border-gray-custom-input rounded btn"
                      selectButtonClassName=""
                      countries={["FR", "US", "IT", "DE", "PT"]}
                      customLabels={{
                        US: "English",
                        FR: "Français",
                        DE: "Deutsch",
                        IT: "Italiano",
                        ES: "Español",
                        PT: "Português",
                      }}
                      fullWidth={true}
                    />
                  </div>
                </div>
                <AccountForm user={user} avatar={avatar} />
              </div>
            )}
            {page === 1 && user && user.role !== "member" && (
              <div className="flex flex-col w-full gap-y-10">
                {user &&
                  plan &&
                  plan !== null &&
                  plan.status !== -3 &&
                  plan.id_product !== "-1" && (
                    <div className="flex flex-col gap-y-3.5">
                      <h3 className="text-primary font-semibold	">
                        {i18n.t("account.my_plan")}
                      </h3>
                      <div className="flex flex-row gap-x-4">
                        <div>
                          <button
                            onClick={() => handleStripe("update_payment")}
                            className="text-primary flex flex-row gap-x-4 bg-white border border-gray-custom-input"
                          >
                            <svg
                              className="my-auto"
                              width="18"
                              height="19"
                              viewBox="0 0 18 19"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8.6745 14.2208H7.69775C5.37993 14.2208 4.21643 14.2208 3.59659 13.601C2.97675 12.9811 2.97675 11.8178 2.97675 9.5C2.97675 9.21108 2.97675 8.94032 2.97792 8.68598H5.74426C6.01404 8.68598 6.23263 8.46738 6.23263 8.19761C6.23263 7.92783 6.01404 7.70923 5.74426 7.70923H2.99355C3.03262 6.53303 3.16272 5.83271 3.59659 5.39884C4.21643 4.77899 5.37993 4.77899 7.69775 4.77899C7.96753 4.77899 8.18613 4.5604 8.18613 4.29062C8.18613 4.02084 7.96734 3.80225 7.69775 3.80225C6.4313 3.80225 5.51629 3.80225 4.77474 3.90187C3.92575 4.01596 3.34947 4.26464 2.90603 4.70808C2.46259 5.15152 2.21391 5.728 2.09963 6.57679C2.04063 7.01691 2.01641 7.51799 2.00664 8.11712C2.00234 8.1433 2 8.17006 2 8.19761C2 8.22027 2.00176 8.24234 2.00469 8.26422C2 8.63636 2 9.04542 2 9.5C2 10.7664 2 11.6815 2.09963 12.423C2.21371 13.272 2.46239 13.8483 2.90583 14.2917C3.34928 14.7352 3.92556 14.9838 4.77455 15.0979C5.51629 15.1976 6.4313 15.1976 7.69756 15.1976H8.6743C8.94408 15.1976 9.16268 14.979 9.16268 14.7092C9.16268 14.4394 8.94428 14.2208 8.6745 14.2208Z"
                                fill="#486497"
                              />
                              <path
                                d="M12.2361 13.0616L11.7675 13.5303V10.8024C11.7675 10.5326 11.5489 10.314 11.2791 10.314C11.0093 10.314 10.7907 10.5326 10.7907 10.8024V13.5303L10.3221 13.0616C10.1314 12.8709 9.82218 12.8709 9.63132 13.0616C9.44066 13.2523 9.44066 13.5615 9.63132 13.7524L10.9312 15.0522C10.932 15.053 10.9327 15.0536 10.9335 15.0544C10.9335 15.0544 10.9349 15.0557 10.9357 15.0565C10.9363 15.0571 10.9372 15.0581 10.9378 15.0587L10.9384 15.0592C10.9384 15.0592 10.9394 15.0602 10.9398 15.0606C10.9402 15.061 10.9404 15.0612 10.9407 15.0616C10.9865 15.1055 11.0386 15.1388 11.0939 15.1614C11.0943 15.1614 11.0947 15.1618 11.0951 15.1618C11.1482 15.1833 11.2058 15.1958 11.2664 15.1972C11.2666 15.1972 11.2668 15.1972 11.2672 15.1972C11.2682 15.1972 11.2693 15.1972 11.2705 15.1972C11.2715 15.1972 11.2726 15.1972 11.2738 15.1972C11.2762 15.1972 11.2769 15.1972 11.2787 15.1972C11.2805 15.1972 11.2818 15.1972 11.2832 15.1972C11.284 15.1972 11.2848 15.1972 11.2855 15.1972C11.2863 15.1972 11.2871 15.1972 11.2879 15.1972C11.2887 15.1972 11.2896 15.1972 11.2906 15.1972C11.3533 15.1956 11.4129 15.1823 11.4676 15.1595C11.468 15.1595 11.4684 15.1593 11.4686 15.1591C11.5215 15.1368 11.5715 15.1046 11.6155 15.0628C11.6159 15.0624 11.6163 15.062 11.6168 15.0616C11.6168 15.0616 11.6174 15.061 11.6176 15.0608C11.6182 15.0602 11.6188 15.0598 11.6192 15.0592C11.6198 15.0587 11.621 15.0575 11.6215 15.0569C11.6223 15.0561 11.6229 15.0555 11.6237 15.0548L12.9261 13.7524C13.1167 13.5617 13.1167 13.2525 12.9261 13.0616C12.7354 12.8708 12.4262 12.8709 12.2353 13.0616H12.2361Z"
                                fill="#486497"
                              />
                              <path
                                d="M15.8569 11.7592L14.5545 10.4568C14.5545 10.4568 14.5531 10.4555 14.5524 10.4547C14.5524 10.4547 14.5508 10.4531 14.5502 10.4525C14.5502 10.4525 14.5487 10.4512 14.5483 10.4506C14.5481 10.4504 14.5477 10.45 14.5475 10.4498C14.5471 10.4494 14.5467 10.449 14.5461 10.4486C14.5453 10.448 14.5447 10.4472 14.544 10.4467C14.4992 10.4045 14.4484 10.3724 14.3947 10.3504C14.3943 10.3504 14.3937 10.35 14.3933 10.3498C14.3402 10.3281 14.2818 10.3154 14.2211 10.314C14.2203 10.314 14.2193 10.314 14.2183 10.314C14.2175 10.314 14.2168 10.314 14.216 10.314C14.2152 10.314 14.2144 10.314 14.2136 10.314C14.2105 10.314 14.2076 10.314 14.2044 10.314C14.2039 10.314 14.2027 10.314 14.2019 10.314C14.2013 10.314 14.2005 10.314 14.1998 10.314C14.1988 10.314 14.198 10.314 14.197 10.314C14.1345 10.3156 14.0747 10.3289 14.0202 10.3517C14.0198 10.3517 14.0195 10.3521 14.0191 10.3523C13.9657 10.3748 13.9155 10.4072 13.8716 10.4494C13.8712 10.4498 13.8706 10.4504 13.8702 10.4508C13.8696 10.4512 13.8692 10.4517 13.8686 10.4521L13.8681 10.4527C13.8681 10.4527 13.8669 10.4539 13.8663 10.4545C13.8655 10.4553 13.8647 10.456 13.864 10.4568L12.5616 11.7592C12.3709 11.9499 12.3709 12.2591 12.5616 12.45C12.7522 12.6406 13.0615 12.6406 13.2523 12.45L13.721 11.9813V14.7094C13.721 14.9792 13.9396 15.1978 14.2093 15.1978C14.4791 15.1978 14.6977 14.9792 14.6977 14.7094V11.9815L15.1663 12.4502C15.357 12.6408 15.6662 12.6408 15.8571 12.4502C16.0478 12.2595 16.0476 11.9501 15.8569 11.7592Z"
                                fill="#486497"
                              />
                              <path
                                d="M5.09297 11.6162C4.82319 11.6162 4.60459 11.8348 4.60459 12.1046C4.60459 12.3744 4.82319 12.593 5.09297 12.593H7.69756C7.96733 12.593 8.18593 12.3744 8.18593 12.1046C8.18593 11.8348 7.96733 11.6162 7.69756 11.6162H5.09297Z"
                                fill="#486497"
                              />
                              <path
                                d="M7.86048 8.19761C7.86048 8.46738 8.07907 8.68598 8.34885 8.68598H15.022C15.0222 8.73989 15.0224 8.79459 15.0226 8.85027C15.0234 9.11946 15.2419 9.33727 15.5109 9.33727H15.5123C15.7821 9.33649 16.0001 9.11711 15.9993 8.84753C15.9987 8.64515 15.9978 8.45039 15.9954 8.26246C15.9981 8.24136 15.9997 8.21968 15.9997 8.1978C15.9997 8.17123 15.9976 8.14506 15.9935 8.11947C15.9686 6.52092 15.8387 5.45314 15.0939 4.70828C14.6504 4.26483 14.0742 4.01615 13.2252 3.90207C12.4838 3.80225 11.5688 3.80225 10.3023 3.80225C10.0326 3.80225 9.81397 4.02084 9.81397 4.29062C9.81397 4.5604 10.0326 4.77899 10.3023 4.77899C12.6202 4.77899 13.7837 4.77899 14.4035 5.39884C14.8331 5.82841 14.9657 6.51291 15.0062 7.70923H8.34905C8.07927 7.70923 7.86048 7.92783 7.86048 8.19761Z"
                                fill="#486497"
                              />
                            </svg>
                            <span className="text-secondary ">
                              {i18n.t("account.plan.update_payment")}
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                <div className="flex flex-col gap-y-3.5">
                  <h3 className="text-primary font-semibold	">
                    {user &&
                      plan &&
                      plan !== null &&
                      plan.status !== -3 &&
                      plan.id_product !== "-1" && (
                        <div>{i18n.t("account.change_plan")}</div>
                      )}
                    {user && !plan && (
                      <div>{i18n.t("account.choose_plan")}</div>
                    )}
                  </h3>
                  <div className="flex flex-col gap-y-4">
                    <div className="flex flex-row gap-x-4">
                      {user && plan && plan !== null && plan.status !== -3 && (
                        <div className="flex flex-row gap-x-4 ">
                          <p className="text-secondary my-auto">
                            {i18n.t("account.actual_plan")}
                          </p>
                          <div className="padding-custom-badge text-success bg-success border border-white rounded-custom-input">
                            {user &&
                              plan &&
                              (plan !== null || plan.status === -3) &&
                              (plan.name && plan.name.includes("demo")
                                ? "Démo"
                                : plan.name)}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="flex flex-row gap-x-4">
                      <Pricing user={user}></Pricing>
                    </div>
                    <div className="flex flex-row gap-x-4">
                      {/* {user &&
                        (plan === null ||
                          plan.id_product === "-1" ||
                          (plan && plan.status !== -3)) &&
                        user.role !== "member" &&
                        (plan === null ||
                          !(
                            plan.id_stripe ===
                              "price_1MdbZOIv4JgLR6izzk0WjMgQ" ||
                            plan.id_stripe === "price_1N3DI0Iv4JgLR6izP5eiQWTt"
                          )) && <Pricing user={user}></Pricing>} */}
                      {plan &&
                        plan.id_stripe !== "price_1MdbZOIv4JgLR6izzk0WjMgQ" &&
                        plan.id_stripe !== "price_1N3DI0Iv4JgLR6izP5eiQWTt" &&
                        plan.id_product !== "-1" &&
                        plan.status !== -3 && (
                          <div>
                            <button
                              onClick={() => handleStripe("upgrade")}
                              className="text-primary flex flex-row gap-x-4 bg-white border border-gray-custom-input"
                            >
                              <svg
                                className="my-auto"
                                width="15"
                                height="15"
                                viewBox="0 0 15 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M3.33333 0.833333C3.33333 0.373096 3.70643 0 4.16667 0H14.1667C14.6269 0 15 0.373096 15 0.833333V10.8333C15 11.2936 14.6269 11.6667 14.1667 11.6667C13.7064 11.6667 13.3333 11.2936 13.3333 10.8333V2.84518L7.5337 8.64481C7.20826 8.97025 6.68063 8.97025 6.35519 8.64481C6.02975 8.31937 6.02975 7.79174 6.35519 7.4663L12.1548 1.66667H4.16667C3.70643 1.66667 3.33333 1.29357 3.33333 0.833333ZM4.20037 10.7996C4.5258 11.1251 4.5258 11.6527 4.20037 11.9781L1.42259 14.7559C1.09715 15.0814 0.569515 15.0814 0.244078 14.7559C-0.0813592 14.4305 -0.0813592 13.9028 0.244078 13.5774L3.02186 10.7996C3.34729 10.4742 3.87493 10.4742 4.20037 10.7996Z"
                                  fill="#486497"
                                />
                              </svg>
                              <span className="text-secondary ">
                                {i18n.t("account.plan.upgrade")}
                              </span>
                            </button>
                          </div>
                        )}
                      {plan &&
                        (!(
                          plan.id_stripe === "price_1MdbWDIv4JgLR6izUtLxRsRp" ||
                          plan.id_stripe === "price_1N3D8wIv4JgLR6izs9gDGQ5N" ||
                          plan.id_product === "-1" ||
                          plan.status === -3
                        ) ||
                          plan.stripe_id === "" ||
                          plan.stripe_id === "") && (
                          <div>
                            <button
                              onClick={() => handleStripe("downgrade")}
                              className="text-primary flex flex-row gap-x-4 bg-white border border-gray-custom-input"
                            >
                              <svg
                                className="my-auto"
                                width="15"
                                height="15"
                                viewBox="0 0 15 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M14.1667 3.33333C14.6269 3.33333 15 3.70643 15 4.16667L15 14.1667C15 14.6269 14.6269 15 14.1667 15L4.16667 15C3.70643 15 3.33333 14.6269 3.33333 14.1667C3.33333 13.7064 3.70643 13.3333 4.16667 13.3333L12.1548 13.3333L6.35519 7.5337C6.02975 7.20826 6.02975 6.68063 6.35519 6.35519C6.68063 6.02975 7.20826 6.02975 7.5337 6.35519L13.3333 12.1548L13.3333 4.16667C13.3333 3.70643 13.7064 3.33333 14.1667 3.33333ZM4.20037 4.20037C3.87493 4.5258 3.34729 4.5258 3.02186 4.20037L0.244077 1.42259C-0.0813599 1.09715 -0.0813599 0.569514 0.244077 0.244077C0.569514 -0.0813599 1.09715 -0.0813599 1.42259 0.244077L4.20037 3.02185C4.5258 3.34729 4.5258 3.87493 4.20037 4.20037Z"
                                  fill="#486497"
                                />
                              </svg>
                              <span className="text-secondary ">
                                {i18n.t("account.plan.downgrade")}
                              </span>
                            </button>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
                {plan && plan.status !== -2 && plan.id_product !== "-1" && (
                  <div className="flex flex-col gap-y-3.5">
                    <h3 className="text-primary font-semibold	">
                      {i18n.t("account.cancel_plan")}
                    </h3>
                    <div className="flex flex-col gap-y-2.5">
                      <p
                        className="text-secondary"
                        dangerouslySetInnerHTML={{
                          __html: i18n.t(`account.cancel_plan_info`),
                        }}
                      />
                      <button
                        onClick={() => handleStripe()}
                        className="text-primary flex flex-row gap-x-4 bg-white border border-gray-custom-input w-fit	"
                      >
                        <svg
                          className="my-auto"
                          width="18"
                          height="19"
                          viewBox="0 0 18 19"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_3292_12759)">
                            <path
                              d="M6.781 12.8962C6.97545 12.9767 7.16845 12.9767 7.48825 12.9767C7.80805 12.9767 8.00106 12.9767 8.19551 12.8962C8.45654 12.788 8.66006 12.5847 8.76817 12.3235C8.84871 12.129 8.84871 11.936 8.84871 11.6162V8.59294C8.84871 8.27314 8.84871 8.08013 8.76817 7.88568C8.66006 7.62465 8.45672 7.42113 8.19551 7.31302C8.00106 7.23248 7.80805 7.23248 7.48825 7.23248C7.16845 7.23248 6.97545 7.23248 6.781 7.31302C6.51979 7.42113 6.31644 7.62447 6.20833 7.88568C6.12779 8.08013 6.12779 8.27314 6.12779 8.59294V11.6162C6.12779 11.936 6.12779 12.129 6.20833 12.3235C6.31644 12.5847 6.51979 12.788 6.781 12.8962ZM7.03477 8.59294C7.03477 8.43603 7.03477 8.27386 7.0471 8.23087C7.06252 8.19496 7.09045 8.16702 7.12637 8.1516C7.16936 8.13945 7.33153 8.13945 7.48825 8.13945C7.64498 8.13945 7.80696 8.13945 7.85013 8.1516C7.88605 8.16702 7.91417 8.19496 7.9294 8.23087C7.94174 8.27386 7.94174 8.43603 7.94174 8.59294V11.6162C7.94174 11.7731 7.94174 11.9351 7.9294 11.9781C7.91417 12.0135 7.88551 12.0423 7.85013 12.0574C7.80714 12.0695 7.64498 12.0695 7.48825 12.0695C7.33153 12.0695 7.16936 12.0695 7.12637 12.0574C7.091 12.0422 7.06216 12.0135 7.0471 11.9781C7.03477 11.9351 7.03477 11.773 7.03477 11.6161V8.59294Z"
                              fill="#486497"
                            />
                            <path
                              d="M9.8043 12.8962C9.99875 12.9767 10.1918 12.9767 10.5116 12.9767C10.8314 12.9767 11.0244 12.9767 11.2188 12.8962C11.4798 12.788 11.6832 12.5847 11.7915 12.3233C11.872 12.1289 11.872 11.9359 11.872 11.6162V8.59294C11.872 8.27314 11.872 8.08013 11.7915 7.88568C11.6834 7.62465 11.48 7.42131 11.2188 7.31302C11.0244 7.23248 10.8314 7.23248 10.5116 7.23248C10.1918 7.23248 9.99875 7.23248 9.8043 7.31302C9.54309 7.42113 9.33975 7.62465 9.23164 7.88568C9.1511 8.08013 9.1511 8.27314 9.1511 8.59294V11.6162C9.1511 11.936 9.1511 12.129 9.23164 12.3235C9.33975 12.5847 9.54309 12.788 9.8043 12.8962ZM10.0581 8.59294C10.0581 8.43621 10.0581 8.27404 10.0702 8.23105C10.0855 8.19568 10.1141 8.16702 10.1495 8.15178C10.1925 8.13945 10.3546 8.13945 10.5116 8.13945C10.6685 8.13945 10.8306 8.13945 10.8736 8.15178C10.909 8.16702 10.9377 8.19568 10.9529 8.23105C10.9652 8.27404 10.9652 8.43621 10.9652 8.59312V11.6164C10.9652 11.7733 10.9652 11.9355 10.9529 11.9785C10.9377 12.0139 10.909 12.0425 10.8736 12.0578C10.8306 12.0701 10.6685 12.0701 10.5116 12.0701C10.3546 12.0701 10.1925 12.0701 10.1495 12.0578C10.1141 12.0425 10.0855 12.0139 10.0702 11.9785C10.0581 11.9355 10.0581 11.7733 10.0581 11.6166V8.59294Z"
                              fill="#486497"
                            />
                            <path
                              d="M7.79046 3.90697H10.209C10.4595 3.90697 10.6625 3.70399 10.6625 3.45349C10.6625 3.20298 10.4595 3 10.2092 3H7.79064C7.54013 3 7.33715 3.20298 7.33715 3.45349C7.33715 3.70399 7.53995 3.90697 7.79046 3.90697Z"
                              fill="#486497"
                            />
                            <path
                              d="M13.1684 5.93605C12.055 4.82265 10.5745 4.20936 8.99981 4.20936C7.96351 4.20936 6.94407 4.48217 6.05179 4.99824C5.83502 5.12359 5.76083 5.40112 5.88636 5.61789C6.0117 5.83465 6.28924 5.90884 6.506 5.78332C7.26042 5.34688 8.12277 5.11633 8.99981 5.11633C10.3323 5.11633 11.5849 5.63512 12.527 6.57746C13.4692 7.51963 13.9882 8.77234 13.9882 10.1047C13.9882 11.437 13.4694 12.6897 12.527 13.6319C11.5849 14.5741 10.3322 15.093 8.99981 15.093C7.66747 15.093 6.41476 14.5742 5.4726 13.6319C4.53043 12.6897 4.01146 11.437 4.01146 10.1047C4.01146 9.22764 4.2422 8.36529 4.67845 7.61087C4.8038 7.3941 4.72979 7.11657 4.51302 6.99122C4.29625 6.86588 4.0189 6.93989 3.89338 7.15665C3.37731 8.04893 3.10449 9.06819 3.10449 10.1047C3.10449 11.6794 3.71779 13.1599 4.83119 14.2733C5.94459 15.3867 7.42513 16 8.99981 16C10.5745 16 12.055 15.3867 13.1684 14.2733C14.2818 13.1599 14.8951 11.6794 14.8951 10.1047C14.8951 8.52999 14.2818 7.04945 13.1684 5.93605Z"
                              fill="#486497"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_3292_12759">
                              <rect
                                width="11.7906"
                                height="13"
                                fill="white"
                                transform="translate(3.10449 3)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                        <span className="text-secondary">
                          {i18n.t("account.cancel_plan_button")}
                        </span>
                      </button>
                    </div>
                  </div>
                )}
                {plan && plan.status === -2 && (
                  <div className="flex flex-col gap-y-3.5">
                    <h3 className="text-primary font-semibold	">
                      {i18n.t("account.cancelled_plan")}
                    </h3>
                    <div className="flex flex-col gap-y-2.5">
                      <p
                        className="text-secondary"
                        dangerouslySetInnerHTML={{
                          __html: i18n.t(`account.cancelled_plan_info`, {
                            date:
                              plan &&
                              formatDate(
                                new Date(
                                  new Date().getFullYear(),
                                  new Date().getMonth() + 1,
                                  new Date(plan.created_at).getDate()
                                )
                              ),
                          }),
                        }}
                      />
                      <button
                        onClick={() => handleStripe()}
                        className="text-primary flex flex-row gap-x-4 bg-white border border-gray-custom-input w-fit	"
                      >
                        <svg
                          className="my-auto"
                          width="18"
                          height="19"
                          viewBox="0 0 18 19"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_3292_12759)">
                            <path
                              d="M6.781 12.8962C6.97545 12.9767 7.16845 12.9767 7.48825 12.9767C7.80805 12.9767 8.00106 12.9767 8.19551 12.8962C8.45654 12.788 8.66006 12.5847 8.76817 12.3235C8.84871 12.129 8.84871 11.936 8.84871 11.6162V8.59294C8.84871 8.27314 8.84871 8.08013 8.76817 7.88568C8.66006 7.62465 8.45672 7.42113 8.19551 7.31302C8.00106 7.23248 7.80805 7.23248 7.48825 7.23248C7.16845 7.23248 6.97545 7.23248 6.781 7.31302C6.51979 7.42113 6.31644 7.62447 6.20833 7.88568C6.12779 8.08013 6.12779 8.27314 6.12779 8.59294V11.6162C6.12779 11.936 6.12779 12.129 6.20833 12.3235C6.31644 12.5847 6.51979 12.788 6.781 12.8962ZM7.03477 8.59294C7.03477 8.43603 7.03477 8.27386 7.0471 8.23087C7.06252 8.19496 7.09045 8.16702 7.12637 8.1516C7.16936 8.13945 7.33153 8.13945 7.48825 8.13945C7.64498 8.13945 7.80696 8.13945 7.85013 8.1516C7.88605 8.16702 7.91417 8.19496 7.9294 8.23087C7.94174 8.27386 7.94174 8.43603 7.94174 8.59294V11.6162C7.94174 11.7731 7.94174 11.9351 7.9294 11.9781C7.91417 12.0135 7.88551 12.0423 7.85013 12.0574C7.80714 12.0695 7.64498 12.0695 7.48825 12.0695C7.33153 12.0695 7.16936 12.0695 7.12637 12.0574C7.091 12.0422 7.06216 12.0135 7.0471 11.9781C7.03477 11.9351 7.03477 11.773 7.03477 11.6161V8.59294Z"
                              fill="#486497"
                            />
                            <path
                              d="M9.8043 12.8962C9.99875 12.9767 10.1918 12.9767 10.5116 12.9767C10.8314 12.9767 11.0244 12.9767 11.2188 12.8962C11.4798 12.788 11.6832 12.5847 11.7915 12.3233C11.872 12.1289 11.872 11.9359 11.872 11.6162V8.59294C11.872 8.27314 11.872 8.08013 11.7915 7.88568C11.6834 7.62465 11.48 7.42131 11.2188 7.31302C11.0244 7.23248 10.8314 7.23248 10.5116 7.23248C10.1918 7.23248 9.99875 7.23248 9.8043 7.31302C9.54309 7.42113 9.33975 7.62465 9.23164 7.88568C9.1511 8.08013 9.1511 8.27314 9.1511 8.59294V11.6162C9.1511 11.936 9.1511 12.129 9.23164 12.3235C9.33975 12.5847 9.54309 12.788 9.8043 12.8962ZM10.0581 8.59294C10.0581 8.43621 10.0581 8.27404 10.0702 8.23105C10.0855 8.19568 10.1141 8.16702 10.1495 8.15178C10.1925 8.13945 10.3546 8.13945 10.5116 8.13945C10.6685 8.13945 10.8306 8.13945 10.8736 8.15178C10.909 8.16702 10.9377 8.19568 10.9529 8.23105C10.9652 8.27404 10.9652 8.43621 10.9652 8.59312V11.6164C10.9652 11.7733 10.9652 11.9355 10.9529 11.9785C10.9377 12.0139 10.909 12.0425 10.8736 12.0578C10.8306 12.0701 10.6685 12.0701 10.5116 12.0701C10.3546 12.0701 10.1925 12.0701 10.1495 12.0578C10.1141 12.0425 10.0855 12.0139 10.0702 11.9785C10.0581 11.9355 10.0581 11.7733 10.0581 11.6166V8.59294Z"
                              fill="#486497"
                            />
                            <path
                              d="M7.79046 3.90697H10.209C10.4595 3.90697 10.6625 3.70399 10.6625 3.45349C10.6625 3.20298 10.4595 3 10.2092 3H7.79064C7.54013 3 7.33715 3.20298 7.33715 3.45349C7.33715 3.70399 7.53995 3.90697 7.79046 3.90697Z"
                              fill="#486497"
                            />
                            <path
                              d="M13.1684 5.93605C12.055 4.82265 10.5745 4.20936 8.99981 4.20936C7.96351 4.20936 6.94407 4.48217 6.05179 4.99824C5.83502 5.12359 5.76083 5.40112 5.88636 5.61789C6.0117 5.83465 6.28924 5.90884 6.506 5.78332C7.26042 5.34688 8.12277 5.11633 8.99981 5.11633C10.3323 5.11633 11.5849 5.63512 12.527 6.57746C13.4692 7.51963 13.9882 8.77234 13.9882 10.1047C13.9882 11.437 13.4694 12.6897 12.527 13.6319C11.5849 14.5741 10.3322 15.093 8.99981 15.093C7.66747 15.093 6.41476 14.5742 5.4726 13.6319C4.53043 12.6897 4.01146 11.437 4.01146 10.1047C4.01146 9.22764 4.2422 8.36529 4.67845 7.61087C4.8038 7.3941 4.72979 7.11657 4.51302 6.99122C4.29625 6.86588 4.0189 6.93989 3.89338 7.15665C3.37731 8.04893 3.10449 9.06819 3.10449 10.1047C3.10449 11.6794 3.71779 13.1599 4.83119 14.2733C5.94459 15.3867 7.42513 16 8.99981 16C10.5745 16 12.055 15.3867 13.1684 14.2733C14.2818 13.1599 14.8951 11.6794 14.8951 10.1047C14.8951 8.52999 14.2818 7.04945 13.1684 5.93605Z"
                              fill="#486497"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_3292_12759">
                              <rect
                                width="11.7906"
                                height="13"
                                fill="white"
                                transform="translate(3.10449 3)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                        <span className="text-secondary">
                          {i18n.t("account.cancelled_plan_button")}
                        </span>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )}
            {page === 2 && user && user.role !== "member" && (
              <div className="flex flex-col w-full gap-y-10">
                <div className="flex flex-col gap-y-3.5">
                  <h3 className="text-primary font-semibold	">
                    {i18n.t("account.my_bill")}
                  </h3>
                  <div className="flex flex-row gap-x-4">
                    <div>
                      <button
                        onClick={() => handleStripe("update_payment")}
                        className="text-primary flex flex-row gap-x-4 bg-white border border-gray-custom-input"
                      >
                        <svg
                          className="my-auto"
                          width="18"
                          height="19"
                          viewBox="0 0 18 19"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.6745 14.2208H7.69775C5.37993 14.2208 4.21643 14.2208 3.59659 13.601C2.97675 12.9811 2.97675 11.8178 2.97675 9.5C2.97675 9.21108 2.97675 8.94032 2.97792 8.68598H5.74426C6.01404 8.68598 6.23263 8.46738 6.23263 8.19761C6.23263 7.92783 6.01404 7.70923 5.74426 7.70923H2.99355C3.03262 6.53303 3.16272 5.83271 3.59659 5.39884C4.21643 4.77899 5.37993 4.77899 7.69775 4.77899C7.96753 4.77899 8.18613 4.5604 8.18613 4.29062C8.18613 4.02084 7.96734 3.80225 7.69775 3.80225C6.4313 3.80225 5.51629 3.80225 4.77474 3.90187C3.92575 4.01596 3.34947 4.26464 2.90603 4.70808C2.46259 5.15152 2.21391 5.728 2.09963 6.57679C2.04063 7.01691 2.01641 7.51799 2.00664 8.11712C2.00234 8.1433 2 8.17006 2 8.19761C2 8.22027 2.00176 8.24234 2.00469 8.26422C2 8.63636 2 9.04542 2 9.5C2 10.7664 2 11.6815 2.09963 12.423C2.21371 13.272 2.46239 13.8483 2.90583 14.2917C3.34928 14.7352 3.92556 14.9838 4.77455 15.0979C5.51629 15.1976 6.4313 15.1976 7.69756 15.1976H8.6743C8.94408 15.1976 9.16268 14.979 9.16268 14.7092C9.16268 14.4394 8.94428 14.2208 8.6745 14.2208Z"
                            fill="#486497"
                          />
                          <path
                            d="M12.2361 13.0616L11.7675 13.5303V10.8024C11.7675 10.5326 11.5489 10.314 11.2791 10.314C11.0093 10.314 10.7907 10.5326 10.7907 10.8024V13.5303L10.3221 13.0616C10.1314 12.8709 9.82218 12.8709 9.63132 13.0616C9.44066 13.2523 9.44066 13.5615 9.63132 13.7524L10.9312 15.0522C10.932 15.053 10.9327 15.0536 10.9335 15.0544C10.9335 15.0544 10.9349 15.0557 10.9357 15.0565C10.9363 15.0571 10.9372 15.0581 10.9378 15.0587L10.9384 15.0592C10.9384 15.0592 10.9394 15.0602 10.9398 15.0606C10.9402 15.061 10.9404 15.0612 10.9407 15.0616C10.9865 15.1055 11.0386 15.1388 11.0939 15.1614C11.0943 15.1614 11.0947 15.1618 11.0951 15.1618C11.1482 15.1833 11.2058 15.1958 11.2664 15.1972C11.2666 15.1972 11.2668 15.1972 11.2672 15.1972C11.2682 15.1972 11.2693 15.1972 11.2705 15.1972C11.2715 15.1972 11.2726 15.1972 11.2738 15.1972C11.2762 15.1972 11.2769 15.1972 11.2787 15.1972C11.2805 15.1972 11.2818 15.1972 11.2832 15.1972C11.284 15.1972 11.2848 15.1972 11.2855 15.1972C11.2863 15.1972 11.2871 15.1972 11.2879 15.1972C11.2887 15.1972 11.2896 15.1972 11.2906 15.1972C11.3533 15.1956 11.4129 15.1823 11.4676 15.1595C11.468 15.1595 11.4684 15.1593 11.4686 15.1591C11.5215 15.1368 11.5715 15.1046 11.6155 15.0628C11.6159 15.0624 11.6163 15.062 11.6168 15.0616C11.6168 15.0616 11.6174 15.061 11.6176 15.0608C11.6182 15.0602 11.6188 15.0598 11.6192 15.0592C11.6198 15.0587 11.621 15.0575 11.6215 15.0569C11.6223 15.0561 11.6229 15.0555 11.6237 15.0548L12.9261 13.7524C13.1167 13.5617 13.1167 13.2525 12.9261 13.0616C12.7354 12.8708 12.4262 12.8709 12.2353 13.0616H12.2361Z"
                            fill="#486497"
                          />
                          <path
                            d="M15.8569 11.7592L14.5545 10.4568C14.5545 10.4568 14.5531 10.4555 14.5524 10.4547C14.5524 10.4547 14.5508 10.4531 14.5502 10.4525C14.5502 10.4525 14.5487 10.4512 14.5483 10.4506C14.5481 10.4504 14.5477 10.45 14.5475 10.4498C14.5471 10.4494 14.5467 10.449 14.5461 10.4486C14.5453 10.448 14.5447 10.4472 14.544 10.4467C14.4992 10.4045 14.4484 10.3724 14.3947 10.3504C14.3943 10.3504 14.3937 10.35 14.3933 10.3498C14.3402 10.3281 14.2818 10.3154 14.2211 10.314C14.2203 10.314 14.2193 10.314 14.2183 10.314C14.2175 10.314 14.2168 10.314 14.216 10.314C14.2152 10.314 14.2144 10.314 14.2136 10.314C14.2105 10.314 14.2076 10.314 14.2044 10.314C14.2039 10.314 14.2027 10.314 14.2019 10.314C14.2013 10.314 14.2005 10.314 14.1998 10.314C14.1988 10.314 14.198 10.314 14.197 10.314C14.1345 10.3156 14.0747 10.3289 14.0202 10.3517C14.0198 10.3517 14.0195 10.3521 14.0191 10.3523C13.9657 10.3748 13.9155 10.4072 13.8716 10.4494C13.8712 10.4498 13.8706 10.4504 13.8702 10.4508C13.8696 10.4512 13.8692 10.4517 13.8686 10.4521L13.8681 10.4527C13.8681 10.4527 13.8669 10.4539 13.8663 10.4545C13.8655 10.4553 13.8647 10.456 13.864 10.4568L12.5616 11.7592C12.3709 11.9499 12.3709 12.2591 12.5616 12.45C12.7522 12.6406 13.0615 12.6406 13.2523 12.45L13.721 11.9813V14.7094C13.721 14.9792 13.9396 15.1978 14.2093 15.1978C14.4791 15.1978 14.6977 14.9792 14.6977 14.7094V11.9815L15.1663 12.4502C15.357 12.6408 15.6662 12.6408 15.8571 12.4502C16.0478 12.2595 16.0476 11.9501 15.8569 11.7592Z"
                            fill="#486497"
                          />
                          <path
                            d="M5.09297 11.6162C4.82319 11.6162 4.60459 11.8348 4.60459 12.1046C4.60459 12.3744 4.82319 12.593 5.09297 12.593H7.69756C7.96733 12.593 8.18593 12.3744 8.18593 12.1046C8.18593 11.8348 7.96733 11.6162 7.69756 11.6162H5.09297Z"
                            fill="#486497"
                          />
                          <path
                            d="M7.86048 8.19761C7.86048 8.46738 8.07907 8.68598 8.34885 8.68598H15.022C15.0222 8.73989 15.0224 8.79459 15.0226 8.85027C15.0234 9.11946 15.2419 9.33727 15.5109 9.33727H15.5123C15.7821 9.33649 16.0001 9.11711 15.9993 8.84753C15.9987 8.64515 15.9978 8.45039 15.9954 8.26246C15.9981 8.24136 15.9997 8.21968 15.9997 8.1978C15.9997 8.17123 15.9976 8.14506 15.9935 8.11947C15.9686 6.52092 15.8387 5.45314 15.0939 4.70828C14.6504 4.26483 14.0742 4.01615 13.2252 3.90207C12.4838 3.80225 11.5688 3.80225 10.3023 3.80225C10.0326 3.80225 9.81397 4.02084 9.81397 4.29062C9.81397 4.5604 10.0326 4.77899 10.3023 4.77899C12.6202 4.77899 13.7837 4.77899 14.4035 5.39884C14.8331 5.82841 14.9657 6.51291 15.0062 7.70923H8.34905C8.07927 7.70923 7.86048 7.92783 7.86048 8.19761Z"
                            fill="#486497"
                          />
                        </svg>
                        <span className="text-secondary ">
                          {i18n.t("account.see_bill")}
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-y-3.5">
                  <h3 className="text-primary font-semibold	">
                    {i18n.t("account.send_bill")}
                  </h3>
                  <div>
                    <form
                      className="flex flex-col w-full gap-y-2"
                      onSubmit={handleSubmit}
                    >
                      {user.role !== "member" && (
                        <div className="flex flex-col gap-y-4">
                          <div className="account-field">
                            <div className="label text-primary">
                              {i18n.t("account.form.email_billing")}
                            </div>
                            <div className="flex flex-row gap-x-7">
                              <div
                                className="my-auto"
                                style={{
                                  paddingTop: "12px",
                                  paddingBottom: "12px",
                                }}
                              >
                                <Switch
                                  checked={sendInvoice}
                                  onChange={toggleSendBill}
                                  onColor="#486497"
                                  onHandleColor="#fff"
                                  offColor="#888888"
                                  handleDiameter={24}
                                  uncheckedIcon={false}
                                  checkedIcon={false}
                                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                  activeBoxShadow="0px 0px 1px 5px rgba(0, 0, 0, 0.2)"
                                  height={32}
                                  width={56}
                                  className="react-switch"
                                  id="material-switch"
                                />
                              </div>
                              {sendInvoice && (
                                <input
                                  type="email"
                                  name="email_billing"
                                  id="email_billing"
                                  value={emailBilling}
                                  className="input rounded  border w-full"
                                  placeholder={i18n.t(
                                    "account.form.email_billing_placeholder"
                                  )}
                                  onChange={(e) =>
                                    setEmailBilling(e.target.value)
                                  }
                                ></input>
                              )}
                            </div>
                          </div>
                          <div>
                            <button
                              type="submit"
                              id="submit-form"
                              className="flex flex-row gap-x-4 bg-secondary text-white btn"
                            >
                              <svg
                                width="18"
                                height="19"
                                viewBox="0 0 18 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12.765 5.73139C13.3491 6.31382 13.79 7.02395 14.0529 7.80582C14.3158 8.58769 14.3935 9.41993 14.2799 10.2369C14.1662 11.0539 13.8644 11.8334 13.3981 12.5139C12.9318 13.1943 12.3138 13.7572 11.5929 14.1581C10.8747 14.5461 10.0777 14.7657 9.26221 14.8003C8.44667 14.835 7.63394 14.6837 6.8855 14.3579C6.13706 14.0322 5.4725 13.5405 4.9421 12.9201C4.4117 12.2997 4.02935 11.5668 3.82397 10.7768C3.61013 9.88661 3.62691 8.95657 3.87273 8.07464C4.11854 7.19271 4.58526 6.38806 5.22875 5.73676C5.53117 5.42833 5.05709 4.95362 4.75468 5.26272C3.77392 6.24446 3.16392 7.53586 3.0286 8.91689C2.89327 10.2979 3.241 11.6831 4.01254 12.8366C4.78408 13.99 5.93169 14.8402 7.25986 15.2425C8.58803 15.6447 10.0146 15.574 11.2965 15.0425C12.2082 14.665 13.0126 14.0684 13.6386 13.3056C14.2645 12.5429 14.6926 11.6375 14.885 10.6698C15.0773 9.70199 15.0279 8.70173 14.7411 7.75762C14.4544 6.81352 13.9391 5.95472 13.2411 5.25736C12.9386 4.94826 12.4646 5.42297 12.767 5.73139H12.765Z"
                                  fill="#F5F8FF"
                                />
                                <path
                                  d="M11.2482 8.55885L9.23658 10.5703C9.17468 10.6335 9.09033 10.6696 9.00189 10.6709C8.95702 10.6709 8.91259 10.662 8.87116 10.6447C8.82973 10.6274 8.79213 10.6022 8.7605 10.5703L6.74887 8.55885C6.68574 8.49572 6.65027 8.4101 6.65027 8.32083C6.65027 8.23155 6.68574 8.14593 6.74887 8.0828C6.812 8.01967 6.89763 7.98421 6.98691 7.98421C7.0762 7.98421 7.16182 8.01967 7.22496 8.0828L8.65992 9.51765V3.83525C8.6613 3.74676 8.69707 3.66229 8.75965 3.59972C8.82222 3.53715 8.9067 3.50138 8.99519 3.5C9.08411 3.5 9.16939 3.53532 9.23226 3.59819C9.29514 3.66106 9.33046 3.74633 9.33046 3.83525V9.52771L10.7721 8.07945C10.8034 8.04819 10.8405 8.0234 10.8813 8.00648C10.9222 7.98956 10.966 7.98086 11.0102 7.98086C11.0544 7.98086 11.0982 7.98956 11.139 8.00648C11.1798 8.0234 11.217 8.04819 11.2482 8.07945C11.2795 8.11071 11.3043 8.14782 11.3212 8.18866C11.3381 8.2295 11.3468 8.27327 11.3468 8.31747C11.3468 8.36168 11.3381 8.40545 11.3212 8.44629C11.3043 8.48713 11.2795 8.52424 11.2482 8.5555V8.55885Z"
                                  fill="#F5F8FF"
                                />
                              </svg>

                              {i18n.t("account.form.save")}
                            </button>
                          </div>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            )}
            {page === 3 && (
              <div className="flex flex-col w-full gap-y-2">
                <Team
                  user={user}
                  plan={plan}
                  team={team}
                  member={member}
                  leader={leader}
                />
              </div>
            )}
            {page === 4 && (
              <div className="flex flex-col w-full gap-y-3.5">
                <h3 className="text-primary font-semibold">
                  {i18n.t("account.delete_account")}
                </h3>
                <div className="flex flex-col w-full gap-y-3.5">
                  <p
                    className="text-primary"
                    dangerouslySetInnerHTML={{
                      __html: i18n.t(`account.delete_account_info`),
                    }}
                  />
                  <div className="flex">
                    <ConfirmEraseUserAccount
                      from="user"
                      accept={() => handleAccountStripe(plan)}
                      acceptNotification={
                        plan && plan.status === -2
                          ? i18n.t("account.accept_notification_reactivate")
                          : i18n.t("account.accept_notification_delete")
                      }
                      rejectNotification={
                        plan && plan.status === -2
                          ? i18n.t("account.reject_notification_reactivate")
                          : i18n.t("account.reject_notification_delete")
                      }
                      label={
                        plan && plan.status === -2
                          ? i18n.t("account.reactivate")
                          : i18n.t("account.delete")
                      }
                      rejectLabel={i18n.t("account.no_thanks")}
                      message={
                        plan && plan.status === -2
                          ? i18n.t("account.message_reactivate")
                          : i18n.t("account.message_delete")
                      }
                      header={
                        plan && plan.status === -2
                          ? i18n.t("account.header_reactivate")
                          : i18n.t("account.header_delete")
                      }
                    ></ConfirmEraseUserAccount>
                  </div>
                  {plan && plan.status === -2 && (
                    <div>
                      {i18n.t("account.delete_at")}{" "}
                      {(() => {
                        let monthAfter = new Date(plan.updated_at);
                        monthAfter.setMonth(monthAfter.getMonth() + 1);
                        const options = {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        };
                        return monthAfter.toLocaleDateString("fr-FR", options);
                      })()}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  );
}

export default Account;
