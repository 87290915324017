import React, { Component } from 'react';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

import '../../../../components/modal/confirm/modalConfirmGlobal.css'
import '../../../../assets/Common.css'
import '../../../../components/users/styles/accountStyle.css'

export class ConfirmEraseUserAccountTeam extends Component {

    constructor(props) {
        super(props);

        this.state = {
            visible: false
        };

        this.label = this.props.label
        this.message = this.props.message
        this.header = this.props.header
        this.rejectLabel = this.props.rejectLabel
        this.acceptNotification = this.props.acceptNotification
        this.rejectNotification = this.props.rejectNotification
        this.invitation = this.props.invitation

        this.onConfirmErase = this.props.accept
        this.accept = this.accept.bind(this);
        this.reject = this.reject.bind(this);
    }

    accept() {
        this.onConfirmErase()
        this.toast.show({ severity: 'info', summary: 'Validation', detail: this.acceptNotification, life: 3000 });
    }

    reject() {
        this.toast.show({ severity: 'warn', summary: 'Annulation', detail: this.rejectNotification, life: 3000 });
    }

    render() {
        return (
            <div>
                {
                    this.state.visible && <div className="overlay"></div>
                }
                <Toast ref={(el) => this.toast = el} />

                <ConfirmDialog visible={this.state.visible} onHide={() => this.setState({ visible: false })}
                    className='confirm-dialog-error'
                    message={this.message} header={this.header}
                    icon="pi pi-exclamation-triangle"
                    accept={this.accept}
                    reject={this.reject}
                    acceptLabel={this.label}
                    rejectLabel={this.rejectLabel}
                    acceptClassName="history-btn">
                </ConfirmDialog>
                <div className='whitespace-nowrap'>
                    <Button onClick={() => this.setState({ visible: true })} className='bg-error rounded-custom-input btn text-error flex flex-row gap-x-3.5 padding-custom-button'>
                        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M7.68946 8.77273C8.03964 8.77273 8.32352 9.08671 8.32352 9.47403V12.9805C8.32352 13.3678 8.03964 13.6818 7.68946 13.6818C7.33928 13.6818 7.0554 13.3678 7.0554 12.9805V9.47403C7.0554 9.08671 7.33928 8.77273 7.68946 8.77273Z" fill="#DC2626"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M10.3102 8.77273C10.6604 8.77273 10.9443 9.08671 10.9443 9.47403V12.9805C10.9443 13.3678 10.6604 13.6818 10.3102 13.6818C9.96005 13.6818 9.67618 13.3678 9.67618 12.9805V9.47403C9.67618 9.08671 9.96005 8.77273 10.3102 8.77273Z" fill="#DC2626"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M3.1665 5.99998C3.1665 5.62342 3.45669 5.31816 3.81465 5.31816H14.185C14.543 5.31816 14.8332 5.62342 14.8332 5.99998C14.8332 6.37654 14.543 6.6818 14.185 6.6818H3.81465C3.45669 6.6818 3.1665 6.37654 3.1665 5.99998Z" fill="#DC2626"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M5.0868 7.40909C5.44698 7.40909 5.73897 7.72218 5.73897 8.10839V13.7028C5.73897 14.4752 6.32294 15.1014 7.04332 15.1014H10.9564C11.6768 15.1014 12.2607 14.4753 12.2607 13.7028V8.10839C12.2607 7.72218 12.5527 7.40909 12.9129 7.40909C13.2731 7.40909 13.5651 7.72218 13.5651 8.10839V13.7028C13.5651 15.2477 12.3971 16.5 10.9564 16.5H7.04332C5.60259 16.5 4.43462 15.2477 4.43462 13.7028V8.10839C4.43462 7.72218 4.72661 7.40909 5.0868 7.40909Z" fill="#DC2626"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M8.34464 3.89394C7.98279 3.89394 7.68945 4.20598 7.68945 4.59091V5.28788H10.3102V4.59091C10.3102 4.20598 10.0169 3.89394 9.65503 3.89394H8.34464ZM6.37906 4.59091C6.37906 3.43613 7.25908 2.5 8.34464 2.5H9.65503C10.7406 2.5 11.6206 3.43614 11.6206 4.59091V5.98485C11.6206 6.36978 11.3273 6.68182 10.9654 6.68182H7.03425C6.6724 6.68182 6.37906 6.36978 6.37906 5.98485V4.59091Z" fill="#DC2626"/>
                        </svg>
                        {
                            !this.invitation && (
                                <span className='text-error'>supprimer l’invitation</span>
                            )
                        }
                        {
                            this.invitation && (
                                <span className='text-error'>supprimer le membre</span>
                            )
                        }
                    </Button>
                </div>
            </div>
        )
    }
}
