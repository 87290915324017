import React, { Component } from "react";
import i18n from "i18next";
import { withTranslation } from "react-i18next";

import "./styles/usersStyle.css";
import "./styles/deviseStyle.css";
import "../../assets/Common.css";

import logo2 from "../../assets/logo.svg";

class SignInvalidation extends Component {
  render() {
    return (
      <div className="card">
        <div className="global-layout">
          <div id="sign_in_form" className="right-part">
            <div className="right-layout flex flex-col gap-y-1">
              <div className="flex flex-col gap-y-8 pb-6">
                <div className="mx-auto">
                  <img
                    src={logo2}
                    alt="Screebot logo"
                    className="right-layout-logo"
                  />
                </div>
                <h1 className="text-secondary text-center">
                  {i18n.t("sign.validation.title")}
                </h1>
              </div>
              <div className="sign_up_token text-secondary text-sm text-center ">
                <div>{i18n.t("sign.validation.token_sended")}</div>
              </div>
              <div className="text-secondary items-center flex-row text-center text-sm ">
                <div>{i18n.t("sign.validation.spam")}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(SignInvalidation);
