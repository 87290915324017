import React, { useState } from "react";
import axios from "axios";

const Project = ({
  id,
  text,
  handleSelect,
  setSelectedProject,
  selectedProject,
  length,
  setCurrentProject,
  projectList,
}) => {
  const [edit, setEdit] = useState(false);
  const [txt, setTxt] = useState(text);
  const [txtTmp] = useState(text);

  const [pen, setPen] = useState(false);
  const [bin, setBin] = useState(false);

  function handleEdit() {
    setEdit(!edit);
    setPen(!pen);
    setBin(!bin);
  }
  function handleConfirm(e) {
    if (edit) {
      setEdit(!edit);
      setPen(!pen);
      setBin(!bin);
      let token = localStorage.getItem("token");
      if (!token) {
        return;
      }
      const config = {
        headers: { Authorization: `${token}` },
      };
      let data = { project: { name: txt } };
      if (txt !== txtTmp) {
        axios
          .put(
            process.env.REACT_APP_URL_API + "/api/v1/projects/" + id,
            data,
            config
          )
          .then((response) => {
            if (response.data.id === selectedProject.id) {
              setSelectedProject(response.data);
            }
            const newArray = projectList.map((obj) => {
              if (obj.name === txtTmp) {
                return { ...obj, name: response.data.name };
              }
              return obj; // keep the original object
            });
            setCurrentProject(newArray);
          })
          .catch((error) => {
            console.log(error);
          });
      }
      // Voir pour essayer de comparer l'état avant le changement pour checker si c'est bien le projet selectionné pour mettre à jour la card principale
      // setSelectedProject(txt); selectedProject
    } else {
      setPen(!pen);
      setBin(!bin);
      let token = localStorage.getItem("token");
      if (!token) {
        return;
      }
      const config = {
        headers: { Authorization: `${token}` },
      };
      axios
        .delete(
          process.env.REACT_APP_URL_API + "/api/v1/projects/" + id,
          config
        )
        .then((response) => {
          let a = document.getElementById("project-" + id);
          a.classList += " hidden";
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  function handleDelete() {
    setPen(!pen);
    setBin(!bin);
  }

  function handleInvalidate() {
    if (edit) {
      setEdit(!edit);
      setPen(!pen);
      setBin(!bin);
    } else {
      setPen(!pen);
      setBin(!bin);
    }
  }

  function handleChange(e) {
    setTxt(e.target.value);
  }

  return (
    <div
      id={`project-${id}`}
      className={`select-none flex flex-row gap-x-4 padding-custom-project rounded-md ${
        selectedProject && selectedProject.name === txt
          ? "bg-secondary"
          : "bg-white"
      }`}
    >
      <p
        className={`select-none	 text-secondary cursor-pointer w-full ${
          !edit ? "block my-auto" : "hidden"
        } ${
          selectedProject && selectedProject.name === txt
            ? "text-white"
            : "text-secondary"
        }`}
        id={id}
        onClick={handleSelect}
      >
        {txt}
      </p>
      <input
        className={`select-none input-project ${
          edit ? "block w-full" : "hidden"
        }`}
        tabIndex="-1"
        type="text"
        value={txt}
        onInput={handleChange}
      />
      <div className="flex flex-row my-auto gap-x-4">
        <svg
          className={`${pen ? "block my-auto" : "hidden"}`}
          onClick={handleConfirm}
          width="16"
          height="16"
          viewBox="0 0 8 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.88 6.72176L0.776255 4.61801C0.675005 4.51676 0.675005 4.35926 0.776255 4.26363L1.31625 3.72363C1.36125 3.67863 1.42313 3.65613 1.49063 3.65613C1.5525 3.65613 1.61438 3.67863 1.665 3.72926L2.8125 4.87676C2.94188 5.00613 3.15001 5.00613 3.27376 4.87676L7.37438 0.776133C7.47563 0.674883 7.63313 0.674883 7.72875 0.776133L8.26875 1.31613C8.37 1.41738 8.37 1.56926 8.26875 1.67051L3.22875 6.72176C3.18375 6.76676 3.12188 6.79488 3.05438 6.79488C2.98688 6.79488 2.925 6.76676 2.88 6.72176Z"
            fill={
              selectedProject && selectedProject.name === txt
                ? "white"
                : "#2B3E60"
            }
          />
        </svg>
        <svg
          className={`${pen ? "hidden" : " my-auto"}`}
          onClick={handleEdit}
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.2766 16C3.41244 16 3.54997 15.9782 3.6839 15.9335L6.2974 15.0624C6.81535 14.8898 7.07529 14.8032 7.34242 14.676C7.65636 14.5265 7.95446 14.3422 8.22855 14.1284C8.46112 13.9469 8.65481 13.7535 9.04148 13.3666L15.0886 7.31999C16.3038 6.10494 16.3038 4.12771 15.0886 2.91242C14.4999 2.32422 13.7172 2 12.8846 2C12.052 2 11.2693 2.32422 10.6805 2.9129L4.63363 8.95908C4.24745 9.34522 4.05352 9.53913 3.87183 9.77191C3.65798 10.046 3.47365 10.344 3.32412 10.6579C3.19715 10.9243 3.11051 11.184 2.9377 11.7028L2.06645 14.3163C1.91236 14.7787 2.03069 15.28 2.37535 15.6247C2.62016 15.8694 2.94394 16 3.2766 16ZM11.5292 3.76125C11.8912 3.39935 12.3726 3.19992 12.8846 3.19992C13.3965 3.19992 13.878 3.39935 14.2399 3.76125C14.9873 4.50856 14.9873 5.72457 14.2399 6.47188L13.8605 6.85129C13.4935 6.77186 12.783 6.54027 12.1223 5.8796C11.4608 5.2182 11.2294 4.50688 11.15 4.14043L11.5292 3.76125ZM3.20483 14.6957L4.07632 12.0815C4.23329 11.6107 4.31225 11.3741 4.4073 11.1746C4.51939 10.9394 4.65763 10.7158 4.81796 10.5103C4.95405 10.3356 5.13046 10.1592 5.48208 9.80791L10.1957 5.09485C10.3911 5.58609 10.7204 6.17478 11.2738 6.72818C11.8268 7.28111 12.4151 7.61013 12.9062 7.80572L8.10374 12.6076C7.81021 12.9006 7.64964 13.0583 7.49051 13.1823C7.28506 13.3426 7.06161 13.4806 6.82615 13.5927C6.62574 13.6882 6.38933 13.7669 5.91794 13.9239L3.30444 14.795C3.27588 14.8044 3.24516 14.7972 3.22403 14.7761C3.20291 14.755 3.19523 14.7242 3.20483 14.6957Z"
            fill={
              selectedProject && selectedProject.name === txt
                ? "white"
                : "#2B3E60"
            }
          />
        </svg>

        <svg
          className={`${bin ? "block my-auto" : "hidden"}`}
          onClick={handleInvalidate}
          width="14"
          height="14"
          viewBox="0 0 8 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.35994 3.50006L6.92616 0.93384C7.02021 0.83979 7.02021 0.678562 6.92616 0.584512L6.41561 0.0739554C6.32156 -0.0200945 6.16033 -0.0200945 6.06628 0.0739554L3.50006 2.64017L0.93384 0.0739554C0.83979 -0.0200945 0.678562 -0.0200945 0.584512 0.0739554L0.0739554 0.577794C-0.0200945 0.671844 -0.0200945 0.833072 0.0739554 0.927122L2.64017 3.49334L0.0739554 6.06628C-0.0200945 6.16033 -0.0200945 6.32156 0.0739554 6.41561L0.584512 6.92616C0.678562 7.02021 0.83979 7.02021 0.93384 6.92616L3.50006 4.35994L6.06628 6.92616C6.16033 7.02021 6.32156 7.02021 6.41561 6.92616L6.92616 6.41561C7.02021 6.32156 7.02021 6.16033 6.92616 6.06628L4.35994 3.50006Z"
            fill={
              selectedProject && selectedProject.name === txt
                ? "white"
                : "#2B3E60"
            }
          />
        </svg>
        {length > 1 && (
          <svg
            className={`${bin ? "hidden" : "block my-auto"}`}
            onClick={handleDelete}
            width="14"
            height="20"
            viewBox="0 0 10 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3.87673 5.26636C4.17689 5.26636 4.42021 5.51663 4.42021 5.82536V8.62039C4.42021 8.92912 4.17689 9.1794 3.87673 9.1794C3.57658 9.1794 3.33325 8.92912 3.33325 8.62039V5.82536C3.33325 5.51663 3.57658 5.26636 3.87673 5.26636Z"
              fill={
                selectedProject && selectedProject.name === txt
                  ? "white"
                  : "#2B3E60"
              }
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.12319 5.26636C6.42334 5.26636 6.66667 5.51663 6.66667 5.82536V8.62039C6.66667 8.92912 6.42334 9.1794 6.12319 9.1794C5.82304 9.1794 5.57971 8.92912 5.57971 8.62039V5.82536C5.57971 5.51663 5.82304 5.26636 6.12319 5.26636Z"
              fill={
                selectedProject && selectedProject.name === txt
                  ? "white"
                  : "#2B3E60"
              }
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 3.05593C0 2.75577 0.248731 2.51245 0.555556 2.51245H9.44444C9.75127 2.51245 10 2.75577 10 3.05593C10 3.35608 9.75127 3.59941 9.44444 3.59941H0.555556C0.248731 3.59941 0 3.35608 0 3.05593Z"
              fill={
                selectedProject && selectedProject.name === txt
                  ? "white"
                  : "#2B3E60"
              }
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1.64604 4.17944C1.95477 4.17944 2.20505 4.42901 2.20505 4.73686V9.19617C2.20505 9.81189 2.7056 10.311 3.32306 10.311H6.6771C7.29459 10.311 7.79511 9.81189 7.79511 9.19617V4.73686C7.79511 4.42901 8.04539 4.17944 8.35412 4.17944C8.66285 4.17944 8.91312 4.42901 8.91312 4.73686V9.19617C8.91312 10.4276 7.91205 11.4258 6.6771 11.4258H3.32306C2.08815 11.4258 1.08704 10.4276 1.08704 9.19617V4.73686C1.08704 4.42901 1.33731 4.17944 1.64604 4.17944Z"
              fill={
                selectedProject && selectedProject.name === txt
                  ? "white"
                  : "#2B3E60"
              }
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.43832 1.37747C4.12816 1.37747 3.87673 1.6262 3.87673 1.93302V2.48858H6.12311V1.93302C6.12311 1.62619 5.87168 1.37747 5.56151 1.37747H4.43832ZM2.75354 1.93302C2.75354 1.01255 3.50784 0.266357 4.43832 0.266357H5.56151C6.49201 0.266357 7.24629 1.01255 7.24629 1.93302V3.04414C7.24629 3.35096 6.99486 3.59969 6.6847 3.59969H3.31513C3.00497 3.59969 2.75354 3.35096 2.75354 3.04414V1.93302Z"
              fill={
                selectedProject && selectedProject.name === txt
                  ? "white"
                  : "#2B3E60"
              }
            />
          </svg>
        )}
      </div>
    </div>
  );
};

export default Project;
