import React from "react";
import axios from "axios";
import i18n from "i18next";
import MyAvatar from "../../dropzone/Avatar.jsx";
import "./accountForm.css";
import "../../../../../assets/Common.css";

class AccountForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: this.props.user.first_name ? this.props.user.first_name : "",
      last_name: this.props.user.last_name ? this.props.user.last_name : "",
      email: this.props.user.email ? this.props.user.email : "",
      siret: this.props.user.siret ? this.props.user.siret : "",
      phone: this.props.user.phone ? this.props.user.phone : "",
      company_name: this.props.user.company_name
        ? this.props.user.company_name
        : "",
      avatar: this.props.avatar ? this.props.avatar : "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    let newValue = event.target.value;
    let key = event.target.name;
    this.setState({
      [key]: newValue,
    });
  }

  handleFileInputChange = (newAvatar) => {
    this.setState({ avatar: newAvatar });
  };

  toggleSendBill = () => {
    this.setState({ send_invoice: !this.state.send_invoice });
  };

  handleSubmit(event) {
    event.preventDefault();
    document.getElementById("submit-form").disabled = true;
    let token = localStorage.getItem("token");
    if (!token) {
      return;
    }
    const config = {
      headers: {
        Authorization: `${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    let data = new FormData();
    if (
      this.state.first_name !== "" &&
      this.state.first_name !== null &&
      this.state.first_name !== "null"
    ) {
      data.append("user[first_name]", this.state.first_name);
    }
    if (
      this.state.last_name !== "" &&
      this.state.last_name !== null &&
      this.state.last_name !== "null"
    ) {
      data.append("user[last_name]", this.state.last_name);
    }
    if (
      this.state.email !== "" &&
      this.state.email !== null &&
      this.state.email !== "null"
    ) {
      data.append("user[email]", this.state.email);
    }
    if (
      this.state.siret !== "" &&
      this.state.siret !== null &&
      this.state.siret !== "null"
    ) {
      data.append("user[siret]", this.state.siret);
    }
    if (
      this.state.phone !== "" &&
      this.state.phone !== null &&
      this.state.phone !== "null"
    ) {
      data.append("user[phone]", this.state.phone);
    }
    if (
      this.state.company_name !== "" &&
      this.state.company_name !== null &&
      this.state.company_name !== "null"
    ) {
      data.append("user[company_name]", this.state.company_name);
    }
    if (
      this.state.avatar !== "" &&
      this.state.avatar !== null &&
      typeof this.state.avatar == "object"
    ) {
      data.append("user[avatar]", this.state.avatar);
    }

    axios
      .put(process.env.REACT_APP_URL_API + "/update_user", data, config)
      .then((response) => {
        if (response["data"]["user"].first_name) {
          document.getElementById("first_name_navbar").innerHTML =
            response["data"]["user"].first_name + "&nbsp;";
        }
        if (response["data"]["user"].last_name) {
          document.getElementById("last_name_navbar").innerHTML =
            response["data"]["user"].last_name;
        }
        if (response["data"]["avatar"]) {
          document.getElementById("avatar_navbar").src =
            process.env.REACT_APP_URL_API + response["data"]["avatar"];
        }
        if (response["data"]["email_billing"]) {
          document.getElementById("email_billing").src =
            process.env.REACT_APP_URL_API + response["data"]["email_billing"];
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  render() {
    return (
      <form
        className="flex flex-col w-full gap-y-5"
        onSubmit={this.handleSubmit}
      >
        <div className="flex flex-row w-full space-between gap-x-4 account-text-grey">
          <div className="flex flex-col w-full gap-y-6 space-between">
            <div className="flex flex-row gap-x-6">
              <div className="account-field w-full">
                <div className="label text-primary">
                  {i18n.t("account.form.firstname")}
                </div>
                <input
                  type="text"
                  name="first_name"
                  value={this.state.first_name}
                  id="first_name"
                  placeholder={i18n.t("account.form.firstname_placeholder")}
                  onChange={this.handleChange}
                ></input>
              </div>
              <div className="account-field w-full">
                <div className="label text-primary">
                  {i18n.t("account.form.lastname")}
                </div>
                <input
                  type="text"
                  name="last_name"
                  value={this.state.last_name}

                  id="last_name"
                  placeholder={i18n.t("account.form.lastname_placeholder")}
                  onChange={this.handleChange}
                ></input>
              </div>
              <div className="account-field w-full">
                <div className="label text-primary">
                  {i18n.t("account.form.email")}
                </div>
                <input
                  type="email"
                  disabled
                  name="email"
                  value={this.state.email}

                  id="email"
                  placeholder={i18n.t("account.form.email_placeholder")}
                  onChange={this.handleChange}
                ></input>
              </div>
            </div>
            <div className="flex flex-row gap-x-6">
              <div className="account-field w-full">
                <div className="label text-primary">
                  {i18n.t("account.form.phone")}
                </div>
                <input
                  type="tel"
                  name="phone"
                  id="phone"
                  value={this.state.phone}

                  placeholder={i18n.t("account.form.phone_placeholder")}
                  onChange={this.handleChange}
                ></input>
              </div>
              <div className="account-field w-full">
                <div className="label text-primary">
                  {i18n.t("account.form.company")}
                </div>
                <input
                  disabled={this.props.user.role === "member"}
                  type="text"
                  id="company_name"
                  name="company_name"
                  value={this.state.company_name}

                  onChange={this.handleChange}
                  placeholder="Screebot"
                ></input>
              </div>
              <div className="account-field w-full">
                <div className="label text-primary">
                  {i18n.t("account.form.siret")}
                </div>
                <input
                  disabled={this.props.user.role === "member"}
                  type="text"
                  name="siret"
                  id="siret"
                  value={this.state.siret}

                  placeholder={i18n.t("account.form.siret_placeholder")}
                  onChange={this.handleChange}
                ></input>
              </div>
            </div>
            <div
              className="account-field"
              style={{
                display: "flex",
                flexDirection: "column",
                width: "33%",
                height: "auto",
              }}
            >
              <div className="label text-primary">
                {i18n.t("account.form.image")}
              </div>
              <MyAvatar onAvatarChange={this.handleFileInputChange}></MyAvatar>
            </div>
          </div>
        </div>
        <div>
          <button
            type="submit"
            id="submit-form"
            className="flex flex-row gap-x-4 bg-secondary text-white btn"
          >
            <svg
              width="18"
              height="19"
              viewBox="0 0 18 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.765 5.73139C13.3491 6.31382 13.79 7.02395 14.0529 7.80582C14.3158 8.58769 14.3935 9.41993 14.2799 10.2369C14.1662 11.0539 13.8644 11.8334 13.3981 12.5139C12.9318 13.1943 12.3138 13.7572 11.5929 14.1581C10.8747 14.5461 10.0777 14.7657 9.26221 14.8003C8.44667 14.835 7.63394 14.6837 6.8855 14.3579C6.13706 14.0322 5.4725 13.5405 4.9421 12.9201C4.4117 12.2997 4.02935 11.5668 3.82397 10.7768C3.61013 9.88661 3.62691 8.95657 3.87273 8.07464C4.11854 7.19271 4.58526 6.38806 5.22875 5.73676C5.53117 5.42833 5.05709 4.95362 4.75468 5.26272C3.77392 6.24446 3.16392 7.53586 3.0286 8.91689C2.89327 10.2979 3.241 11.6831 4.01254 12.8366C4.78408 13.99 5.93169 14.8402 7.25986 15.2425C8.58803 15.6447 10.0146 15.574 11.2965 15.0425C12.2082 14.665 13.0126 14.0684 13.6386 13.3056C14.2645 12.5429 14.6926 11.6375 14.885 10.6698C15.0773 9.70199 15.0279 8.70173 14.7411 7.75762C14.4544 6.81352 13.9391 5.95472 13.2411 5.25736C12.9386 4.94826 12.4646 5.42297 12.767 5.73139H12.765Z"
                fill="#F5F8FF"
              />
              <path
                d="M11.2482 8.55885L9.23658 10.5703C9.17468 10.6335 9.09033 10.6696 9.00189 10.6709C8.95702 10.6709 8.91259 10.662 8.87116 10.6447C8.82973 10.6274 8.79213 10.6022 8.7605 10.5703L6.74887 8.55885C6.68574 8.49572 6.65027 8.4101 6.65027 8.32083C6.65027 8.23155 6.68574 8.14593 6.74887 8.0828C6.812 8.01967 6.89763 7.98421 6.98691 7.98421C7.0762 7.98421 7.16182 8.01967 7.22496 8.0828L8.65992 9.51765V3.83525C8.6613 3.74676 8.69707 3.66229 8.75965 3.59972C8.82222 3.53715 8.9067 3.50138 8.99519 3.5C9.08411 3.5 9.16939 3.53532 9.23226 3.59819C9.29514 3.66106 9.33046 3.74633 9.33046 3.83525V9.52771L10.7721 8.07945C10.8034 8.04819 10.8405 8.0234 10.8813 8.00648C10.9222 7.98956 10.966 7.98086 11.0102 7.98086C11.0544 7.98086 11.0982 7.98956 11.139 8.00648C11.1798 8.0234 11.217 8.04819 11.2482 8.07945C11.2795 8.11071 11.3043 8.14782 11.3212 8.18866C11.3381 8.2295 11.3468 8.27327 11.3468 8.31747C11.3468 8.36168 11.3381 8.40545 11.3212 8.44629C11.3043 8.48713 11.2795 8.52424 11.2482 8.5555V8.55885Z"
                fill="#F5F8FF"
              />
            </svg>

            {i18n.t("account.form.save")}
          </button>
        </div>
      </form>
    );
  }
}

export default AccountForm;
