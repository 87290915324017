
import React, { Component } from 'react';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import i18n from 'i18next';

import './modalConfirmGlobal.css'

export class ConfirmErasePromptsHistorical extends Component {

    constructor(props) {
        super(props);

        this.state = {
            visible: false
        };

        this.onConfirmErase = this.props.accept
        this.accept = this.accept.bind(this);
        this.reject = this.reject.bind(this);
    }

    accept() {
        this.onConfirmErase()
        this.toast.show({ severity: 'info', summary: 'Validation', detail: i18n.t('prompt.modal.accept_detail'), life: 3000 });
    }

    reject() {
        this.toast.show({ severity: 'warn', summary: 'Annulation', detail: i18n.t('prompt.modal.reject_detail'), life: 3000 });
    }

    render() {
        return (
            <div>
                {
                    this.state.visible && <div className="overlay"></div>
                }
                <Toast ref={(el) => this.toast = el} />

                <ConfirmDialog visible={this.state.visible} onHide={() => this.setState({ visible: false })}
                    className='confirm-dialog-error'
                    message={i18n.t('prompt.modal.message')}
                    header={i18n.t('prompt.modal.header')}
                    icon="pi pi-exclamation-triangle"
                    accept={this.accept} reject={this.reject}
                    acceptLabel={i18n.t('prompt.modal.accept_label')}
                    rejectLabel={i18n.t('prompt.modal.reject_label')}
                    acceptClassName="history-btn">
                </ConfirmDialog>

                <div className='whitespace-nowrap'>
                    <Button onClick={() => this.setState({ visible: true })} className='flex flex-row-reverse text-error bg-error gap-x-3.5 rounded-custom-input' icon="pi pi-check" label= { i18n.t('prompt.delete_all') } >
                        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M7.68961 8.77273C8.03979 8.77273 8.32367 9.08671 8.32367 9.47403V12.9805C8.32367 13.3678 8.03979 13.6818 7.68961 13.6818C7.33943 13.6818 7.05555 13.3678 7.05555 12.9805V9.47403C7.05555 9.08671 7.33943 8.77273 7.68961 8.77273Z" fill="#DC2626"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M10.3104 8.77273C10.6606 8.77273 10.9444 9.08671 10.9444 9.47403V12.9805C10.9444 13.3678 10.6606 13.6818 10.3104 13.6818C9.96021 13.6818 9.67633 13.3678 9.67633 12.9805V9.47403C9.67633 9.08671 9.96021 8.77273 10.3104 8.77273Z" fill="#DC2626"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M3.16666 5.99998C3.16666 5.62342 3.45684 5.31816 3.8148 5.31816H14.1852C14.5431 5.31816 14.8333 5.62342 14.8333 5.99998C14.8333 6.37654 14.5431 6.6818 14.1852 6.6818H3.8148C3.45684 6.6818 3.16666 6.37654 3.16666 5.99998Z" fill="#DC2626"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M5.08695 7.40909C5.44714 7.40909 5.73912 7.72218 5.73912 8.10839V13.7028C5.73912 14.4752 6.3231 15.1014 7.04347 15.1014H10.9565C11.6769 15.1014 12.2609 14.4753 12.2609 13.7028V8.10839C12.2609 7.72218 12.5529 7.40909 12.913 7.40909C13.2732 7.40909 13.5652 7.72218 13.5652 8.10839V13.7028C13.5652 15.2477 12.3973 16.5 10.9565 16.5H7.04347C5.60274 16.5 4.43478 15.2477 4.43478 13.7028V8.10839C4.43478 7.72218 4.72676 7.40909 5.08695 7.40909Z" fill="#DC2626"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M8.34479 3.89394C7.98294 3.89394 7.6896 4.20598 7.6896 4.59091V5.28788H10.3104V4.59091C10.3104 4.20598 10.017 3.89394 9.65518 3.89394H8.34479ZM6.37921 4.59091C6.37921 3.43613 7.25923 2.5 8.34479 2.5H9.65518C10.7408 2.5 11.6208 3.43614 11.6208 4.59091V5.98485C11.6208 6.36978 11.3274 6.68182 10.9656 6.68182H7.03441C6.67255 6.68182 6.37921 6.36978 6.37921 5.98485V4.59091Z" fill="#DC2626"/>
                    </svg>
                    </Button>
                </div>
            </div>
        )
    }
}
