import React from "react";
import { Link } from "react-router-dom";
import { formatDate } from "../../components/product/component/sharedFunction.js";

import "../styles/admin.css";

const AdminDashboard = ({
  currentItems,
  setUsers,
  fromBeginningCA,
  setFromBeginningCA,
  currentYearCA,
  setCurrentYearCA,
  currentQuarterCA,
  setCurrentQuarterCA,
  numberAffiliater,
  setNumberAffiliater,
  numberAffiliates,
  setNumberAffiliates,
  numberTokenOpenAI,
  setNumberTokenOpenAI,
  dataPlans,
  setDataPlans,
}) => {
  const mostPopularGroup =
    dataPlans &&
    dataPlans.reduce(
      (maxGroup, currentGroup) => {
        if (currentGroup.name) {
          return currentGroup.count > maxGroup.count ? currentGroup : maxGroup;
        } else {
          return maxGroup;
        }
      },
      { count: -1 }
    );

  return (
    <div className="flex flex-row gap-x-2">
      <div className="screebot-card flex flex-col w-full gap-x-1">
        <p className="text-primary text-base font-medium pb-6">
          Chiffre d’Affaires
        </p>
        <div className="flex flex-col gap-y-1">
          <div className="small-card-screebot justify-between">
            <div className="flex flex-col gap-y-1">
              <p className="text-admin font-medium">Année en cours</p>
              <p className="text-gray text-sm text-left">
                {new Date().getFullYear()}
              </p>
            </div>
            <div className="flex flex-row my-auto text-md">
              <p className="text-green label font-extrabold text-md">
                {currentYearCA}
              </p>
              <p className="text-md label text-primary text-sm font-medium">
                {" "}
                € HT
              </p>
            </div>
          </div>
          <div className="small-card-screebot justify-between">
            <div className="flex flex-col gap-y-1">
              <p className="text-admin font-medium">Trimestre en cours</p>
              <p className="text-gray text-sm text-left">
                {Math.ceil((new Date().getMonth() + 1) / 3)}ème trimestre
              </p>
            </div>
            <div className="flex flex-row my-auto text-md">
              <p className="text-green label font-extrabold text-md">
                {currentQuarterCA}
              </p>
              <p className="text-md label text-primary text-sm font-medium">
                {" "}
                € HT
              </p>
            </div>
          </div>
          <div className="small-card-screebot justify-between">
            <div className="flex flex-col gap-y-1 text-left">
              <p className="text-admin font-medium">CA N+1</p>
              <p className="text-gray text-sm text-left">
                {new Date().getFullYear() - 1} vs {new Date().getFullYear()}
              </p>
            </div>
            <div className="flex flex-row my-auto text-md">
              <p className="text-green label font-extrabold text-md">TODO</p>
              <p className="text-md label text-primary text-sm font-medium">
                {" "}
                € HT
              </p>
            </div>
          </div>
          <div className="small-card-screebot justify-between">
            <div className="flex flex-col gap-y-1">
              <p className="text-primary font-medium">Depuis le lancement</p>
              <p className="text-gray text-sm text-left">
                depuis le 17/03/2023
              </p>
            </div>
            <div className="flex flex-row my-auto text-md">
              <p className="text-green label font-extrabold text-md">
                {fromBeginningCA}
              </p>
              <p className="text-md label text-primary text-sm font-medium">
                {" "}
                € HT
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="screebot-card flex flex-col w-full gap-x-1">
        <div className="text-primary text-base font-medium pb-6">
          Dernières inscriptions
        </div>
        <div className="text-left">
          {!currentItems?.length > 0 && (
            <p className="text-error font-medium text-lg">Aucun utilisateur</p>
          )}
          {currentItems?.length > 0 &&
            currentItems.slice(0, 4).map((client, index) => (
              <Link
                to={`${client.id}`}
                key={index}
                className={`small-card-screebot justify-between gap-x-1`}
              >
                <div className="flex flex-col">
                  <p className="text-sm text-primary text-left">
                    {client.first_name === null ? "N/C" : client.first_name}{" "}
                    {client.last_name === null ? "N/C" : client.last_name}
                  </p>
                  <p className="text-xs text-secondary">
                    {client.email.substring(0, 25)}
                  </p>
                </div>
                <div className="my-auto">
                  <div className="text-center my-auto">
                    {(client.plan === null || client.plan === undefined) && (
                      <p className="text-xs text-error">N/C</p>
                    )}
                    {client.plan !== null && client.plan !== undefined && (
                      <p className="text-green badge-plan-affiliate text-xs">
                        {client.plan.name}
                      </p>
                    )}
                  </div>
                </div>
                <p className="text-xs text-right text-secondary my-auto">
                  {formatDate(client.created_at, "with_slash")}
                </p>
              </Link>
            ))}
        </div>
      </div>
      <div className="screebot-card flex flex-col w-full gap-x-1">
        <div className="text-primary text-base font-medium pb-6">
          Informations additionnelles
        </div>
        <div className="flex flex-col gap-y-1">
          <div className="small-card-screebot justify-between">
            <div className="flex flex-col gap-y-1">
              <p className="text-admin font-medium">Clients</p>
              <p className="text-gray text-sm text-left">
                {new Date().getFullYear()}
              </p>
            </div>
            <div className="flex flex-row my-auto text-md">
              <p className="text-green label font-extrabold text-md">
                {currentItems?.length}
              </p>
            </div>
          </div>
          <div className="small-card-screebot justify-between">
            <div className="flex flex-col gap-y-1">
              <p className="text-admin font-medium">Tokens OpenAi</p>
              <p className="text-gray text-sm text-left">
                {new Date().getFullYear()}
              </p>
            </div>
            <div className="flex flex-row my-auto text-md">
              <p className="text-green label font-extrabold text-md">
                {numberTokenOpenAI && numberTokenOpenAI.toLocaleString()}
              </p>
              <p className="text-md label text-primary text-sm font-medium">
                {" "}
              </p>
            </div>
          </div>
          <div className="small-card-screebot justify-between">
            <div className="flex flex-col gap-y-1 text-left">
              <p className="text-admin font-medium">Meilleur forfait</p>
              <p className="text-gray text-sm text-left">
                forfait le plus utilisé
              </p>
            </div>
            <div className="flex flex-row my-auto text-md">
              <p className="text-secondary label font-extrabold text-md">
                {mostPopularGroup?.name}
              </p>
              <p className="text-md label text-primary text-sm font-medium">
                {" "}
              </p>
            </div>
          </div>
          <div className="small-card-screebot justify-between">
            <div className="flex flex-col gap-y-1">
              <p className="text-primary font-medium">Affiliateurs</p>
              <p className="text-gray text-sm text-left">
                affiliés {numberAffiliates}
              </p>
            </div>
            <div className="flex flex-row my-auto text-md">
              <p className="text-green label font-extrabold text-md">
                {numberAffiliater}
              </p>
              <p className="text-md label text-primary text-sm font-medium">
                {" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
