import React from "react";

function Home(props) {
  const { user, plan } = props;
  // let token = localStorage.getItem("token");
  // if(!token){
  //     window.location = process.env.REACT_APP_URL + "/users/sign_in";
  // }
  // if(!user){
  //     window.location = process.env.REACT_APP_URL + "/users/sign_in";
  // }

  // return;

  if (user === null && plan === null) {
    window.location = process.env.REACT_APP_URL + "/users/sign_in";
    return;
  }

  if (user && plan == null && user.role === "client") {
    window.location = process.env.REACT_APP_URL + "/users/account?plan";
    return;
  }
  if (
    user &&
    user.role !== "admin" &&
    (plan !== null || user.referrer_id !== null)
  ) {
    window.location = process.env.REACT_APP_URL + "/dashboard";
  }
  if (user && user.role === "admin") {
    window.location = process.env.REACT_APP_URL + "/users/admin";
  }

  return <div className="container w-full"></div>;
}

export default Home;
