import React from "react";
import { useTranslation } from "react-i18next";
import { NotifyPaymentError } from "../product/component/Toast";
import Card from "./Card";

import "../../assets/Common.css";
import iconeDashboard from "../../assets/product/icon-dashboard.svg";

function Dashboard(props) {
  const { user, plan } = props;
  const { t } = useTranslation();

  if (plan && plan.status === -3 && user && user.role !== "member") {
    NotifyPaymentError(t("account.payment_update"), user);
    setTimeout(function () {
      window.location = process.env.REACT_APP_URL + "/users/account?plan";
    }, 5000);
  }
  return (
    <div className="container gap-8">
      <div className="flex items-center">
        <img
          alt={t("dashboard.main_title")}
          className="mr-4"
          style={{ width: "30px" }}
          src={iconeDashboard}
        ></img>
        <h1 className="text-secondary">{t("dashboard.main_title")}</h1>
      </div>
      <p className="text-left">{t("dashboard.main_subtitle")}</p>
      <div className="dashboard-cards">
        <Card
          icon="blog"
          title={t("dashboard.blog_title")}
          text={t("dashboard.blog_text")}
          product="blog"
          badges={["SEO", "BLOG"]}
          newFeature={true}
        />
        <Card
          icon="social"
          title={t("dashboard.social_title")}
          text={t("dashboard.social_text")}
          product="social"
          badges={["SOCIAL"]}
        />
        <Card
          icon="sheet"
          title={t("dashboard.sheet_title")}
          text={t("dashboard.sheet_text")}
          product="sheet"
          badges={["SEO", "ECOMMERCE"]}
        />
        <Card
          icon="sentences"
          title={t("dashboard.paragraph_title")}
          text={t("dashboard.paragraph_text")}
          product="paragraph"
          badges={["SEO", "SOCIAL", "BLOG", "ECOMMERCE"]}
        />
        <Card
          icon="faq"
          title={t("dashboard.faq_title")}
          text={t("dashboard.faq_text")}
          product="faq"
          badges={["SEO", "SOCIAL", "BLOG", "ECOMMERCE"]}
        />
        <Card
          icon="definition"
          title={t("dashboard.definition")}
          text={t("dashboard.definition_text")}
          product="definition"
          badges={["SEO"]}
        />
        <Card
          icon="rewrite"
          title={t("dashboard.rewrite_title")}
          text={t("dashboard.rewrite_text")}
          product="rewrite"
          badges={["SEO", "SOCIAL", "BLOG", "ECOMMERCE"]}
        />
        <Card
          icon="web"
          title={t("dashboard.web_title")}
          text={t("dashboard.web_text")}
          product="web"
          badges={["SEO"]}
        />
        <Card
          icon="meta"
          title={t("dashboard.metas_title")}
          text={t("dashboard.metas_text")}
          product="metas"
          badges={["SEO", "BLOG", "ECOMMERCE"]}
        />
        <Card
          icon="excel"
          title={t("dashboard.excel_title")}
          text={t("dashboard.excel_text")}
          product="excel"
        />
        <Card
          icon="press"
          title={t("dashboard.press_title")}
          text={t("dashboard.press_text")}
          product="press"
          badges={["SOCIAL"]}
        />
        {user && user.role === "admin" && <></>}
      </div>
    </div>
  );
}

export default Dashboard;
