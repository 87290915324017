import React, { Component } from 'react';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

import './modalConfirmGlobal.css'
import '../../../assets/Common.css'
import '../../../components/users/styles/accountStyle.css'

export class ConfirmEraseUserAccount extends Component {

    constructor(props) {
        super(props);

        this.state = {
            visible: false
        };
        this.from = this.props.from
        this.label = this.props.label
        this.message = this.props.message
        this.header = this.props.header
        this.rejectLabel = this.props.rejectLabel
        this.acceptNotification = this.props.acceptNotification
        this.rejectNotification = this.props.rejectNotification

        this.onConfirmErase = this.props.accept
        this.accept = this.accept.bind(this);
        this.reject = this.reject.bind(this);
    }

    accept() {
        this.onConfirmErase()
        this.toast.show({ severity: 'info', summary: 'Validation', detail: this.acceptNotification, life: 3000 });
    }

    reject() {
        this.toast.show({ severity: 'warn', summary: 'Annulation', detail: this.rejectNotification, life: 3000 });
    }

    render() {
        return (
            <div>
                {
                    this.state.visible && <div className="overlay"></div>
                }
                <Toast ref={(el) => this.toast = el} />

                <ConfirmDialog visible={this.state.visible} onHide={() => this.setState({ visible: false })}
                    className='confirm-dialog-error'
                    message={this.message} header={this.header}
                    icon="pi pi-exclamation-triangle"
                    accept={this.accept}
                    reject={this.reject}
                    acceptLabel={this.label}
                    rejectLabel={this.rejectLabel}
                    acceptClassName="history-btn">
                </ConfirmDialog>

                <div className=''>
                    {this.from === "user" && (
                        <Button onClick={() => this.setState({ visible: true })} className="bg-error rounded-custom-input btn text-error flex-row-reverse flex gap-x-3.5 padding-custom-button" label={`${this.label}`}>
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M7.68946 8.27273C8.03964 8.27273 8.32352 8.58671 8.32352 8.97403V12.4805C8.32352 12.8678 8.03964 13.1818 7.68946 13.1818C7.33928 13.1818 7.0554 12.8678 7.0554 12.4805V8.97403C7.0554 8.58671 7.33928 8.27273 7.68946 8.27273Z" fill="#DC2626"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M10.3102 8.27273C10.6604 8.27273 10.9443 8.58671 10.9443 8.97403V12.4805C10.9443 12.8678 10.6604 13.1818 10.3102 13.1818C9.96005 13.1818 9.67618 12.8678 9.67618 12.4805V8.97403C9.67618 8.58671 9.96005 8.27273 10.3102 8.27273Z" fill="#DC2626"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M3.1665 5.49998C3.1665 5.12342 3.45669 4.81816 3.81465 4.81816H14.185C14.543 4.81816 14.8332 5.12342 14.8332 5.49998C14.8332 5.87654 14.543 6.1818 14.185 6.1818H3.81465C3.45669 6.1818 3.1665 5.87654 3.1665 5.49998Z" fill="#DC2626"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M5.0868 6.90909C5.44698 6.90909 5.73897 7.22218 5.73897 7.60839V13.2028C5.73897 13.9752 6.32294 14.6014 7.04332 14.6014H10.9564C11.6768 14.6014 12.2607 13.9753 12.2607 13.2028V7.60839C12.2607 7.22218 12.5527 6.90909 12.9129 6.90909C13.2731 6.90909 13.5651 7.22218 13.5651 7.60839V13.2028C13.5651 14.7477 12.3971 16 10.9564 16H7.04332C5.60259 16 4.43462 14.7477 4.43462 13.2028V7.60839C4.43462 7.22218 4.72661 6.90909 5.0868 6.90909Z" fill="#DC2626"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M8.34464 3.39394C7.98279 3.39394 7.68945 3.70598 7.68945 4.09091V4.78788H10.3102V4.09091C10.3102 3.70598 10.0169 3.39394 9.65503 3.39394H8.34464ZM6.37906 4.09091C6.37906 2.93613 7.25908 2 8.34464 2H9.65503C10.7406 2 11.6206 2.93614 11.6206 4.09091V5.48485C11.6206 5.86978 11.3273 6.18182 10.9654 6.18182H7.03425C6.6724 6.18182 6.37906 5.86978 6.37906 5.48485V4.09091Z" fill="#DC2626"/>
                            </svg>
                         </Button>
                    )}
                    {this.from === "admin" && (
                        <Button onClick={() => this.setState({ visible: true })} className="btn bg-error p-3.5 rounded-custom-input text-error" label={``}>
                           <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M7.68946 8.27273C8.03964 8.27273 8.32352 8.58671 8.32352 8.97403V12.4805C8.32352 12.8678 8.03964 13.1818 7.68946 13.1818C7.33928 13.1818 7.0554 12.8678 7.0554 12.4805V8.97403C7.0554 8.58671 7.33928 8.27273 7.68946 8.27273Z" fill="#DC2626"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M10.3102 8.27273C10.6604 8.27273 10.9443 8.58671 10.9443 8.97403V12.4805C10.9443 12.8678 10.6604 13.1818 10.3102 13.1818C9.96005 13.1818 9.67618 12.8678 9.67618 12.4805V8.97403C9.67618 8.58671 9.96005 8.27273 10.3102 8.27273Z" fill="#DC2626"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M3.1665 5.49998C3.1665 5.12342 3.45669 4.81816 3.81465 4.81816H14.185C14.543 4.81816 14.8332 5.12342 14.8332 5.49998C14.8332 5.87654 14.543 6.1818 14.185 6.1818H3.81465C3.45669 6.1818 3.1665 5.87654 3.1665 5.49998Z" fill="#DC2626"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M5.0868 6.90909C5.44698 6.90909 5.73897 7.22218 5.73897 7.60839V13.2028C5.73897 13.9752 6.32294 14.6014 7.04332 14.6014H10.9564C11.6768 14.6014 12.2607 13.9753 12.2607 13.2028V7.60839C12.2607 7.22218 12.5527 6.90909 12.9129 6.90909C13.2731 6.90909 13.5651 7.22218 13.5651 7.60839V13.2028C13.5651 14.7477 12.3971 16 10.9564 16H7.04332C5.60259 16 4.43462 14.7477 4.43462 13.2028V7.60839C4.43462 7.22218 4.72661 6.90909 5.0868 6.90909Z" fill="#DC2626"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M8.34464 3.39394C7.98279 3.39394 7.68945 3.70598 7.68945 4.09091V4.78788H10.3102V4.09091C10.3102 3.70598 10.0169 3.39394 9.65503 3.39394H8.34464ZM6.37906 4.09091C6.37906 2.93613 7.25908 2 8.34464 2H9.65503C10.7406 2 11.6206 2.93614 11.6206 4.09091V5.48485C11.6206 5.86978 11.3273 6.18182 10.9654 6.18182H7.03425C6.6724 6.18182 6.37906 5.86978 6.37906 5.48485V4.09091Z" fill="#DC2626"/>
                            </svg>
                        </Button>
                    )}
                </div>
            </div>
        )
    }
}
