import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import credit from "./assets/credits-icon.svg";
import { TutoIcon } from "../sidebar/icons/tuto";

import "./Header.css";
import "../assets/Common.css";

const formatDate = (date, i18n) => {
  const options = {
    weekday: "short",
    day: "numeric",
    month: "long",
    year: "numeric",
  };
  if (i18n.language) {
    return new Intl.DateTimeFormat(i18n.language, options).format(date);
  } else {
    return new Intl.DateTimeFormat("fr", options).format(date);
  }
};

function Header(props) {
  const { user, avatar, tokenprops } = props;
  const [currentDate] = React.useState(new Date());
  const { i18n, t } = useTranslation();
  const [token, setToken] = useState(tokenprops);

  //Plus utile, mais au cas ou, dans Home.jsx maintenant
  let token_check = localStorage.getItem("token");
  if (!token_check && !token) {
    window.location = process.env.REACT_APP_URL + "/users/sign_in";
  }

  // function handleClickCredit(e) {
  //   document.getElementById(`link_users_account`).click();
  // }

  function handleDashboard(e) {
    window.location.replace(
      process.env.REACT_APP_URL + `/users/account?profile`
    );
  }

  React.useEffect(() => {
    setToken(tokenprops > 0 ? tokenprops : 0);
  }, [tokenprops]);

  if (user) {
    return (
      <div
        id="header"
        className="header-sections flex flex-row gap-x-2 fixed z-50"
      >
        <div className="header-first-section">
          {
            <div style={{ width: "75px", height: "75px" }}>
              <span onClick={handleDashboard} style={{ cursor: "pointer" }}>
                {avatar !== undefined ? (
                  <img
                    id="avatar_navbar"
                    className="header-avatar border-gray-custom-input border"
                    src={process.env.REACT_APP_URL_API + avatar}
                    alt="avatar of the user"
                  ></img>
                ) : (
                  <div
                    id="avatar_navbar"
                    className="header-avatar border-gray-custom-input border"
                    alt="avatar of the user"
                  ></div>
                )}
              </span>
            </div>
          }
          <div className="flex flex-col my-auto gap-y-1.5">
            <div className="flex flex-row">
              <div className="text-primary text-xl font-normal">
                {t("navbar.welcome")},&nbsp;
              </div>
              <div
                className="text-secondary text-xl font-normal"
                id="first_name_navbar"
              >
                {user.first_name}&nbsp;
              </div>
              <div
                className="text-primary text-xl font-normal"
                id="last_name_navbar"
              >
                {user.last_name}
              </div>
            </div>
            <p
              className="text-primary"
              style={{
                fontSize: "16px",
                fontWeight: "300",
                display: "flex",
                textTransform: "capitalize",
              }}
            >
              {formatDate(currentDate, i18n)}
            </p>
          </div>
        </div>

        <div className="header-second-section">
          <a
            href="https://screebot.com/docs/"
            target="_blank"
            rel="noreferrer"
            className="help-button text-secondary btn"
          >
            <TutoIcon></TutoIcon>
            <p>{t("navbar.help")}</p>
          </a>
          <div className="">
            <div className="flex flex-row gap-x-3.5 border border-gray-custom-input rounded-custom-input padding-custom-button">
              <img src={credit} alt="credit" />
              <div className="flex flex-row whitespace-nowrap items-center text-secondary">
                <p>{t("navbar.token")} :&nbsp;</p>
                <p id="user-token">
                  {token &&
                    token.toLocaleString("fr-FR", { minimumFractionDigits: 0 })}
                </p>
              </div>
            </div>
            {/* <svg
              onClick={handleClickCredit}
              style={{
                position: "absolute",
                top: "-10px",
                right: "-10px",
                height: "25px",
                cursor: "pointer",
              }}
              width="20"
              height="22"
              viewBox="0 0 20 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.45386 0.32431C2.14911 0.32431 0.270264 2.20315 0.270264 4.5079V13.2647V13.6542V17.0596C0.270264 19.3644 2.14911 21.2432 4.45386 21.2432H15.5461C17.8509 21.2432 19.7297 19.3644 19.7297 17.0596V13.6542V13.2647V4.5079C19.7297 2.20315 17.8509 0.32431 15.5461 0.32431H4.45386ZM9.54486 5.18918H10.5178V8.59458H13.9232V9.56755H10.5178V12.973H9.54486V9.56755H6.13946V8.59458H9.54486V5.18918ZM1.24324 16.3309C2.01162 17.2505 3.16611 17.8378 4.45386 17.8378H15.5461C16.8339 17.8378 17.9884 17.2505 18.7568 16.3309V17.0596C18.7568 18.8385 17.3249 20.2703 15.5461 20.2703H4.45386C2.67504 20.2703 1.24324 18.8385 1.24324 17.0596V16.3309Z"
                fill="#645FEB"
              />
              <path
                d="M4.45386 0.324341C2.14911 0.324341 0.270264 2.20318 0.270264 4.50794V13.2647V13.6543V17.0597C0.270264 19.3644 2.14911 21.2433 4.45386 21.2433H15.5461C17.8509 21.2433 19.7297 19.3644 19.7297 17.0597V13.6543V13.2647V4.50794C19.7297 2.20318 17.8509 0.324341 15.5461 0.324341H4.45386ZM9.54486 5.18921H10.5178V8.59461H13.9232V9.56758H10.5178V12.973H9.54486V9.56758H6.13946V8.59461H9.54486V5.18921ZM1.24324 16.3309C2.01162 17.2505 3.16611 17.8379 4.45386 17.8379H15.5461C16.8339 17.8379 17.9884 17.2505 18.7568 16.3309V17.0597C18.7568 18.8385 17.3249 20.2703 15.5461 20.2703H4.45386C2.67504 20.2703 1.24324 18.8385 1.24324 17.0597V16.3309Z"
                fill="#645FEB"
              />
            </svg> */}
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
