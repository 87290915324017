import React from "react";
import i18n from "i18next";

import "./Card.css";
import "../../assets/Common.css";

import { ReturnImage } from "../../components/product/component/sharedFunction.js";

function Card(props) {
  function Badge({ text }) {
    let classList = "";
    switch (text) {
      case "SEO":
        classList = "b-seo";
        break;
      case "BLOG":
        classList = "b-blog";
        break;
      case "SOCIAL":
        classList = "b-social";
        break;
      case "ECOMMERCE":
        classList = "b-ecommerce";
        break;
      case "NEW":
        classList = "b-new";
        break;
      default:
        classList = "";
        break;
    }
    return (
      <span className={`bubble ${classList}`}>
        {text === "NEW" && (
          <svg
            width="11"
            height="10"
            viewBox="0 0 11 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.04404 0.351523C5.94367 0.136703 5.73157 0 5.49864 0C5.26571 0 5.05551 0.136703 4.95324 0.351523L3.73557 2.93522L1.01617 3.34923C0.788917 3.38439 0.599543 3.54843 0.529475 3.77302C0.459407 3.9976 0.516219 4.24562 0.67908 4.41162L2.65235 6.42506L2.18649 9.27045C2.14862 9.5048 2.24331 9.74305 2.43079 9.88171C2.61827 10.0204 2.86635 10.0379 3.07087 9.92662L5.50053 8.58888L7.9302 9.92662C8.13472 10.0379 8.3828 10.0223 8.57028 9.88171C8.75776 9.7411 8.85245 9.5048 8.81457 9.27045L8.34682 6.42506L10.3201 4.41162C10.483 4.24562 10.5417 3.9976 10.4697 3.77302C10.3977 3.54843 10.2103 3.38439 9.98301 3.34923L7.26171 2.93522L6.04404 0.351523Z"
              fill="white"
            />
          </svg>
        )}
        {i18n.t(`dashboard.badges.${text}`)}
      </span>
    );
  }

  function ReturnBadges() {
    const { badges, newFeature } = props;

    const allBadges = [];

    if (newFeature) {
      allBadges.push(<Badge text="NEW" />);
    }

    if (badges) {
      badges.forEach((element) => {
        allBadges.push(<Badge text={element} />);
      });
    }

    if (allBadges.length >= 1) {
      return (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            rowGap: "0.4rem",
            columnGap: "0.5rem",
          }}
        >
          {allBadges.map((badge, index) => (
            <span key={index} className="badge">
              {badge}
            </span>
          ))}
        </div>
      );
    }
  }

  function handleActive(e, disabled) {
    if (!disabled) {
      let btnSidebarName = e.currentTarget.id;
      document.getElementById(`link_product_${btnSidebarName}`).click();
    }
  }

  const {
    icon,
    title,
    text,
    product,
    disabled = false,
    badges,
    newFeature = false,
  } = props;

  return (
    <div
      id={product}
      onClick={(e) => {
        handleActive(e, disabled);
      }}
      className={`card-dashboard text-primary ${
        disabled ? "card-dashboard-disabled" : ""
      }`}
    >
      <ReturnImage icon={icon} classList={"bg-img-feature"}></ReturnImage>
      <div className="flex flex-col gap-y-3.5 text-primary text-left">
        <h2>{title}</h2>
        <ReturnBadges badges={badges} newFeature={newFeature}></ReturnBadges>
        <p>{text}</p>
      </div>
    </div>
  );
}

export default Card;
