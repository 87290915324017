function Page(props) {
  const { page, setPage, user, plan } = props;

  return (
    <div className="flex flex-row gap-x-12">
      <p
        className={`font-medium text-base text-secondary cursor-pointer my-auto ${
          page !== 0 ? "opacity-60" : "cursor-not-allowed"
        }`}
        onClick={() => setPage(0)}
      >
        Profil et préférences
      </p>
      {user && user.role !== "member" && (
        <p
          className={`font-medium text-base text-secondary cursor-pointer my-auto ${
            page !== 1 ? "opacity-60" : "cursor-not-allowed"
          }`}
          onClick={() => setPage(1)}
        >
          Forfait
        </p>
      )}
      {user &&
        user.role !== "member" &&
        plan !== null &&
        plan.id_product !== "-1" && (
          <p
            className={`font-medium text-base text-secondary cursor-pointer my-auto ${
              page !== 2 ? "opacity-60" : "cursor-not-allowed"
            }`}
            onClick={() => {
              setPage(2);
            }}
          >
            Factures
          </p>
        )}
      {user &&
        ((user.role !== "member" &&
          plan !== null &&
          plan.id_product !== "-1") ||
          user.role === "admin") && (
          <p
            className={`font-medium text-base text-secondary cursor-pointer my-auto ${
              page !== 3 ? "opacity-60" : "cursor-not-allowed"
            }`}
            onClick={() => setPage(3)}
          >
            Mon équipe
          </p>
        )}
      <p
        className={`text-base text-error bg-error padding-custom-button cursor-pointer rounded-custom-input my-auto flex flex-row gap-x-3.5 font-normal ${
          page !== 4 ? "opacity-60" : "cursor-not-allowed"
        }`}
        onClick={() => setPage(4)}
      >
        <svg
          className="my-auto"
          width="18"
          height="19"
          viewBox="0 0 18 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.7361 12.2217C15.4169 11.3572 14.8294 10.3153 14.0162 8.87346L13.7795 8.45352C12.8037 6.72315 12.0986 5.47308 11.4122 4.60135C10.6229 3.59895 9.87871 3.15186 8.99956 3.15186C7.36158 3.15186 6.34687 4.72069 4.87042 7.30443C4.73663 7.53862 4.81788 7.83688 5.05207 7.97068C5.28627 8.10448 5.58452 8.02303 5.71832 7.78903C6.46446 6.48329 7.03519 5.5514 7.57487 4.94004C8.07724 4.37126 8.50324 4.12847 8.99936 4.12847C9.55818 4.12847 10.0502 4.45056 10.6446 5.20549C11.2845 6.01823 11.9741 7.24095 12.9287 8.93323L13.1654 9.35318C13.9563 10.7554 14.5278 11.7685 14.8198 12.5598C15.1458 13.4432 15.0155 13.8122 14.845 14.0568C14.2772 14.8713 12.4867 14.8713 9.2361 14.8713H8.76244C5.51186 14.8713 3.72133 14.8713 3.15353 14.0568C2.70722 13.4167 3.13185 12.4076 4.25261 10.3878C4.38348 10.1521 4.29832 9.85477 4.06256 9.7239C3.82681 9.59304 3.52953 9.6782 3.39866 9.91395C2.78183 11.0259 2.3572 11.8578 2.14683 12.5684C1.89858 13.4073 1.96381 14.0577 2.35251 14.6152C2.77695 15.224 3.48167 15.5635 4.6376 15.7156C5.64117 15.8477 6.95042 15.8477 8.76244 15.8477H9.2361C11.0481 15.8477 12.3574 15.8477 13.3609 15.7156C14.5169 15.5635 15.2216 15.224 15.646 14.6152C16.2101 13.8062 15.9906 12.9112 15.7361 12.2217Z"
            fill="#DC2626"
          />
          <path
            d="M8.99956 10.6392C9.2693 10.6392 9.48787 10.4206 9.48787 10.1509V6.89542C9.48787 6.62568 9.2693 6.40712 8.99956 6.40712C8.72982 6.40712 8.51125 6.62568 8.51125 6.89542V10.1509C8.51125 10.4206 8.72982 10.6392 8.99956 10.6392Z"
            fill="#DC2626"
          />
          <path
            d="M9.60094 12.3533C9.73854 12.0211 9.58079 11.6403 9.24862 11.5027C8.91644 11.3651 8.53562 11.5229 8.39803 11.855C8.26044 12.1872 8.41818 12.568 8.75035 12.7056C9.08253 12.8432 9.46335 12.6855 9.60094 12.3533Z"
            fill="#DC2626"
          />
        </svg>
        Danger zone
      </p>
    </div>
  );
}

export default Page;
