import React, { Component } from "react";
import Modal from "../../../modal/Modal.js";
import i18n from "i18next";
import "../../../../sidebar/Sidebar.css";

class Pricing extends Component {
  constructor() {
    super();
    this.state = {
      show: false,
    };
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  showModal = () => {
    document.getElementById("header").classList.remove("z-50");
    this.setState({ show: true });
  };

  hideModal = () => {
    document.getElementById("header").classList.add("z-50");
    this.setState({ show: false });
  };

  render() {
    const { user } = this.props;
    return (
      <main>
        <button
          id="pricing-button"
          className="button-modal btn w-full rounded-custom-input"
          type="button"
          onClick={this.showModal}
        >
          <div>{i18n.t("sidebar.payment.subscription")}</div>
        </button>
        <Modal show={this.state.show} handleClose={this.hideModal}>
          <div
            style={{
              paddingBottom: "2rem",
              paddingTop: "2rem",
              textAlign: "center",
            }}
            className="modal-title"
          >
            {i18n.t("sidebar.payment.package")}
          </div>
          <stripe-pricing-table
            pricing-table-id={`${process.env.REACT_APP_STRIPE_PRICING_ID}`}
            publishable-key={`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`}
            customer-email={user.email}
          ></stripe-pricing-table>
        </Modal>
      </main>
    );
  }
}

export default Pricing;
