import React, { Component } from "react";
import i18n from "i18next";
import Markdown from "react-markdown";
import Settings from "./component/Settings.jsx";
import Select from "react-dropdown-select";

import {
  renderButtonHtml,
  initFieldbacksService,
  refreshPage,
  handleChange,
  headerProduct,
  btnCopy,
  canGenerate,
  generateV2,
} from "./component/sharedFunction.js";

import screebotNormal from "./assets/zap.svg";
import screebotHuhu from "./assets/zap.svg";

import iconeHeader from "../../assets/product/blog.svg";

import "./styles/product.css";
import "../../assets/Common.css";

class BlogPremium extends Component {
  state = {
    title: "",

    name: "",
    context: "",
    key_word: "",
    section: "5",
    output: "",
    product: "blogv2",
    click: false,
    buttonDisabled: true,
    returnApiSpan: "",
    copied: false,
    copied2: false,
    txtScreebot: "",
    imgScreebot: "",
    colorBackgroundScreebot: "",
    finished: "",
    id: "",
    project: "",
    language: "",
    problemTokenOrPlan: false,
    loading: "",
    fieldbacks: null,
    step: "summary",
    h1_tmp: "",
    planIdProduct: "",
  };

  constructor(props) {
    super(props);
    if (this.props.selectedProject) {
      if (!canGenerate(this.props.plan, this.props.user)) {
        this.state = {
          step: "summary",
          title: "",
          name: "",
          output: "",
          product: "blogv2",
          buttonDisabled: false,
          click: false,
          section: "5",
          returnApiSpan: "",
          copied: false,
          copied2: false,
          txtScreebot: i18n.t("products.shared.choose"),
          imgScreebot: screebotHuhu,
          colorBackgroundScreebot: "#486497",
          finished: false,
          id: null,
          project: this.props.selectedProject.id,
          language: this.props.selectCountry,
          problemTokenOrPlan: true,
          loading: "",
          key_word: "",
          h1_tmp: "",
          planIdProduct: this.props.plan ? this.props.plan.id_product : "-1",
          planName: this.props.plan ? this.props.plan.name : "",
        };
      } else {
        this.state = {
          step: "summary",
          title: "",
          section: "5",
          name: "",
          output: "",
          product: "blogv2",
          buttonDisabled: true,
          click: false,
          returnApiSpan: "",
          copied: false,
          copied2: false,
          txtScreebot: i18n.t("products.blogv2.generate_summary"),
          imgScreebot: screebotNormal,
          colorBackgroundScreebot: "#486497",
          finished: false,
          id: null,
          project: this.props.selectedProject.id,
          language: this.props.selectCountry,
          problemTokenOrPlan: false,
          loading: "",
          key_word: "",
          h1_tmp: "",
          planIdProduct: this.props.plan ? this.props.plan.id_product : "-1",
          planName: this.props.plan ? this.props.plan.name : "",
        };
      }

      initFieldbacksService(
        this,
        this.state.product,
        this.props.selectedProject.id
      );
    }
    this.options = {
      section: [
        {
          value: "4",
          label: i18n.t("products.blogv2.section_options.four"),
        },
        {
          value: "5",
          label: i18n.t("products.blogv2.section_options.five"),
        },
        {
          value: "6",
          label: i18n.t("products.blogv2.section_options.six"),
        },
        {
          value: "7",
          label: i18n.t("products.blogv2.section_options.seven"),
        },
        {
          value: "8",
          label: i18n.t("products.blogv2.section_options.height"),
        },
      ],
    };

    if (
      this.state.planIdProduct === "-1" &&
      !(
        this.state.planName === "demo_admin_affiliate_10000" ||
        this.props.user.role === "admin"
      )
    ) {
      this.options.section = this.options.section.map((option, index) => {
        return { ...option, disabled: index !== 0 };
      });
      this.state.section = "4";
    }
  }

  handleBlogv1 = () => {
    window.location = process.env.REACT_APP_URL + "/product/blog";
  };

  render() {
    return (
      <div className="container flex flex-col gap-y-5">
        <div className="flex flex-col w-full gap-y-3.5">
          {headerProduct(iconeHeader, this.state.product)}

          <div className="bg-white border rounded-custom-input border-gray-custom-input flex flex-row gap-x-3 w-fit p-1">
            <button
              onClick={this.handleBlogv1}
              className="flex flex-row gap-x-2 padding-custom-switch text-gray-custom rounded-md"
            >
              <svg
                className="my-auto"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.0792 10.2243C10.8108 10.2502 10.6143 10.4889 10.6403 10.7576C10.678 11.1471 10.7202 11.5821 10.694 11.7605C10.5246 11.7247 10.1587 11.5563 9.83094 11.4053L9.63598 11.3157C9.38535 11.2004 9.21989 11.1242 9.00012 11.1242C8.78035 11.1242 8.6147 11.2004 8.36329 11.3162L8.16931 11.4055C7.84151 11.5565 7.47563 11.7249 7.30626 11.7607C7.28008 11.5823 7.32228 11.1473 7.35998 10.7577L7.38127 10.5374C7.40823 10.2598 7.42601 10.0762 7.36174 9.87009C7.29708 9.66185 7.1785 9.52315 6.99898 9.31335L6.85774 9.14808C6.59929 8.84588 6.3094 8.50695 6.24474 8.35614C6.29104 8.33114 6.39223 8.28875 6.61102 8.23131C6.87181 8.16275 7.02769 7.89571 6.95913 7.63472C6.89056 7.37393 6.62352 7.21804 6.36253 7.28661C5.9271 7.40108 5.44967 7.56459 5.29671 8.05648C5.17814 8.4378 5.3395 8.75992 5.47116 8.96914C5.61474 9.19711 5.82474 9.44286 6.11561 9.78296L6.25685 9.94803C6.33147 10.0352 6.40844 10.1252 6.42876 10.1606C6.43266 10.202 6.42075 10.3245 6.40922 10.4432L6.38793 10.6636C6.34398 11.1162 6.31233 11.4432 6.32444 11.7144C6.33558 11.965 6.38715 12.326 6.7042 12.5667C7.07047 12.8446 7.49418 12.7233 7.63347 12.6835C7.88801 12.6106 8.17732 12.4774 8.57797 12.2928L8.77254 12.2031C8.86299 12.1615 8.95636 12.1185 9.00012 12.1041C9.04407 12.1185 9.13726 12.1615 9.22829 12.2033L9.42266 12.2928C9.82312 12.4772 10.1124 12.6104 10.367 12.6833C10.4371 12.7034 10.5793 12.7442 10.75 12.7442C10.9182 12.7442 11.1142 12.7046 11.296 12.5667C11.6133 12.3258 11.6649 11.965 11.676 11.7144C11.6881 11.443 11.6565 11.1162 11.6125 10.6636C11.5865 10.3952 11.3474 10.1989 11.0794 10.2246L11.0792 10.2243Z"
                  fill="#486497"
                />
                <path
                  d="M12.2857 9.30432C12.5662 8.9527 12.8532 8.53719 12.7035 8.05625C12.5685 7.62218 12.1652 7.46317 12.0327 7.41101C11.7858 7.31373 11.4766 7.24379 11.0485 7.1469L10.8413 7.10002C10.7409 7.07736 10.6375 7.05392 10.5965 7.03868C10.569 7.00234 10.5137 6.9033 10.4604 6.80719L10.3537 6.61595C10.1341 6.22212 9.97569 5.9377 9.81238 5.72457C9.7235 5.60854 9.45333 5.25574 9.00032 5.25574C8.54731 5.25574 8.27695 5.60873 8.18826 5.72457C8.02495 5.9377 7.86652 6.22232 7.64695 6.61595C7.51568 6.85153 7.60007 7.14905 7.83566 7.28032C8.07125 7.4116 8.36876 7.32701 8.50004 7.09162C8.67956 6.76949 8.8796 6.41083 9.00032 6.27077C9.12105 6.41063 9.32108 6.76949 9.50061 7.09162L9.60727 7.28286C9.74303 7.52646 9.8327 7.68762 10.0058 7.8189C10.1814 7.95232 10.3584 7.99217 10.626 8.05273L10.8333 8.09961C11.2019 8.18303 11.6151 8.2766 11.7553 8.35454C11.7334 8.40357 11.6752 8.50359 11.5225 8.69523C11.3543 8.90601 11.3888 9.21329 11.5996 9.38149C11.6897 9.45318 11.7971 9.48815 11.904 9.48815C12.0474 9.48815 12.1896 9.42524 12.2861 9.30432H12.2857Z"
                  fill="#486497"
                />
              </svg>
              {i18n.t("products.shared.switch.blogv1")}
            </button>
            <button className="flex flex-row gap-x-2 padding-custom-switch bg-blue-switch text-secondary rounded-md">
              <svg
                className="my-auto"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.9999 4.27912C9.26967 4.27912 9.48827 4.06053 9.48827 3.79075V2.48837C9.48827 2.21859 9.26967 2 8.9999 2C8.73012 2 8.51153 2.21859 8.51153 2.48837V3.79075C8.51153 4.06053 8.73012 4.27912 8.9999 4.27912Z"
                  fill="#486497"
                />
                <path
                  d="M8.9999 13.7209C8.73012 13.7209 8.51153 13.9395 8.51153 14.2093V15.5116C8.51153 15.7814 8.73012 16 8.9999 16C9.26967 16 9.48827 15.7814 9.48827 15.5116V14.2093C9.48827 13.9395 9.26967 13.7209 8.9999 13.7209Z"
                  fill="#486497"
                />
                <path
                  d="M3.79075 8.51154H2.48836C2.21859 8.51154 1.99999 8.73013 1.99999 8.99991C1.99999 9.26968 2.21859 9.48828 2.48836 9.48828H3.79075C4.06052 9.48828 4.27912 9.26968 4.27912 8.99991C4.27912 8.73013 4.06052 8.51154 3.79075 8.51154Z"
                  fill="#486497"
                />
                <path
                  d="M15.5116 8.51154H14.2092C13.9395 8.51154 13.7209 8.73013 13.7209 8.99991C13.7209 9.26968 13.9395 9.48828 14.2092 9.48828H15.5116C15.7814 9.48828 16 9.26968 16 8.99991C16 8.73013 15.7814 8.51154 15.5116 8.51154Z"
                  fill="#486497"
                />
                <path
                  d="M4.97103 12.3382L4.74755 12.5615C4.55689 12.7521 4.5567 13.0614 4.74755 13.2522C4.84288 13.3475 4.96791 13.3952 5.09293 13.3952C5.21795 13.3952 5.34278 13.3475 5.4383 13.2522L5.66178 13.0289C5.85244 12.8383 5.85244 12.529 5.66178 12.3382C5.47112 12.1475 5.16189 12.1475 4.97123 12.3382H4.97103Z"
                  fill="#486497"
                />
                <path
                  d="M12.5617 4.74774L12.3382 4.97122C12.1475 5.16188 12.1475 5.47112 12.3382 5.66178C12.4335 5.75711 12.5585 5.80477 12.6836 5.80477C12.8086 5.80477 12.9334 5.75711 13.0289 5.66178L13.2524 5.4383C13.4431 5.24764 13.4431 4.9384 13.2524 4.74755C13.0617 4.55689 12.7525 4.55689 12.5617 4.74755V4.74774Z"
                  fill="#486497"
                />
                <path
                  d="M13.0289 12.3382C12.8383 12.1475 12.529 12.1475 12.3382 12.3382C12.1475 12.5288 12.1475 12.8381 12.3382 13.0289L12.5615 13.2522C12.6568 13.3475 12.7818 13.3952 12.9068 13.3952C13.0319 13.3952 13.1569 13.3475 13.2522 13.2522C13.4429 13.0616 13.4429 12.7523 13.2522 12.5615L13.0289 12.3382Z"
                  fill="#486497"
                />
                <path
                  d="M4.97125 5.66178C5.06658 5.75711 5.1916 5.80477 5.31663 5.80477C5.44165 5.80477 5.56667 5.75711 5.662 5.66178C5.85266 5.47112 5.85266 5.16188 5.662 4.97103L5.43852 4.74755C5.24786 4.55689 4.93863 4.55689 4.74797 4.74755C4.55731 4.93821 4.55731 5.24744 4.74797 5.4381L4.97145 5.66158L4.97125 5.66178Z"
                  fill="#486497"
                />
                <path
                  d="M11.0792 10.2246C10.8107 10.2505 10.6142 10.4893 10.6402 10.7579C10.6779 11.1474 10.7201 11.5824 10.6939 11.7608C10.5246 11.725 10.1587 11.5566 9.83088 11.4056L9.63593 11.316C9.38529 11.2007 9.21983 11.1245 9.00007 11.1245C8.7803 11.1245 8.61465 11.2007 8.36323 11.3166L8.16925 11.4058C7.84146 11.5568 7.47557 11.7252 7.30621 11.761C7.28003 11.5826 7.32222 11.1476 7.35993 10.7581L7.38122 10.5377C7.40818 10.2601 7.42595 10.0765 7.36168 9.87039C7.29702 9.66215 7.17845 9.52345 6.99892 9.31365L6.85769 9.14839C6.59924 8.84618 6.30935 8.50725 6.24468 8.35645C6.29098 8.33144 6.39217 8.28905 6.61096 8.23162C6.87175 8.16305 7.02764 7.89601 6.95907 7.63503C6.89051 7.37424 6.62346 7.21835 6.36248 7.28692C5.92705 7.40139 5.44962 7.5649 5.29666 8.05678C5.17809 8.4381 5.33944 8.76023 5.47111 8.96945C5.61469 9.19742 5.82469 9.44317 6.11556 9.78327L6.2568 9.94834C6.33142 10.0355 6.40839 10.1255 6.4287 10.1609C6.43261 10.2023 6.42069 10.3248 6.40917 10.4435L6.38787 10.6639C6.34392 11.1165 6.31228 11.4435 6.32439 11.7147C6.33552 11.9653 6.38709 12.3263 6.70414 12.567C7.07042 12.845 7.49413 12.7236 7.63341 12.6838C7.88795 12.6109 8.17726 12.4777 8.57792 12.2931L8.77249 12.2034C8.86293 12.1618 8.95631 12.1188 9.00007 12.1044C9.04402 12.1188 9.1372 12.1618 9.22823 12.2036L9.42261 12.2931C9.82307 12.4775 10.1124 12.6107 10.3669 12.6836C10.437 12.7037 10.5793 12.7445 10.75 12.7445C10.9182 12.7445 11.1141 12.7049 11.296 12.567C11.6132 12.3261 11.6648 11.9653 11.6759 11.7147C11.6881 11.4433 11.6564 11.1165 11.6125 10.6639C11.5865 10.3955 11.3474 10.1992 11.0794 10.2249L11.0792 10.2246Z"
                  fill="#486497"
                />
                <path
                  d="M12.2856 9.30463C12.5661 8.953 12.8531 8.5375 12.7035 8.05655C12.5685 7.62249 12.1651 7.46347 12.0327 7.41132C11.7857 7.31403 11.4765 7.2441 11.0485 7.14721L10.8412 7.10032C10.7408 7.07766 10.6375 7.05422 10.5965 7.03898C10.5689 7.00265 10.5136 6.90361 10.4603 6.8075L10.3536 6.61625C10.1341 6.22243 9.97564 5.938 9.81233 5.72488C9.72345 5.60884 9.45328 5.25604 9.00027 5.25604C8.54726 5.25604 8.27689 5.60904 8.18821 5.72488C8.0249 5.938 7.86647 6.22262 7.6469 6.61625C7.51562 6.85184 7.60001 7.14936 7.8356 7.28063C8.07119 7.4119 8.36871 7.32732 8.49998 7.09192C8.67951 6.76979 8.87954 6.41114 9.00027 6.27107C9.12099 6.41094 9.32103 6.76979 9.50055 7.09192L9.60721 7.28317C9.74298 7.52677 9.83265 7.68793 10.0057 7.8192C10.1813 7.95263 10.3583 7.99248 10.626 8.05303L10.8332 8.09992C11.2018 8.18333 11.615 8.2769 11.7553 8.35485C11.7334 8.40388 11.6752 8.5039 11.5224 8.69553C11.3542 8.90631 11.3888 9.2136 11.5996 9.38179C11.6896 9.45348 11.7971 9.48845 11.9039 9.48845C12.0473 9.48845 12.1895 9.42555 12.286 9.30463H12.2856Z"
                  fill="#486497"
                />
              </svg>
              {i18n.t("products.shared.switch.blogv2")}
            </button>
          </div>

          <form
            className="flex flex-col gap-y-5 w-full"
            onSubmit={(e) =>
              generateV2(
                e,
                this.state,
                this,
                this.state.id,
                this.props.userToken
              )
            }
          >
            <div className="flex flex-col gap-y-1.5 ctn-document">
              <div className="flex flex-row items-center gap-x-5">
                <input
                  disabled={this.state.click}
                  value={this.state.title}
                  type="text"
                  name="title"
                  onInput={(e) => handleChange(this, e)}
                  onChange={(e) => handleChange(this, e)}
                  placeholder={i18n.t("products.shared.place_holder_document")}
                />
                <Settings
                  setSelectCountry={this.props.setSelectCountry}
                  selectCountry={this.props.selectCountry}
                ></Settings>
              </div>
            </div>

            <hr className="separator-product my-5"></hr>

            <input
              value={this.state.type}
              className="hidden"
              type="text"
              name="title"
            />
            <h2 className="text-secondary text-left">
              {i18n.t("products.shared.parameter")}
            </h2>

            <div className="flex flex-col relative gap-y-1.5 w-full">
              <div className="flex flex-row justify-between">
                <h4 id="name" className="flex flex-row text-primary obligatory">
                  {i18n.t("products.blog.name_article")}
                </h4>
                <p className="text-primary text-xs my-auto">
                  <span>0</span>
                  <span>/80</span>
                </p>
              </div>
              {renderButtonHtml(this, "name", () =>
                handleChange(
                  this,
                  {
                    target: {
                      value: this.state.name,
                      name: "name",
                    },
                  },
                  true,
                  80
                )
              )}
              <input
                disabled={this.state.click}
                value={this.state.name}
                name="name"
                id="name"
                placeholder={i18n.t("products.blog.place_holder_name_article")}
                onInput={(e) => handleChange(this, e, true, 80)}
                onChange={(e) => handleChange(this, e, true, 80)}
              ></input>
            </div>

            <div className="flex flex-col relative gap-y-1.5 w-full">
              <span className="flex flex-row text-primary">
                <h4 id="name" className="obligatory">
                  {i18n.t("products.blogv2.section")}
                </h4>
                {this.state.planIdProduct === "-1" &&
                  !(
                    this.state.planName === "demo_admin_affiliate_10000" ||
                    this.props.user.role === "admin"
                  ) && (
                    <span className="text-error text-sm">
                      &nbsp; {i18n.t("products.blogv2.freemium")}
                    </span>
                  )}
              </span>
              <Select
                name="section"
                id="section"
                values={[
                  this.options.section.find(
                    (opt) => opt.value === this.state.section
                  ),
                ]}
                disabled={this.state.click}
                isOptionDisabled={(option) => option.disabled}
                options={this.options.section}
                onChange={(e) => handleChange(this, e, false, 80, "section")}
              />
            </div>

            <div className="flex flex-col gap-y-1.5 w-full relative">
              <div className="flex flex-row justify-between w-full">
                <h4
                  id="context"
                  htmlFor="context"
                  className="flex flex-row text-primary"
                >
                  {i18n.t("products.blog.context_title")}
                </h4>
                <p className="text-primary text-xs my-auto">
                  <span>0</span>
                  <span>/2000</span>
                </p>
              </div>
              {renderButtonHtml(this, "context", () =>
                handleChange(
                  this,
                  {
                    target: {
                      value: this.state.context,
                      name: "context",
                    },
                  },
                  true,
                  2000
                )
              )}
              <textarea
                disabled={this.state.click}
                value={this.state.context}
                name="context"
                cols="30"
                rows="10"
                onInput={(e) => handleChange(this, e, true, 2000)}
                onChange={(e) => handleChange(this, e, true, 2000)}
                placeholder={i18n.t("products.blog.place_holder_context")}
              ></textarea>
            </div>

            <div className="flex flex-col gap-y-1.5 w-full relative">
              <div className="flex flex-row justify-between w-full">
                <h4 id="key_word" className="flex flex-row text-primary">
                  {i18n.t("products.blog.key_word_title")}
                  {this.state.planIdProduct === "-1" &&
                    !(
                      this.state.planName === "demo_admin_affiliate_10000" ||
                      this.props.user.role === "admin"
                    ) && (
                      <span className="text-error text-sm">
                        &nbsp; {i18n.t("products.blogv2.freemium")}
                      </span>
                    )}
                </h4>
                <p className="text-primary text-xs my-auto">
                  <span>0</span>
                  <span>/500</span>
                </p>
              </div>
              {renderButtonHtml(this, "key_word", () =>
                handleChange(
                  this,
                  {
                    target: {
                      value: this.state.key_word,
                      name: "key_word",
                    },
                  },
                  true,
                  500
                )
              )}
              <input
                disabled={
                  this.state.click ||
                  (this.state.planIdProduct === "-1" &&
                    !(
                      this.state.planName === "demo_admin_affiliate_10000" ||
                      this.props.user.role === "admin"
                    ))
                }
                value={this.state.key_word}
                className={
                  this.state.planIdProduct === "-1" &&
                  !(
                    this.state.planName === "demo_admin_affiliate_10000" ||
                    this.props.user.role === "admin"
                  )
                    ? "disabled-blur"
                    : ""
                }
                name="key_word"
                id="key_word"
                placeholder={i18n.t("products.blog.place_holder_key_word")}
                onInput={(e) => handleChange(this, e, true, 500)}
                onChange={(e) => handleChange(this, e, true, 500)}
              ></input>
            </div>

            <div className="flex flex-row gap-x-8">
              {!this.state.output && (
                <div>
                  <button
                    disabled={this.state.buttonDisabled}
                    id="generate-button"
                    className="button-product text-sm"
                    style={{ background: this.state.colorBackgroundScreebot }}
                  >
                    <img
                      src={this.state.imgScreebot}
                      alt="logo product"
                      className="m-auto"
                    ></img>
                    <div>{this.state.txtScreebot}</div>
                  </button>
                </div>
              )}
              {this.state.click && (
                <div>
                  <div
                    onClick={() => refreshPage(this)}
                    disabled={!this.state.buttonDisabled}
                    className="button-product bg-white text-secondary btn text-sm"
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_1855_5207)">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M3.83375 1.77436C2.66957 1.77436 1.72578 2.71816 1.72578 3.88233V12.1179C1.72578 13.2821 2.66957 14.2259 3.83375 14.2259H12.0693C13.2335 14.2259 14.1773 13.2821 14.1773 12.1179V8.00023C14.1773 7.6483 14.4626 7.36316 14.8143 7.36316C15.1661 7.36316 15.4514 7.64852 15.4514 8.00023V12.1179C15.4514 13.9859 13.9371 15.5002 12.0691 15.5002H3.83375C1.96572 15.5 0.451416 13.9857 0.451416 12.1177V3.88233C0.451416 2.0143 1.96572 0.5 3.83375 0.5H7.95142C8.30334 0.5 8.58848 0.785365 8.58848 1.13707C8.58848 1.48877 8.30312 1.77414 7.95142 1.77414H3.83375V1.77436Z"
                          fill="#486497"
                        />
                        <path
                          d="M12.8237 5.75138C11.3771 5.75138 10.2001 4.57439 10.2001 3.12778C10.2001 1.68116 11.3771 0.504395 12.8237 0.504395C14.2704 0.504395 15.4473 1.68139 15.4473 3.128C15.4473 4.57462 14.2704 5.75161 12.8237 5.75161V5.75138ZM12.8237 1.77853C12.0799 1.77853 11.4745 2.38389 11.4745 3.12778C11.4745 3.87166 12.0799 4.47702 12.8237 4.47702C13.5676 4.47702 14.173 3.87166 14.173 3.12778C14.173 2.38389 13.5676 1.77853 12.8237 1.77853Z"
                          fill="#486497"
                        />
                        <path
                          d="M7.23224 11.6355H4.20341C3.85149 11.6355 3.56635 11.3503 3.56635 10.9984C3.56635 10.6465 3.85171 10.3613 4.20341 10.3613H7.23224C7.58417 10.3613 7.86931 10.6465 7.86931 10.9984C7.86931 11.3503 7.58395 11.6355 7.23224 11.6355Z"
                          fill="#486497"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1855_5207">
                          <rect
                            width="15"
                            height="15"
                            fill="white"
                            transform="translate(0.451416 0.5)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                    {i18n.t("products.shared.new")}
                  </div>
                </div>
              )}
            </div>
          </form>
        </div>

        {this.state.click && (
          <div className="output-response">
            {!this.state.output && (
              <>
                <div className="ctn-loader">
                  <lottie-player
                    src="https://assets8.lottiefiles.com/packages/lf20_xv923ge8.json"
                    background="transparent"
                    speed="1"
                    style={{ width: "75px", height: "25px" }}
                    loop
                    autoplay
                  ></lottie-player>
                  <span
                    style={{
                      textAlign: "left",
                      marginTop: "auto",
                      marginBottom: "auto",
                    }}
                  >
                    {this.state.loading}
                  </span>
                </div>
              </>
            )}

            <span id="output-screebot" className="output-screebot">
              <div className="markdown pb-6">
                {this.state.output && this.state.step === "end" && (
                  <h1>{this.state.h1_tmp}</h1>
                )}
                <Markdown>{this.state.output}</Markdown>
                {this.state.output && this.state.step !== "end" && (
                  <div className="flex flex-row gap-x-8 pt-2">
                    <div
                      onClick={(e) => {
                        this.setState({ step: "introduction" }, () => {
                          generateV2(
                            e,
                            this.state,
                            this,
                            this.state.id,
                            this.props.userToken
                          );
                        });
                      }}
                      id="generate-button"
                      className="button-product text-sm"
                      style={{ background: this.state.colorBackgroundScreebot }}
                    >
                      <img
                        src={this.state.imgScreebot}
                        alt="logo product"
                        className="m-auto"
                      ></img>
                      <div>{i18n.t("products.blogv2.generate")}</div>
                    </div>

                    <div
                      onClick={(e) => {
                        this.setState({ step: "summary" }, () => {
                          generateV2(
                            e,
                            this.state,
                            this,
                            this.state.id,
                            this.props.userToken
                          );
                        });
                      }}
                      className="button-product bg-white text-sm"
                    >
                      <svg
                        width="19"
                        height="16"
                        viewBox="0 0 19 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_2637_10817)">
                          <path
                            d="M10.0792 9.61548V6.38479C10.0792 6.13982 9.92626 5.91883 9.69167 5.82506C9.45709 5.73129 9.18684 5.78314 9.00725 5.95639L7.75145 7.16793C7.50631 7.40442 7.50631 7.788 7.75145 8.02449C7.99658 8.26098 8.39417 8.26098 8.63955 8.02449L8.8234 7.84712V9.61548C8.8234 9.95011 9.10445 10.2212 9.45131 10.2212C9.79816 10.2212 10.0792 9.95011 10.0792 9.61548Z"
                            fill="#486497"
                          />
                          <path
                            d="M1.70725 8C1.70725 5.21201 4.04984 2.952 6.9397 2.952H8.6142C8.86812 2.952 9.09718 2.80444 9.19438 2.57812C9.29158 2.35181 9.23783 2.09133 9.05825 1.91808L7.38376 0.302369C7.13862 0.0658769 6.74104 0.0658769 6.49565 0.302369C6.25027 0.538862 6.25052 0.922435 6.49565 1.15917L7.09819 1.74047H6.9397C3.35638 1.74047 0.451447 4.543 0.451447 8C0.451447 8.33463 0.732496 8.60577 1.07935 8.60577C1.4262 8.60577 1.70725 8.33463 1.70725 8Z"
                            fill="#486497"
                          />
                          <path
                            d="M7.35839 13.048H6.93995C5.16223 13.048 3.59146 12.1934 2.64484 10.8842C2.44642 10.6099 2.05511 10.5426 1.77054 10.734C1.48623 10.9254 1.4164 11.3029 1.61482 11.5775C2.78624 13.1975 4.7345 14.2598 6.93995 14.2598H7.35839C7.70524 14.2598 7.98629 13.9887 7.98629 13.654C7.98629 13.3194 7.70524 13.0483 7.35839 13.0483V13.048Z"
                            fill="#486497"
                          />
                          <path
                            d="M18.4515 8C18.4515 7.66537 18.1704 7.39423 17.8236 7.39423C17.4767 7.39423 17.1956 7.66537 17.1956 8C17.1956 10.788 14.8531 13.048 11.9632 13.048H10.2887C10.0348 13.048 9.80572 13.1956 9.70852 13.4219C9.61132 13.6482 9.66507 13.9087 9.84465 14.0819L11.5191 15.6974C11.7643 15.9339 12.1619 15.9339 12.407 15.6974C12.6521 15.4609 12.6521 15.0773 12.407 14.8408L11.8045 14.2595H11.9629C15.5463 14.2595 18.4512 11.457 18.4512 8H18.4515Z"
                            fill="#486497"
                          />
                          <path
                            d="M11.5445 2.95202H11.9632C13.7406 2.95202 15.3117 3.80663 16.2583 5.11582C16.4567 5.39012 16.848 5.45748 17.1326 5.26605C17.4172 5.07463 17.4867 4.69711 17.2883 4.42258C16.1169 2.80227 14.1686 1.74023 11.9634 1.74023H11.5447C11.1979 1.74023 10.9168 2.01138 10.9168 2.346C10.9168 2.68063 11.1979 2.95177 11.5447 2.95177L11.5445 2.95202Z"
                            fill="#486497"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_2637_10817">
                            <rect
                              width="18"
                              height="15.75"
                              fill="white"
                              transform="translate(0.451447 0.125)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      {i18n.t("products.blogv2.regenerate")}
                    </div>
                  </div>
                )}
              </div>
            </span>

            {this.state.output && (
              <div className="button-copy-list">
                {btnCopy(
                  this,
                  "# " + this.state.h1_tmp + " " + this.state.output,
                  "copied",
                  "html"
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default BlogPremium;
