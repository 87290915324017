import React from "react";

export const TutoIcon = ({ size = 18, ...rest }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.7092 6.96515C10.7092 6.0226 9.94247 5.25586 8.99991 5.25586C8.05736 5.25586 7.29062 6.0226 7.29062 6.96515C7.29062 7.23493 7.50921 7.45352 7.77899 7.45352C8.04876 7.45352 8.26736 7.23493 8.26736 6.96515C8.26736 6.56117 8.59593 6.2326 8.99991 6.2326C9.40389 6.2326 9.73247 6.56117 9.73247 6.96515C9.73247 7.22692 9.59768 7.4629 9.37205 7.59632C8.66079 8.0171 8.51154 8.50332 8.51154 8.83717V9.65119C8.51154 9.92096 8.73014 10.1396 8.99991 10.1396C9.26969 10.1396 9.48828 9.92096 9.48828 9.65119V8.83717C9.48828 8.7309 9.63792 8.57384 9.86941 8.43691C10.3875 8.1304 10.7092 7.56643 10.7092 6.96515Z"
        fill="#486497"
      />
      <path
        d="M9.10459 12.2471C9.45954 12.1894 9.70057 11.855 9.64295 11.5C9.58534 11.1451 9.25089 10.9041 8.89595 10.9617C8.541 11.0193 8.29997 11.3537 8.35759 11.7087C8.4152 12.0636 8.74965 12.3047 9.10459 12.2471Z"
        fill="#486497"
      />
      <path
        d="M15.5116 8.51153C15.2419 8.51153 15.0233 8.73013 15.0233 8.9999C15.0233 11.9309 15.0233 13.4021 14.2126 14.2126C13.4021 15.0231 11.9309 15.0231 8.9999 15.0231C6.0689 15.0231 4.59774 15.0231 3.78724 14.2124C2.97674 13.4019 2.97674 11.9307 2.97674 8.99971C2.97674 6.06871 2.97674 4.59754 3.78724 3.78704C4.59774 2.97654 6.0689 2.97654 8.9999 2.97654C11.9309 2.97654 13.4021 2.97654 14.2126 3.78704C14.7068 4.28127 14.9168 4.99468 14.9879 6.41936C15.0014 6.68874 15.2309 6.89659 15.4999 6.88272C15.7693 6.86924 15.9768 6.6401 15.9635 6.37071C15.8937 4.97202 15.6869 3.87983 14.9033 3.09629C14.3692 2.56221 13.6705 2.26196 12.6377 2.12326C11.7186 2 10.5783 2 8.9999 2C7.42149 2 6.28125 2 5.36233 2.12366C4.32952 2.26255 3.63076 2.5626 3.09649 3.09668C2.56221 3.63076 2.26235 4.32952 2.12366 5.36233C2 6.28144 2 7.42169 2 8.9999C2 10.5781 2 11.7186 2.12366 12.6375C2.26255 13.6703 2.5626 14.369 3.09668 14.9031C3.63076 15.4372 4.32952 15.7375 5.36252 15.8762C6.28144 15.9998 7.42188 15.9998 9.0001 15.9998C10.5783 15.9998 11.7188 15.9998 12.6377 15.8762C13.6705 15.7373 14.3692 15.4372 14.9033 14.9031C15.4374 14.369 15.7376 13.6703 15.8763 12.6375C16 11.7186 16 10.5781 16 8.9999C16 8.73013 15.7814 8.51153 15.5116 8.51153Z"
        fill="#486497"
      />
    </svg>
  );
};
