import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Select from "react-dropdown-select";

import Projects from "../../sidebar/Projects";
import PromptsListPaginated from "../../components/users/PromptsPaginated";
import { ConfirmEraseUserAccount } from "../../components/modal/confirm/confirmEraseUserAccount.js";
// import { ConfirmEraseUserDeleteWithAccount } from "../../components/modal/confirm/confirmEraseUserDeleteWithAccount.js";
import {
  formatDate,
  nbTokenByPlan,
} from "../../components/product/component/sharedFunction.js";

import "../styles/admin.css";
import "../../assets/Common.css";
import credit2 from "../../header/assets/credits-icon.svg";

function AdminUser() {
  const { id } = useParams();
  const [data, setData] = useState("");
  const [selectedProject, setSelectedProject] = useState(null);
  const [currentProject, setCurrentProject] = useState(null);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [nbToken, setNbToken] = useState(0);
  const [transactions, setTransactions] = useState(0);
  const { i18n } = useTranslation();
  const roles_to_update = [
    { value: "affiliater", label: "Affiliateur" },
    { value: "client", label: "Client" },
  ];

  let navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      window.location.replace(process.env.REACT_APP_URL + `/users/sign_in`);
    }
    const config = {
      headers: { Authorization: `${token}` },
    };

    axios
      .get(process.env.REACT_APP_URL_API + `/specific_client/${id}`, config)
      .then((response) => {
        if (response["data"]) {
          const data = response.data;
          setData(data);
          setCurrentProject(data.project);
          setNbToken(data.user.token);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(process.env.REACT_APP_URL_API + `/api/v1/transactions/${id}`, config)
      .then((response) => {
        setTransactions(response["data"]["transactions"]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  function addCredit() {
    let token = localStorage.getItem("token");
    if (!token) {
      localStorage.removeItem("token");
      localStorage.removeItem("tokenExpiration");
      window.location = process.env.REACT_APP_URL + "/users/sign_in";
      return;
    }
    const config = {
      headers: { Authorization: `${token}` },
    };
    let data = {
      user: id,
    };
    axios
      .post(
        process.env.REACT_APP_URL_API + "/api/v1/admin/add_token",
        data,
        config,
        {
          validateStatus: function (status) {
            return status >= 200 && status < 500; // Catch errors from 200 to 499
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setNbToken(nbToken + 500);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setButtonDisabled(true);
        setTimeout(() => {
          setButtonDisabled(false);
        }, 30000);
      });
  }

  function updateRole(e) {
    let token = localStorage.getItem("token");
    if (!token) {
      localStorage.removeItem("token");
      localStorage.removeItem("tokenExpiration");
      window.location = process.env.REACT_APP_URL + "/users/sign_in";
      return;
    }
    const config = {
      headers: { Authorization: `${token}` },
    };
    let data = {
      user_id: id,
      role: e[0].value,
    };
    axios
      .post(
        process.env.REACT_APP_URL_API + "/api/v1/admin/update_role",
        data,
        config,
        {
          validateStatus: function (status) {
            return status >= 200 && status < 500; // Catch errors from 200 to 499
          },
        }
      )
      .then((res) => {
        window.location = window.location.href;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div>
      <div className="container flex flex-col gap-x-4 ">
        <h1 className="text-secondary pb-2.5 text-left">Administration</h1>
        <div className="flex flex-row justify-between pb-8">
          <button
            onClick={() => navigate(-1)}
            className="btn bg-white border border-gray-custom-input flex flex-row gap-x-3.5"
          >
            <svg
              width="18"
              height="19"
              viewBox="0 0 18 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.5 12.5L7.5 9.5L10.5 6.5"
                stroke="#486497"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span className="text-secondary">retour</span>
          </button>
          <div className="flex flex-row gap-x-2.5">
            {data?.user && data?.user?.role !== "admin" && (
              <Select
                name="update_role"
                id="update_role"
                disabled={data?.user?.role === "admin"}
                values={[
                  roles_to_update.find((opt) => opt.value === data?.user?.role),
                ]}
                options={roles_to_update}
                onChange={(e) => updateRole(e)}
              ></Select>
            )}

            <button
              disabled={isButtonDisabled}
              onClick={() => addCredit()}
              className="btn bg-white border border-gray-custom-input flex flex-row gap-x-2"
            >
              <img src={credit2} alt="credit" />
              <div className="flex flex-row whitespace-nowrap items-center">
                <div id="user-token" className="text-secondary text-md">
                  + 500 crédits
                </div>
              </div>
            </button>

            {data?.user &&
              (data?.user?.role === "admin" ||
                data?.user?.role === "affiliater") && (
                <Link
                  to={`/users/admin/affiliater/${data.user.id}`}
                  className="flex flex-row gap-x-1 bg-white text-white border border-gray-custom-input btn p-3.5 rounded-custom-input"
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 4.875C5.43268 4.875 3.1284 8.67461 3.08672 8.7438C3.03062 8.81667 3.00006 8.90696 3 9C3.00007 9.07982 3.0226 9.1579 3.06484 9.22478C3.06536 9.22559 3.06588 9.22639 3.06641 9.2272C3.07328 9.24183 5.00753 13.125 9 13.125C12.9755 13.125 14.9069 9.27998 14.9305 9.23284C14.9321 9.23017 14.9336 9.22748 14.9352 9.22478C14.9774 9.1579 14.9999 9.07982 15 9C15 8.90733 14.9697 8.81737 14.9141 8.7446L14.9133 8.7438C14.8716 8.67461 12.5673 4.875 9 4.875ZM9 6.1125C10.5464 6.1125 11.8 7.40528 11.8 9C11.8 10.5947 10.5464 11.8875 9 11.8875C7.4536 11.8875 6.2 10.5947 6.2 9C6.2 7.40528 7.4536 6.1125 9 6.1125ZM9 7.7625C8.68174 7.7625 8.37651 7.89288 8.15147 8.12496C7.92643 8.35703 7.8 8.67179 7.8 9C7.8 9.32821 7.92643 9.64297 8.15147 9.87504C8.37651 10.1071 8.68174 10.2375 9 10.2375C9.31826 10.2375 9.62348 10.1071 9.84853 9.87504C10.0736 9.64297 10.2 9.32821 10.2 9C10.2 8.67179 10.0736 8.35703 9.84853 8.12496C9.62348 7.89288 9.31826 7.7625 9 7.7625Z"
                      fill="#486497"
                    />
                  </svg>
                </Link>
              )}

            <ConfirmEraseUserAccount
              from="admin"
              accept={() => console.log("TODO")}
              acceptNotification={
                data.plan && data.plan.status === -2
                  ? i18n.t("account.accept_notification_reactivate")
                  : i18n.t("account.accept_notification_delete")
              }
              rejectNotification={
                data.plan && data.plan.status === -2
                  ? i18n.t("account.reject_notification_reactivate")
                  : i18n.t("account.reject_notification_delete")
              }
              label={
                data.plan && data.plan.status === -2
                  ? i18n.t("account.reactivate")
                  : i18n.t("account.delete")
              }
              rejectLabel={i18n.t("account.no_thanks")}
              message={
                data.plan && data.plan.status === -2
                  ? i18n.t("account.message_reactivate")
                  : i18n.t("account.message_delete")
              }
              header={
                data.plan && data.plan.status === -2
                  ? i18n.t("account.header_reactivate")
                  : i18n.t("account.header_delete")
              }
            ></ConfirmEraseUserAccount>
          </div>
        </div>
        <div className="flex flex-col gap-x-4 gap-y-8">
          <div className="bg-white rounded-card border border-gray-custom-input w-full">
            <div
              className="flex flex-row gap-y-2 w-full"
              style={{ padding: "1rem 1.5rem" }}
            >
              {data && (
                <div className="flex flex-col w-full gap-y-3">
                  <div className="flex justify-between w-full">
                    <div className="flex flex-row gap-x-2">
                      <div>
                        {data.avatar !== null && (
                          <img
                            id="avatar_navbar"
                            className="header-avatar border-gray-custom-input border"
                            src={process.env.REACT_APP_URL_API + data.avatar}
                            alt="avatar of the user"
                          ></img>
                        )}
                        {data.avatar === null && (
                          <div className="admin-user-image-badges">
                            <img
                              src="https://ucarecdn.com/b555ca32-eee0-48cf-a943-175bc1498367/-/scale_crop/60x60/smart/"
                              alt="avatar of the user"
                            />
                          </div>
                        )}
                      </div>
                      <div
                        className="flex flex-col text-left"
                        style={{ rowGap: "0.75rem" }}
                      >
                        <div className="">
                          {data.user.first_name}{" "}
                          <span className="user-lastname">
                            {data.user.last_name}
                          </span>{" "}
                          ({data.user.id})
                        </div>
                        <div className="">{data.user.email}</div>
                        {data.plan && data.plan.name && (
                          <div className="admin-user-badge-plan w-fit">
                            {data.plan.name}
                          </div>
                        )}
                        {data.plan === null && (
                          <div className="admin-user-badge-plan-false w-fit">
                            Pas de forfait
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="my-auto flex flex-col gap-y-1">
                      <div>
                        <span
                          id="token_admin"
                          className="font-semibold text-green"
                        >
                          {data &&
                            nbToken &&
                            nbToken.toLocaleString("fr-FR", {
                              minimumFractionDigits: 0,
                            })}
                        </span>
                        <span className="font-normal text-secondary">
                          {" "}
                          / {nbTokenByPlan(data.plan)} crédits restants
                        </span>
                      </div>
                      <div>
                        <span className="font-semibold text-secondary">
                          {data && data.plan === null && "N/C"}

                          {data &&
                            data.plan !== null &&
                            data.user.token &&
                            (
                              parseInt(
                                nbTokenByPlan(data.plan).replace(/\s/g, ""),
                                10
                              ) - data.user.token
                            ).toLocaleString("fr-FR", {
                              minimumFractionDigits: 0,
                            })}
                        </span>
                        <span className="font-normal text-secondary">
                          {" "}
                          crédits consommés
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row space-between gap-x-8 text-left text-md text-secondary">
                    <div className="flex flex-col w-full gap-y-3">
                      <div className="font-semibold text-lg">Informations</div>
                      <hr className="border-my-account" />
                      <div className="flex flex-col gap-y-3">
                        <div className="flex flex-row">
                          <div className="">date d'inscription : </div>
                          <div className="font-normal">
                            {" "}
                            {formatDate(data.user.created_at, "with_slash")}
                          </div>
                        </div>
                        <div className="flex flex-row">
                          <div className="">ancienneté : </div>
                          <div className="font-normal">
                            {" "}
                            {formatDate(
                              data.user.created_at,
                              "number_of_month"
                            )}{" "}
                            mois{" "}
                          </div>
                        </div>
                        <div className="flex flex-row">
                          <div className="">parain : </div>
                          <div className="font-normal">
                            {" "}
                            {data &&
                            data.referrer !== null &&
                            data.referrer !== undefined ? (
                              <Link to={`/users/admin/${data.referrer.id}`}>
                                {data.referrer.first_name +
                                  " " +
                                  data.referrer.last_name}
                              </Link>
                            ) : (
                              "/"
                            )}
                          </div>
                        </div>
                        <div className="flex flex-row">
                          <div className="">téléphone : </div>
                          <div className="font-normal">
                            {" "}
                            {data.user.phone === null ? "N/C" : data.user.phone}
                          </div>
                        </div>
                        <div className="flex flex-row">
                          <div className="">entreprise : </div>
                          <div className="font-normal">
                            {" "}
                            {data.user.company_name === null
                              ? "N/C"
                              : data.user.company_name}
                          </div>
                        </div>
                        <div className="flex flex-row">
                          <div className="">SIRET : </div>
                          <div className="font-normal">
                            {" "}
                            {data.user.siret === null ? "N/C" : data.user.siret}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col w-full gap-y-3">
                      <div className="font-semibold text-md">Utilisation</div>
                      <hr className="border-my-account" />
                      <div className="flex flex-col gap-y-3">
                        <div className="flex flex-row">
                          <div className="">crédits consommés : </div>
                          <div className="font-normal">
                            {" "}
                            {data.screebot_token_used}
                          </div>
                        </div>
                        <div className="flex flex-row">
                          <div className="">tokens OpenAI consommés : </div>
                          <div className="font-normal">
                            {" "}
                            {data.openai_token_used}
                          </div>
                        </div>
                        <div className="flex flex-row">
                          <div className="">documents générés : </div>
                          <div className="font-normal">{" "}TODO</div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col w-full gap-y-3">
                      <div className="font-semibold text-md">
                        Chiffre d'affaire
                      </div>
                      <hr className="border-my-account" />
                      <div className="flex flex-col gap-y-3">
                        <div className="flex flex-row">
                          <div className="">CAHT : </div>
                          <div className="font-normal">
                            &nbsp;
                            {data.user.money.toLocaleString("fr-FR", {
                              minimumFractionDigits: 0,
                            })}{" "}
                            €
                          </div>
                        </div>
                        <div className="flex flex-row">
                          <div className="">coût total : </div>
                          <div className="font-normal">{" "}TODO</div>
                        </div>
                        <div className="flex flex-row">
                          <div className="">marge brute : </div>
                          <div className="font-normal">{" "}TODO</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            className="bg-white rounded-card border border-gray-custom-input w-full"
            style={{ padding: "1rem 1.5rem" }}
          >
            <h4 className="text-primary text-left">Nom du dossier</h4>
            <div className="history-admin-user" style={{ width: "16rem" }}>
              {data && (
                <Projects
                  projects={currentProject}
                  selectedProject={selectedProject}
                  setSelectedProject={setSelectedProject}
                  setCurrentProject={setCurrentProject}
                  classes="admin"
                ></Projects>
              )}
            </div>
            {data && (
              <PromptsListPaginated
                itemsPerPage={10}
                selectedProject={selectedProject}
                user_id={id}
                classes="admin"
              ></PromptsListPaginated>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminUser;
