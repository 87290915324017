import React, { Component } from 'react';
import { ConfirmDialog } from 'primereact/confirmdialog';
// import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

import './modalConfirmGlobal.css'
import '../../../assets/Common.css'
import '../../../components/users/styles/accountStyle.css'

export class ConfirmEraseUserDeleteWithAccount extends Component {

    constructor(props) {
        super(props);

        this.state = {
            visible: this.props.visible
        };
        this.label = this.props.label
        this.message = this.props.message
        this.header = this.props.header
        this.rejectLabel = this.props.rejectLabel
        this.acceptNotification = this.props.acceptNotification
        this.rejectNotification = this.props.rejectNotification

        this.onConfirmErase = this.props.accept
        this.accept = this.accept.bind(this);
        this.reject = this.reject.bind(this);
    }

    accept() {
        this.onConfirmErase()
        this.toast.show({ severity: 'info', summary: 'Validation', detail: this.acceptNotification, life: 3000 });
        // console.log('TODO call api with params cancel_subscription')
    }

    reject() {
        this.toast.show({ severity: 'warn', summary: 'Annulation', detail: this.rejectNotification, life: 3000 });
    }

    render() {
        return (
            <div>
                <Toast ref={(el) => this.toast = el} />

                <ConfirmDialog visible={this.state.visible} onHide={() => this.setState({ visible: false })}
                    message={this.message} header={this.header}
                    icon="pi pi-exclamation-triangle"
                    accept={this.accept} reject={this.reject}
                    acceptLabel={this.label}
                    rejectLabel={this.rejectLabel}
                    acceptClassName="history-btn bg-error">
                </ConfirmDialog>
            </div>
        )
    }
}
