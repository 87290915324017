import React, { Component } from "react";
import { Link } from "react-router-dom";
import i18n from "i18next";
import { withTranslation } from "react-i18next";
import axios from "axios";

import "./styles/usersStyle.css";
import "../../assets/Common.css";

import logo2 from "../../assets/logo.svg";
import screebotShow from "./assets/screebot_show.svg";
import screebotHide from "./assets/screebot_hide.svg";

class CreatePassword extends Component {
  constructor(props) {
    super(props);
    const queryParameters = new URLSearchParams(window.location.search);
    const options =
      queryParameters.get("options") || localStorage.getItem("options");
    const affiliater =
      queryParameters.get("affiliater") || localStorage.getItem("affiliater");
    const lng =
      queryParameters.get("lng") || localStorage.getItem("i18nextLng") || "fr";
    this.state = {
      first_name: "",
      last_name: "",
      password: "",
      password_confirmation: "",
      buttonDisabled: true,
      error: "",
      email: "",
      showPassword: false,
      showPassword2: false,
      affiliater: affiliater || "",
      lng: lng,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    if (options) {
      localStorage.setItem("options", options);
    }

    if (affiliater) {
      localStorage.setItem("affiliater", affiliater);
    }
  }

  showPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };
  showPassword2 = () => {
    this.setState({ showPassword2: !this.state.showPassword2 });
  };

  validate = () => {
    if (
      this.state.password !== undefined &&
      this.state.password_confirmation !== undefined
    ) {
      this.setState({
        buttonDisabled: !(
          this.state.password === this.state.password_confirmation
        ),
      });
    } else {
      this.setState({ buttonDisabled: true });
    }
  };

  handleChange = (e) => {
    let newValue = e.target.value;
    let key = e.target.name;
    this.setState(
      {
        [key]: newValue,
      },
      () => {
        this.validate();
      }
    );
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.validate();
    const queryParameters = new URLSearchParams(window.location.search);
    let data = {
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      password: this.state.password,
      password_confirmation: this.state.password_confirmation,
      invitation_token: queryParameters.get("invitation_token"),
      affiliater: this.state.affiliater,
      language: this.state.lng,
    };
    axios
      .put(process.env.REACT_APP_URL_API + "/invitation", data, {
        validateStatus: function (status) {
          return status >= 200 && status < 500;
        },
      })
      .then((response) => {
        if (response.status === 401) {
          // console.log("TODO");
          // this.setState( {error: i18n.t("sign.password.wrong_password_email") } )
        }
        if (response.status === 202) {
          window.location = process.env.REACT_APP_URL + "/users/sign_in";
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  render() {
    return (
      <div className="card">
        <div className="global-layout">
          <div className="right-part">
            <div className="right-layout flex flex-col gap-y-1">
            <div className="flex flex-col gap-y-8 pb-6">
              <div className="mx-auto">
                  <img
                    src={logo2}
                    alt="Screebot logo"
                    className="right-layout-logo"
                  />
                </div>
                <h1
                  className="text-secondary text-center whitespace-nowrap"
                >
                  {i18n.t("account.form.create_account")}
                </h1>
              </div>
              <div className="">{this.state.error}</div>
              <form onSubmit={this.handleSubmit.bind(this)}>
                <div className="flex flex-col gap-y-6">
                  <div className="flex flex-col gap-y-4">
                    <div className="account-field">
                      <div className="label text-primary">
                        {i18n.t("account.form.firstname")}
                      </div>
                      <input
                        autoComplete="firstName"
                        type="text"
                        name="first_name"
                        value={this.state.first_name}
                        id="first_name"
                        placeholder={i18n.t("account.form.firstname_placeholder")}
                        onChange={this.handleChange}
                      ></input>
                    </div>
                    <div className="account-field">
                      <div className="label text-primary">
                        {i18n.t("account.form.lastname")}
                      </div>
                      <input
                        autoComplete="lastName"
                        type="text"
                        name="last_name"
                        value={this.state.last_name}
                        id="last_name"
                        placeholder={i18n.t("account.form.lastname_placeholder")}
                        onChange={this.handleChange}
                      ></input>
                    </div>
                    <div className="account-field">
                      <div className="label text-primary">
                        {i18n.t("account.form.create_password")}
                      </div>
                      <div
                        className="relative w-full flex flex-row"
                      >
                        <input
                          type={this.state.showPassword ? "text" : "password"}
                          autoComplete="new-password"
                          name="password"
                          className="field pwd-container w-full"
                          placeholder={i18n.t("sign.up.password_placeholder")}
                          onChange={this.handleChange}
                          value={this.state.password}
                        />
                        <span className="show-password">
                          {this.state.showPassword ? (
                            <img
                              src={screebotShow}
                              alt="show password"
                              onClick={() => this.showPassword()}
                            />
                          ) : (
                            <img
                              src={screebotHide}
                              alt="hide password"
                              onClick={() => this.showPassword()}
                            />
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="account-field">
                      <div className="label text-primary">
                        {i18n.t("account.form.create_password_confirmation")}
                      </div>
                      <div
                        className="relative w-full flex flex-row"
                      >
                        <input
                          type={this.state.showPassword2 ? "text" : "password"}
                          autoComplete="new-password"
                          name="password_confirmation"
                          className="field pwd-container w-full"
                          placeholder={i18n.t("sign.up.password_2_placeholder")}
                          onChange={this.handleChange}
                          value={this.state.password_confirmation}
                        />
                        <span className="show-password">
                          {this.state.showPassword2 ? (
                            <img
                              src={screebotShow}
                              alt="show password"
                              onClick={() => this.showPassword2()}
                            />
                          ) : (
                            <img
                              src={screebotHide}
                              alt="hide password"
                              onClick={() => this.showPassword2()}
                            />
                          )}
                        </span>
                      </div>
                    </div>

                  </div>
                  <div className="actions mx-auto">
                      <button
                        disabled={this.state.buttonDisabled}
                        type="submit"
                        className="secondary-btn"
                      >
                        <svg
                          width="25"
                          height="21"
                          viewBox="0 0 25 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_1690_8701)">
                            <g opacity="0.5">
                              <path
                                d="M14.8255 20.5003H10.1741C7.91299 20.5003 6.27904 20.5003 4.95451 20.3251C3.43847 20.1249 2.40801 19.6885 1.61755 18.9102C0.827089 18.1319 0.381626 17.1204 0.177906 15.6304C8.31689e-08 14.3275 0 12.7229 0 10.5002C0 8.27742 8.31689e-08 6.67146 0.177906 5.36997C0.381626 3.8799 0.825694 2.8671 1.61755 2.09018C2.40941 1.31326 3.43847 0.875431 4.95451 0.675201C6.28009 0.5 7.91299 0.5 10.1741 0.5H14.8255C17.0867 0.5 18.7206 0.5 20.0451 0.675201C21.5612 0.875431 22.5916 1.31189 23.3821 2.09018C24.1726 2.86847 24.618 3.8799 24.8217 5.36997C24.9997 6.67283 24.9997 8.27742 24.9997 10.5002C24.9997 12.7229 24.9997 14.3289 24.8217 15.6304C24.618 17.1204 24.174 18.1332 23.3821 18.9102C22.5902 19.6871 21.5612 20.1249 20.0451 20.3251C18.7206 20.5003 17.0867 20.5003 14.8255 20.5003ZM10.1741 2.2143C6.0352 2.2143 3.95789 2.2143 2.85103 3.30219C1.74418 4.39008 1.74418 6.43215 1.74418 10.5002C1.74418 14.5682 1.74418 16.6103 2.85103 17.6978C3.95789 18.7854 6.0352 18.786 10.1741 18.786H14.8255C18.9644 18.786 21.0421 18.786 22.1486 17.6982C23.2551 16.6103 23.2555 14.5682 23.2555 10.5002C23.2555 6.43215 23.2555 4.39008 22.1486 3.30219C21.0418 2.2143 18.9644 2.2143 14.8255 2.2143H10.1741Z"
                                fill="white"
                              />
                            </g>
                            <path
                              d="M12.4997 13.6434C12.2684 13.6434 12.0466 13.5531 11.8831 13.3923C11.7195 13.2316 11.6276 13.0136 11.6276 12.7863V11.9846L10.9209 12.3861C10.7207 12.498 10.4836 12.5275 10.2614 12.4682C10.0391 12.4089 9.84977 12.2656 9.73462 12.0696C9.61947 11.8736 9.58788 11.6407 9.64675 11.4219C9.70561 11.2031 9.85014 11.016 10.0488 10.9016L10.7556 10.5004L10.0499 10.1C9.85119 9.98548 9.70666 9.79842 9.64779 9.57959C9.58893 9.36077 9.62052 9.12794 9.73567 8.93192C9.85081 8.7359 10.0402 8.59261 10.2624 8.5333C10.4847 8.474 10.7218 8.5035 10.9219 8.61537L11.6276 9.01617V8.21457C11.6276 7.98724 11.7195 7.76922 11.8831 7.60848C12.0466 7.44773 12.2684 7.35742 12.4997 7.35742C12.731 7.35742 12.9528 7.44773 13.1164 7.60848C13.2799 7.76922 13.3718 7.98724 13.3718 8.21457V9.01549L14.0768 8.61469C14.277 8.50281 14.5141 8.47331 14.7363 8.53262C14.9586 8.59192 15.1479 8.73522 15.2631 8.93124C15.3782 9.12725 15.4098 9.36008 15.351 9.57891C15.2921 9.79773 15.1476 9.98479 14.9489 10.0993L14.2439 10.5004L14.95 10.9009C15.1486 11.0153 15.2932 11.2024 15.352 11.4212C15.4109 11.6401 15.3793 11.8729 15.2641 12.0689C15.149 12.2649 14.9596 12.4082 14.7374 12.4675C14.5151 12.5268 14.2781 12.4973 14.0779 12.3855L13.3718 11.9853V12.7863C13.3718 13.0136 13.2799 13.2316 13.1164 13.3923C12.9528 13.5531 12.731 13.6434 12.4997 13.6434Z"
                              fill="white"
                            />
                            <path
                              d="M6.37453 13.6434C6.14323 13.6434 5.92142 13.5531 5.75787 13.3923C5.59432 13.2316 5.50244 13.0136 5.50244 12.7863V11.9846L4.79605 12.3861C4.59585 12.498 4.35876 12.5275 4.13652 12.4682C3.91427 12.4089 3.72491 12.2656 3.60977 12.0696C3.49462 11.8736 3.46303 11.6407 3.52189 11.4219C3.58075 11.2031 3.72529 11.016 3.92396 10.9016L4.63 10.5004L3.92431 10.1C3.72564 9.98548 3.5811 9.79842 3.52224 9.57959C3.46338 9.36077 3.49497 9.12794 3.61011 8.93192C3.72526 8.7359 3.91462 8.59261 4.13686 8.5333C4.35911 8.474 4.5962 8.5035 4.79639 8.61537L5.50244 9.01617V8.21457C5.50244 7.98724 5.59432 7.76922 5.75787 7.60848C5.92142 7.44773 6.14323 7.35742 6.37453 7.35742C6.60582 7.35742 6.82764 7.44773 6.99119 7.60848C7.15474 7.76922 7.24662 7.98724 7.24662 8.21457V9.01549L7.95196 8.61469C8.05118 8.55752 8.16092 8.5202 8.27487 8.50489C8.38882 8.48957 8.50473 8.49656 8.61592 8.52546C8.72711 8.55435 8.83139 8.60458 8.92275 8.67324C9.01411 8.74191 9.09076 8.82765 9.14826 8.92555C9.20577 9.02345 9.24301 9.13155 9.25783 9.24365C9.27265 9.35575 9.26477 9.46962 9.23464 9.57871C9.2045 9.68781 9.15271 9.78996 9.08225 9.8793C9.01178 9.96864 8.92403 10.0434 8.82405 10.0993L8.11836 10.5004L8.8244 10.9009C8.92438 10.9567 9.01213 11.0315 9.0826 11.1208C9.15306 11.2102 9.20485 11.3123 9.23499 11.4214C9.26512 11.5305 9.273 11.6444 9.25818 11.7565C9.24335 11.8686 9.20612 11.9767 9.14861 12.0746C9.0911 12.1725 9.01446 12.2582 8.9231 12.3269C8.83174 12.3956 8.72746 12.4458 8.61627 12.4747C8.50508 12.5036 8.38917 12.5106 8.27522 12.4953C8.16127 12.4799 8.05153 12.4426 7.95231 12.3855L7.24662 11.9853V12.7863C7.24662 13.0136 7.15474 13.2316 6.99119 13.3923C6.82764 13.5531 6.60582 13.6434 6.37453 13.6434Z"
                              fill="white"
                            />
                            <path
                              d="M18.6253 13.6434C18.394 13.6434 18.1722 13.5531 18.0086 13.3923C17.8451 13.2316 17.7532 13.0136 17.7532 12.7863V11.9846L17.0468 12.3861C16.8466 12.498 16.6095 12.5275 16.3873 12.4682C16.165 12.4089 15.9757 12.2656 15.8605 12.0696C15.7454 11.8736 15.7138 11.6407 15.7726 11.4219C15.8315 11.2031 15.976 11.016 16.1747 10.9016L16.8804 10.5004L16.1747 10.1C15.976 9.98548 15.8315 9.79842 15.7726 9.57959C15.7138 9.36077 15.7454 9.12794 15.8605 8.93192C15.9757 8.7359 16.165 8.59261 16.3873 8.5333C16.6095 8.474 16.8466 8.5035 17.0468 8.61537L17.7532 9.01617V8.21457C17.7532 7.98724 17.8451 7.76922 18.0086 7.60848C18.1722 7.44773 18.394 7.35742 18.6253 7.35742C18.8566 7.35742 19.0784 7.44773 19.2419 7.60848C19.4055 7.76922 19.4974 7.98724 19.4974 8.21457V9.01549L20.2027 8.61469C20.4029 8.50281 20.64 8.47331 20.8622 8.53262C21.0845 8.59192 21.2739 8.73522 21.389 8.93124C21.5041 9.12725 21.5357 9.36008 21.4769 9.57891C21.418 9.79773 21.2735 9.98479 21.0748 10.0993L20.3688 10.5004L21.0748 10.9009C21.2735 11.0153 21.418 11.2024 21.4769 11.4212C21.5357 11.6401 21.5041 11.8729 21.389 12.0689C21.2739 12.2649 21.0845 12.4082 20.8622 12.4675C20.64 12.5268 20.4029 12.4973 20.2027 12.3855L19.4974 11.9853V12.7863C19.4974 13.0136 19.4055 13.2316 19.2419 13.3923C19.0784 13.5531 18.8566 13.6434 18.6253 13.6434Z"
                              fill="white"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_1690_8701">
                              <rect
                                width="25"
                                height="20"
                                fill="white"
                                transform="translate(0 0.5)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                        {i18n.t("account.form.save")}
                      </button>
                    </div>
                    <div className="mx-auto">
                      <Link
                        to="/users/sign_in"
                        className="cursor-pointer font-normal text-secondary text-base"
                      >
                        {i18n.t("sign.password.back_connexion")}
                      </Link>
                    </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(CreatePassword);
