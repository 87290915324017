import i18n from "i18next";
import axios from "axios";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { NotifyYourDocument } from "./Toast";
import Deconnexion from "../../users/Deconnexion";

import screebotThinking from "../assets/zap.svg";
import screebotNormal from "../assets/zap.svg";

// import iconeTooltip from "../assets/tooltip-icon.svg";
// import iconeEstim from "../assets/estim-icon.svg";

import rewrite from "../../../assets/product/rewrite.svg";
import product_sheet from "../../../assets/product/sheet.svg";
import faq from "../../../assets/product/faq.svg";
import sentences from "../../../assets/product/sentences.svg";
import meta from "../../../assets/product/meta.svg";
import blog from "../../../assets/product/blog.svg";
import social from "../../../assets/product/social.svg";
import web from "../../../assets/product/web.svg";
import definition from "../../../assets/product/definition.svg";
import excel from "../../../assets/product/excel.svg";
import press from "../../../assets/product/press.svg";

// import copyTextSvgFalse from "../../../assets/button/copy_false.svg";
// import copyHtmlSvgFalse from "../../../assets/button/copy_html_false.svg";
// import copyWordSvgFalse from "../../../assets/button/copy_word_false.svg";
import copyTextSvgTrue from "../../../assets/button/copy_true.svg";
import copyTextSvgTrueWhite from "../../../assets/button/copy_true_white.svg";
import copyHtmlSvgTrue from "../../../assets/button/copy_html_true.svg";
import copyHtmlSvgTrueWhite from "../../../assets/button/copy_html_true_white.svg";

import "../../../../src/assets/Common.css";

const svgMapping = {
  // copy_text_false: copyTextSvgFalse,
  // copy_html_false: copyHtmlSvgFalse,
  // copy_word_false: copyWordSvgFalse,
  copy_text_true: copyTextSvgTrue,
  copy_text_true_white: copyTextSvgTrueWhite,
  copy_html_true: copyHtmlSvgTrue,
  copy_html_true_white: copyHtmlSvgTrueWhite,
};

const btnCopy = (context, text, name, svg) => {
  let classList = "btn-copy btn";
  let svgSelect;
  if (context.state[name] === true) {
    classList += " copied";
    svgSelect = `copy_${svg}_true_white`;
  } else {
    classList += " copy";
    svgSelect = svg = `copy_${svg}_true`;
  }

  if (context.state.product === "excel") {
    const regex = /<pre\b[^>]*>(.*?)<\/pre>/s;
    const matches = text.match(regex);
    if (matches) {
      const preContent = matches[1];
      text = preContent;
    }
  }

  const timeoutCopied = () => {
    context.setState({ [`${name}`]: !context.state[name] });
    setTimeout(() => {
      context.setState({ [`${name}`]: !context.state[name] });
    }, 800);
  };

  const selectedSvg = svgMapping[svgSelect] || copyTextSvgTrue;
  return (
    <CopyToClipboard text={text} onCopy={() => timeoutCopied()}>
      <button className={`${classList}`}>
        <img src={selectedSvg} alt="Copy Icon" />
      </button>
    </CopyToClipboard>
  );
};

function btnCopyHistory(
  context,
  text,
  copied,
  setCopied,
  textCopied,
  setTextCopied,
  svg
) {
  let tmpSvg;
  if (svg === "excel") {
    tmpSvg = "text";
  } else {
    tmpSvg = svg;
  }
  let classList = "copy-btn bg-white flex flex-row gap-x-3.5 btn padding-custom-button rounded-custom-input border border-gray-custom-input";
  let svgSelect;
  if (copied === true) {
    classList += " copied";
    svgSelect = `copy_${tmpSvg}_true`;
  } else {
    classList += " copy";
    // svgSelect = `copy_${tmpSvg}_false`;
    svgSelect = `copy_${tmpSvg}_true`;
  }

  const selectedSvg = svgMapping[svgSelect] || copyTextSvgTrue;

  const onCopy = (setCopied) => {
    setCopied(true);
    setTextCopied(i18n.t(`products.shared.copied`));
    setTimeout(() => {
      setTextCopied(i18n.t(`products.shared.copy_${svg}`));
      setCopied(false);
    }, 800);
  };

  return (
    <CopyToClipboard text={text} onCopy={() => onCopy(setCopied)}>
      <button className={`${classList}`}>
        <img src={selectedSvg} alt="Copy Icon" />
        <span className="text-secondary">{textCopied}</span>
      </button>
    </CopyToClipboard>
  );
}

const headerProduct = (iconeHeader, product) => {
  return (
    <div className="flex flex-col gap-y-2.5">
      <div className="flex flex-row gap-x-2.5">
        <img src={iconeHeader} alt="icon product" className="pb-2.5"></img>
        <h1 className="flex flex-row text-secondary">
          {i18n.t(`products.${product}.title`)}
        </h1>
      </div>
      <p className="text-primary text-left">
        {i18n.t(`products.${product}.description`)}
      </p>
      {
        product === "blog" && (
          <p className="text-primary text-left">
            {i18n.t(`products.${product}.description_tmp`)}
          </p>
        )
      }
    </div>
  );
};

const checkIfKeyExists = (context, keyToCheck) => {
  if (context.state.fieldbacks) {
    return context.state.fieldbacks.hasOwnProperty(keyToCheck);
  }
  return false;
};

const renderButtonHtml = (context, title, validateFunction) => {
  if (checkIfKeyExists(context, title)) {
    return (
      <button
        tabIndex={-1}
        type="button"
        className="fieldbackBtn"
        onClick={(e) => {
          e.stopPropagation();
          const btn = e.target.closest("button");
          if (!btn.disabled) {
            addFielbackInInput(context, title, validateFunction);
          }
        }}
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.588438 0.766602H10.454C10.7739 0.766602 11.0672 1.03324 11.0672 1.3532V2.60639C11.0672 2.92635 10.8006 3.21965 10.4806 3.21965H0.615101C0.295138 3.21965 0.00183852 2.95302 0.00183852 2.63305V1.37986C-0.0248251 1.0599 0.241811 0.766602 0.588438 0.766602ZM14.1602 0.766602H15.3867C15.7067 0.766602 16 1.03324 16 1.3532V2.60639C16 2.92635 15.7334 3.21965 15.4134 3.21965H14.1602C13.8402 3.21965 13.5469 2.95302 13.5469 2.63305V1.37986C13.5203 1.0599 13.7603 0.793265 14.0802 0.766602H14.1602ZM14.8268 8.47238C13.0137 11.592 8.72084 13.0052 5.86783 11.6187L5.09459 11.2721C4.82795 11.1387 4.56131 11.432 4.64131 11.6987L5.38789 14.2051C5.46788 14.4717 5.3079 14.7383 5.06792 14.845L4.16136 15.1916C3.94805 15.2983 3.70808 15.1916 3.60142 14.9783C3.60142 14.9516 3.57476 14.925 3.57476 14.8983L1.76164 8.84567C1.68165 8.57904 1.81496 8.28574 2.0816 8.20575L7.94759 5.91268C8.1609 5.80602 8.40087 5.91268 8.50753 6.12599C8.50753 6.15265 8.53419 6.17931 8.53419 6.20598L8.82749 7.1392C8.90748 7.40584 8.77416 7.69914 8.50753 7.77913L6.1078 8.73902C5.92116 8.79235 5.84117 8.97899 5.89449 9.16564C5.92116 9.24563 5.97449 9.32562 6.05448 9.35228L6.50776 9.56559C8.72084 10.6588 12.1071 9.53893 13.4403 7.32585C13.5736 7.11254 14.0536 7.05921 14.2935 7.16587L14.7468 7.59248C14.9868 7.69914 14.9601 8.23241 14.8268 8.47238Z"
            fill="#486497"
          />
        </svg>
      </button>
    );
  }
  return null;
};

const addFielbackInInput = (context, title, validateFunction) => {
  let data;
  if (title === "date_press") {
    const date = new Date(context.state.fieldbacks[title].input);
    data = date;
  } else {
    data = context.state.fieldbacks[title].input;
  }
  context.setState(
    {
      [`${title}`]: data,
    },
    () => {
      validateFunction();
    }
  );
};

const initFieldbacks = (context, response) => {
  let tmp = {};

  response.forEach((element) => {
    const newFieldback = {
      title: element["title"],
      input: element["input"],
    };
    tmp[newFieldback["title"]] = newFieldback;
  });

  context.setState(
    {
      fieldbacks: tmp,
    },
    () => {
      context.setState({});
    }
  );
};

const counter = (id, start, end, duration) => {
  let obj = document.getElementById(id),
    current = start,
    range = end - start,
    increment = end > start ? 1 : -1,
    step = Math.abs(Math.floor(duration / range)),
    timer = setInterval(() => {
      current += increment;
      obj.textContent = current.toLocaleString("fr-FR", {
        minimumFractionDigits: 0,
      });
      if (current === end) {
        clearInterval(timer);
      }
    }, step);
};

const reactiveInput = () => {
  const visibleInputsSelectsAndTextareas = document.querySelectorAll(
    'input:not([style*="display: none;"]):not([name="title"]), select:not([style*="display: none;"]), textarea:not([style*="display: none;"]), button.fieldbackBtn, [aria-label="Dropdown select"]'
  );

  visibleInputsSelectsAndTextareas.forEach((element) => {
    element.disabled = false;
  });
};

const deactiveInput = () => {
  const visibleInputsSelectsAndTextareas = document.querySelectorAll(
    'input:not([style*="display: none;"]):not([name="title"]), select:not([style*="display: none;"]), textarea:not([style*="display: none;"]), button.fieldbackBtn, [aria-label="Dropdown select"]'
  );

  visibleInputsSelectsAndTextareas.forEach((element) => {
    element.disabled = true;
  });
};

const refreshPage = (e) => {
  if (!window.location.hash) {
    window.location.replace(window.location.href + "?fieldbacks=true");
  }
};

const checkPromptStatus = (context, id, config, checkBy = "output") => {
  axios
    .get(process.env.REACT_APP_URL_API + "/api/v1/prompts/" + id, config, {
      validateStatus: function (status) {
        return status >= 200 && status < 500; // Catch errors from 200 to 499
      },
    })
    .then((response) => {
      let check = false;
      switch (checkBy) {
        case "nb_step":
          check = response["data"].nb_step === 0;
          break;
        case "output":
          check = response["data"].output;
          break;
        default:
          check = false;
          break;
      }
      if (check) {
        let output = null;
        let asyncronous = checkBy === "nb_step" ? true : false;
        switch (checkBy) {
          case "nb_step":
            axios.get(process.env.REACT_APP_URL_API + '/api/v1/prompts/' + id + '/steps', config)
              .then(response => {
                let newPromptSteps = response.data.output.map(step => step).join(' ');
                output = newPromptSteps;
                context.setState({ output: output });
                context.setState({
                  returnApiSpan: document.querySelector("output-screebot"),
                });
                context.setState({ colorBackgroundScreebot: "#486497" });
                context.setState({ id: response["data"].id });
                context.setState({ step: "end" });
                context.setState({ buttonDisabled: true });
                context.setState({ imgScreebot: screebotNormal });
                context.setState({ txtScreebot: i18n.t("products.shared.regenerate") });
                context.setState({ finished: true });
                context.setState({ h1_tmp: response.data.structure.match(/#\s*(.*?)\s*#/)[1].trim() });
              })
              .catch(error => {
                console.log(error);
              });
            break;
          case "output":
            output = response["data"].output;
            break;
          default:
            break;
        }
        if ((checkBy === "nb_step" && response["data"].nb_step === 0) || (checkBy === "output" && response["data"].output && context.state.product !== 'blogv2')) {
          NotifyYourDocument(context.state.title, response["data"].id);
        }
        if (!asyncronous) {
          context.setState({ output: output });
          context.setState({
            returnApiSpan: document.querySelector("output-screebot"),
          });

          context.setState({ colorBackgroundScreebot: "#486497" });
          context.setState({ id: response["data"].id });
          context.setState({ txtScreebot: i18n.t("products.shared.regenerate") });
          context.setState({ buttonDisabled: false });
          context.setState({ imgScreebot: screebotNormal });
          context.setState({ finished: true });
          context.setState({ currentPromptSteps: output });
        }

        reactiveInput();
        let start = parseInt(
          document.getElementById("user-token").innerHTML.replace(/\s+/g, "")
        );
        counter(
          "user-token",
          start,
          start - response["data"].screebot_token,
          2000
        );
      } else {
        setTimeout(() => checkPromptStatus(context, id, config, checkBy), checkBy === "nb_step" ? 10000 : 5000);
      }
    })
    .catch((error) => {
      console.log(error);
      Deconnexion();
    });
};

const handleCommonSubmit = (event, state, context, id, user_token) => {
  event.preventDefault();
  if (user_token <= 0) {
    localStorage.removeItem("noCreditClient");
    localStorage.removeItem("noCreditProspect");
    window.location.replace(process.env.REACT_APP_URL + `/users/account?plan`);
    return;
  }
  context.setState({
    output: "",
    buttonDisabled: true,
    txtScreebot: i18n.t("products.shared.thinking"),
    colorBackgroundScreebot: "#486497",
    imgScreebot: screebotThinking,
    loading: i18n.t("products.shared.loading"),
    finished: false,
  });

  deactiveInput();

  const tmpData = { ...state };
  delete tmpData.buttonDisabled;
  delete tmpData.click;
  delete tmpData.returnApiSpan;
  delete tmpData.txtScreebot;
  delete tmpData.copied;
  delete tmpData.imgScreebot;
  delete tmpData.colorBackgroundScreebot;
  delete tmpData.finished;
  if (id) {
    delete tmpData.id;
  }

  const data = { prompt: tmpData };
  const token = localStorage.getItem("token");

  if (!token) {
    window.location.replace(process.env.REACT_APP_URL + `/users/sign_in`);
  }

  const config = {
    headers: { Authorization: `${token}` },
  };

  context.setState({ click: true });

  if (id) {
    axios
      .put(
        process.env.REACT_APP_URL_API + "/api/v1/prompts/" + id,
        data,
        config,
        {
          validateStatus: function (status) {
            return status >= 200 && status < 500; // Catch errors from 200 to 499
          },
        }
      )
      .then((response) => {
        checkPromptStatus(context, response["data"].id, config);
      })
      .catch((error) => {
        console.error(error);
        context.setState({ output: i18n.t("products.shared.error") });
      });
  } else {
    axios
      .post(process.env.REACT_APP_URL_API + "/api/v1/prompts", data, config, {
        validateStatus: function (status) {
          return status >= 200 && status < 500; // Catch errors from 200 to 499
        },
      })
      .then((response) => {
        checkPromptStatus(context, response["data"].id, config);
      })
      .catch((error) => {
        console.error(error);
        context.setState({ output: i18n.t("products.shared.error") });
      });
  }
};

const generateV2 = (e, state, context, id, user_token) => {
  e.preventDefault();
  if (user_token <= 0) {
    window.location.replace(process.env.REACT_APP_URL + `/users/account?plan`);
    return;
  }

  if (context !== undefined) {
    context.setState({
      output: "",
      buttonDisabled: true,
      txtScreebot: i18n.t("products.shared.thinking"),
      colorBackgroundScreebot: "#486497",
      imgScreebot: screebotThinking,
      loading: i18n.t("products.shared.loading"),
      finished: false,
    });
  }
  deactiveInput();

  const tmpData = { ...state };
  delete tmpData.buttonDisabled;
  delete tmpData.click;
  delete tmpData.returnApiSpan;
  delete tmpData.txtScreebot;
  delete tmpData.copied;
  delete tmpData.imgScreebot;
  delete tmpData.colorBackgroundScreebot;
  delete tmpData.finished;
  if (id) {
    delete tmpData.id;
  }

  const data = { prompt: tmpData };
  const token = localStorage.getItem("token");

  if (!token) {
    window.location.replace(process.env.REACT_APP_URL + `/users/sign_in`);
  }

  const config = {
    headers: { Authorization: `${token}` },
  };

  if (context !== undefined) {
    context.setState({ click: true });
  }

  if (id) {
    if (context !== undefined) {
      context.setState({
        output: "",
        buttonDisabled: true,
        txtScreebot: i18n.t("products.shared.thinking"),
        colorBackgroundScreebot: "#486497",
        imgScreebot: screebotThinking,
        loading: i18n.t("products.shared.loading"),
        finished: false,
      });
    }
    axios
      .put(
        process.env.REACT_APP_URL_API + "/api/v1/prompts/generate_v2/" + id,
        data,
        config,
        {
          validateStatus: function (status) {
            return status >= 200 && status < 500; // Catch errors from 200 to 499
          },
        }
      )
      .then((response) => {

        // if we regenerate the summary
        if (state.step === "summary") {
          checkPromptStatus(context, response["data"].id, config);
        } else {
          checkPromptStatus(context, response["data"].id, config, "nb_step");
        }
      })
      .catch((error) => {
        console.error(error);

        // context.setState({ output: i18n.t("products.shared.error") });
      });
  } else {
    axios
      .post(process.env.REACT_APP_URL_API + "/api/v1/prompts/generate_v2", data, config, {
        validateStatus: function (status) {
          return status >= 200 && status < 500; // Catch errors from 200 to 499
        },
      })
      .then((response) => {
        checkPromptStatus(context, response["data"].id, config);
      })
      .catch((error) => {
        console.error(error);
        context.setState({ output: i18n.t("products.shared.error") });
      });
  }
}

const handleChange = (context, e, count = false, max = 80, keyState = null) => {
  let newValue = null;
  let key = null;
  if (e.target === undefined) {
    newValue = e[0].value;
    key = e[0].name;
  } else {
    newValue = e.target.value;
    key = e.target.name;
  }
  if (keyState !== null) {
    key = keyState;
  }

  if (newValue.length <= max) {
    context.setState(
      {
        [key]: newValue,
      },
      () => {
        validate(context);
      }
    );

    if (context.state.product === "paragraph") {
      if (key === "type_text") {
        switch (newValue) {
          case "introduction":
            context.setState({
              length_txt: i18n.t("products.paragraph.introduction_length_txt"),
            });
            context.setState({
              subject_txt: i18n.t(
                "products.paragraph.introduction_subject_txt"
              ),
            });
            return "";
          case "conclusion":
            context.setState({
              length_txt: i18n.t("products.paragraph.conclusion_length_txt"),
            });
            context.setState({
              subject_txt: i18n.t("products.paragraph.conclusion_subject_txt"),
            });
            return "";
          case "intemerdiaire":
            context.setState({
              length_txt: i18n.t("products.paragraph.paragraph_length_txt"),
            });
            context.setState({
              subject_txt: i18n.t("products.paragraph.paragraph_subject_txt"),
            });
            return "";
          case "resume":
            context.setState({
              length_txt: i18n.t("products.paragraph.resume_length_txt"),
            });
            context.setState({
              subject_txt: i18n.t("products.paragraph.resume_subject_txt"),
            });
            return "";
          default:
            return "";
        }
      }
    }
    if (context.state.product === "metas") {
      if (key === "page") {
        if (newValue === "blog") {
          document.getElementById("brand_or_name").innerHTML = i18n.t(
            "products.metas.name_blog"
          );
          document.getElementById("name_or_title").innerHTML = i18n.t(
            "products.metas.title_article"
          );
        }
        if (newValue === "ecommerce") {
          document.getElementById("brand_or_name").innerHTML = i18n.t(
            "products.metas.name_brand"
          );
          document.getElementById("name_or_title").innerHTML = i18n.t(
            "products.metas.name_product"
          );
        }
      }
    }
    if (context.state.product === "excel") {
      if (key === "type") {
        if (context.state.type_response === "create") {
          if (newValue === "basic") {
            document.getElementById("context").innerHTML = i18n.t(
              "products.excel.context_basic_create"
            );
            document.getElementById("context_textarea").placeholder = i18n.t(
              "products.excel.context_basic_create_placeholder"
            );
          }
          if (newValue === "excel") {
            document.getElementById("context").innerHTML = i18n.t(
              "products.excel.context_excel_create"
            );
            document.getElementById("context_textarea").placeholder = i18n.t(
              "products.excel.context_excel_create_placeholder"
            );
          }
          if (newValue === "sheet") {
            document.getElementById("context").innerHTML = i18n.t(
              "products.excel.context_sheet_create"
            );
            document.getElementById("context_textarea").placeholder = i18n.t(
              "products.excel.context_sheet_create_placeholder"
            );
          }
        }
        if (context.state.type_response === "explain") {
          if (newValue === "basic") {
            document.getElementById("context").innerHTML = i18n.t(
              "products.excel.context_basic_explain"
            );
            document.getElementById("context_textarea").placeholder = i18n.t(
              "products.excel.context_basic_explain_placeholder"
            );
          }
          if (newValue === "excel") {
            document.getElementById("context").innerHTML = i18n.t(
              "products.excel.context_excel_explain"
            );
            document.getElementById("context_textarea").placeholder = i18n.t(
              "products.excel.context_excel_explain_placeholder"
            );
          }
          if (newValue === "sheet") {
            document.getElementById("context").innerHTML = i18n.t(
              "products.excel.context_sheet_explain"
            );
            document.getElementById("context_textarea").placeholder = i18n.t(
              "products.excel.context_sheet_explain_placeholder"
            );
          }
        }
      }
      if (key === "type_response") {
        if (newValue === "explain") {
          if (context.state.type === "basic") {
            document.getElementById("context").innerHTML = i18n.t(
              "products.excel.context_basic_explain"
            );
            document.getElementById("context_textarea").placeholder = i18n.t(
              "products.excel.context_basic_explain_placeholder"
            );
          }
          if (context.state.type === "excel") {
            document.getElementById("context").innerHTML = i18n.t(
              "products.excel.context_excel_explain"
            );
            document.getElementById("context_textarea").placeholder = i18n.t(
              "products.excel.context_excel_explain_placeholder"
            );
          }
          if (context.state.type === "sheet") {
            document.getElementById("context").innerHTML = i18n.t(
              "products.excel.context_sheet_explain"
            );
            document.getElementById("context_textarea").placeholder = i18n.t(
              "products.excel.context_sheet_explain_placeholder"
            );
          }
        }
        if (newValue === "create") {
          if (context.state.type === "basic") {
            document.getElementById("context").innerHTML = i18n.t(
              "products.excel.context_basic_create"
            );
            document.getElementById("context_textarea").placeholder = i18n.t(
              "products.excel.context_basic_create_placeholder"
            );
          }
          if (context.state.type === "excel") {
            document.getElementById("context").innerHTML = i18n.t(
              "products.excel.context_excel_create"
            );
            document.getElementById("context_textarea").placeholder = i18n.t(
              "products.excel.context_excel_create_placeholder"
            );
          }
          if (context.state.type === "sheet") {
            document.getElementById("context").innerHTML = i18n.t(
              "products.excel.context_sheet_create"
            );
            document.getElementById("context_textarea").placeholder = i18n.t(
              "products.excel.context_sheet_create_placeholder"
            );
          }
        }
      }
    }

    if (count) {
      const element = document
        .getElementById(key)
        .nextElementSibling.querySelector("span:first-child");
      if (newValue.length === max) {
        element.classList.add("few-characters");
      } else {
        element.classList.remove("few-characters");
      }
      element.innerHTML = newValue.length;
    }
  }
};

const formatDate = (newDate, options = null) => {
  const date = new Date(newDate);

  let option = null;
  if (options === "month_year") {
    option = {
      month: "long",
      year: "numeric",
    };
    const formatted = new Intl.DateTimeFormat("fr-FR", option).format(date);

    return formatted;
  } else if (options === "with_slash") {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    const formatted = `${day}/${month}/${year}`;

    return formatted;
  } else if (options === "number_of_month") {
    const currentDate = new Date();
    const monthsDiff =
      (currentDate.getFullYear() - date.getFullYear()) * 12 +
      currentDate.getMonth() -
      date.getMonth();

    return monthsDiff;
  } else {
    option = {
      day: "numeric",
      month: "long",
      year: "numeric",
    };

    const formatted = new Intl.DateTimeFormat("fr-FR", option).format(date);

    return formatted;
  }
};

const validate = (context) => {
  if (context.state.problemTokenOrPlan !== true) {
    if (context.state.product === "social") {
      if (context.state.post_type === "generaliste") {
        context.state.title.length >= 1 &&
        context.state.post_type.length >= 1 &&
        context.state.cie_name.length >= 1 &&
        context.state.business_type.length >= 1 &&
        context.state.title_article.length >= 1 &&
        context.state.tone.length >= 1 &&
        context.state.pov.length >= 1
          ? context.setState({ buttonDisabled: false })
          : context.setState({ buttonDisabled: true });
      } else {
        context.state.title.length >= 1 &&
        context.state.post_type.length >= 1 &&
        context.state.cie_name.length >= 1 &&
        context.state.product_name.length >= 1 &&
        context.state.anchor_cta.length >= 1
          ? context.setState({ buttonDisabled: false })
          : context.setState({ buttonDisabled: true });
      }
    }
    if (context.state.product === "web") {
      context.state.title.length >= 1 &&
      context.state.type_page.length >= 1 &&
      context.state.title_page.length >= 1 &&
      context.state.name_site_entreprise.length &&
      context.state.nb_paragraph.length >= 1 &&
      context.state.faq.length >= 1
        ? context.setState({ buttonDisabled: false })
        : context.setState({ buttonDisabled: true });
    }
    if (context.state.product === "blog") {
      context.state.title.length >= 1 &&
      context.state.name.length >= 1 &&
      context.state.tone.length >= 1
        ? context.setState({ buttonDisabled: false })
        : context.setState({ buttonDisabled: true });
    }
    if (context.state.product.includes("blogv2")) {
      context.state.title.length >= 1 &&
      context.state.name.length >= 1
        ? context.setState({ buttonDisabled: false })
        : context.setState({ buttonDisabled: true });
    }
    if (context.state.product === "sheet") {
      context.state.title.length >= 1 &&
      context.state.brand_name.length >= 1 &&
      context.state.product_name.length >= 1 &&
      context.state.name_site.length >= 1 &&
      context.state.short_description.length >= 1
        ? context.setState({ buttonDisabled: false })
        : context.setState({ buttonDisabled: true });
    }
    if (context.state.product === "paragraph") {
      context.state.title.length >= 1 &&
      context.state.length.length >= 1 &&
      context.state.subject.length >= 1 &&
      context.state.context.length >= 1
        ? context.setState({ buttonDisabled: false })
        : context.setState({ buttonDisabled: true });
    }
    if (context.state.product === "faq") {
      context.state.title.length >= 1 &&
      context.state.thematic.length >= 1 &&
      context.state.questions.length >= 1
        ? context.setState({ buttonDisabled: false })
        : context.setState({ buttonDisabled: true });
    }
    if (context.state.product === "definition") {
      context.state.title.length >= 1 && context.state.term.length >= 1
        ? context.setState({ buttonDisabled: false })
        : context.setState({ buttonDisabled: true });
    }
    if (context.state.product === "rewrite") {
      context.state.title.length >= 1 && context.state.input.length >= 1
        ? context.setState({ buttonDisabled: false })
        : context.setState({ buttonDisabled: true });
    }
    if (context.state.product === "metas") {
      context.state.title.length >= 1 &&
      context.state.name_or_title.length >= 1 &&
      context.state.brand_or_name.length >= 1
        ? context.setState({ buttonDisabled: false })
        : context.setState({ buttonDisabled: true });
    }
    if (context.state.product === "excel") {
      context.state.title.length >= 1 &&
      context.state.context.length >= 1 &&
      context.state.type.length >= 1 &&
      context.state.type_response.length >= 1
        ? context.setState({ buttonDisabled: false })
        : context.setState({ buttonDisabled: true });
    }
    if (context.state.product === "press") {
      context.state.title.length >= 1 &&
      context.state.context.length >= 1 &&
      context.state.name_press.length >= 1 &&
      context.state.name_company.length >= 1 &&
      context.state.location.length >= 1 &&
      context.state.name_contact.length >= 1 &&
      context.state.function_contact.length >= 1 &&
      context.state.phone_contact.length >= 1 &&
      context.state.mail_contact.length >= 1
        ? context.setState({ buttonDisabled: false })
        : context.setState({ buttonDisabled: true });
    }
  } else {
    context.setState({ buttonDisabled: false });
  }
};

const initFieldbacksService = (context, product, projectId) => {
  const token = localStorage.getItem("token");
  if (!token) {
    window.location.replace(process.env.REACT_APP_URL + `/users/sign_in`);
  }
  const config = {
    headers: { Authorization: `${token}` },
  };
  axios
    .get(
      process.env.REACT_APP_URL_API +
        "/api/v1/by_product/" +
        product +
        "/" +
        projectId,
      config,
      {
        validateStatus: function (status) {
          return status >= 200 && status < 500;
        },
      }
    )
    .then((response) => {
      if (response["data"].length > 0) {
        initFieldbacks(context, response["data"]);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

function ReturnImage(props) {
  const { icon, classList } = props;
  switch (icon) {
    case "rewrite":
      return (
        <div className={`${classList}`}>
          <img src={rewrite} alt="logo product"></img>
        </div>
      );
    case "sheet":
      return (
        <div className={`${classList}`}>
          <img src={product_sheet} alt="logo product"></img>
        </div>
      );
    case "faq":
      return (
        <div className={`${classList}`}>
          <img src={faq} alt="logo product"></img>
        </div>
      );
    case "paragraph":
      return (
        <div className={`${classList}`}>
          <img src={sentences} alt="logo product"></img>
        </div>
      );
    case "metas":
      return (
        <div className={`${classList}`}>
          <img src={meta} alt="logo product"></img>
        </div>
      );
    case "blogv2":
    case "blog":
      return (
        <div className={`${classList}`}>
          <img src={blog} alt="logo product"></img>
        </div>
      );
    case "social":
      return (
        <div className={`${classList}`}>
          <img src={social} alt="logo product"></img>
        </div>
      );
    case "web":
      return (
        <div className={`${classList}`}>
          <img src={web} alt="logo product"></img>
        </div>
      );
    case "definition":
      return (
        <div className={`${classList}`}>
          <img src={definition} alt="logo product"></img>
        </div>
      );
    case "excel":
      return (
        <div className={`${classList}`}>
          <img src={excel} alt="logo product"></img>
        </div>
      );
    case "press":
      return (
        <div className={`${classList}`}>
          <img src={press} alt="logo product"></img>
        </div>
      );
    default:
      return (
        <div className={`${classList}`}>
          <img src={rewrite} alt="logo product"></img>
        </div>
      );
  }
}

const canGenerate = (plan, user) => {
  return (
    (user && user.token > 0) ||
    (user && user.role === "member")
  );
};

const anonymizeText = (text, option) => {
  if (option === "full") {
    return "*".repeat(text.length);
  } else if (option === "start_to_end") {
    const firstLetter = text.charAt(0);
    const lastLetter = text.charAt(text.length - 1);
    if (text.includes("@")) {
      const atIndex = text.indexOf("@");
      const middle =
        "*".repeat(atIndex) +
        text.charAt(atIndex) +
        "*".repeat(text.length - atIndex - 2);
      return `${firstLetter}${middle}${lastLetter}`;
    } else {
      const middle = "*".repeat(text.length - 2);
      return `${firstLetter}${middle}${lastLetter}`;
    }
  } else {
    // Handle invalid option
    return text;
  }
};

function nbTokenByPlan(plan) {
  if (plan && plan.id_product === "-1") {
    switch (plan.name) {
      case "demo_500":
        return '500';
      case "demo_affiliate_1000":
        return '1 000';
      case "demo_1000":
        return '1 000';
      case "demo_admin_affiliate_10000":
        return "10 000";
      default:
        return '0';
    }
  }
  if(plan === null || plan === undefined) {
    return '0';
  }
  if(plan) {
    switch (plan.amount) {
      case '1900':
        return '5 000';
      case '4900':
        return '20 000';
      case '5900':
        return '20 000';
      case '9900':
        return '50 000';
      case '14900':
        return '150 000';
      default:
        return '20 000';
    }
  }
}

function planStyle(plan) {
  const name = plan.name;
  const id_product = plan.id_product;

  if (id_product === "-1") {
    return "bg-blue-demo text-blue-demo";
  }
  switch (name) {
    case "free":
      return "bg-blue-500 text-white";
    case "Starter":
      return "bg-success text-success";
    case "Pro":
      return "bg-success text-success";
    case "Expert":
      return "bg-success text-success";
    case "premium":
      return "bg-yellow-500 text-black";
    default:
      return "bg-gray-500 text-white";
  }
}

function allBadges(user, plan) {
  const role = user && user.role
  const invitation_accepted_at = user && user.invitation_accepted_at

  return (
    <div className="flex flex-row gap-x-2">
      <span
        className={`rounded-custom-input w-fit text-xs font-medium flex items-center justify-center padding-custom-badge ${
          invitation_accepted_at === null
            ? "bg-warning text-warning "
            : "bg-success text-success"
        }`}
      >
        {invitation_accepted_at === null
          ? i18n.t("team.pending_submission")
          : i18n.t("team.accepted")}
      </span>
      {
        (role === "admin") && (
          <span className="rounded-custom-input w-fit text-xs font-medium flex items-center justify-center padding-custom-badge bg-blue-admin text-white">admin</span>
        )
      }
      {plan && plan !== null && plan !== undefined && (
        <span
          className={`rounded-custom-input w-fit padding-custom-badge text-xs font-medium flex items-center justify-center ${planStyle(
            plan
          )}`}
        >
          {plan && plan.name && plan.name.includes("demo")
            ? "démo"
            : plan.name}
        </span>
      )}
      {/* {plan !== null && plan !== undefined && (
        <div className="rounded-custom-input w-fit text-xs font-medium flex items-center justify-center padding-custom-badge bg-success text-success ">
          {plan.name}
        </div>
      )} */}
      {(plan === null || plan === undefined) && (
        <div className="rounded-custom-input w-fit text-xs font-medium flex items-center justify-center padding-custom-badge bg-error text-error">
          Pas de plan
        </div>
      )}
      {
        user && user.referrer_id !== null && (
          <span className="rounded-custom-input w-fit text-xs font-medium flex items-center justify-center padding-custom-badge bg-blue-affiliate text-blue-affiliate">affilié</span>
        )
      }
      {
        (role === "affiliater" || role === "admin") && (
          <span className="rounded-custom-input w-fit text-xs font-medium flex items-center justify-center padding-custom-badge bg-blue-affiliate text-blue-affiliate">affiliateur</span>
        )
      }
      {
        (user && user.leader === true && (
          <span className="rounded-custom-input w-fit text-xs font-medium flex items-center justify-center padding-custom-badge bg-warning text-warning">team leader</span>
        ))
      }
    </div>
 )
}

export {
  allBadges,
  planStyle,
  addFielbackInInput,
  checkIfKeyExists,
  renderButtonHtml,
  initFieldbacks,
  counter,
  reactiveInput,
  deactiveInput,
  refreshPage,
  handleCommonSubmit,
  initFieldbacksService,
  validate,
  handleChange,
  headerProduct,
  ReturnImage,
  btnCopy,
  btnCopyHistory,
  canGenerate,
  formatDate,
  anonymizeText,
  nbTokenByPlan,
  generateV2
};
