import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import Select from "react-dropdown-select";
import axios from "axios";
import i18n from "i18next";

import {
  formatDate,
  nbTokenByPlan,
  allBadges,
} from "../../components/product/component/sharedFunction.js";
import { ConfirmEraseUserAccount } from "../../components/modal/confirm/confirmEraseUserAccount.js";
import { ConfirmEraseUserDeleteWithAccount } from "../../components/modal/confirm/confirmEraseUserDeleteWithAccount.js";

import "../styles/admin.css";

const AdminUsers = ({
  users,
  endOffset,
  setEndOffset,
  currentItems,
  setCurrentItems,
  pageCount,
  setPageCount,
  itemOffset,
  setItemOffset,
  itemsPerPage,
  setItemsPerPage,
  dataPlans,
}) => {
  const globalFilter = [
    ...dataPlans
      .filter((item) => item.name !== null && item.name !== "Admin")
      .map((item) => {
        return {
          value: `plan+${item.name}`,
          label: item.name,
        };
      }),
    {
      value: "plan+empty",
      label: "Pas de plan",
    },
    {
      value: "specificrole+affiliater",
      label: "Affiliateur",
    },
    {
      value: "specificrole+affiliate",
      label: "Affilié",
    },
    {
      value: "specificrole+teamleader",
      label: "Team leader",
    },
    {
      value: "role+member",
      label: "Team member",
    },
    {
      value: "role+admin",
      label: "Admin",
    },
    {
      value: "all",
      label: "Tous",
    },
  ];

  const [sortOrderAlphabetic, setSortOrderAlphabetic] = useState("ascending");
  const [sortOrderCreatedAt, setSortOrderCreatedAt] = useState("ascending");
  const [sortOrderCA, setSortOrderCA] = useState("ascending");
  const [nameFilter, setNameFilter] = useState("");
  const [planFilter, setPlanFilter] = useState([]);

  function filteredUsersDuplicateIfNeeded(users) {
    return users.filter((user) => {
      const isDuplicate = users.some((otherUser) =>
        otherUser.referrals.some((referral) => referral.id === user.id)
      );

      return !isDuplicate;
    });
  }

  function CardsUsers() {
    let filteredUsers = filteredUsersDuplicateIfNeeded(currentItems);
    if (filteredUsers.length > 0) {
      return filteredUsers.map((user) => {
        return <CardUser key={user.id} user={user} />;
      });
    } else {
      return <div className="p-3 text-error">Il n'y a rien mon ami ...</div>;
    }
  }

  function CardUser({ user }) {
    const [collapse, setCollapse] = useState(true);
    const [confirmationDeleteUserAccount, setConfirmationDeleteUserAccount] =
      useState(false);

    useEffect(() => {
      if (confirmationDeleteUserAccount) {
        // Logic to show the second modal or trigger any other actions
        console.log("Confirmation Delete User Account is true");
      }
    }, [confirmationDeleteUserAccount, user]);

    function handleAccountStripe(user, cancel_subscription = false) {
      let token = localStorage.getItem("token");
      if (!token) {
        return;
      }
      const config = {
        headers: { Authorization: `${token}` },
        data: { user: user.id, cancel_subscription: cancel_subscription },
      };

      axios
        .delete(process.env.REACT_APP_URL_API + "/api/v1/admin/user", config)
        .then((response) => {
          const id = response.data.user_id;
          const newUsers = [...currentItems].filter((user) => user.id !== id);
          setEndOffset(itemOffset + itemsPerPage);
          setCurrentItems(newUsers.slice(itemOffset, endOffset));
          setPageCount(Math.ceil(newUsers.length / itemsPerPage));
        })

        .catch((error) => {
          console.log(error);
          if (error.response.status === 451) {
            setConfirmationDeleteUserAccount(true);
          }
        });
    }

    return (
      <div id={user.id} className="w-full">
        <div className="card-admin w-full">
          <div className="flex flex-row justify-between gap-x-1 items-center">
            <div className="admin-user-image-badges">
              {user && user.avatar_url !== null && (
                <img
                  id="avatar_navbar"
                  className="header-avatar border-gray-custom-input border"
                  src={process.env.REACT_APP_URL_API + user.avatar_url}
                  alt="avatar of the user"
                ></img>
              )}
              {user && user.avatar_url === null && (
                <img
                  src="https://ucarecdn.com/b555ca32-eee0-48cf-a943-175bc1498367/-/scale_crop/60x60/smart/"
                  alt="avatar of the user"
                />
              )}
            </div>
            <div className="flex flex-col text-left gap-y-1">
              <div className="font-semibold text-md text-secondary">
                {user.first_name === null ? "N/C" : user.first_name}{" "}
                {user.last_name === null ? "N/C" : user.last_name}{" "}
                <span className="text-xs my-auto">({user.id})</span>
              </div>
              <div className="text-secondary font-normal">{user.email}</div>
              {allBadges(user, user.plan)}
            </div>
          </div>
          <div className="flex flex-row justify-between gap-x-3 items-center">
            <div className="flex flex-col w-full text-secondary text-right">
              <div>
                <span className="font-semibold">
                  {user &&
                    user.token.toLocaleString("fr-FR", {
                      minimumFractionDigits: 0,
                    })}
                </span>
                <span className="font-normal">
                  {" "}
                  / {nbTokenByPlan(user && user.plan)}
                </span>
              </div>
              <div>
                <span className="font-semibold">
                  {formatDate(user.created_at, "number_of_month")} mois{" "}
                </span>
                <span className="font-normal">d’ancienneté</span>
              </div>
              <div className="">
                <span className="font-semibold">
                  {user.money.toLocaleString("fr-FR", {
                    minimumFractionDigits: 0,
                  })}{" "}
                  €{" "}
                </span>
                <span>CA HT générés</span>
              </div>
            </div>
            <div className="text-secondary">
              <div>Dernière connexion</div>
              <div className="font-semibold">
                {formatDate(user.updated_at, "with_slash")}
              </div>
            </div>
            {user.referrals.length > 0 && (
              <button
                style={{ padding: "10px 10px" }}
                className={`flex flex-row gap-x-1 border-gray-custom-input border p-3.5 btn ${
                  !collapse
                    ? "bg-secondary text-white"
                    : "bg-white text-secondary"
                }`}
                onClick={() => setCollapse(!collapse)}
              >
                <svg
                  width="20"
                  height="19"
                  viewBox="0 0 20 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 0C4.76175 0 0.5 4.26175 0.5 9.5C0.5 14.7382 4.76175 19 10 19C15.2382 19 19.5 14.7382 19.5 9.5C19.5 4.26175 15.2382 0 10 0ZM13.1651 4.39238C13.8199 4.39328 14.4596 4.58825 15.0036 4.95265C15.5475 5.31705 15.9712 5.83452 16.2211 6.43966C16.4711 7.0448 16.536 7.71045 16.4077 8.35248C16.2794 8.9945 15.9637 9.58409 15.5004 10.0467C15.0371 10.5094 14.4471 10.8243 13.8049 10.9517C13.1627 11.0791 12.4971 11.0132 11.8923 10.7625C11.2876 10.5117 10.7707 10.0873 10.407 9.54286C10.0434 8.99842 9.84928 8.3584 9.84928 7.70368C9.84958 7.26853 9.93558 6.83771 10.1024 6.4358C10.2692 6.03389 10.5135 5.66877 10.8214 5.36129C11.1293 5.05381 11.4948 4.80998 11.8969 4.64374C12.2991 4.47749 12.73 4.39208 13.1651 4.39238ZM6.08125 5.59632C6.61695 5.59704 7.14041 5.75656 7.58547 6.0547C8.03054 6.35284 8.37723 6.77624 8.58173 7.27136C8.78624 7.76649 8.83937 8.31113 8.73442 8.83645C8.62947 9.36177 8.37114 9.84418 7.99209 10.2227C7.61304 10.6013 7.13027 10.8589 6.60482 10.9632C6.07936 11.0674 5.53479 11.0136 5.03994 10.8084C4.54509 10.6032 4.12216 10.256 3.82462 9.81049C3.52708 9.36502 3.36827 8.84135 3.36827 8.30565C3.36845 7.9496 3.43877 7.59707 3.57522 7.2682C3.71167 6.93934 3.91157 6.64057 4.16351 6.38897C4.41544 6.13738 4.71447 5.93788 5.04353 5.80187C5.37258 5.66587 5.7252 5.59602 6.08125 5.59632ZM2.61695 12.6756C3.73594 12.0687 5.1687 11.7649 6.08125 11.7649C6.76635 11.7649 7.64875 11.9019 8.517 12.2308C7.93497 12.5556 7.41033 12.9739 6.96411 13.469C6.36168 14.1541 6.01594 14.9725 6.01594 15.7906V16.4756C4.50322 15.6092 3.31099 14.2771 2.61695 12.6779V12.6756ZM10 17.5385C9.0752 17.5392 8.15727 17.3796 7.28702 17.0667V15.8312C7.28702 13.4293 11.2035 12.2189 13.1651 12.2189C14.2156 12.2189 15.8315 12.5674 17.1026 13.2607C16.4186 14.5514 15.3961 15.6315 14.1448 16.3851C12.8936 17.1387 11.4607 17.5374 10 17.5385Z"
                    fill="currentColor"
                  />
                </svg>
              </button>
            )}

            <Link
              to={`${user.id}`}
              className="flex flex-row gap-x-1 bg-white text-white border border-gray-custom-input btn p-3.5 rounded-custom-input"
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 4.875C5.43268 4.875 3.1284 8.67461 3.08672 8.7438C3.03062 8.81667 3.00006 8.90696 3 9C3.00007 9.07982 3.0226 9.1579 3.06484 9.22478C3.06536 9.22559 3.06588 9.22639 3.06641 9.2272C3.07328 9.24183 5.00753 13.125 9 13.125C12.9755 13.125 14.9069 9.27998 14.9305 9.23284C14.9321 9.23017 14.9336 9.22748 14.9352 9.22478C14.9774 9.1579 14.9999 9.07982 15 9C15 8.90733 14.9697 8.81737 14.9141 8.7446L14.9133 8.7438C14.8716 8.67461 12.5673 4.875 9 4.875ZM9 6.1125C10.5464 6.1125 11.8 7.40528 11.8 9C11.8 10.5947 10.5464 11.8875 9 11.8875C7.4536 11.8875 6.2 10.5947 6.2 9C6.2 7.40528 7.4536 6.1125 9 6.1125ZM9 7.7625C8.68174 7.7625 8.37651 7.89288 8.15147 8.12496C7.92643 8.35703 7.8 8.67179 7.8 9C7.8 9.32821 7.92643 9.64297 8.15147 9.87504C8.37651 10.1071 8.68174 10.2375 9 10.2375C9.31826 10.2375 9.62348 10.1071 9.84853 9.87504C10.0736 9.64297 10.2 9.32821 10.2 9C10.2 8.67179 10.0736 8.35703 9.84853 8.12496C9.62348 7.89288 9.31826 7.7625 9 7.7625Z"
                  fill="#486497"
                />
              </svg>
            </Link>
            <div>
              <ConfirmEraseUserAccount
                from="admin"
                accept={() => handleAccountStripe(user)}
                acceptNotification={
                  user.plan && user.plan.status === -2
                    ? i18n.t("account.accept_notification_reactivate")
                    : i18n.t("account.accept_notification_delete")
                }
                rejectNotification={
                  user.plan && user.plan.status === -2
                    ? i18n.t("account.reject_notification_reactivate")
                    : i18n.t("account.reject_notification_delete")
                }
                label={
                  user.plan && user.plan.status === -2
                    ? i18n.t("account.reactivate")
                    : i18n.t("account.delete")
                }
                rejectLabel={i18n.t("account.no_thanks")}
                message={
                  user.plan && user.plan.status === -2
                    ? i18n.t("account.message_reactivate")
                    : i18n.t("account.message_delete")
                }
                header={
                  user.plan && user.plan.status === -2
                    ? i18n.t("account.header_reactivate")
                    : i18n.t("account.header_delete")
                }
              ></ConfirmEraseUserAccount>
              {confirmationDeleteUserAccount && (
                <ConfirmEraseUserDeleteWithAccount
                  visible={confirmationDeleteUserAccount}
                  accept={() => handleAccountStripe(user, true)}
                  acceptNotification={"Le plan a été supprimé"}
                  rejectNotification={"Le plan n'a pas été supprimé"}
                  label={"Supprimer le plan chez Stripe"}
                  rejectLabel={i18n.t("account.no_thanks")}
                  message={
                    "Voulez vous le couper (à la fin  de la période de facturation) ?"
                  }
                  header={"Ce compte possède un plan"}
                ></ConfirmEraseUserDeleteWithAccount>
              )}
            </div>
          </div>
        </div>
        {!collapse &&
          user.referrals &&
          user.referrals.map((user) => (
            <div key={user.id} className="flex flex-row">
              <div className="my-auto ml-8 mr-4">
                <svg
                  width="24"
                  height="4"
                  viewBox="0 0 24 4"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 2H22"
                    stroke="#EAAF17"
                    strokeWidth="3"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
              <div className="card-admin w-full">
                <div className="flex flex-row justify-between gap-x-1 items-center">
                  <div className="admin-user-image-badges">
                    {user && user.avatar_url !== null && (
                      <img
                        id="avatar_navbar"
                        className="header-avatar border-gray-custom-input border"
                        src={process.env.REACT_APP_URL_API + user.avatar_url}
                        alt="avatar of the user"
                      ></img>
                    )}
                    {user && user.avatar_url === null && (
                      <img
                        src="https://ucarecdn.com/b555ca32-eee0-48cf-a943-175bc1498367/-/scale_crop/60x60/smart/"
                        alt="avatar of the user"
                      />
                    )}
                  </div>
                  <div className="flex flex-col text-left gap-y-1">
                    <div className="font-semibold text-md text-secondary">
                      {user.first_name === null ? "N/C" : user.first_name}{" "}
                      {user.last_name === null ? "N/C" : user.last_name}
                      <span className="text-xs my-auto">({user.id})</span>
                    </div>
                    <div className="text-secondary font-normal">
                      {user.email}
                    </div>
                    {allBadges(user, user.plan)}
                  </div>
                </div>
                <div className="flex flex-row justify-between gap-x-3 items-center">
                  <div className="flex flex-col w-full text-secondary text-right">
                    <div>
                      <span className="font-semibold">
                        {user.token.toLocaleString("fr-FR", {
                          minimumFractionDigits: 0,
                        })}
                      </span>
                      <span className="font-normal">
                        {" "}
                        / {nbTokenByPlan(user && user.plan)}
                      </span>
                    </div>
                    <div>
                      <span className="font-semibold">
                        {formatDate(user.created_at, "number_of_month")} mois{" "}
                      </span>
                      <span className="font-normal">d’ancienneté</span>
                    </div>
                    <div className="">
                      <span className="font-semibold">
                        {user.money.toLocaleString("fr-FR", {
                          minimumFractionDigits: 0,
                        })}{" "}
                        €{" "}
                      </span>
                      <span>CA HT générés</span>
                    </div>
                  </div>
                  <div className="text-secondary">
                    <div>Dernière connexion</div>
                    <div className="font-semibold">
                      {formatDate(user.updated_at, "with_slash")}
                    </div>
                  </div>
                  <Link
                    to={`${user.id}`}
                    className="flex flex-row gap-x-1 bg-white text-white border border-gray-custom-input btn p-3.5 rounded-custom-input"
                  >
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9 4.875C5.43268 4.875 3.1284 8.67461 3.08672 8.7438C3.03062 8.81667 3.00006 8.90696 3 9C3.00007 9.07982 3.0226 9.1579 3.06484 9.22478C3.06536 9.22559 3.06588 9.22639 3.06641 9.2272C3.07328 9.24183 5.00753 13.125 9 13.125C12.9755 13.125 14.9069 9.27998 14.9305 9.23284C14.9321 9.23017 14.9336 9.22748 14.9352 9.22478C14.9774 9.1579 14.9999 9.07982 15 9C15 8.90733 14.9697 8.81737 14.9141 8.7446L14.9133 8.7438C14.8716 8.67461 12.5673 4.875 9 4.875ZM9 6.1125C10.5464 6.1125 11.8 7.40528 11.8 9C11.8 10.5947 10.5464 11.8875 9 11.8875C7.4536 11.8875 6.2 10.5947 6.2 9C6.2 7.40528 7.4536 6.1125 9 6.1125ZM9 7.7625C8.68174 7.7625 8.37651 7.89288 8.15147 8.12496C7.92643 8.35703 7.8 8.67179 7.8 9C7.8 9.32821 7.92643 9.64297 8.15147 9.87504C8.37651 10.1071 8.68174 10.2375 9 10.2375C9.31826 10.2375 9.62348 10.1071 9.84853 9.87504C10.0736 9.64297 10.2 9.32821 10.2 9C10.2 8.67179 10.0736 8.35703 9.84853 8.12496C9.62348 7.89288 9.31826 7.7625 9 7.7625Z"
                        fill="#486497"
                      />
                    </svg>
                  </Link>
                  <ConfirmEraseUserAccount
                    from="admin"
                    accept={() => handleAccountStripe(user)}
                    acceptNotification={
                      user.plan && user.plan.status === -2
                        ? i18n.t("account.accept_notification_reactivate")
                        : i18n.t("account.accept_notification_delete")
                    }
                    rejectNotification={
                      user.plan && user.plan.status === -2
                        ? i18n.t("account.reject_notification_reactivate")
                        : i18n.t("account.reject_notification_delete")
                    }
                    label={
                      user.plan && user.plan.status === -2
                        ? i18n.t("account.reactivate")
                        : i18n.t("account.delete")
                    }
                    rejectLabel={i18n.t("account.no_thanks")}
                    message={
                      user.plan && user.plan.status === -2
                        ? i18n.t("account.message_reactivate")
                        : i18n.t("account.message_delete")
                    }
                    header={
                      user.plan && user.plan.status === -2
                        ? i18n.t("account.header_reactivate")
                        : i18n.t("account.header_delete")
                    }
                  ></ConfirmEraseUserAccount>
                  {confirmationDeleteUserAccount && (
                    <ConfirmEraseUserDeleteWithAccount
                      visible={confirmationDeleteUserAccount}
                      accept={() => handleAccountStripe(user, true)}
                      acceptNotification={"Le plan a été supprimé"}
                      rejectNotification={"Le plan n'a pas été supprimé"}
                      label={"Supprimer le plan chez Stripe"}
                      rejectLabel={i18n.t("account.no_thanks")}
                      message={
                        "Voulez vous le couper (à la fin  de la période de facturation) ?"
                      }
                      header={"Ce compte possède un plan"}
                    ></ConfirmEraseUserDeleteWithAccount>
                  )}
                </div>
              </div>
            </div>
          ))}
      </div>
    );
  }

  function handleSortCreatedAt() {
    const sortedData = [...users].sort((a, b) => {
      if (sortOrderCreatedAt === "descending") {
        return b.created_at.localeCompare(a.created_at);
      } else {
        return a.created_at.localeCompare(b.created_at);
      }
    });

    setEndOffset(itemOffset + itemsPerPage);
    setCurrentItems(sortedData.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(sortedData.length / itemsPerPage));
    setSortOrderCreatedAt(
      sortOrderCreatedAt === "ascending" ? "descending" : "ascending"
    );
  }

  function handleSortAlphabetic() {
    const sortedData = [...users].sort((a, b) => {
      if (sortOrderAlphabetic === "descending") {
        return b.last_name.localeCompare(a.last_name);
      } else {
        return a.last_name.localeCompare(b.last_name);
      }
    });

    setEndOffset(itemOffset + itemsPerPage);
    setCurrentItems(sortedData.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(sortedData.length / itemsPerPage));
    setSortOrderAlphabetic(
      sortOrderAlphabetic === "ascending" ? "descending" : "ascending"
    );
  }

  function handleSortCA() {
    const sortedData = [...users].sort((a, b) => {
      if (sortOrderCA === "descending") {
        return b.money - a.money;
      } else {
        return a.money - b.money;
      }
    });

    setEndOffset(itemOffset + itemsPerPage);
    setCurrentItems(sortedData.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(sortedData.length / itemsPerPage));
    setSortOrderCA(sortOrderCA === "ascending" ? "descending" : "ascending");
  }

  useEffect(() => {
    let filteredUsers = [...users]; // Create a copy of the users list

    // Apply name filter
    if (nameFilter !== "") {
      filteredUsers = filteredUsers.filter((user) => {
        return (
          (user.first_name === null
            ? ""
            : user.first_name.toLowerCase().includes(nameFilter)) ||
          (user.last_name === null
            ? ""
            : user.last_name.toLowerCase().includes(nameFilter)) ||
          user.email.toLowerCase().includes(nameFilter) ||
          user.id.toString().includes(nameFilter)
        );
      });
    }

    // Apply plan filter
    if (planFilter.length > 0) {
      filteredUsers = filteredUsers.filter((user) => {
        return planFilter.some((filter) => {
          const filterValue = filter.value.split("+");
          if (filter.value === "all") {
            return user;
          } else if (filterValue[0] === "plan") {
            if (filterValue[1] === "empty") {
              return !user.plan;
            } else {
              return user.plan && user.plan.name === filterValue[1];
            }
          } else if (filterValue[0] === "role") {
            return user.role === filterValue[1];
          } else if (filterValue[0] === "specificrole") {
            if (filterValue[1] === "affiliate") {
              return user.referrer_id === true;
            } else if (filterValue[1] === "teamleader") {
              return user.leader === true;
            } else if (filterValue[1] === "affiliate") {
              return user.leader === true;
            } else if (filterValue[1] === "affiliater") {
              return user.referrals.length > 0;
            } else {
              return false;
            }
          } else {
            return false;
          }
        });
      });
    }

    setEndOffset(itemOffset + itemsPerPage);
    setCurrentItems(filteredUsers.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(filteredUsers.length / itemsPerPage));
  }, [
    nameFilter,
    planFilter,
    endOffset,
    itemOffset,
    itemsPerPage,
    setCurrentItems,
    setEndOffset,
    setPageCount,
    users,
  ]); // Apply filters whenever nameFilter or planFilter changes

  function handleFilterChange(event, filterType) {
    switch (filterType) {
      case "name":
        setNameFilter(event.target.value.toLowerCase());
        break;
      case "plan":
        setPlanFilter(event);
        break;
      default:
        break;
    }

    let filteredUsers = [...users]; // Create a copy of the users list

    // Apply name filter
    if (nameFilter !== "") {
      filteredUsers = filteredUsers.filter((user) => {
        return (
          (user.first_name === null
            ? ""
            : user.first_name.toLowerCase().includes(nameFilter)) ||
          (user.last_name === null
            ? ""
            : user.last_name.toLowerCase().includes(nameFilter)) ||
          user.email.toLowerCase().includes(nameFilter) ||
          user.id.toString().includes(nameFilter)
        );
      });
    }

    // Apply plan filter
    if (planFilter.length > 0) {
      filteredUsers = filteredUsers.filter((user) => {
        return planFilter.some((filter) => {
          const filterValue = filter.value.split("+");
          if (filter.value === "all") {
            return user;
          } else if (filterValue[0] === "plan") {
            if (filterValue[1] === "empty") {
              return !user.plan;
            } else {
              return user.plan && user.plan.name === filterValue[1];
            }
          } else if (filterValue[0] === "role") {
            return user.role === filterValue[1];
          } else if (filterValue[0] === "specificrole") {
            if (filterValue[1] === "affiliate") {
              return user.referrer_id === true;
            } else if (filterValue[1] === "teamleader") {
              return user.leader === true;
            } else if (filterValue[1] === "affiliater") {
              return user.referrals.length > 0;
            } else {
              return false;
            }
          } else {
            return false;
          }
        });
      });
    }

    setEndOffset(itemOffset + itemsPerPage);
    setCurrentItems(filteredUsers.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(filteredUsers.length / itemsPerPage));
  }

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % users.length;
    setItemOffset(newOffset);
  };

  return (
    <div className="flex flex-col gap-y-6">
      <div className="flex flex-col w-full gap-y-6">
        <div className="text-left w-full">
          <input
            name="search"
            type="text"
            placeholder="recherche par prénom / nom / mail / id"
            className="w-full"
            onChange={(e) => handleFilterChange(e, "name")}
          />
        </div>
        <div className="flex flex-row gap-x-2.5">
          <div className="min-w-52 max-w-80 text-nowrap">
            <Select
              // closeOnSelect={true}
              closeOnClickInput={true}
              name="plan"
              id="plan"
              disabled={false}
              multi
              options={globalFilter}
              values={[globalFilter[globalFilter.length - 1]]}
              onChange={(e) => handleFilterChange(e, "plan")}
            />
          </div>
          <div className="w-fit text-nowrap">
            <button
              className="react-dropdown-select react-dropdown-select rounded-custom-input border-gray-custom-input border bg-white text-primary font-normal label w-full css-wmy1p7-ReactDropdownSelect"
              onClick={() => handleSortCreatedAt()}
            >
              Date de création{" "}
              {sortOrderCreatedAt === "ascending"
                ? "Plus récent"
                : "Moins récent"}
            </button>
          </div>
          <div className="w-fit text-nowrap">
            <button
              className="react-dropdown-select react-dropdown-select rounded-custom-input border-gray-custom-input border bg-white text-primary font-normal label w-full css-wmy1p7-ReactDropdownSelect"
              onClick={() => handleSortAlphabetic()}
            >
              Alphabétique{" "}
              {sortOrderAlphabetic === "ascending" ? "De A à Z" : "De Z à A"}
            </button>
          </div>
          <div className="w-fit text-nowrap">
            <button
              className="react-dropdown-select react-dropdown-select rounded-custom-input border-gray-custom-input border bg-white text-primary font-normal label w-full css-wmy1p7-ReactDropdownSelect"
              onClick={() => handleSortCA()}
            >
              CA HT généré {sortOrderCA === "ascending" ? "+ grand" : "- grand"}
            </button>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full">
        <div className="cards-admin">
          <CardsUsers />
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            containerClassName="paginate"
          />
        </div>
      </div>
      <div>
        <div className="bg-white rounded-input flex flex-row gap-x-4 w-fit">
          <span className="my-auto">affichage</span>
          <div className="flex flex-row bg-white gap-x-2">
            <button
              onClick={() => setItemsPerPage(25)}
              className={`bg-white btn-per-page ${
                itemsPerPage === 25
                  ? "text-secondary font-semibold"
                  : "text-gray opacity-gray"
              }`}
            >
              25
            </button>
            <hr className="display-btn"></hr>
            <button
              onClick={() => setItemsPerPage(50)}
              className={`bg-white btn-per-page ${
                itemsPerPage === 50
                  ? "text-secondary font-semibold"
                  : "text-gray opacity-gray"
              }`}
            >
              50
            </button>
            <hr className="display-btn"></hr>
            <button
              onClick={() => setItemsPerPage(100)}
              className={`bg-white btn-per-page ${
                itemsPerPage === 100
                  ? "text-secondary font-semibold"
                  : "text-gray opacity-gray"
              }`}
            >
              100
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminUsers;
