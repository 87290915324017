import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import i18n from "i18next";

import Projects from "../../../../sidebar/Projects";
import PromptsListPaginatedTeam from "./PromptsPaginatedTeam";
import { ConfirmEraseUserAccountTeam } from "./confirmEraseUserAccount";
// import { disconnectUser } from "../../../../utils/replaceUserSignIn";
import { Toast } from "primereact/toast";
import logo from "../../../../assets/logo.svg";

import "../../../../assets/Common.css";

function Member(props) {
  const { id, invitation, avatar, plan, setMember, member, updateState } =
    props;
  const [data, setData] = useState("");
  const [selectedProject, setSelectedProject] = useState(null);
  const [currentProject, setCurrentProject] = useState(null);
  const [screebotTokenUsed, setScreebotTokenUsed] = useState(0);
  const [promptCountMonth, setpromptCountMonth] = useState(0);

  const [collapse, setCollapse] = useState(false);
  const toast = useRef(null);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      window.location.replace(process.env.REACT_APP_URL + `/users/sign_in`);
    }
    const config = {
      headers: { Authorization: `${token}` },
    };

    axios
      .get(process.env.REACT_APP_URL_API + `/specific_client/${id}`, config)
      .then((response) => {
        if (response["data"]) {
          const data = response.data;
          setData(data);
          setCurrentProject(data.project);
          setpromptCountMonth(data.prompt_count_month);
          setScreebotTokenUsed(data.screebot_token_used);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  function deleteUser() {
    let token = localStorage.getItem("token");
    if (!token) {
      return;
    }
    const config = {
      headers: { Authorization: `${token}` },
    };

    axios
      .delete(
        process.env.REACT_APP_URL_API + "/api/v1/teams/user/" + id,
        config
      )
      .then((response) => {
        member.forEach((user, index) => {
          setMember((prevMembers) =>
            prevMembers.filter((member) => member.user.id !== id)
          );
          if (member.length === 0) {
            updateState(0);
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function resendInvitation() {
    const token = localStorage.getItem("token");
    if (!token) {
      window.location.replace(process.env.REACT_APP_URL + `/users/sign_in`);
    }
    const config = {
      headers: { Authorization: `${token}` },
    };
    let data = {
      id: id,
      language: localStorage.getItem("i18nextLng"),
    };
    axios
      .post(
        process.env.REACT_APP_URL_API + `/api/v1/teams/user/resend_invitation/`,
        data,
        config
      )
      .then((response) => {
        if (response["data"].status === "ok") {
          toast.current.show({
            severity: "success",
            summary: "Invitation envoyé",
            detail: "Votre mail est bien parti",
            life: 3000,
          });
        } else {
          toast.current.show({
            severity: "error",
            summary: "Invitation non envoyé",
            detail: "Votre mail n'est pas parti",
            life: 3000,
          });
        }
      })
      .catch((error) => {
        toast.current.show({
          severity: "error",
          summary: "Invitation non envoyé",
          detail: "Votre mail n'est pas parti",
          life: 3000,
        });
        console.log(error);
      });
  }

  return (
    currentProject && (
      <div className="w-full bg-white border border-gray-custom-input rounded-custom-input team-panel flex flex-col gap-y-1">
        <Toast ref={toast} />
        <div className="flex flex-row justify-between">
          <div className="flex flex-row gap-x-2">
            <div>
              {avatar && avatar !== null && (
                <img
                  id="avatar_navbar"
                  className="header-avatar border-gray-custom-input border"
                  src={avatar && process.env.REACT_APP_URL_API + avatar}
                  alt="avatar of the user"
                ></img>
              )}
              {avatar === null && (
                <img
                  className="header-avatar border-gray-custom-input border"
                  src={logo}
                  alt="avatar of the user"
                />
              )}
            </div>
            <div className="flex flex-col gap-y-2">
              <span className="text-secondary text-lg font-semibold">
                {data && data.user.first_name} {data && data.user.last_name}
              </span>
              <span className="text-secondary text-md font-normal">
                {data && data.user.email}
              </span>
              <div>
                <span
                  className={`badge-team ${
                    invitation ? "accepted" : "pending_submission"
                  }`}
                >
                  {invitation
                    ? i18n.t("team.accepted")
                    : i18n.t("team.pending_submission")}
                </span>
              </div>
            </div>
          </div>
          <div className="flex flex-row gap-x-10">
            {invitation && (
              <div className="flex flex-col gap-y-1 text-align-right my-auto">
                <div>
                  <span className="font-medium text-secondary">
                    {screebotTokenUsed}
                  </span>
                  <span className="text-secondary font-normal">
                    {" "}
                    / 150 000 crédits
                  </span>
                </div>
                <div>
                  <span className="text-secondary">{promptCountMonth}</span>
                  <span className="text-secondary font-normal">
                    {" "}
                    documents générés ce mois-ci
                  </span>
                </div>
              </div>
            )}

            <div className="flex flex-row gap-x-3 my-auto">
              {!invitation && (
                // TODO fix resend_invitation function on back
                <button
                  disabled={true}
                  onClick={(e) => resendInvitation()}
                  className="flex flex-row gap-x-4 bg-white border border-gray-custom-input rounded-custom-input btn"
                >
                  <svg
                    width="19"
                    height="17"
                    viewBox="0 0 19 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_2185_7126)">
                      <path
                        d="M1.39279 16.5401C1.33467 16.5401 1.27626 16.533 1.21784 16.5186C0.891618 16.4377 0.662354 16.143 0.662354 15.8048C0.662354 13.4721 1.00756 11.4251 1.68864 9.72116C1.83934 9.34442 2.26457 9.16208 2.6387 9.31354C3.01282 9.465 3.1939 9.8935 3.04349 10.2702C2.66265 11.2231 2.39892 12.3057 2.25406 13.505C2.93134 12.87 3.74909 12.313 4.68689 11.8531C6.34898 11.0375 8.29057 10.5884 10.1542 10.5884C10.5575 10.5884 10.8843 10.9175 10.8843 11.3237V13.622C10.8843 13.8017 10.8843 13.9911 10.8875 14.1605C11.0154 14.0505 11.1559 13.9247 11.2891 13.8053L15.1565 10.3438C16.575 9.07385 17.2021 8.49036 17.2021 7.96246C17.2021 7.43456 16.575 6.85078 15.1565 5.58117L11.2894 2.11966C11.1562 2.00025 11.0154 1.87438 10.8875 1.76439C10.8843 1.93379 10.8843 2.12348 10.8843 2.30288V4.60124C10.8843 5.00739 10.5575 5.33648 10.1542 5.33648C9.21668 5.33648 7.39308 5.51735 5.71142 6.72843C5.38344 6.96459 4.92725 6.88842 4.69273 6.55815C4.45821 6.22788 4.53385 5.76851 4.86183 5.53235C6.16411 4.59447 7.76165 4.02422 9.42404 3.89423V2.30259C9.42404 1.79498 9.42404 1.42824 9.46755 1.12503C9.54144 0.609475 9.76837 0.262442 10.1425 0.0933369C10.5166 -0.0757682 10.9249 -0.0160667 11.3566 0.270677C11.6104 0.439488 11.8829 0.683293 12.2596 1.02033L16.1267 4.48184C17.7617 5.94526 18.6624 6.75137 18.6624 7.96217C18.6624 9.17296 17.7619 9.97878 16.1273 11.4419L12.2596 14.904C11.8826 15.2413 11.6104 15.4851 11.3566 15.6537C10.9249 15.9407 10.5163 16.0001 10.1422 15.831C9.76808 15.6619 9.54115 15.3149 9.46726 14.7993C9.42374 14.4961 9.42374 14.1294 9.42374 13.6217V12.0839C8.00844 12.1821 6.57707 12.5607 5.3262 13.1744C3.79816 13.9241 2.66148 14.9519 2.03852 16.1463C1.91031 16.3921 1.65856 16.5398 1.3922 16.5398L1.39279 16.5401Z"
                        fill="#486497"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_2185_7126">
                        <rect
                          width="18"
                          height="16.5405"
                          fill="white"
                          transform="translate(0.662354)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  <span className="text-secondary">
                    renvoyer le mail d’invitation
                  </span>
                </button>
              )}
              {invitation && (
                <button
                  onClick={(e) => setCollapse(!collapse)}
                  className={`${
                    collapse ? "bg-primary text-white" : "bg-white text-primary"
                  } border border-gray-custom-input rounded-custom-input btn px-3.5`}
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 4.875C5.43268 4.875 3.1284 8.67461 3.08672 8.7438C3.03062 8.81667 3.00006 8.90696 3 9C3.00007 9.07982 3.0226 9.1579 3.06484 9.22478C3.06536 9.22559 3.06588 9.22639 3.06641 9.2272C3.07328 9.24183 5.00753 13.125 9 13.125C12.9755 13.125 14.9069 9.27998 14.9305 9.23284C14.9321 9.23017 14.9336 9.22748 14.9352 9.22478C14.9774 9.1579 14.9999 9.07982 15 9C15 8.90733 14.9697 8.81737 14.9141 8.7446L14.9133 8.7438C14.8716 8.67461 12.5673 4.875 9 4.875ZM9 6.1125C10.5464 6.1125 11.8 7.40528 11.8 9C11.8 10.5947 10.5464 11.8875 9 11.8875C7.4536 11.8875 6.2 10.5947 6.2 9C6.2 7.40528 7.4536 6.1125 9 6.1125ZM9 7.7625C8.68174 7.7625 8.37651 7.89288 8.15147 8.12496C7.92643 8.35703 7.8 8.67179 7.8 9C7.8 9.32821 7.92643 9.64297 8.15147 9.87504C8.37651 10.1071 8.68174 10.2375 9 10.2375C9.31826 10.2375 9.62348 10.1071 9.84853 9.87504C10.0736 9.64297 10.2 9.32821 10.2 9C10.2 8.67179 10.0736 8.35703 9.84853 8.12496C9.62348 7.89288 9.31826 7.7625 9 7.7625Z"
                      fill="currentColor"
                    />
                  </svg>
                </button>
              )}

              <ConfirmEraseUserAccountTeam
                accept={() => deleteUser()}
                acceptNotification="Le compte a bien été supprimé"
                rejectNotification="Le compte n'a pas été supprimé"
                label="Supprimer"
                rejectLabel={i18n.t("account.no_thanks")}
                message="Êtes-vous sur de vouloir supprimer ce compte"
                header="Suppression de compte"
                invitation={invitation}
              ></ConfirmEraseUserAccountTeam>
            </div>
          </div>
        </div>
        {collapse && id && (
          <div>
            <Projects
              projects={currentProject}
              selectedProject={selectedProject}
              setSelectedProject={setSelectedProject}
              setCurrentProject={setCurrentProject}
              classes="admin"
            ></Projects>
            <PromptsListPaginatedTeam
              itemsPerPage={10}
              selectedProject={selectedProject}
              user_id={id}
              classes="admin"
            ></PromptsListPaginatedTeam>
          </div>
        )}
      </div>
    )
  );
}

export default Member;
