import React, { useState, useEffect } from "react";
import axios from "axios";

import AdminPanelButton from "./component/AdminPanelButton";
import AdminDashboard from "./component/AdminDashboard";
import AdminUsers from "./component/AdminUsers";
import AdminAffiliaters from "./component/AdminAffiliaters";

import "./styles/admin.css";

export default function Admin() {
  const [panel, setPanel] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [endOffset, setEndOffset] = useState("");
  const [currentItems, setCurrentItems] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [users, setUsers] = useState(null);

  const [fromBeginningCA, setFromBeginningCA] = useState(0);
  const [currentYearCA, setCurrentYearCA] = useState(0);
  const [currentQuarterCA, setCurrentQuarterCA] = useState(0);

  const [numberAffiliater, setNumberAffiliater] = useState(null);
  const [numberAffiliates, setNumberAffiliates] = useState(null);
  const [numberTokenOpenAI, setNumberTokenOpenAI] = useState(null);
  const [dataPlans, setDataPlans] = useState(null);

  const propsAdminUsers = {
    users,
    endOffset,
    setEndOffset,
    currentItems,
    setCurrentItems,
    pageCount,
    setPageCount,
    itemOffset,
    setItemOffset,
    itemsPerPage,
    setItemsPerPage,
    dataPlans,
  };

  const propsAdminDashboard = {
    currentItems,
    fromBeginningCA,
    setFromBeginningCA,
    currentYearCA,
    setCurrentYearCA,
    currentQuarterCA,
    setCurrentQuarterCA,
    numberAffiliater,
    setNumberAffiliater,
    numberAffiliates,
    setNumberAffiliates,
    numberTokenOpenAI,
    setNumberTokenOpenAI,
    dataPlans,
    setDataPlans,
  };

  const propsAdminAffiliaters = {
    users,
    endOffset,
    setEndOffset,
    currentItems,
    setCurrentItems,
    pageCount,
    setPageCount,
    itemOffset,
    setItemOffset,
    itemsPerPage,
    setItemsPerPage,
    dataPlans,
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      window.location.replace(process.env.REACT_APP_URL + `/users/sign_in`);
    }
    const config = {
      headers: { Authorization: `${token}` },
    };

    axios
      .get(process.env.REACT_APP_URL_API + "/clients", config)
      .then((response) => {
        if (response["data"]) {
          const data = response.data["users"];
          setUsers(data);
          setEndOffset(itemOffset + itemsPerPage);
          setCurrentItems(data.slice(itemOffset, endOffset));
          setPageCount(Math.ceil(data.length / itemsPerPage));
        }
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(process.env.REACT_APP_URL_API + "/statistics", config)
      .then((response) => {
        if (response["data"]) {
          const data = response["data"];
          setFromBeginningCA(data["statistic"]["from_beginning_ca"]);
          setCurrentYearCA(data["statistic"]["current_year_ca"]);
          setCurrentQuarterCA(data["statistic"]["current_quarter_ca"]);
          setNumberTokenOpenAI(data["tokens"]["openai_token"]);
          setNumberAffiliater(data["users"]["affiliater"].length);
          setNumberAffiliates(data["users"]["affiliates"].length);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(process.env.REACT_APP_URL_API + "/all_plans", config)
      .then((response) => {
        if (response["data"]) {
          const data = response["data"];
          setDataPlans(data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    const queryParameters = window.location.search;
    switch (queryParameters) {
      case "?dashboard":
        setPanel(0);
        break;
      case "?users":
        setPanel(1);
        break;
      case "?affiliation":
        setPanel(2);
        break;
      default:
        break;
    }
  }, [endOffset, itemOffset, itemsPerPage]);

  return (
    <div className="container text-left gap-y-2.5">
      <h1 className="text-secondary pb-2.5 text-left">Administration</h1>
      <div className="flex flex-col gap-y-6">
        <AdminPanelButton panel={panel} setPanel={setPanel}></AdminPanelButton>
        <hr className="separator-product"></hr>
        {panel === 0 && dataPlans && (
          <>
            <h3 className="text-primary font-semibold text-lg">
              Statistiques globales
            </h3>
            <AdminDashboard {...propsAdminDashboard}></AdminDashboard>
          </>
        )}
        {panel === 1 && dataPlans && users && (
          <>
            <h3 className="text-primary font-semibold text-lg">
              Gestion des utilisateurs
            </h3>
            <AdminUsers {...propsAdminUsers}></AdminUsers>
          </>
        )}
        {panel === 2 && dataPlans && users && (
          <>
            <h3 className="text-primary font-semibold text-lg">
              Gestion de l'affiliation
            </h3>
            <AdminAffiliaters {...propsAdminAffiliaters}></AdminAffiliaters>
          </>
        )}
      </div>
    </div>
  );
}
