import React, { useState, useEffect } from "react";
import {
  Sidebar,
  Menu,
  MenuItem,
  useProSidebar,
  SubMenu,
} from "react-pro-sidebar";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { DashboardIcon } from "./icons/dashboard";
import { RewriteIcon } from "./icons/rewrite";
import { AccountIcon } from "./icons/account";
import { BlogIcon } from "./icons/blog";
import { FaqIcon } from "./icons/faq";
import { HistoryIcon } from "./icons/history";
import { MetasIcon } from "./icons/metas";
import { ParagraphIcon } from "./icons/paragraph";
import { ProductSheetIcon } from "./icons/product_sheet";
import { SocialIcon } from "./icons/social";
import { WebIcon } from "./icons/web";
import { DefinitionIcon } from "./icons/definition";
import { ExcelIcon } from "./icons/excel";
import { PressIcon } from "./icons/press";

import { ContentIcon } from "./icons/content";

import Pricing from "../components/users/component/pricing_table/pricingSidebar";
import { isTokenValid } from "../utils/tokenUtils";
import { disconnectUser } from "../utils/replaceUserSignIn";
import NewBadge from "./component/badge";

import Projects from "./Projects.jsx";
import MyAccount from "./MyAccount.jsx";

import "./Sidebar.css";
import logo from "../assets/logo.svg";
import logoSmall from "../assets/logo_small.png";
import { MarketingIcon } from "./icons/marketing.jsx";
import { VariousIcon } from "./icons/various.jsx";

const SideBar = ({
  backgroundColorProps,
  user,
  team,
  projects,
  selectedProject,
  setSelectedProject,
  setCurrentProject,
  currentPlan,
}) => {
  const { collapsed } = useProSidebar();
  const [activePage, setActivePage] = useState(null);
  const [showMyAccount, setShowMyAccount] = useState(false);
  const [loading, setLoading] = useState(true);
  const [collapsedSubMenu, setCollapsedSubMenu] = useState(1);
  const { t } = useTranslation();

  useEffect(() => {
    let token = localStorage.getItem("token");
    if (isTokenValid() && token) {
      let path = window.location.pathname;
      path = path.replaceAll("/", "_");
      let elemClick = document.getElementById(`link${path}`);
      if (elemClick) {
        elemClick.click();
        setLoading(true);
        return;
      }
    } else {
      disconnectUser();
      setLoading(true);
      return;
    }
    setLoading(false);
  }, [currentPlan, user]);

  const handleSubMenuClick = (id) => {
    if (collapsedSubMenu !== id) {
      setCollapsedSubMenu(id);
    }
  };

  function handleActive(event) {
    if (!event.target.closest("a").classList.value.includes("active")) {
      event.target.closest("a").classList.toggle("active");
      if (activePage) {
        activePage.classList.remove("active");
      }
      setActivePage(event.target.closest("a"));
    }
  }

  function handleMyAccount(event) {
    setShowMyAccount(!showMyAccount);
    handleActive(event);
  }

  // function handleAccount() {
  //   let path = window.location.pathname;
  //   if (path === "/users/account") {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  function handleMyAccountClose() {
    setShowMyAccount(false);
  }

  function handleStripe(event) {
    if (user.stripe_id === null) {
      // window.location = process.env.REACT_APP_URL + "/users/account";
    } else {
      const token = localStorage.getItem("token");
      if (!token) {
        window.location.replace(process.env.REACT_APP_URL + `/users/sign_in`);
      }
      const config = {
        headers: { Authorization: `${token}` },
      };
      let data = {
        user: {
          stripe_id: user.stripe_id,
        },
        lng: localStorage.getItem("i18nextLng"),
        modification: event,
      };

      axios
        .post(
          process.env.REACT_APP_URL_API + "/create-customer-portal-session",
          data,
          config
        )
        .then((response) => {
          // TODO
          // if (currentPlan.id_product === "-1"){
          window.location = response["data"].response;
          // }else{
          //     window.location = response["data"].response + '/subscriptions/'+currentPlan.id_product+'/update'
          // }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  function handleDashboard(e) {
    document.getElementById(`link_dashboard`).click();
  }

  backgroundColorProps.theme === "dark"
    ? (backgroundColorProps = "#474857")
    : (backgroundColorProps = "white");

  return (
    !loading && (
      <div
        style={{
          display: "flex",
          height: "100vh",
          position: "sticky",
          top: "0",
        }}
        className="sidebar"
      >
        <Sidebar
          className="sidebarClasses "
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            backgroundColor: backgroundColorProps,
            textAlign: "center",
            borderRightStyle: "none",
          }}
        >
          <span className="cursor-pointer" onClick={handleDashboard}>
            <img
              src={collapsed ? logoSmall : logo}
              alt="Logo"
              className={`side_bar_logo_${
                collapsed ? "not_collapsed" : "collapsed"
              }`}
            />
          </span>
          {collapsed ? (
            ""
          ) : (
            <Projects
              projects={projects}
              selectedProject={selectedProject}
              setSelectedProject={setSelectedProject}
              setCurrentProject={setCurrentProject}
            ></Projects>
          )}
          <div
            style={{
              overflowY: "scroll",
              flex: 1,
              overflowX: "hidden",
              height: "100%",
              maxHeight: "40rem",
            }}
          >
            <Menu
              iconShape="square"
              className="top-menu"
              style={{ flex: 1, textAlign: "left" }}
            >
              <MenuItem
                id="link_dashboard"
                className="mb-2"
                active={window.location.pathname.includes("dashboard")}
                icon={<DashboardIcon />}
                component={<Link onClick={handleActive} to="/dashboard" />}
              >
                {t("sidebar.dashboard")}
              </MenuItem>
              <SubMenu
                open={collapsedSubMenu === 1}
                icon={<ContentIcon />}
                label={t("sidebar.menu.content")}
                collapsed={collapsedSubMenu["subMenu1"]}
                onClick={() => handleSubMenuClick(1)}
              >
                <MenuItem
                  id="link_product_blog"
                  suffix={<NewBadge />}
                  icon={<BlogIcon />}
                  component={
                    <Link onClick={handleActive} to="/product/blog-premium" />
                  }
                >
                  {t("sidebar.blog")}
                </MenuItem>
                <MenuItem
                  id="link_product_sheet"
                  icon={<ProductSheetIcon />}
                  component={
                    <Link onClick={handleActive} to="/product/sheet" />
                  }
                >
                  {t("sidebar.sheet")}
                </MenuItem>
                <MenuItem
                  id="link_product_paragraph"
                  icon={<ParagraphIcon />}
                  component={
                    <Link onClick={handleActive} to="/product/paragraph" />
                  }
                >
                  {t("sidebar.paragraph")}
                </MenuItem>
                <MenuItem
                  id="link_product_faq"
                  icon={<FaqIcon />}
                  component={<Link onClick={handleActive} to="/product/faq" />}
                >
                  {t("sidebar.faq")}
                </MenuItem>
                <MenuItem
                  id="link_product_definition"
                  icon={<DefinitionIcon />}
                  component={
                    <Link onClick={handleActive} to="/product/definition" />
                  }
                >
                  {t("sidebar.definition")}
                </MenuItem>
                <MenuItem
                  id="link_product_rewrite"
                  icon={<RewriteIcon />}
                  component={
                    <Link onClick={handleActive} to="/product/rewrite" />
                  }
                >
                  {t("sidebar.rewrite")}
                </MenuItem>
                <MenuItem
                  id="link_product_web"
                  icon={<WebIcon />}
                  component={<Link onClick={handleActive} to="/product/web" />}
                >
                  {t("sidebar.web")}
                </MenuItem>
              </SubMenu>
              <SubMenu
                open={collapsedSubMenu === 2}
                icon={<MarketingIcon />}
                label={t("sidebar.menu.marketing")}
                collapsed={collapsedSubMenu["subMenu2"]}
                onClick={() => handleSubMenuClick(2)}
              >
                <MenuItem
                  id="link_product_social"
                  icon={<SocialIcon />}
                  component={
                    <Link onClick={handleActive} to="/product/social" />
                  }
                >
                  {t("sidebar.social")}
                </MenuItem>
                <MenuItem
                  id="link_product_press"
                  icon={<PressIcon />}
                  component={
                    <Link onClick={handleActive} to="/product/press" />
                  }
                >
                  {t("sidebar.press")}
                </MenuItem>
              </SubMenu>
              <SubMenu
                open={collapsedSubMenu === 3}
                icon={<VariousIcon />}
                label={t("sidebar.menu.various")}
                onClick={() => handleSubMenuClick(3)}
              >
                <MenuItem
                  id="link_product_metas"
                  icon={<MetasIcon />}
                  component={
                    <Link onClick={handleActive} to="/product/metas" />
                  }
                >
                  {t("sidebar.metas")}
                </MenuItem>
                <MenuItem
                  id="link_product_excel"
                  icon={<ExcelIcon />}
                  component={
                    <Link onClick={handleActive} to="/product/excel" />
                  }
                >
                  {t("sidebar.excel")}
                </MenuItem>
              </SubMenu>
              {user && user.role === "admin" && (
                <>
                  <SubMenu
                    open={collapsedSubMenu === 4}
                    label={"Admin"}
                    onClick={() => handleSubMenuClick(4)}
                  >
                    <MenuItem
                      id="link_product_blog_premium_admin"
                      component={
                        <Link
                          onClick={handleActive}
                          to="/product/blog-premium-admin"
                        />
                      }
                    >
                      Blog V2 gpt4.o
                    </MenuItem>
                  </SubMenu>
                </>
              )}
            </Menu>
          </div>
          <Menu iconShape="square" className="bottom-menu pt-5 mt-auto">
            {collapsed ? (
              ""
            ) : (
              <div className="button-payment-sidebar">
                {user && currentPlan && currentPlan.id_product !== "-1" && (
                  <div>
                    {currentPlan &&
                      (currentPlan.id_stripe ===
                        "price_1MdbZOIv4JgLR6izzk0WjMgQ" ||
                        currentPlan.id_stripe ===
                          "price_1N3DI0Iv4JgLR6izP5eiQWTt") && (
                        <div
                          onClick={() => handleStripe("billing")}
                          className="button-modal btn w-full rounded-custom-input cursor-pointer whitespace-nowrap text-sm"
                        >
                          <span>{t("sidebar.account.bills")}</span>
                        </div>
                      )}
                    {currentPlan &&
                      !(
                        currentPlan.id_stripe ===
                          "price_1MdbZOIv4JgLR6izzk0WjMgQ" ||
                        currentPlan.id_stripe ===
                          "price_1N3DI0Iv4JgLR6izP5eiQWTt"
                      ) && (
                        <div
                          onClick={() => handleStripe("upgrading")}
                          className="button-modal btn w-full rounded-custom-input cursor-pointer whitespace-nowrap text-sm"
                        >
                          {/* <svg
                            width="16"
                            height="17"
                            viewBox="0 0 16 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M14.2722 0.5C14.0702 0.503333 9.30023 0.609121 7.30698 3.53633L3.79736 9.79259C3.53904 10.2496 3.6813 10.8318 4.11961 11.1174L8.46058 13.9981C8.63091 14.1105 8.82646 14.1642 9.02045 14.1642C9.31911 14.1642 9.6151 14.0364 9.81209 13.7944L14.3653 8.22755C14.3716 8.22022 14.3775 8.21276 14.3829 8.20476C15.1135 7.13347 15.3383 5.85878 15.3333 4.6665C15.3247 2.62625 14.6343 0.828257 14.5879 0.710929C14.5369 0.582267 14.4049 0.512 14.2722 0.5ZM10.8309 4.16652C11.184 4.16577 11.5412 4.26649 11.8563 4.47835C12.6966 5.04333 12.9199 6.18222 12.355 7.02252C11.79 7.86282 10.6511 8.08618 9.81079 7.5212C8.97049 6.95622 8.74713 5.81733 9.31211 4.97703C9.66522 4.45184 10.2424 4.16777 10.8309 4.16652ZM4.88065 6.50301C3.15072 6.54634 1.7903 7.24327 0.733681 8.62988C0.657017 8.73321 0.647148 8.86614 0.700479 8.97947C0.757144 9.0928 0.873936 9.16631 1.0006 9.16631H1.58391C2.05055 9.16631 2.65371 9.21287 3.25702 9.39287L4.88065 6.50301ZM12.3934 11.6792C11.2734 13.0425 10.1933 14.3559 10.12 14.4259C10.5733 15.0825 10.8269 15.7627 10.9969 16.2227L11.0204 16.2826C11.0637 16.3959 11.1634 16.4794 11.2834 16.4961C11.3 16.4994 11.3168 16.4993 11.3335 16.4993C11.4368 16.4993 11.5371 16.4525 11.6004 16.3691C12.3471 15.3825 12.6668 14.4261 12.6668 13.1662C12.6668 12.6162 12.55 12.1125 12.3934 11.6792ZM4.14175 11.9305C3.42511 13.1105 2.87173 15.5815 3.92235 16.3021C4.11435 16.4334 4.34263 16.5 4.60462 16.5C4.71761 16.5 4.83703 16.4872 4.96202 16.4629C5.98898 16.2612 7.26454 15.2469 7.85318 14.3966L7.1618 13.937C6.5155 14.5613 5.53517 15.0932 4.95486 14.7045C4.37455 14.3132 4.4801 13.2065 4.80708 12.3726L4.14175 11.9305Z"
                              fill="white"
                            />
                          </svg> */}
                          <span>{t("sidebar.payment.update")}</span>
                        </div>
                      )}
                  </div>
                )}
                {user &&
                  (currentPlan === null || currentPlan.id_product === "-1") &&
                  user.role !== "member" && <Pricing user={user}></Pricing>}
              </div>
            )}
            <MenuItem
              id="link_history"
              icon={<HistoryIcon />}
              component={<Link onClick={handleActive} to="/history" />}
            >
              {t("sidebar.documents")}
            </MenuItem>
            {showMyAccount ? (
              <MyAccount
                handleClose={handleMyAccountClose}
                user={user}
                team={team}
                plan={currentPlan}
              ></MyAccount>
            ) : (
              ""
            )}
            <MenuItem
              id="link_users_account"
              icon={<AccountIcon />}
              component={<Link onClick={handleMyAccount} />}
            >
              {t("sidebar.account.title")}
            </MenuItem>
          </Menu>
        </Sidebar>
        {/* <div style={{ display: 'flex', alignItems: 'center', columnGap: '2rem', width: '0px' }}>
          <button id="sidebar-button-arrow" className='sidebar-button-arrow' onClick={() => collapseSidebar()}>
              <svg className={`side_bar_button_${collapsed ? 'not_collapsed' : 'collapsed'}`}  width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.8256 6.18602C10.9953 6.18602 11.1649 6.25071 11.2943 6.38008C11.5531 6.63884 11.5531 7.05852 11.2943 7.31754L9.11187 9.5L11.2943 11.6825C11.5531 11.9412 11.5531 12.3609 11.2943 12.6199C11.0356 12.8787 10.6159 12.8787 10.3569 12.6199L7.70568 9.96873C7.44692 9.70998 7.44692 9.29029 7.70568 9.03127L10.3569 6.38008C10.4862 6.25071 10.6559 6.18602 10.8256 6.18602Z" fill="#486497"/>
                  <path d="M9.5 19.0003C8.21789 19.0003 6.97368 18.7489 5.80186 18.2534C4.6706 17.7749 3.65466 17.0901 2.78242 16.2178C1.91018 15.3456 1.22538 14.3297 0.74684 13.1984C0.251333 12.0266 0 10.7824 0 9.5C0 7.83002 0.439567 6.18734 1.27124 4.7496C1.45444 4.43279 1.86007 4.32435 2.17689 4.50781C2.49371 4.69101 2.60214 5.09664 2.41868 5.41346C1.70339 6.64971 1.32559 8.06306 1.32559 9.50027C1.32559 11.6838 2.17583 13.7366 3.71988 15.2804C5.26393 16.8244 7.31675 17.6747 9.5 17.6747C11.6833 17.6747 13.7363 16.8244 15.2801 15.2804C16.8242 13.7363 17.6744 11.6835 17.6744 9.50027C17.6744 7.31701 16.8242 5.26393 15.2801 3.72015C13.7361 2.1761 11.6833 1.32586 9.5 1.32586C8.06279 1.32586 6.64944 1.70392 5.41319 2.41894C5.09638 2.60214 4.69075 2.49397 4.50755 2.17716C4.32435 1.86034 4.43252 1.45497 4.74934 1.27151C6.18734 0.439567 7.83002 0 9.5 0C10.7821 0 12.0263 0.251333 13.1981 0.74684C14.3294 1.22538 15.3453 1.91018 16.2176 2.78242C17.0898 3.65466 17.7746 4.6706 18.2532 5.80186C18.7487 6.97342 19 8.21762 19 9.5C19 10.7824 18.7487 12.0263 18.2532 13.1981C17.7746 14.3294 17.0898 15.3453 16.2176 16.2176C15.3453 17.0898 14.3294 17.7746 13.1981 18.2532C12.0266 18.7487 10.7824 19 9.5 19V19.0003Z" fill="#486497"/>
              </svg>
          </button>
        </div> */}
      </div>
    )
  );
};

export default SideBar;
