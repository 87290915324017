import React, { Component } from "react";
import i18n from "i18next";
import parse from "html-react-parser";

import Settings from "./component/Settings.jsx";
import {
  renderButtonHtml,
  initFieldbacksService,
  refreshPage,
  handleCommonSubmit,
  handleChange,
  headerProduct,
  btnCopy,
  canGenerate,
} from "./component/sharedFunction.js";

import screebotNormal from "./assets/zap.svg";
import screebotHuhu from "./assets/huhu.svg";
import iconeHeader from "../../assets/product/definition.svg";

import "./styles/product.css";
import "../../assets/Common.css";

class Definition extends Component {
  state = {
    title: "",

    output: "",
    product: "",
    click: false,
    buttonDisabled: true,
    returnApiSpan: "",
    copied: false,
    copied2: false,
    txtScreebot: "",
    imgScreebot: "",
    colorBackgroundScreebot: "",
    finished: "",
    id: "",
    project: "",
    language: "",
    problemTokenOrPlan: false,
    loading: "",

    key_word: "",
    term: "",

    fieldbacks: null,
  };

  constructor(props) {
    super(props);

    if (this.props.selectedProject) {
      if (!canGenerate(this.props.plan, this.props.user)) {
        this.state = {
          title: "",
          term: "",
          output: "",
          product: "definition",
          buttonDisabled: false,
          click: false,
          returnApiSpan: "",
          copied: false,
          copied2: false,
          txtScreebot: i18n.t("products.shared.choose"),
          imgScreebot: screebotHuhu,
          colorBackgroundScreebot: "#486497",
          finished: false,
          id: null,
          project: this.props.selectedProject.id,
          language: this.props.selectCountry,
          problemTokenOrPlan: true,
          loading: "",
          key_word: "",
        };
      } else {
        this.state = {
          title: "",
          term: "",
          output: "",
          product: "definition",
          buttonDisabled: true,
          click: false,
          returnApiSpan: "",
          copied: false,
          copied2: false,
          txtScreebot: i18n.t("products.shared.generate"),
          imgScreebot: screebotNormal,
          colorBackgroundScreebot: "#486497",
          finished: false,
          id: null,
          project: this.props.selectedProject.id,
          language: this.props.selectCountry,
          problemTokenOrPlan: false,
          loading: "",
          key_word: "",
        };
      }

      initFieldbacksService(
        this,
        this.state.product,
        this.props.selectedProject.id
      );
    }
  }

  render() {
    return (
      <div className="container flex flex-col gap-y-5">
        <div className="flex flex-col w-full gap-y-1.5">
          {headerProduct(iconeHeader, this.state.product)}

          <form
            className="flex flex-col gap-y-5 w-full"
            onSubmit={(e) =>
              handleCommonSubmit(
                e,
                this.state,
                this,
                this.state.id,
                this.props.user && this.props.user.token
              )
            }
          >
            <div className="flex flex-col gap-y-1.5 ctn-document">
              <div className="flex flex-row items-center gap-x-5">
                <input
                  disabled={this.state.click}
                  value={this.state.title}
                  type="text"
                  name="title"
                  onInput={(e) => handleChange(this, e)}
                  onChange={(e) => handleChange(this, e)}
                  placeholder={i18n.t("products.shared.place_holder_document")}
                />
                <Settings
                  setSelectCountry={this.props.setSelectCountry}
                  selectCountry={this.props.selectCountry}
                ></Settings>
              </div>
            </div>

            <hr className="separator-product my-5"></hr>

            <input
              value={this.state.type}
              className="hidden"
              type="text"
              name="title"
            />
            <h2 className="text-secondary text-left">
              {i18n.t("products.shared.parameter")}
            </h2>

            <div className="flex flex-col relative gap-y-1.5 w-full">
              <div className="flex flex-row justify-between w-full">
                <h4 id="term" className="flex flex-row text-primary obligatory">
                  {i18n.t("products.definition.term")}{" "}
                </h4>
                <p className="text-primary text-xs my-auto">
                  <span>0</span>
                  <span>/80</span>
                </p>
              </div>
              {renderButtonHtml(this, "term", () =>
                handleChange(
                  this,
                  {
                    target: {
                      value: this.state.term,
                      name: "term",
                    },
                  },
                  true
                )
              )}
              <input
                disabled={this.state.click}
                value={this.state.term}
                placeholder={i18n.t("products.definition.term_placeholder")}
                name="term"
                onInput={(e) => handleChange(this, e, true, 80)}
                onChange={(e) => handleChange(this, e, true, 80)}
              />
            </div>

            <div className="flex flex-col relative gap-y-1.5 w-full">
              <div className="flex flex-row justify-between w-full">
                <h4 id="key_word" className="flex flex-row text-primary">
                  {i18n.t("products.definition.keyword")}
                </h4>
                <p className="text-primary text-xs my-auto">
                  <span>0</span>
                  <span>/500</span>
                </p>
              </div>
              <input
                disabled={this.state.click}
                value={this.state.key_word}
                name="key_word"
                id="key_word"
                placeholder={i18n.t("products.definition.keyword_placeholder")}
                onInput={(e) => handleChange(this, e, true, 500)}
                onChange={(e) => handleChange(this, e, true, 500)}
              ></input>
            </div>

            <div className="flex flex-row gap-x-8">
              <div>
                <button
                  disabled={this.state.buttonDisabled}
                  id="generate-button"
                  className="button-product text-sm"
                  style={{ background: this.state.colorBackgroundScreebot }}
                >
                  <img
                    src={this.state.imgScreebot}
                    alt="logo product"
                    style={{ margin: "auto" }}
                  ></img>
                  <div>{this.state.txtScreebot}</div>
                </button>
              </div>
              {this.state.click && (
                <div>
                  <div
                    onClick={() => refreshPage(this)}
                    disabled={!this.state.buttonDisabled}
                    className="button-product bg-white text-secondary btn text-sm"
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_1855_5207)">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M3.83375 1.77436C2.66957 1.77436 1.72578 2.71816 1.72578 3.88233V12.1179C1.72578 13.2821 2.66957 14.2259 3.83375 14.2259H12.0693C13.2335 14.2259 14.1773 13.2821 14.1773 12.1179V8.00023C14.1773 7.6483 14.4626 7.36316 14.8143 7.36316C15.1661 7.36316 15.4514 7.64852 15.4514 8.00023V12.1179C15.4514 13.9859 13.9371 15.5002 12.0691 15.5002H3.83375C1.96572 15.5 0.451416 13.9857 0.451416 12.1177V3.88233C0.451416 2.0143 1.96572 0.5 3.83375 0.5H7.95142C8.30334 0.5 8.58848 0.785365 8.58848 1.13707C8.58848 1.48877 8.30312 1.77414 7.95142 1.77414H3.83375V1.77436Z"
                          fill="#486497"
                        />
                        <path
                          d="M12.8237 5.75138C11.3771 5.75138 10.2001 4.57439 10.2001 3.12778C10.2001 1.68116 11.3771 0.504395 12.8237 0.504395C14.2704 0.504395 15.4473 1.68139 15.4473 3.128C15.4473 4.57462 14.2704 5.75161 12.8237 5.75161V5.75138ZM12.8237 1.77853C12.0799 1.77853 11.4745 2.38389 11.4745 3.12778C11.4745 3.87166 12.0799 4.47702 12.8237 4.47702C13.5676 4.47702 14.173 3.87166 14.173 3.12778C14.173 2.38389 13.5676 1.77853 12.8237 1.77853Z"
                          fill="#486497"
                        />
                        <path
                          d="M7.23224 11.6355H4.20341C3.85149 11.6355 3.56635 11.3503 3.56635 10.9984C3.56635 10.6465 3.85171 10.3613 4.20341 10.3613H7.23224C7.58417 10.3613 7.86931 10.6465 7.86931 10.9984C7.86931 11.3503 7.58395 11.6355 7.23224 11.6355Z"
                          fill="#486497"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1855_5207">
                          <rect
                            width="15"
                            height="15"
                            fill="white"
                            transform="translate(0.451416 0.5)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                    {i18n.t("products.shared.new")}
                  </div>
                </div>
              )}
            </div>
          </form>
        </div>

        {this.state.click && (
          <div className="output-response">
            {!this.state.output && (
              <>
                <div className="ctn-loader">
                  <lottie-player
                    src="https://assets8.lottiefiles.com/packages/lf20_xv923ge8.json"
                    background="transparent"
                    speed="1"
                    style={{ width: "75px", height: "25px" }}
                    loop
                    autoplay
                  ></lottie-player>
                  <span
                    style={{
                      textAlign: "left",
                      marginTop: "auto",
                      marginBottom: "auto",
                    }}
                  >
                    {this.state.loading}
                  </span>
                </div>
              </>
            )}

            <span id="output-screebot" className="output-screebot">
              {parse(this.state.output.replace(/<br\s*\/?>/gi, ""))}
            </span>

            {this.state.output && (
              <div className="button-copy-list">
                {btnCopy(this, this.state.output, "copied", "html")}
                {btnCopy(
                  this,
                  document.getElementById("output-screebot").innerText,
                  "copied2",
                  "text"
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default Definition;
