import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import i18n from "i18next";
import {
  formatDate,
  anonymizeText,
  allBadges,
} from "../../../../components/product/component/sharedFunction.js";
import { Toast } from "primereact/toast";
import logo from "../../../../assets/logo.svg";

import "../../../../assets/Common.css";

function Member(props) {
  const {
    id,
    avatar,
    invitation_accepted_at,
    plan_created_at,
    comission_counter,
  } = props;
  const [data, setData] = useState("");

  const toast = useRef(null);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      window.location.replace(process.env.REACT_APP_URL + `/users/sign_in`);
    }
    const config = {
      headers: { Authorization: `${token}` },
    };

    axios
      .get(process.env.REACT_APP_URL_API + `/specific_client/${id}`, config)
      .then((response) => {
        if (response["data"]) {
          setData(response.data);
          // setpromptCountMonth(data.prompt_count_month)
          // setScreebotTokenUsed(data.screebot_token_used)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  // function resendInvitation(){
  //     const token = localStorage.getItem("token");
  //     if (!token) {
  //         window.location.replace(process.env.REACT_APP_URL +`/users/sign_in`);
  //     }
  //     const config = {
  //         headers: { Authorization: `${token}` }
  //     };
  //     let data = {
  //         id: id,
  //         language: localStorage.getItem("i18nextLng")
  //     };
  //     axios.post(process.env.REACT_APP_URL_API + `/api/v1/teams/user/resend_invitation/`, data, config)
  //     .then(response => {
  //         if (response["data"].status === "ok") {
  //             toast.current.show({severity:'success', summary: 'Invitation envoyé', detail:'Votre mail est bien parti', life: 3000});
  //         }else{
  //             toast.current.show({severity:'error', summary: 'Invitation non envoyé', detail:'Votre mail n\'est pas parti', life: 3000});
  //         }
  //     })
  //     .catch(error => {
  //         toast.current.show({severity:'error', summary: 'Invitation non envoyé', detail:'Votre mail n\'est pas parti', life: 3000});
  //         console.log(error);
  //     });
  // }

  return (
    <div className="w-full team-panel flex flex-col gap-y-1">
      <Toast ref={toast} />
      <div className="flex flex-row justify-between">
        <div className="flex flex-row gap-x-4">
          <div className="my-auto">
            {avatar && avatar !== null && (
              <img
                id="avatar_navbar"
                className="header-avatar border-gray-custom-input border"
                src={avatar && process.env.REACT_APP_URL_API + avatar}
                alt="avatar of the user"
              ></img>
            )}
            {avatar === null && (
              <img
                className="header-avatar border-gray-custom-input border"
                src={logo}
                alt="avatar of the user"
              />
            )}
          </div>
          <div className="flex flex-col gap-y-2">
            <span className="text-secondary text-lg font-semibold">
              {data &&
              (data.user.first_name === null ||
                data.user.first_name === undefined)
                ? "N/C"
                : data && data.user.first_name}{" "}
              {data &&
              (data.user.last_name === null ||
                data.user.last_name === undefined)
                ? "N/C"
                : data && anonymizeText(data.user.last_name, "full")}
            </span>
            <span className="text-secondary text-md font-normal">
              {data &&
                anonymizeText(data.user.email, "start_to_end").toString()}
            </span>
            <div className="flex flex-row gap-x-3">
              {allBadges(data.user, data.plan)}
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-y-1 text-right text-sm text-secondary">
          <span>
            inscription:{" "}
            {invitation_accepted_at === null
              ? "N/C"
              : formatDate(invitation_accepted_at, "with_slash")}
          </span>
          <span>
            souscription:{" "}
            {plan_created_at === null || plan_created_at === undefined
              ? "N/C"
              : formatDate(plan_created_at, "with_slash")}{" "}
          </span>
          <div>
            <span className="font-medium text-md">
              {comission_counter === null || Number.isNaN(comission_counter)
                ? "0"
                : comission_counter}
            </span>{" "}
            € de com. totales
          </div>
        </div>
      </div>
    </div>
  );
}

export default Member;
