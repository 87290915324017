function Page(props) {
  const { page, setPage } = props;

  return (
    <div className="flex flex-row gap-x-12">
      <p
        className={`font-medium text-base text-secondary cursor-pointer my-auto ${
          page !== 0 ? "opacity-60" : "cursor-not-allowed"
        }`}
        onClick={() => setPage(0)}
      >
        Tableau de bord
      </p>
      <p
        className={`font-medium text-base text-secondary cursor-pointer my-auto ${
          page !== 1 ? "opacity-60" : "cursor-not-allowed"
        }`}
        onClick={() => setPage(1)}
      >
        Liens et invitations
      </p>

      <p
        className={`font-medium text-base text-secondary cursor-pointer my-auto ${
          page !== 2 ? "opacity-60" : "cursor-not-allowed"
        }`}
        onClick={() => {
          setPage(2);
        }}
      >
        Comptabilité
      </p>
      <p
        className={`font-medium text-base text-secondary my-auto cursor-not-allowed ${
          page !== 3 ? "opacity-60" : "cursor-not-allowed"
        }`}
        // () => setPage(3)
        onClick={() => {}}
      >
        Guide
      </p>
    </div>
  );
}

export default Page;
