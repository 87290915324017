export default function NotFound() {
    return (
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '50%'}}>
            {/* <h1>Vous vous êtes perdu ?</h1> */}
            <span>Il n'y a rien ici ...</span>
            <svg style={{marginLeft: 'auto', marginRight: 'auto'}} width="146" height="116" viewBox="0 0 146 116" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M47.9915 30.7202C40.6818 31.0999 33.655 33.6674 27.8224 38.0896C21.9898 42.5118 17.6205 48.5845 15.2814 55.5201C11.0593 56.0576 7.17811 58.1161 4.36499 61.31C1.55187 64.5039 0 68.614 0 72.8701C0 77.1263 1.55187 81.2363 4.36499 84.4302C7.17811 87.6241 11.0593 89.6827 15.2814 90.2202C17.9423 98.0988 23.2084 104.836 30.2114 109.32C30.2114 109.32 35.6414 111.92 38.4514 113.25C42.1202 114.459 45.9586 115.073 49.8214 115.07H94.9814C98.8475 115.073 102.689 114.459 106.361 113.25L114.601 109.32C121.599 104.832 126.861 98.0961 129.521 90.2202C132.765 89.8046 135.827 88.4879 138.36 86.4197C140.893 84.3515 142.796 81.6146 143.852 78.5196C144.908 75.4246 145.075 72.0956 144.334 68.9105C143.593 65.7253 141.975 62.8116 139.661 60.5001C136.935 57.7474 133.365 55.9872 129.521 55.5001C127.183 48.5637 122.815 42.4903 116.982 38.0679C111.149 33.6456 104.121 31.0786 96.8114 30.7001H88.3915H56.3915L47.9915 30.7202ZM133.841 66.3301C134.885 67.3743 135.664 68.6531 136.113 70.0598C136.561 71.4664 136.666 72.96 136.42 74.4156C136.173 75.8713 135.582 77.2468 134.695 78.4271C133.808 79.6074 132.651 80.5582 131.321 81.2001C131.381 80.3601 131.381 65.3801 131.321 64.5302C132.255 64.9865 133.107 65.5948 133.841 66.3301ZM13.4915 81.2001C12.1619 80.5582 11.0051 79.6074 10.1182 78.4271C9.23118 77.2468 8.63964 75.8713 8.39294 74.4156C8.14625 72.96 8.25162 71.4664 8.7002 70.0598C9.14877 68.6531 9.92757 67.3743 10.9714 66.3301C11.7022 65.5903 12.5546 64.9814 13.4915 64.5302C13.4315 65.3801 13.4315 80.3601 13.4915 81.2001ZM94.9814 106.83H49.8214C42.3491 106.82 35.1861 103.846 29.9033 98.5612C24.6206 93.2765 21.6494 86.1124 21.6415 78.6401V67.1002C21.6494 59.6288 24.6209 52.4657 29.9039 47.1826C35.187 41.8996 42.35 38.9281 49.8214 38.9202H94.9814C102.454 38.9281 109.618 41.8992 114.902 47.182C120.187 52.4648 123.161 59.6279 123.171 67.1002V78.6401C123.161 86.1133 120.187 93.2774 114.903 98.5618C109.619 103.846 102.455 106.82 94.9814 106.83Z" fill="#645FEB"/>
                <path d="M80.0754 84.6468C82.7786 86.9413 89.3506 83.6405 94.7545 77.2742C100.158 70.9079 102.348 63.887 99.6447 61.5925C96.9416 59.2979 90.3695 62.5988 84.9656 68.965C79.5617 75.3313 77.3723 82.3523 80.0754 84.6468Z" fill="#00E1D4"/>
                <path d="M46.4464 84.6468C49.1496 86.9413 55.7216 83.6405 61.1255 77.2742C66.5294 70.9079 68.7188 63.887 66.0157 61.5925C63.3125 59.2979 56.7405 62.5988 51.3366 68.965C45.9327 75.3313 43.7433 82.3523 46.4464 84.6468Z" fill="#00E1D4"/>
                <path d="M79.1686 62.4282C76.6615 64.9354 79.4157 71.7545 85.3205 77.6593C91.2252 83.564 98.0444 86.3183 100.552 83.8111C103.059 81.3039 100.304 74.4848 94.3997 68.58C88.495 62.6753 81.6758 59.921 79.1686 62.4282Z" fill="#00E1D4"/>
                <path d="M45.5396 62.4282C43.0325 64.9354 45.7867 71.7545 51.6914 77.6593C57.5962 83.564 64.4154 86.3183 66.9225 83.8111C69.4297 81.3039 66.6754 74.4848 60.7707 68.58C54.866 62.6753 48.0468 59.921 45.5396 62.4282Z" fill="#00E1D4"/>
                <path d="M142.479 16.9657C142.704 17.2167 142.879 17.5089 142.994 17.8261C143.147 18.2712 143.168 18.7514 143.053 19.208C142.938 19.6646 142.692 20.0777 142.346 20.3967L131.95 29.9475C131.717 30.1616 131.443 30.3276 131.145 30.436C130.847 30.5444 130.531 30.5931 130.214 30.5793C129.897 30.5654 129.586 30.4893 129.299 30.3553C129.012 30.2213 128.754 30.032 128.539 29.7983C128.325 29.5645 128.159 29.2908 128.051 28.9929C127.942 28.695 127.894 28.3786 127.908 28.0619C127.921 27.7451 127.998 27.4343 128.132 27.1469C128.266 26.8596 128.455 26.6016 128.689 26.3874L139.062 16.8366C139.533 16.4035 140.157 16.1741 140.797 16.1982C141.437 16.2224 142.041 16.4982 142.479 16.9657Z" fill="#00E1D4"/>
                <path d="M127.511 1.38971C128.245 1.76602 128.801 2.4179 129.056 3.2027C129.311 3.9875 129.244 4.84131 128.871 5.57727L120.631 21.7486C120.22 22.4091 119.576 22.8913 118.827 23.1004C118.078 23.3094 117.277 23.23 116.583 22.8777C115.89 22.5255 115.353 21.926 115.08 21.1977C114.806 20.4693 114.816 19.6649 115.106 18.9432L123.337 2.77637C123.709 2.04088 124.356 1.48226 125.138 1.22247C125.92 0.962674 126.773 1.02279 127.511 1.38971Z" fill="#00E1D4"/>
                <path d="M108.065 6.90648C108.134 7.10607 108.169 7.31627 108.166 7.52762L107.939 17.6092C107.863 18.0149 107.645 18.3801 107.323 18.6387C107.001 18.8974 106.598 19.0324 106.185 19.0196C105.772 19.0067 105.378 18.8467 105.073 18.5685C104.768 18.2903 104.572 17.9122 104.522 17.5025L104.762 7.42531C104.764 7.19828 104.811 6.97382 104.901 6.7653C104.991 6.55678 105.122 6.36839 105.285 6.21118C105.449 6.05398 105.643 5.93118 105.855 5.85003C106.067 5.76887 106.293 5.73099 106.52 5.73866C106.865 5.7557 107.197 5.87623 107.472 6.08452C107.748 6.29281 107.954 6.57922 108.065 6.90648Z" fill="#00E1D4"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M83.5392 104.029C74.7636 97.7104 65.8564 101.374 62.5408 103.969C61.4534 104.82 59.8822 104.628 59.0312 103.541C58.1803 102.453 58.3719 100.882 59.4592 100.031C63.8102 96.6261 75.2363 91.8896 86.4608 99.9712C87.5812 100.778 87.8356 102.34 87.0288 103.461C86.2221 104.581 84.6597 104.836 83.5392 104.029Z" fill="#00E1D4"/>
            </svg>
        </div>
    )
}